import React, { Fragment, useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import withAuth from "../../../hoc/withAuth";
import Layout from "../../../layouts";
import NumberFormat from "react-number-format";
import { useGetBehaviour } from "../../../store/actions/analytic";
import { Chart } from "react-chartjs-2";
import "chart.js/auto";
import { Button, Form, Modal, Table } from "react-bootstrap";
import moment from "moment";

const BehaviourAnalytic = (props) => {
  const { t } = props;
  const pageTitle = t("behaviour");
  // ChatGroup
  const [dataChatGroup, setDataChatGroup] = useState([]);
  const [chatGroupLabels, setChatGroupLabels] = useState(null);
  const [chatGroupValue, setChatGroupValue] = useState(null);
  // Celluler
  const [dataCelluler, setDataCelluler] = useState([]);
  const [cellulerLabels, setCellulerLabels] = useState(null);
  const [cellulerValue, setCellulerValue] = useState(null);
  // external link
  const [dataExternalLink, setExternalLink] = useState([]);
  const [externalLinkLabels, setExternalLinkLabels] = useState(null);
  const [externalLinkValue, setExternalLinkValue] = useState(null);
  // news link
  const [dataNews, setDataNews] = useState([]);
  const [newsLabels, setNewsLabels] = useState(null);
  const [newsValues, setNewsValues] = useState(null);
  // sosmed link
  const [dataSosmed, setDataSosmed] = useState([]);
  const [sosmedLabels, setSosmedLabels] = useState(null);
  const [sosmedValues, setSosmedValues] = useState(null);
  // modal link show
  const [dataShow, setDataShow] = useState([]);
  const [showLabels, setShowLabels] = useState(null);
  const [showValues, setShowValues] = useState(null);
  const [showCharts, setShowCharts] = useState(null);
  // tanggal
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  //  get interest
  const [getBehaviour, { data: dataBehaviour, loading: loadingDataBehaviour }] =
    useGetBehaviour();

  const handleDate = () => {
    // console.log(new Date())
    loadDataBehaviour(startDate, finishDate);
    // console.log(startDate,finishDate)
  };

  const loadDataBehaviour = async (startDate, finishDate) => {
    await getBehaviour(startDate, finishDate);
  };

  useEffect(() => {
    const today = new Date().toISOString().slice(0, 10);
    loadDataBehaviour("",today);
  }, []);

  //   data chatgroup
  useEffect(() => {
    if (dataBehaviour && dataBehaviour.total_user) {
      const resultChatGroup = dataBehaviour.chatgroup;
      const dataChatGroup = Object.keys(resultChatGroup).map((key) => ({
        data: key,
        total: resultChatGroup[key],
      }));
      const mapData = dataChatGroup.map((item) => {
        return {
          ...item,
          data:
            item.data === "pengguna_chat"
              ? "Pengguna Chat"
              : item.data === "non_pengguna_chat"
              ? "Non Penggunaan Chat"
              : item.data === "pengguna_group"
              ? "Pengguna Group"
              : "Non Pengguna Group",
        };
      });
      const chatGroupLabels = mapData.map(({ data }) => data);
      const chatGroupTotal = dataChatGroup.map(({ total }) => total);
      setChatGroupLabels(chatGroupLabels);
      setChatGroupValue(chatGroupTotal);
      setDataChatGroup(dataChatGroup);
    }
  }, [dataBehaviour]);

  //   data cellular
  useEffect(() => {
    if (dataBehaviour && dataBehaviour.total_user) {
      const resultCelluler = dataBehaviour.celluler;
      const dataCelluler = Object.keys(resultCelluler).map((key) => ({
        data: key,
        total: resultCelluler[key],
      }));
      const mapData = dataChatGroup.map((item) => {
        return {
          ...item,
          data:
            item.data === "pengguna_chat"
              ? "Pengguna Chat"
              : item.data === "non_pengguna_chat"
              ? "Non Penggunaan Chat"
              : item.data === "pengguna_group"
              ? "Pengguna Group"
              : "Non Pengguna Group",
        };
      });
      const cellulerLabels = dataCelluler.map(({ data }) => data);
      const cellulerTotal = dataCelluler.map(({ total }) => total);
      setCellulerLabels(cellulerLabels);
      setCellulerValue(cellulerTotal);
      setDataCelluler(dataCelluler);
    }
  }, [dataBehaviour]);

  // external link
  useEffect(() => {
    if (dataBehaviour && dataBehaviour.total_user) {
      const resultExternalLink = Object.values(dataBehaviour.external_link);
      const mapDataLink = resultExternalLink.map((item) => {
        return {
          ...item,
          link_type:
            item.link_type.charAt(0).toUpperCase() + item.link_type.slice(1),
        };
      });
      const linkLabels = mapDataLink.map(({ link_type }) => link_type);
      const linkTotal = mapDataLink.map(({ total }) => total);
      setExternalLinkLabels(linkLabels);
      setExternalLinkValue(linkTotal);
      setExternalLink(mapDataLink);

      const resultNews = Object.values(resultExternalLink[0].detail);
      const mapDataNews = resultNews.map((item) => {
        return {
          ...item,
          link: item.link.charAt(0).toUpperCase() + item.link.slice(1),
        };
      });
      const newsLabels = mapDataNews.map(({ link }) => link);
      const newsTotal = mapDataNews.map(({ total }) => total);
      setDataNews(mapDataNews);
      setNewsLabels(newsLabels);
      setNewsValues(newsTotal);

      const resultSosmed = Object.values(resultExternalLink[1].detail);
      const mapDataSosmed = resultSosmed.map((item) => {
        return {
          ...item,
          link: item.link.charAt(0).toUpperCase() + item.link.slice(1),
        };
      });
      const sosmedLabels = mapDataSosmed.map(({ link }) => link);
      const sosmedTotal = mapDataSosmed.map(({ total }) => total);
      setDataSosmed(mapDataSosmed);
      setSosmedLabels(sosmedLabels);
      setSosmedValues(sosmedTotal);
    }
  }, [dataBehaviour]);

  const colorIndex = [
    "#A52A2A",
    "#DEB887",
    "#5F9EA0",
    "#7FFF00",
    "#D2691E",
    "#6495ED",
    "#DC143C",
  ];

  const dataChartChatGroup = {
    labels: chatGroupLabels,
    datasets: [
      {
        label: "Chat & Group",
        data: chatGroupValue,
        backgroundColor: colorIndex,
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const dataChartCelluler = {
    labels: cellulerLabels,
    datasets: [
      {
        label: "Provider",
        data: cellulerValue,
        backgroundColor: colorIndex,
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const dataChartLink = {
    labels: externalLinkLabels,
    datasets: [
      {
        label: "Repost Link",
        data: externalLinkValue,
        backgroundColor: colorIndex,
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const dataChartNews = {
    labels: newsLabels,
    datasets: [
      {
        label: "Repost Link Berita",
        data: newsValues,
        backgroundColor: colorIndex,
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const dataChartSosmed = {
    labels: sosmedLabels,
    datasets: [
      {
        label: "Repost Link Media Sosial",
        data: sosmedValues,
        backgroundColor: colorIndex,
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  //   config for bar charts
  const barChartsConfig = {
    plugins: {
      legend: {
        labels: {
          boxWidth: 0,
        },
      },
    },
  };

  //   Modal detail
  const [modalTitle, setModalTitle] = useState("");
  const [showing, setshowing] = useState(false);

  const modalShow = (data) => {
    if (data === "News") {
      setshowing(!showing);
      setModalTitle(data);
      setDataShow(dataNews);
      setShowLabels(newsLabels);
      setShowValues(newsValues);
      setShowCharts(dataChartNews);
    } else {
      setshowing(!showing);
      setModalTitle(data);
      setDataShow(dataSosmed);
      setShowLabels(sosmedLabels);
      setShowValues(sosmedValues);
      setShowCharts(dataChartSosmed);
    }
  };
  const modalClose = () => {
    setshowing(!showing);
    setDataShow([]);
    setShowLabels(null);
    setShowValues(null);
  };

  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Form>
        <div className="row g-3 align-items-center mb-5">
          <div className="col-auto">
            <Form.Group>
              <label>Tanggal Mulai</label>
              <input
                type="date"
                id="inputStart"
                className="form-control w-100"
                placeholder="Tanggal Mulai"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-auto">
            <label for="inputPassword6" className="col-form-label">
              -
            </label>
          </div>
          <div className="col-auto">
            <Form.Group>
              <label>Tanggal Berakhir</label>
              <input
                type="date"
                id="inputFinish"
                className="form-control w-100"
                placeholder="Tanggal Berakhir"
                value={finishDate}
                onChange={(e) => setFinishDate(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="">
            <Button onClick={handleDate}>Submit</Button>
          </div>
        </div>
      </Form>
      <div className="row">
        {/* chatgroup */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  {/* <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div> */}
                </div>
                <div className="text-center">
                  <h6 className="text-muted font-semibold">Chat & Group</h6>
                  {loadingDataBehaviour ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <Chart
                type="doughnut"
                data={dataChartChatGroup}
                options={barChartsConfig}
              />
              <div>
                {!loadingDataBehaviour ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Hoby</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataChatGroup.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.data}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
        {/* celluler */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  {/* <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div> */}
                </div>
                <div className="text-center">
                  <h6 className="text-muted font-semibold ">Provider</h6>
                  {loadingDataBehaviour ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <Chart
                type="bar"
                data={dataChartCelluler}
                options={barChartsConfig}
              />
              <div>
                {!loadingDataBehaviour ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Provider</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataCelluler.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.data}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
        {/* external link */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  {/* <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div> */}
                </div>
                <div className="text-center">
                  <h6 className="text-muted font-semibold">External Link</h6>
                  {loadingDataBehaviour ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <Chart
                type="doughnut"
                data={dataChartLink}
                options={barChartsConfig}
              />
              <div>
                {!loadingDataBehaviour ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>External Link</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataExternalLink.map((data, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => modalShow(data.link_type)}
                            >
                              <td>{index + 1}</td>
                              <td>{data.link_type}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* Modal show */}
      <Modal show={showing} onHide={modalClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "70vh", overflowY: "auto" }}>
          <Chart type="bar" data={showCharts} options={barChartsConfig} />
          <div>
            {!loadingDataBehaviour ? (
              <>
                <Table
                  striped
                  bordered
                  hover
                  responsive
                  className="text-center mt-3"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{modalTitle}</th>
                      <th>{t("total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataShow.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.link}</td>
                          <td>{data.total}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={modalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default withAuth(translate(BehaviourAnalytic))([]);
