export const MENUITEMS = [
  {
    menuTitle: "dashboard",
    url: "/",
    icon: "bi bi-grid-fill",
    access: [],
  },
  {
    menuTitle: "posts",
    url: "/posts",
    icon: "bi bi-file-earmark-check-fill",
    access: ["admin"],
  },
  // {
  //   menuTitle: "point",
  //   url: "/point",
  //   icon: "bi bi-coin",
  //   access: [],
  // },
  // {
  //   menuTitle: "notification",
  //   url: "/notification",
  //   icon: "bi bi-bell-fill",
  //   access: [],
  // },
  {
    menuTitle: "pop_reward",
    url: "/rewards",
    icon: "bi bi-gift-fill",
    items: [
      {
        menuTitle: "rewards",
        url: "/rewards",
        access: ["admin"],
      },
      {
        menuTitle: "category",
        url: "/reward-category",
        access: ["admin"],
      },
      {
        menuTitle: "validation",
        url: "/reward-validation",
        access: ["admin"],
      },
      {
        menuTitle: "history",
        url: "/reward-history",
        access: ["admin"],
      },
    ],
  },
  {
    menuTitle: "utility",
    url: "#",
    icon: "bi bi-key-fill",
    items: [
      {
        menuTitle: "users",
        url: "/users",
        access: ["admin"],
      },
      {
        menuTitle: "topic",
        url: "/topics",
        access: ["admin"],
      },
      {
        menuTitle: "postType",
        url: "/post-type",
        access: ["admin"],
      },
      {
        menuTitle: "redaction",
        url: "/redaction",
        access: ["admin"],
      },
      {
        menuTitle: "email",
        url: "/settings/email",
        access: ["admin"],
      },
      {
        menuTitle: "konten_kreator",
        url: "/contentcreator",
        access: ["admin"],
      },
      {
        menuTitle: "report",
        url: "/laporan",
        access: ["admin"],
      },
      {
        menuTitle: "advertisment",
        url: "/ads",
        access: ["admin"],
      },
      {
        menuTitle: "addpoint",
        url: "/addpoint",
        access: ["admin"],
      },
    ],
  },
  // {
  //   menuTitle: "analytic",
  //   url: "/analytic",
  //   icon: "bi bi-grid-fill",
  //   access: [],
  // },
  {
    menuTitle: "analytic",
    url: "#",
    icon: "",
    items: [
      {
        menuTitle: "background",
        url: "/background",
        access: ["admin"],
      },
      {
        menuTitle: "interest",
        url: "/interests",
        access: ["admin"],
      },
      {
        menuTitle: "behaviour",
        url: "/behaviour",
        access: ["admin"],
      },
      {
        menuTitle: "advanced_analytic",
        url: "/advanced-analytic",
        access: ["admin"],
      }
    ],
  },
];
