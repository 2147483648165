export const isAuthorized = (user, role) => {
  if (user && user.role && user.role.name === "admin") {
    return true;
  }
  return user && user.role && user.role.name === role;
};

export const isAuthorizedArray = (user, role) => {
  if (typeof role === "string" && !isAuthorized(user, role)) {
    return false;
  } else if (Array.isArray(role)) {
    if (role.length <= 0) {
      return true;
    }
    let isAutho = 0;
    role.map((item) => {
      if (isAuthorized(user, item)) {
        isAutho = isAutho + 1;
      }
    });
    if (isAutho === 0) {
      return false;
    }
  }
  return true;
};
