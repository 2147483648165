import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { translate } from "react-switch-lang";
const tabNav = (props) => {
  const { t, tab } = props;
  return (
    <Fragment>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <Link
            className={`nav-link ${tab === "general" ? "active" : ""}`}
            to={`${process.env.PUBLIC_URL}/settings`}
            aria-selected="true"
          >
            {t("general")}
          </Link>
        </li>

        <li className="nav-item" role="presentation">
          <Link
            className={`nav-link ${tab === "email" ? "active" : ""}`}
            to={`${process.env.PUBLIC_URL}/settings/email`}
            aria-selected="true"
          >
            {t("email")}
          </Link>
        </li>
      </ul>
    </Fragment>
  );
};

export default translate(tabNav);
