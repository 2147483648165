export const dataIndonesia = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [137.916656, -1.49852],
              [137.9338, -1.49744],
              [138.0597, -1.59959],
              [138.1827, -1.63461],
              [138.2778, -1.68744],
              [138.4207, -1.7405],
              [138.6188, -1.77941],
              [138.6698, -1.79784],
              [138.7496, -1.86845],
              [138.7888, -1.91927],
              [138.865, -1.96898],
              [138.8976, -1.95781],
              [138.9963, -1.97618],
              [139.0299, -2.00013],
              [139.1262, -2.03612],
              [139.2746, -2.12263],
              [139.366, -2.14902],
              [139.4327, -2.18193],
              [139.5288, -2.24622],
              [139.6417, -2.29143],
              [139.7026, -2.33298],
              [139.8123, -2.37845],
              [139.9037, -2.37683],
              [140.0552, -2.36226],
              [140.084, -2.33063],
              [140.1469, -2.3549],
              [140.2184, -2.41447],
              [140.2503, -2.40516],
              [140.2968, -2.44369],
              [140.3564, -2.45473],
              [140.3693, -2.39869],
              [140.4978, -2.4425],
              [140.6079, -2.44113],
              [140.7242, -2.49758],
              [140.7159, -2.55721],
              [140.6849, -2.59298],
              [140.6927, -2.62533],
              [140.768, -2.62793],
              [140.7768, -2.60213],
              [140.9112, -2.62327],
              [141, -2.60031],
              [141, -3.16673],
              [141, -3.83344],
              [141, -4.33331],
              [141, -5.00033],
              [141, -5.5833],
              [140.9997, -6.31582],
              [140.9702, -6.31724],
              [140.9489, -6.36466],
              [140.9563, -6.41372],
              [140.9277, -6.45862],
              [140.9305, -6.52812],
              [140.9095, -6.57817],
              [140.8735, -6.61222],
              [140.8552, -6.71917],
              [140.9008, -6.75162],
              [140.8732, -6.78795],
              [140.9167, -6.82109],
              [140.914, -6.86447],
              [141.0006, -6.89019],
              [141.000333, -7.526043],
              [140.99949, -8.245275],
              [141.0005, -8.75063],
              [141.0009, -9.13392],
              [140.8783, -9.09482],
              [140.8244, -9.04792],
              [140.7803, -8.98197],
              [140.6128, -8.83404],
              [140.5231, -8.7087],
              [140.4924, -8.63307],
              [140.3741, -8.53262],
              [140.3442, -8.48087],
              [140.2959, -8.46132],
              [140.1759, -8.35554],
              [140.041, -8.2623],
              [139.9751, -8.2021],
              [139.9441, -8.14077],
              [139.9079, -8.11628],
              [139.8222, -8.10667],
              [139.7044, -8.10821],
              [139.5818, -8.13468],
              [139.5058, -8.18444],
              [139.346757, -8.196656],
              [139.283978, -8.169614],
              [139.226548, -8.086309],
              [139.068488, -8.132796],
              [138.985422, -8.194328],
              [138.936066, -8.267034],
              [138.876314, -8.202662],
              [138.840685, -8.13892],
              [138.858954, -8.083077],
              [138.904071, -8.079918],
              [138.928799, -8.049947],
              [138.904474, -8.019299],
              [138.9244, -7.90775],
              [139.0156, -7.86162],
              [139.0163, -7.7606],
              [139.0574, -7.69036],
              [139.0709, -7.63131],
              [139.125, -7.58559],
              [139.0973, -7.53778],
              [139.0341, -7.51555],
              [138.9741, -7.52399],
              [138.9458, -7.48102],
              [138.9054, -7.36368],
              [138.8549, -7.31258],
              [138.7688, -7.2617],
              [138.6893, -7.23343],
              [138.683, -7.19875],
              [138.7536, -7.20998],
              [138.9087, -7.19978],
              [139.0432, -7.24438],
              [139.1857, -7.23549],
              [139.2139, -7.20185],
              [139.2531, -7.20698],
              [139.2831, -7.23132],
              [139.3069, -7.21929],
              [139.3517, -7.23871],
              [139.4344, -7.2296],
              [139.4269, -7.19398],
              [139.3871, -7.20388],
              [139.3576, -7.16434],
              [139.3869, -7.113],
              [139.3461, -7.04385],
              [139.3192, -7.04893],
              [139.2391, -7.01381],
              [139.2257, -6.98176],
              [139.1449, -6.95998],
              [139.0287, -6.87144],
              [138.9573, -6.83117],
              [138.9208, -6.7831],
              [138.8748, -6.80003],
              [138.8271, -6.77829],
              [138.8243, -6.70032],
              [138.7972, -6.67235],
              [138.6918, -6.61436],
              [138.5317, -6.44945],
              [138.4256, -6.35529],
              [138.3854, -6.21394],
              [138.3985, -6.17884],
              [138.3501, -6.09068],
              [138.284, -5.94058],
              [138.2614, -5.92456],
              [138.2653, -5.86077],
              [138.3548, -5.85019],
              [138.4181, -5.81562],
              [138.443, -5.76262],
              [138.4671, -5.77078],
              [138.48, -5.75775],
              [138.4799, -5.72145],
              [138.5064, -5.69941],
              [138.5181, -5.73572],
              [138.5605, -5.77195],
              [138.568, -5.77554],
              [138.5766, -5.77314],
              [138.593, -5.76148],
              [138.6229, -5.75944],
              [138.6338, -5.74545],
              [138.622, -5.71998],
              [138.6449, -5.68379],
              [138.6973, -5.65714],
              [138.7479, -5.65189],
              [138.7629, -5.70385],
              [138.8384, -5.71053],
              [138.8826, -5.66336],
              [138.9183, -5.65514],
              [138.9459, -5.6683],
              [138.9617, -5.68314],
              [138.9794, -5.68688],
              [138.9754, -5.71233],
              [139.0123, -5.76509],
              [138.9888, -5.81715],
              [139.013715, -5.859159],
              [139.070352, -5.88946],
              [139.0201, -5.86076],
              [138.9926, -5.82057],
              [139.0113, -5.78817],
              [139.0136, -5.75521],
              [138.9974, -5.72506],
              [138.9771, -5.71077],
              [138.9829, -5.6941],
              [138.9785, -5.68419],
              [138.9613, -5.68153],
              [138.9464, -5.66583],
              [138.9256, -5.65177],
              [138.8788, -5.66068],
              [138.8392, -5.70817],
              [138.7664, -5.70182],
              [138.7616, -5.66117],
              [138.7449, -5.64898],
              [138.6908, -5.6559],
              [138.6457, -5.67896],
              [138.6201, -5.71144],
              [138.6187, -5.72768],
              [138.6297, -5.74731],
              [138.593, -5.75775],
              [138.569, -5.77272],
              [138.5529, -5.76087],
              [138.5446, -5.75012],
              [138.5229, -5.73456],
              [138.5114, -5.69956],
              [138.4969, -5.69527],
              [138.4724, -5.73203],
              [138.4761, -5.76041],
              [138.4574, -5.76582],
              [138.4267, -5.72576],
              [138.4989, -5.66342],
              [138.5656, -5.65508],
              [138.5845, -5.65826],
              [138.6267, -5.64922],
              [138.6627, -5.61365],
              [138.6126, -5.60959],
              [138.4873, -5.58644],
              [138.4267, -5.65199],
              [138.4, -5.66175],
              [138.2525, -5.6672],
              [138.2436, -5.61448],
              [138.2632, -5.56432],
              [138.2594, -5.51926],
              [138.252, -5.47932],
              [138.2432, -5.44919],
              [138.2185, -5.45503],
              [138.1943, -5.47578],
              [138.1874, -5.44433],
              [138.1622, -5.44145],
              [138.1338, -5.48056],
              [138.127, -5.5322],
              [138.0891, -5.53403],
              [138.0523, -5.50182],
              [138.0666, -5.45108],
              [138.0333, -5.44286],
              [137.9906, -5.39269],
              [138.0425, -5.37611],
              [138.0535, -5.36816],
              [138.0599, -5.32994],
              [138.0847, -5.28419],
              [138.148, -5.27291],
              [138.1523, -5.24915],
              [138.1409, -5.20372],
              [138.1705, -5.19068],
              [138.18, -5.18583],
              [138.1875, -5.17518],
              [138.1863, -5.15047],
              [138.1586, -5.14159],
              [138.0962, -5.14795],
              [138.0842, -5.1847],
              [138.041, -5.19446],
              [138.0573, -5.2366],
              [137.8811, -5.32375],
              [137.8771, -5.30778],
              [137.8675, -5.29702],
              [137.8503, -5.28475],
              [137.74, -5.35667],
              [137.7526, -5.31325],
              [137.68, -5.23707],
              [137.5483, -5.17053],
              [137.6148, -5.14202],
              [137.603212, -5.081332],
              [137.623871, -5.027635],
              [137.624661, -5.026139],
              [137.637131, -5.002526],
              [137.707184, -4.882552],
              [137.754623, -4.787083],
              [137.802963, -4.649354],
              [137.812637, -4.593312],
              [137.741455, -4.483698],
              [137.726715, -4.397165],
              [137.754501, -4.287362],
              [137.791611, -4.178586],
              [137.649048, -4.171586],
              [137.571579, -4.179802],
              [137.454086, -4.172867],
              [137.360977, -4.177821],
              [136.984039, -4.168376],
              [137.000381, -4.041937],
              [137.046936, -3.865869],
              [137.064789, -3.699162],
              [137.084717, -3.585353],
              [137.152542, -3.328905],
              [137.165848, -3.175012],
              [137.219757, -3.176061],
              [137.283081, -3.153617],
              [137.351212, -3.084761],
              [137.42363, -3.054462],
              [137.526779, -3.056891],
              [137.568817, -3.016699],
              [137.620621, -3.017284],
              [137.676544, -3.04416],
              [137.785339, -3.060651],
              [137.90802, -3.043166],
              [138.234619, -3.023306],
              [138.299744, -2.95566],
              [138.421799, -2.927594],
              [138.456223, -2.865694],
              [138.441696, -2.768596],
              [138.341919, -2.605156],
              [138.205627, -2.481592],
              [138.174255, -2.418834],
              [138.119553, -2.373802],
              [138.080307, -2.321883],
              [138.026489, -2.316186],
              [137.955231, -2.230669],
              [137.938522, -2.177074],
              [137.865494, -2.115197],
              [137.838348, -2.073237],
              [137.854721, -1.960676],
              [137.90004, -1.865164],
              [137.878708, -1.76878],
              [137.890228, -1.725458],
              [137.872772, -1.667199],
              [137.916656, -1.49852],
            ],
            [
              [140.610987, -2.610271],
              [140.5405, -2.57942],
              [140.4912, -2.62786],
              [140.4424, -2.60462],
              [140.4183, -2.55935],
              [140.3849, -2.59911],
              [140.4899, -2.63687],
              [140.5453, -2.63235],
              [140.5854, -2.64992],
              [140.610987, -2.610271],
            ],
            [
              [140.939407, -6.976415],
              [140.897568, -6.925714],
              [140.80719, -6.901972],
              [140.74733, -6.940971],
              [140.726196, -6.976496],
              [140.760834, -7.005919],
              [140.739182, -7.046083],
              [140.805313, -7.051597],
              [140.832123, -6.988703],
              [140.86763, -7.05746],
              [140.817383, -7.104256],
              [140.816406, -7.171035],
              [140.833389, -7.191659],
              [140.888214, -7.169472],
              [140.895203, -7.139591],
              [140.954468, -7.068027],
              [140.939407, -6.976415],
            ],
          ],
          [
            [
              [138.563931, -8.27698],
              [138.485002, -8.320071],
              [138.467642, -8.347486],
              [138.295554, -8.391337],
              [138.052107, -8.391454],
              [137.8795, -8.38451],
              [137.6925, -8.42095],
              [137.6471, -8.39707],
              [137.7012, -8.22148],
              [137.7497, -8.11782],
              [137.789, -8.05765],
              [137.8104, -7.98792],
              [137.8624, -7.87504],
              [137.9154, -7.78564],
              [137.9692, -7.73395],
              [137.978, -7.70249],
              [138.0513, -7.60636],
              [138.1508, -7.52242],
              [138.2327, -7.47275],
              [138.3807, -7.41705],
              [138.6686, -7.36957],
              [138.7692, -7.37539],
              [138.8683, -7.4363],
              [138.9275, -7.53907],
              [138.9573, -7.55328],
              [139.0821, -7.56891],
              [139.0464, -7.62207],
              [139.0353, -7.68676],
              [139.0033, -7.72943],
              [138.9908, -7.77959],
              [139.0007, -7.83147],
              [138.9737, -7.86629],
              [138.9239, -7.8817],
              [138.8911, -7.92054],
              [138.9023, -7.98959],
              [138.891252, -8.032517],
              [138.824234, -8.073695],
              [138.79567, -8.133433],
              [138.676268, -8.146131],
              [138.653457, -8.201856],
              [138.563931, -8.27698],
            ],
          ],
          [
            [
              [139.0474, -7.20733],
              [138.9806, -7.17473],
              [138.8977, -7.15529],
              [138.8598, -7.15979],
              [138.7629, -7.12149],
              [138.6438, -7.02609],
              [138.5898, -6.99632],
              [138.5537, -6.94161],
              [138.5771, -6.90455],
              [138.6282, -6.88226],
              [138.7176, -6.90736],
              [138.7986, -6.85274],
              [138.9096, -6.83958],
              [138.9876, -6.86856],
              [139.1203, -6.96202],
              [139.1894, -6.97328],
              [139.2395, -7.01748],
              [139.313, -7.05351],
              [139.3448, -7.04751],
              [139.3816, -7.11746],
              [139.3419, -7.16399],
              [139.3879, -7.21546],
              [139.2825, -7.21997],
              [139.2618, -7.15338],
              [139.2123, -7.14795],
              [139.1669, -7.20417],
              [139.0474, -7.20733],
            ],
          ],
          [
            [
              [138.580081, -8.294314],
              [138.665165, -8.223996],
              [138.677984, -8.168969],
              [138.752775, -8.158745],
              [138.817269, -8.132089],
              [138.845733, -8.173173],
              [138.847246, -8.251294],
              [138.923248, -8.30041],
              [138.910733, -8.379953],
              [138.74137, -8.382471],
              [138.67324, -8.362418],
              [138.623178, -8.364556],
              [138.549901, -8.333875],
              [138.580081, -8.294314],
            ],
          ],
          [
            [
              [138.2429, -5.82168],
              [138.1782, -5.80375],
              [138.1674, -5.74707],
              [138.28, -5.68657],
              [138.447, -5.65324],
              [138.489, -5.59],
              [138.6399, -5.61862],
              [138.6173, -5.65069],
              [138.5085, -5.6544],
              [138.3833, -5.70852],
              [138.4075, -5.81719],
              [138.2921, -5.84054],
              [138.2429, -5.82168],
            ],
          ],
          [
            [
              [138.0648, -5.70003],
              [138.0449, -5.60394],
              [138.064, -5.55804],
              [138.1368, -5.53223],
              [138.1396, -5.4911],
              [138.168, -5.44424],
              [138.1889, -5.4792],
              [138.2101, -5.48438],
              [138.233, -5.45234],
              [138.2525, -5.48586],
              [138.253, -5.54087],
              [138.2265, -5.66476],
              [138.1852, -5.72251],
              [138.0976, -5.73053],
              [138.0648, -5.70003],
            ],
          ],
          [
            [
              [138.137234, -5.208506],
              [138.147, -5.26632],
              [138.0852, -5.27828],
              [138.0525, -5.33152],
              [138.0496, -5.36456],
              [137.9851, -5.3675],
              [137.9864, -5.40201],
              [138.0403, -5.48091],
              [138.0159, -5.49607],
              [137.9556, -5.45513],
              [137.8729, -5.35952],
              [137.9501, -5.29447],
              [137.9871, -5.29744],
              [138.022, -5.25825],
              [138.0702, -5.23493],
              [138.0982, -5.15086],
              [138.1628, -5.14398],
              [138.1825, -5.15089],
              [138.1786, -5.18343],
              [138.1411, -5.19397],
              [138.137234, -5.208506],
            ],
          ],
          [
            [
              [138.8299, -6.82552],
              [138.7266, -6.73924],
              [138.6729, -6.72936],
              [138.6623, -6.65902],
              [138.689, -6.63098],
              [138.7857, -6.68013],
              [138.8213, -6.71103],
              [138.8299, -6.82552],
            ],
          ],
          [
            [
              [138.6736, -6.83746],
              [138.616, -6.78294],
              [138.6332, -6.73332],
              [138.738, -6.78589],
              [138.7003, -6.80568],
              [138.6736, -6.83746],
            ],
          ],
          [
            [
              [138.6883, -6.87799],
              [138.7037, -6.80705],
              [138.7539, -6.7793],
              [138.7935, -6.83214],
              [138.7274, -6.8876],
              [138.6883, -6.87799],
            ],
          ],
          [
            [
              [138.258412, -8.39568],
              [138.302619, -8.392567],
              [138.433326, -8.357625],
              [138.443216, -8.380087],
              [138.376097, -8.408831],
              [138.28001, -8.425317],
              [138.258412, -8.39568],
            ],
          ],
          [
            [
              [137.8539, -5.36266],
              [137.8017, -5.37445],
              [137.7787, -5.33372],
              [137.8215, -5.31118],
              [137.8528, -5.28666],
              [137.8731, -5.30717],
              [137.8854, -5.33656],
              [137.8539, -5.36266],
            ],
          ],
        ],
      },
      properties: {
        ID: 1,
        kode: 85,
        Propinsi: "IRIAN JAYA TIMUR",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [117.6272, -8.5064],
              [117.6347, -8.5577],
              [117.7339, -8.56494],
              [117.7511, -8.59203],
              [117.7476, -8.65059],
              [117.7706, -8.71411],
              [117.8005, -8.72671],
              [117.8649, -8.69836],
              [117.9643, -8.74235],
              [118.042, -8.66336],
              [118.1307, -8.65005],
              [118.1907, -8.67446],
              [118.2592, -8.65611],
              [118.2602, -8.58876],
              [118.0921, -8.49019],
              [118.0745, -8.45144],
              [117.9755, -8.46654],
              [117.8264, -8.36613],
              [117.69, -8.23815],
              [117.7187, -8.16267],
              [117.7737, -8.12987],
              [117.8297, -8.12762],
              [117.923, -8.08018],
              [117.9477, -8.1023],
              [118.0027, -8.09299],
              [118.0848, -8.11166],
              [118.1397, -8.13938],
              [118.1403, -8.20128],
              [118.1771, -8.26983],
              [118.2107, -8.28859],
              [118.2371, -8.34483],
              [118.3065, -8.37104],
              [118.376, -8.3322],
              [118.3766, -8.30573],
              [118.4821, -8.24431],
              [118.6543, -8.28915],
              [118.6959, -8.3531],
              [118.696, -8.43382],
              [118.666, -8.49455],
              [118.7134, -8.49515],
              [118.7031, -8.40608],
              [118.7351, -8.33774],
              [118.8045, -8.28351],
              [118.8935, -8.28139],
              [119.0086, -8.3121],
              [118.9956, -8.37304],
              [119.0554, -8.44954],
              [119.058, -8.5075],
              [119.0193, -8.52801],
              [119.0157, -8.56836],
              [119.0402, -8.64027],
              [119.0823, -8.64853],
              [119.1626, -8.63554],
              [119.1608, -8.70023],
              [119.136, -8.7498],
              [119.0251, -8.73356],
              [118.9733, -8.74543],
              [118.9094, -8.67704],
              [118.8719, -8.72035],
              [118.814, -8.69996],
              [118.7487, -8.69728],
              [118.7364, -8.75995],
              [118.8394, -8.76959],
              [118.9111, -8.7657],
              [118.952, -8.80798],
              [118.9095, -8.84041],
              [118.823, -8.83576],
              [118.7604, -8.79343],
              [118.6678, -8.8026],
              [118.5264, -8.87052],
              [118.4338, -8.88751],
              [118.368, -8.84007],
              [118.3683, -8.76699],
              [118.4155, -8.7207],
              [118.4147, -8.64578],
              [118.3432, -8.68399],
              [118.313, -8.72504],
              [118.2689, -8.74803],
              [118.1936, -8.83756],
              [118.1125, -8.88099],
              [118.0707, -8.84253],
              [117.994, -8.85823],
              [117.9719, -8.89648],
              [117.8884, -8.92488],
              [117.7711, -8.93188],
              [117.6781, -8.92221],
              [117.5918, -8.98328],
              [117.4106, -9.05105],
              [117.3334, -9.05713],
              [117.206, -9.02215],
              [117.1763, -9.02927],
              [117.1348, -9.08871],
              [117.0162, -9.11278],
              [116.9575, -9.06298],
              [116.8858, -9.06688],
              [116.7943, -9.04135],
              [116.7278, -8.98057],
              [116.7335, -8.87307],
              [116.7631, -8.86169],
              [116.8032, -8.79202],
              [116.7648, -8.74296],
              [116.7527, -8.67578],
              [116.7814, -8.59762],
              [116.831, -8.54152],
              [116.9356, -8.52108],
              [116.9938, -8.49331],
              [117.0946, -8.4171],
              [117.1216, -8.37079],
              [117.2359, -8.40081],
              [117.39, -8.47539],
              [117.4425, -8.40256],
              [117.4728, -8.41637],
              [117.5649, -8.40792],
              [117.6245, -8.44271],
              [117.6272, -8.5064],
            ],
          ],
          [
            [
              [116.3869, -8.95521],
              [116.3737, -8.90937],
              [116.3083, -8.9054],
              [116.2538, -8.91775],
              [116.1917, -8.91269],
              [116.1736, -8.86446],
              [116.0229, -8.90188],
              [115.9412, -8.83306],
              [115.833, -8.7992],
              [115.8193, -8.74502],
              [115.852, -8.71975],
              [115.913, -8.77189],
              [116.0066, -8.73022],
              [116.0682, -8.72393],
              [116.0982, -8.66515],
              [116.0939, -8.52486],
              [116.0652, -8.49738],
              [116.058, -8.44775],
              [116.0852, -8.40538],
              [116.1331, -8.39276],
              [116.2819, -8.25297],
              [116.37, -8.2136],
              [116.4473, -8.2233],
              [116.6033, -8.28462],
              [116.6502, -8.2903],
              [116.7153, -8.35162],
              [116.7078, -8.44609],
              [116.6622, -8.48923],
              [116.6654, -8.55788],
              [116.6227, -8.60767],
              [116.5379, -8.76436],
              [116.5104, -8.78135],
              [116.5681, -8.89245],
              [116.4771, -8.92124],
              [116.4524, -8.89981],
              [116.475, -8.82107],
              [116.4287, -8.82842],
              [116.3951, -8.86296],
              [116.4041, -8.94031],
              [116.3869, -8.95521],
            ],
          ],
          [
            [
              [117.5764, -8.1487],
              [117.6696, -8.15196],
              [117.6882, -8.17372],
              [117.6109, -8.23826],
              [117.5718, -8.33329],
              [117.5289, -8.39122],
              [117.4692, -8.35647],
              [117.509, -8.27402],
              [117.478, -8.25889],
              [117.4797, -8.19131],
              [117.5179, -8.16035],
              [117.5764, -8.1487],
            ],
          ],
          [
            [
              [119.0875, -8.12935],
              [119.1209, -8.16271],
              [119.1206, -8.22137],
              [119.0524, -8.26051],
              [119.0052, -8.23024],
              [119.0052, -8.17625],
              [119.0592, -8.12652],
              [119.0875, -8.12935],
            ],
          ],
        ],
      },
      properties: {
        ID: 2,
        kode: 52,
        Propinsi: "NUSATENGGARA BARAT",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [122.188141, 1.045298],
            [122.226273, 1.00335],
            [122.320999, 1.010509],
            [122.375313, 0.997158],
            [122.4674, 1.025363],
            [122.494095, 0.990939],
            [122.589439, 0.954507],
            [122.653801, 0.949849],
            [122.690964, 0.895421],
            [122.718719, 0.895446],
            [122.847275, 0.816487],
            [122.925346, 0.861357],
            [122.950432, 0.910492],
            [122.948494, 0.965814],
            [122.989319, 0.968382],
            [123.028053, 0.933226],
            [123.088364, 0.911231],
            [123.117294, 0.92862],
            [123.1465, 0.881753],
            [123.240799, 0.83596],
            [123.257477, 0.749624],
            [123.368813, 0.661965],
            [123.494171, 0.636227],
            [123.527229, 0.57175],
            [123.51918, 0.449638],
            [123.465736, 0.330308],
            [123.416306, 0.313156],
            [123.343658, 0.309153],
            [123.269981, 0.325868],
            [123.199982, 0.396042],
            [123.165573, 0.404253],
            [123.122955, 0.465821],
            [123.071152, 0.510825],
            [123.000008, 0.484606],
            [122.895432, 0.484932],
            [122.836777, 0.495058],
            [122.712585, 0.478211],
            [122.548058, 0.493073],
            [122.535942, 0.507037],
            [122.423508, 0.502104],
            [122.406532, 0.518997],
            [122.280655, 0.475349],
            [122.228798, 0.486402],
            [122.177422, 0.471345],
            [122.138397, 0.491899],
            [122.056122, 0.479517],
            [121.949081, 0.445606],
            [121.876221, 0.44692],
            [121.814293, 0.414142],
            [121.717461, 0.521079],
            [121.572685, 0.536764],
            [121.534134, 0.550474],
            [121.528786, 0.49727],
            [121.45018, 0.468869],
            [121.419083, 0.484982],
            [121.345665, 0.474487],
            [121.35453, 0.571319],
            [121.247383, 0.597502],
            [121.168579, 0.690562],
            [121.225578, 0.708211],
            [121.260277, 0.78299],
            [121.294579, 0.82408],
            [121.386032, 0.870693],
            [121.499672, 0.850688],
            [121.620621, 0.883505],
            [121.673096, 0.914232],
            [121.754608, 0.926077],
            [121.831047, 0.963612],
            [121.882187, 0.9727],
            [121.925529, 0.946584],
            [121.968292, 0.894658],
            [121.993927, 0.94102],
            [122.02533, 0.949058],
            [122.067818, 0.997213],
            [122.188141, 1.045298],
          ],
          [
            [122.957306, 0.584448],
            [122.980988, 0.556596],
            [123.020927, 0.563377],
            [123.007317, 0.617577],
            [122.957306, 0.584448],
          ],
        ],
      },
      properties: {
        ID: 3,
        kode: 75,
        Propinsi: "GORONTALO",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [120.98423, -2.835341],
              [121.078339, -2.778087],
              [121.133423, -2.87806],
              [121.167259, -2.970928],
              [121.210396, -2.97805],
              [121.255768, -3.057803],
              [121.315308, -3.065957],
              [121.355957, -3.019798],
              [121.486954, -2.992209],
              [121.568336, -2.914573],
              [121.645721, -2.883784],
              [121.703339, -2.811771],
              [121.724182, -2.759546],
              [121.760185, -2.71899],
              [121.797012, -2.710249],
              [121.81871, -2.763949],
              [121.872299, -2.777285],
              [121.899376, -2.80432],
              [121.940231, -2.932159],
              [122.000359, -2.962439],
              [122.090057, -2.961801],
              [122.130539, -3.015782],
              [122.191582, -3.032609],
              [122.190437, -3.094346],
              [122.241173, -3.138603],
              [122.274086, -3.138374],
              [122.294449, -3.195179],
              [122.285965, -3.253934],
              [122.312798, -3.271106],
              [122.288254, -3.309927],
              [122.292755, -3.35732],
              [122.373749, -3.379456],
              [122.390915, -3.405149],
              [122.372299, -3.463288],
              [122.330208, -3.423481],
              [122.322395, -3.380808],
              [122.277771, -3.365802],
              [122.238159, -3.398722],
              [122.302132, -3.471626],
              [122.275734, -3.552534],
              [122.198738, -3.56528],
              [122.195618, -3.608036],
              [122.248512, -3.666231],
              [122.308617, -3.691788],
              [122.367409, -3.752801],
              [122.4011, -3.737035],
              [122.448067, -3.749501],
              [122.465042, -3.795845],
              [122.512268, -3.854226],
              [122.515129, -3.882586],
              [122.671906, -3.897003],
              [122.603531, -3.976277],
              [122.663445, -4.008508],
              [122.643234, -4.129524],
              [122.704361, -4.158368],
              [122.792816, -4.1576],
              [122.843758, -4.125524],
              [122.771515, -4.059357],
              [122.842255, -4.057042],
              [122.8918, -4.125212],
              [122.905563, -4.196556],
              [122.88662, -4.297779],
              [122.904427, -4.377195],
              [122.840179, -4.445022],
              [122.773346, -4.407986],
              [122.683952, -4.323638],
              [122.663979, -4.351196],
              [122.701942, -4.401843],
              [122.767426, -4.449976],
              [122.746147, -4.507518],
              [122.681007, -4.479528],
              [122.613434, -4.401861],
              [122.586288, -4.393665],
              [122.503342, -4.448484],
              [122.42131, -4.430861],
              [122.36808, -4.461316],
              [122.257019, -4.487848],
              [122.224892, -4.476355],
              [122.174736, -4.514592],
              [122.082832, -4.536929],
              [122.025444, -4.649319],
              [122.023575, -4.716242],
              [122.04731, -4.770176],
              [122.108398, -4.812253],
              [122.079865, -4.843765],
              [121.992233, -4.894716],
              [121.904724, -4.88616],
              [121.818916, -4.84867],
              [121.808197, -4.830569],
              [121.724449, -4.830998],
              [121.662598, -4.848654],
              [121.651039, -4.805225],
              [121.56263, -4.786319],
              [121.455299, -4.681352],
              [121.45945, -4.585207],
              [121.506104, -4.435465],
              [121.507233, -4.331302],
              [121.522499, -4.277308],
              [121.564728, -4.245612],
              [121.608521, -4.13802],
              [121.599144, -4.055925],
              [121.499374, -4.014913],
              [121.337173, -3.98036],
              [121.293785, -3.888254],
              [121.226234, -3.822368],
              [121.181694, -3.828741],
              [121.061172, -3.703674],
              [121.020912, -3.690099],
              [120.910744, -3.582204],
              [120.876328, -3.525793],
              [120.858788, -3.440268],
              [120.928894, -3.342],
              [120.95388, -3.329225],
              [120.966698, -3.26637],
              [121.016693, -3.244607],
              [121.051331, -3.184905],
              [121.056152, -3.070598],
              [121.084557, -2.923684],
              [121.071167, -2.89214],
              [120.98423, -2.835341],
            ],
          ],
          [
            [
              [122.97496, -5.080378],
              [122.974899, -5.114667],
              [122.942909, -5.160937],
              [122.962776, -5.195495],
              [123.032532, -5.13616],
              [123.085037, -5.172699],
              [123.151733, -5.246449],
              [123.176643, -5.245639],
              [123.21479, -5.304791],
              [123.135201, -5.395737],
              [123.014626, -5.44086],
              [122.972809, -5.394393],
              [122.921967, -5.43637],
              [122.888062, -5.441972],
              [122.846359, -5.485299],
              [122.897339, -5.518404],
              [122.897095, -5.56276],
              [122.815216, -5.65809],
              [122.797371, -5.695441],
              [122.744087, -5.637722],
              [122.661079, -5.689842],
              [122.623024, -5.679935],
              [122.578278, -5.571494],
              [122.563545, -5.480018],
              [122.647797, -5.425014],
              [122.617264, -5.403909],
              [122.646088, -5.356668],
              [122.651276, -5.326329],
              [122.682716, -5.320218],
              [122.732628, -5.241536],
              [122.793892, -5.241115],
              [122.806274, -5.200619],
              [122.737282, -5.170575],
              [122.788528, -5.106222],
              [122.80249, -5.037634],
              [122.768929, -5.008087],
              [122.811577, -4.978685],
              [122.832375, -4.890354],
              [122.807869, -4.866568],
              [122.858742, -4.783739],
              [122.831207, -4.679556],
              [122.850807, -4.590418],
              [122.910202, -4.460138],
              [123.040962, -4.370708],
              [123.080978, -4.45977],
              [123.136269, -4.483249],
              [123.199295, -4.605664],
              [123.21109, -4.722411],
              [123.201141, -4.780681],
              [123.171539, -4.834829],
              [123.149704, -4.735183],
              [123.103752, -4.713612],
              [123.038658, -4.800051],
              [122.993774, -4.828905],
              [123.001671, -4.93299],
              [122.969612, -4.950684],
              [122.955971, -5.000822],
              [122.97496, -5.080378],
            ],
          ],
          [
            [
              [122.311989, -5.189582],
              [122.322166, -5.108933],
              [122.382103, -5.084469],
              [122.377975, -4.944457],
              [122.353973, -4.920957],
              [122.321449, -4.834823],
              [122.374245, -4.743024],
              [122.451523, -4.74067],
              [122.536934, -4.704957],
              [122.596466, -4.641494],
              [122.668556, -4.609775],
              [122.730896, -4.638964],
              [122.721161, -4.689926],
              [122.747383, -4.792154],
              [122.727425, -4.832773],
              [122.762848, -4.887722],
              [122.735123, -4.955379],
              [122.679199, -5.035037],
              [122.637512, -5.059143],
              [122.58609, -5.182532],
              [122.637917, -5.2589],
              [122.640091, -5.352726],
              [122.599274, -5.35376],
              [122.592781, -5.388764],
              [122.545418, -5.438321],
              [122.511826, -5.402669],
              [122.539757, -5.326673],
              [122.524986, -5.288412],
              [122.481499, -5.295955],
              [122.495522, -5.34572],
              [122.463638, -5.400284],
              [122.363136, -5.385505],
              [122.275864, -5.388109],
              [122.268753, -5.306668],
              [122.315941, -5.233175],
              [122.311989, -5.189582],
            ],
          ],
          [
            [
              [121.805817, -5.245513],
              [121.808357, -5.201829],
              [121.843086, -5.139951],
              [121.859879, -5.074867],
              [121.930122, -5.102027],
              [121.94725, -5.05961],
              [121.986343, -5.092185],
              [121.992439, -5.15234],
              [122.026848, -5.14409],
              [122.060753, -5.18434],
              [122.051247, -5.260043],
              [122.043472, -5.455512],
              [122.029358, -5.46955],
              [121.944969, -5.474207],
              [121.945465, -5.446015],
              [121.875435, -5.380198],
              [121.802193, -5.27901],
              [121.805817, -5.245513],
            ],
          ],
          [
            [
              [123.003357, -3.999794],
              [123.03022, -3.981664],
              [123.086868, -4.022165],
              [123.144112, -4.010673],
              [123.215721, -4.022439],
              [123.254143, -4.066495],
              [123.254288, -4.114],
              [123.149765, -4.243471],
              [123.118874, -4.261611],
              [123.073792, -4.249185],
              [122.996155, -4.201651],
              [122.945335, -4.099027],
              [122.980415, -4.061724],
              [123.003357, -3.999794],
            ],
          ],
          [
            [
              [123.537064, -5.338479],
              [123.51709, -5.270007],
              [123.581436, -5.249413],
              [123.647369, -5.299086],
              [123.618355, -5.385718],
              [123.581528, -5.386153],
              [123.537064, -5.338479],
            ],
          ],
          [
            [
              [124.07579, -5.99996],
              [123.987404, -5.965688],
              [123.973602, -5.935432],
              [123.991692, -5.87584],
              [124.050156, -5.91096],
              [124.07579, -5.99996],
            ],
          ],
          [
            [
              [123.092873, -3.570833],
              [123.161133, -3.560991],
              [123.181412, -3.602412],
              [123.133232, -3.610474],
              [123.113304, -3.638883],
              [123.045197, -3.594425],
              [123.092873, -3.570833],
            ],
          ],
          [
            [
              [123.721954, -5.477765],
              [123.767891, -5.50372],
              [123.827209, -5.571645],
              [123.784508, -5.591027],
              [123.718681, -5.512402],
              [123.721954, -5.477765],
            ],
          ],
          [
            [
              [123.959496, -5.727983],
              [123.997734, -5.768229],
              [123.928597, -5.776584],
              [123.896194, -5.737903],
              [123.959496, -5.727983],
            ],
          ],
          [
            [
              [122.547577, -5.634616],
              [122.532173, -5.690142],
              [122.487648, -5.661558],
              [122.507607, -5.62051],
              [122.547577, -5.634616],
            ],
          ],
          [
            [
              [122.417923, -3.397853],
              [122.470146, -3.466125],
              [122.436958, -3.505473],
              [122.400879, -3.439657],
              [122.417923, -3.397853],
            ],
          ],
          [
            [
              [123.062531, -4.839228],
              [123.079178, -4.794402],
              [123.115738, -4.797104],
              [123.108261, -4.845974],
              [123.062531, -4.839228],
            ],
          ],
        ],
      },
      properties: {
        ID: 4,
        kode: 74,
        Propinsi: "SULAWESI TENGGARA",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.011826, -7.886903],
            [110.042953, -7.89204],
            [110.06321, -7.813906],
            [110.132286, -7.741732],
            [110.142883, -7.645877],
            [110.25954, -7.645667],
            [110.254532, -7.683113],
            [110.379147, -7.595244],
            [110.444958, -7.536631],
            [110.498291, -7.745311],
            [110.54336, -7.798195],
            [110.588886, -7.804361],
            [110.657083, -7.785867],
            [110.723794, -7.791503],
            [110.750929, -7.82466],
            [110.786538, -7.818944],
            [110.771225, -7.899249],
            [110.758797, -8.030742],
            [110.790009, -8.1165],
            [110.788666, -8.154592],
            [110.836136, -8.202934],
            [110.677467, -8.182687],
            [110.584984, -8.139478],
            [110.438988, -8.095153],
            [110.37883, -8.066612],
            [110.345947, -8.028257],
            [110.217957, -7.985723],
            [110.116516, -7.927296],
            [110.011826, -7.886903],
          ],
        ],
      },
      properties: {
        ID: 5,
        kode: 34,
        Propinsi: "DAERAH ISTIMEWA YOGYAKARTA",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [108.829338, -6.74608],
              [108.854889, -6.799195],
              [108.952652, -6.83733],
              [109.027512, -6.774646],
              [109.116945, -6.849453],
              [109.284729, -6.873732],
              [109.375954, -6.859314],
              [109.444908, -6.835716],
              [109.525772, -6.783483],
              [109.574196, -6.837207],
              [109.704632, -6.859836],
              [109.83173, -6.91085],
              [109.994609, -6.926026],
              [110.135114, -6.886767],
              [110.161217, -6.857075],
              [110.218449, -6.858777],
              [110.224055, -6.898671],
              [110.282545, -6.910134],
              [110.360636, -6.958464],
              [110.439422, -6.946518],
              [110.482428, -6.918728],
              [110.567362, -6.805168],
              [110.593362, -6.738227],
              [110.63764, -6.680221],
              [110.692026, -6.53139],
              [110.686522, -6.496921],
              [110.72816, -6.449049],
              [110.8402, -6.409402],
              [110.920682, -6.406552],
              [111.03451, -6.421138],
              [111.051265, -6.504015],
              [111.089495, -6.595203],
              [111.135153, -6.652081],
              [111.208681, -6.687917],
              [111.288116, -6.682676],
              [111.353966, -6.700903],
              [111.460175, -6.661293],
              [111.493095, -6.616364],
              [111.563789, -6.638492],
              [111.694595, -6.752857],
              [111.659874, -6.780993],
              [111.648499, -6.828923],
              [111.615326, -6.836943],
              [111.609039, -6.903624],
              [111.566604, -6.951681],
              [111.612236, -6.971057],
              [111.62486, -7.065532],
              [111.580716, -7.201196],
              [111.544593, -7.209885],
              [111.448722, -7.280662],
              [111.438232, -7.353508],
              [111.346387, -7.352231],
              [111.219641, -7.295056],
              [111.241771, -7.24923],
              [111.146592, -7.262771],
              [111.133153, -7.30824],
              [111.132803, -7.386832],
              [111.112659, -7.415796],
              [111.151654, -7.563807],
              [111.189209, -7.603958],
              [111.180164, -7.709611],
              [111.230736, -7.747669],
              [111.283073, -7.744789],
              [111.281219, -7.782755],
              [111.314003, -7.85079],
              [111.269707, -7.930492],
              [111.231346, -7.948041],
              [111.181503, -7.916514],
              [111.140442, -7.938492],
              [111.134033, -8.020032],
              [111.108658, -8.065952],
              [111.072105, -8.050748],
              [111.031685, -8.082599],
              [110.956131, -8.063713],
              [110.911171, -8.121149],
              [110.904457, -8.210409],
              [110.836136, -8.202934],
              [110.788666, -8.154592],
              [110.790009, -8.1165],
              [110.758797, -8.030742],
              [110.771225, -7.899249],
              [110.786538, -7.818944],
              [110.750929, -7.82466],
              [110.723794, -7.791503],
              [110.657083, -7.785867],
              [110.588886, -7.804361],
              [110.54336, -7.798195],
              [110.498291, -7.745311],
              [110.444958, -7.536631],
              [110.379147, -7.595244],
              [110.254532, -7.683113],
              [110.25954, -7.645667],
              [110.142883, -7.645877],
              [110.132286, -7.741732],
              [110.06321, -7.813906],
              [110.042953, -7.89204],
              [110.011826, -7.886903],
              [109.896675, -7.844425],
              [109.690193, -7.792683],
              [109.47419, -7.756908],
              [109.424683, -7.76845],
              [109.380403, -7.724499],
              [109.277812, -7.701472],
              [109.105467, -7.685618],
              [109.048995, -7.697629],
              [108.993577, -7.744561],
              [108.96375, -7.715322],
              [108.933997, -7.720566],
              [108.93102, -7.713349],
              [108.919786, -7.706645],
              [108.896909, -7.70961],
              [108.876058, -7.694295],
              [108.865476, -7.611036],
              [108.827862, -7.599123],
              [108.804252, -7.673145],
              [108.738433, -7.609161],
              [108.753132, -7.543514],
              [108.724345, -7.486524],
              [108.714968, -7.421927],
              [108.662295, -7.340506],
              [108.603483, -7.350607],
              [108.560766, -7.334588],
              [108.555715, -7.290434],
              [108.582017, -7.240565],
              [108.556373, -7.171165],
              [108.628929, -7.137631],
              [108.696854, -7.151166],
              [108.726479, -7.116722],
              [108.777687, -7.104835],
              [108.798622, -7.004968],
              [108.761101, -6.98307],
              [108.76516, -6.896383],
              [108.824615, -6.829293],
              [108.829338, -6.74608],
            ],
          ],
          [
            [
              [108.864219, -7.706541],
              [108.870832, -7.712705],
              [108.910178, -7.708335],
              [108.92574, -7.719276],
              [108.932456, -7.721225],
              [108.947685, -7.721218],
              [108.957728, -7.718627],
              [108.98867, -7.776379],
              [108.907681, -7.751549],
              [108.811508, -7.734937],
              [108.828885, -7.685307],
              [108.864219, -7.706541],
            ],
          ],
        ],
      },
      properties: {
        ID: 6,
        kode: 3329,
        Propinsi: "JAWA TENGAH",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.721344, -6.089998],
              [106.692719, -6.095602],
              [106.688759, -6.174209],
              [106.718864, -6.185875],
              [106.775894, -6.316725],
              [106.767433, -6.361234],
              [106.599113, -6.361764],
              [106.532104, -6.333633],
              [106.500175, -6.356728],
              [106.466515, -6.313413],
              [106.432259, -6.359931],
              [106.45713, -6.419496],
              [106.404884, -6.453141],
              [106.401779, -6.529884],
              [106.431358, -6.583818],
              [106.429398, -6.695778],
              [106.511475, -6.765959],
              [106.43618, -6.818825],
              [106.427551, -6.865082],
              [106.390602, -6.90497],
              [106.396103, -6.979798],
              [106.379082, -6.995171],
              [106.263062, -6.972657],
              [106.236198, -6.931706],
              [106.145668, -6.902672],
              [106.093071, -6.874006],
              [106.060333, -6.833915],
              [105.988388, -6.814358],
              [105.913567, -6.815527],
              [105.775536, -6.849614],
              [105.684792, -6.836181],
              [105.604584, -6.845806],
              [105.546936, -6.867788],
              [105.476051, -6.862449],
              [105.372208, -6.813282],
              [105.300941, -6.801612],
              [105.269058, -6.843428],
              [105.241913, -6.822674],
              [105.218208, -6.747766],
              [105.263069, -6.761764],
              [105.374222, -6.655813],
              [105.392738, -6.691132],
              [105.38649, -6.738562],
              [105.467102, -6.827007],
              [105.509209, -6.757093],
              [105.505432, -6.729187],
              [105.595924, -6.650365],
              [105.625389, -6.585471],
              [105.615372, -6.561943],
              [105.648323, -6.488072],
              [105.713791, -6.53336],
              [105.771484, -6.516271],
              [105.813004, -6.468743],
              [105.825912, -6.429081],
              [105.818306, -6.370665],
              [105.825676, -6.252214],
              [105.883545, -6.074547],
              [105.976501, -5.999971],
              [106.021919, -5.888695],
              [106.074554, -5.885445],
              [106.113579, -5.930358],
              [106.099228, -5.986111],
              [106.165306, -6.02962],
              [106.222588, -6.015579],
              [106.245636, -5.961533],
              [106.292465, -5.950864],
              [106.388206, -5.992125],
              [106.396767, -6.018975],
              [106.472466, -6.052454],
              [106.534706, -6.012048],
              [106.570259, -6.035616],
              [106.634544, -6.014261],
              [106.684662, -6.016144],
              [106.721344, -6.089998],
            ],
          ],
          [
            [
              [105.246979, -6.565058],
              [105.242393, -6.63545],
              [105.204712, -6.650276],
              [105.167686, -6.576644],
              [105.183006, -6.542505],
              [105.250877, -6.532654],
              [105.246979, -6.565058],
            ],
          ],
        ],
      },
      properties: {
        ID: 7,
        kode: 36,
        Propinsi: "PROBANTEN",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [111.694595, -6.752857],
              [111.832825, -6.799014],
              [111.939568, -6.764695],
              [111.972176, -6.765804],
              [112.027229, -6.859045],
              [112.060112, -6.890543],
              [112.16993, -6.89966],
              [112.23793, -6.871494],
              [112.411819, -6.865529],
              [112.5198, -6.905662],
              [112.544864, -6.848775],
              [112.602792, -6.89387],
              [112.608869, -6.982774],
              [112.652966, -7.041649],
              [112.625006, -7.126014],
              [112.66918, -7.166654],
              [112.674713, -7.225679],
              [112.727264, -7.199742],
              [112.782669, -7.214118],
              [112.836922, -7.30876],
              [112.826752, -7.347414],
              [112.830528, -7.46781],
              [112.816696, -7.488018],
              [112.857704, -7.548725],
              [112.843674, -7.586721],
              [112.901512, -7.62622],
              [112.994942, -7.657773],
              [113.048615, -7.659442],
              [113.110321, -7.72505],
              [113.154587, -7.746615],
              [113.212761, -7.73906],
              [113.280533, -7.786507],
              [113.337135, -7.77614],
              [113.481895, -7.698964],
              [113.575294, -7.711989],
              [113.667999, -7.736984],
              [113.706665, -7.709392],
              [113.784576, -7.733948],
              [113.838509, -7.684821],
              [113.931564, -7.699339],
              [114.0299, -7.606591],
              [114.065537, -7.637639],
              [114.095688, -7.699542],
              [114.137779, -7.71711],
              [114.208633, -7.719072],
              [114.239769, -7.700929],
              [114.286819, -7.74787],
              [114.368515, -7.751497],
              [114.449234, -7.804945],
              [114.460304, -7.88781],
              [114.41713, -7.920804],
              [114.42569, -8.064825],
              [114.395546, -8.121264],
              [114.337181, -8.422754],
              [114.372826, -8.53333],
              [114.443161, -8.637075],
              [114.467758, -8.61793],
              [114.513489, -8.648381],
              [114.569321, -8.661139],
              [114.602242, -8.715033],
              [114.591324, -8.750443],
              [114.530891, -8.780732],
              [114.416534, -8.748014],
              [114.349121, -8.744769],
              [114.375259, -8.698574],
              [114.361153, -8.65963],
              [114.317375, -8.624228],
              [114.225525, -8.602414],
              [114.216881, -8.643929],
              [114.090469, -8.615832],
              [114.045151, -8.639547],
              [114.021385, -8.595541],
              [113.970848, -8.609504],
              [113.942947, -8.56012],
              [113.905838, -8.579295],
              [113.8125, -8.546223],
              [113.79007, -8.488746],
              [113.73201, -8.526714],
              [113.646294, -8.494339],
              [113.61882, -8.442991],
              [113.524757, -8.430819],
              [113.425812, -8.380055],
              [113.387894, -8.398775],
              [113.364845, -8.353276],
              [113.284279, -8.295891],
              [113.243156, -8.284298],
              [113.072037, -8.284934],
              [112.972435, -8.326396],
              [112.937477, -8.383437],
              [112.875587, -8.409164],
              [112.781456, -8.387782],
              [112.74437, -8.419105],
              [112.670242, -8.446231],
              [112.552414, -8.401976],
              [112.44194, -8.398211],
              [112.37439, -8.376492],
              [112.329979, -8.334126],
              [112.265106, -8.349245],
              [112.142982, -8.318054],
              [112.031502, -8.319978],
              [111.969475, -8.282909],
              [111.897675, -8.301271],
              [111.776794, -8.257344],
              [111.769699, -8.290105],
              [111.710297, -8.294887],
              [111.710007, -8.325211],
              [111.672142, -8.373858],
              [111.625053, -8.325221],
              [111.513535, -8.335917],
              [111.41066, -8.279475],
              [111.254616, -8.248837],
              [111.188499, -8.278602],
              [111.118698, -8.254065],
              [111.023735, -8.252783],
              [110.904457, -8.210409],
              [110.911171, -8.121149],
              [110.956131, -8.063713],
              [111.031685, -8.082599],
              [111.072105, -8.050748],
              [111.108658, -8.065952],
              [111.134033, -8.020032],
              [111.140442, -7.938492],
              [111.181503, -7.916514],
              [111.231346, -7.948041],
              [111.269707, -7.930492],
              [111.314003, -7.85079],
              [111.281219, -7.782755],
              [111.283073, -7.744789],
              [111.230736, -7.747669],
              [111.180164, -7.709611],
              [111.189209, -7.603958],
              [111.151654, -7.563807],
              [111.112659, -7.415796],
              [111.132803, -7.386832],
              [111.133153, -7.30824],
              [111.146592, -7.262771],
              [111.241771, -7.24923],
              [111.219641, -7.295056],
              [111.346387, -7.352231],
              [111.438232, -7.353508],
              [111.448722, -7.280662],
              [111.544593, -7.209885],
              [111.580716, -7.201196],
              [111.62486, -7.065532],
              [111.612236, -6.971057],
              [111.566604, -6.951681],
              [111.609039, -6.903624],
              [111.615326, -6.836943],
              [111.648499, -6.828923],
              [111.659874, -6.780993],
              [111.694595, -6.752857],
            ],
          ],
          [
            [
              [113.638163, -6.887474],
              [113.797623, -6.884495],
              [113.912071, -6.864436],
              [113.992722, -6.884586],
              [114.086349, -6.927759],
              [114.121918, -6.980549],
              [114.035805, -7.008666],
              [113.985703, -7.011945],
              [113.941284, -7.057282],
              [113.880783, -7.07095],
              [113.883873, -7.135548],
              [113.678551, -7.110577],
              [113.604645, -7.127305],
              [113.573105, -7.163429],
              [113.551178, -7.2346],
              [113.328033, -7.214845],
              [113.092392, -7.229101],
              [112.962181, -7.200501],
              [112.866043, -7.164714],
              [112.801178, -7.157979],
              [112.721176, -7.174918],
              [112.690369, -7.151152],
              [112.708206, -7.102842],
              [112.673004, -7.075121],
              [112.683548, -7.031513],
              [112.725105, -7.040094],
              [112.787674, -6.985271],
              [112.845161, -6.898797],
              [113.042206, -6.880765],
              [113.118645, -6.894146],
              [113.42231, -6.88627],
              [113.499718, -6.897233],
              [113.638163, -6.887474],
            ],
          ],
          [
            [
              [115.499336, -6.946886],
              [115.4478, -6.919424],
              [115.38356, -6.911211],
              [115.390144, -6.98852],
              [115.320747, -6.993716],
              [115.193825, -6.931066],
              [115.233414, -6.885417],
              [115.251526, -6.826602],
              [115.394707, -6.834252],
              [115.439651, -6.846564],
              [115.553787, -6.90412],
              [115.534569, -6.96106],
              [115.499336, -6.946886],
            ],
          ],
          [
            [
              [112.67501, -5.859471],
              [112.564216, -5.833381],
              [112.578552, -5.779873],
              [112.671714, -5.72369],
              [112.71614, -5.742655],
              [112.731986, -5.793182],
              [112.722518, -5.834838],
              [112.67501, -5.859471],
            ],
          ],
          [
            [
              [114.28257, -7.073864],
              [114.325409, -7.053657],
              [114.362137, -7.081872],
              [114.404289, -7.172804],
              [114.383644, -7.186969],
              [114.3144, -7.167445],
              [114.267868, -7.098571],
              [114.28257, -7.073864],
            ],
          ],
          [
            [
              [115.899406, -7.107687],
              [115.891769, -7.186985],
              [115.832756, -7.205947],
              [115.736443, -7.153445],
              [115.778282, -7.109709],
              [115.804092, -7.15064],
              [115.843468, -7.16076],
              [115.899406, -7.107687],
            ],
          ],
          [
            [
              [113.271072, -8.464405],
              [113.306946, -8.450875],
              [113.400581, -8.458902],
              [113.399284, -8.49789],
              [113.30629, -8.509363],
              [113.271072, -8.464405],
            ],
          ],
          [
            [
              [114.48246, -7.141308],
              [114.538559, -7.120292],
              [114.604576, -7.134747],
              [114.60331, -7.171029],
              [114.48246, -7.141308],
            ],
          ],
        ],
      },
      properties: {
        ID: 8,
        kode: 35,
        Propinsi: "JAWA TIMUR",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [127.742072, 0.816295],
              [127.7974, 0.7996],
              [127.8397, 0.80633],
              [127.932, 0.88722],
              [127.9239, 0.95794],
              [127.98, 1.08488],
              [128.0237, 1.11131],
              [128.0883, 1.12649],
              [128.1659, 1.12245],
              [128.2007, 1.19261],
              [128.18, 1.23451],
              [128.1092, 1.23794],
              [128.0916, 1.26956],
              [128.1347, 1.30145],
              [128.1604, 1.36163],
              [128.2424, 1.41862],
              [128.3401, 1.45979],
              [128.3597, 1.49011],
              [128.4626, 1.54464],
              [128.5052, 1.52351],
              [128.5524, 1.56312],
              [128.6649, 1.58865],
              [128.7197, 1.57194],
              [128.6797, 1.48842],
              [128.6905, 1.44729],
              [128.7364, 1.3984],
              [128.7418, 1.33011],
              [128.7071, 1.29313],
              [128.7158, 1.25066],
              [128.6878, 1.19439],
              [128.6842, 1.07075],
              [128.5998, 1.04118],
              [128.5361, 0.97025],
              [128.5073, 0.969],
              [128.3849, 0.91071],
              [128.3338, 0.91042],
              [128.2815, 0.86741],
              [128.3166, 0.81788],
              [128.2052, 0.80334],
              [128.2201, 0.756],
              [128.2597, 0.73909],
              [128.3137, 0.6577],
              [128.4445, 0.62671],
              [128.5697, 0.56647],
              [128.6909, 0.54882],
              [128.6694, 0.49282],
              [128.6897, 0.46074],
              [128.6767, 0.33845],
              [128.7976, 0.31372],
              [128.8473, 0.26065],
              [128.6011, 0.31807],
              [128.5584, 0.31668],
              [128.4994, 0.35481],
              [128.4753, 0.39434],
              [128.3869, 0.40178],
              [128.3124, 0.39049],
              [128.2183, 0.4098],
              [128.1369, 0.46332],
              [128.1165, 0.45372],
              [128.0398, 0.47984],
              [127.9746, 0.4816],
              [127.9154, 0.45615],
              [127.9102, 0.39127],
              [127.8716, 0.30896],
              [127.9191, 0.27721],
              [127.9243, 0.13099],
              [127.8861, 0.07639],
              [127.8873, 0.00948],
              [127.9483, -0.11003],
              [127.9867, -0.26911],
              [128.0997, -0.50944],
              [128.1851, -0.62383],
              [128.2195, -0.70072],
              [128.3036, -0.76573],
              [128.3741, -0.84649],
              [128.3489, -0.88716],
              [128.29, -0.87902],
              [128.2325, -0.8483],
              [128.2714, -0.82066],
              [128.239, -0.77439],
              [128.063, -0.7163],
              [128.0045, -0.66521],
              [127.936, -0.49685],
              [127.8621, -0.36417],
              [127.7496, -0.31774],
              [127.6863, -0.26548],
              [127.6595, -0.21024],
              [127.6996, -0.08177],
              [127.6984, 0.01083],
              [127.7194, 0.06459],
              [127.6883, 0.10672],
              [127.6981, 0.19853],
              [127.7323, 0.31467],
              [127.7144, 0.3493],
              [127.619, 0.36049],
              [127.5751, 0.41658],
              [127.5623, 0.5117],
              [127.5249, 0.55191],
              [127.549, 0.67995],
              [127.5449, 0.72851],
              [127.6029, 0.76229],
              [127.6378, 0.83297],
              [127.5627, 0.8698],
              [127.5083, 0.85684],
              [127.4906, 0.92754],
              [127.51, 0.95334],
              [127.4721, 1.059],
              [127.4192, 1.02931],
              [127.3987, 1.0772],
              [127.4301, 1.14734],
              [127.3989, 1.18898],
              [127.4067, 1.22822],
              [127.5177, 1.42418],
              [127.5333, 1.48478],
              [127.5242, 1.57819],
              [127.5751, 1.68532],
              [127.5565, 1.70354],
              [127.5795, 1.76204],
              [127.607, 1.77525],
              [127.7001, 1.93801],
              [127.7764, 1.99706],
              [127.8375, 2.09089],
              [127.9303, 2.16371],
              [128.0521, 2.20415],
              [128.0675, 2.17477],
              [128.0163, 2.09971],
              [127.9575, 2.04664],
              [127.9438, 1.98637],
              [127.8478, 1.92255],
              [127.8422, 1.81305],
              [127.8788, 1.79133],
              [127.9263, 1.80236],
              [127.9917, 1.76257],
              [128.0203, 1.70558],
              [127.9918, 1.66442],
              [127.9915, 1.61054],
              [128.0335, 1.58746],
              [128.0265, 1.47197],
              [127.9907, 1.36575],
              [127.9981, 1.3337],
              [127.9776, 1.27595],
              [127.9317, 1.236],
              [127.8934, 1.14881],
              [127.8325, 1.14733],
              [127.7644, 1.06888],
              [127.6534, 1.0226],
              [127.6247, 0.96958],
              [127.6535, 0.90266],
              [127.697, 0.87726],
              [127.742072, 0.816295],
            ],
          ],
          [
            [
              [124.936874, -1.929927],
              [124.889656, -1.92754],
              [124.865112, -1.893923],
              [124.77874, -1.916949],
              [124.669197, -1.977551],
              [124.590477, -2.003751],
              [124.534431, -2.007188],
              [124.449631, -2.030549],
              [124.398903, -2.024912],
              [124.384331, -1.94311],
              [124.339363, -1.883676],
              [124.344444, -1.811057],
              [124.375023, -1.733304],
              [124.363998, -1.71271],
              [124.398499, -1.661184],
              [124.473778, -1.65911],
              [124.52462, -1.634892],
              [124.636864, -1.644307],
              [124.798882, -1.686176],
              [124.869881, -1.68325],
              [124.897141, -1.711954],
              [124.955513, -1.699806],
              [125.076996, -1.762628],
              [125.131844, -1.686383],
              [125.202957, -1.727396],
              [125.292274, -1.733136],
              [125.32077, -1.784913],
              [125.311661, -1.895589],
              [125.233017, -1.87573],
              [125.167656, -1.875936],
              [125.108589, -1.898453],
              [125.059608, -1.88656],
              [125.020279, -1.930932],
              [124.936874, -1.929927],
            ],
          ],
          [
            [
              [127.5001, -1.72513],
              [127.4048, -1.66221],
              [127.3915, -1.61568],
              [127.41, -1.56593],
              [127.4126, -1.48042],
              [127.4573, -1.4385],
              [127.5097, -1.41778],
              [127.5734, -1.36802],
              [127.71, -1.33658],
              [127.7904, -1.37072],
              [127.7995, -1.41196],
              [127.8465, -1.43784],
              [127.8983, -1.43163],
              [127.9399, -1.45149],
              [128.0786, -1.57834],
              [128.1245, -1.56462],
              [128.1673, -1.6148],
              [128.159, -1.67794],
              [128.0989, -1.71322],
              [128.0372, -1.71459],
              [127.885, -1.68033],
              [127.8162, -1.69945],
              [127.7037, -1.6869],
              [127.6446, -1.72921],
              [127.5001, -1.72513],
            ],
          ],
          [
            [
              [128.2792, 2.00001],
              [128.3096, 2.08133],
              [128.2571, 2.12537],
              [128.2514, 2.25059],
              [128.2244, 2.30658],
              [128.2787, 2.35976],
              [128.2987, 2.44234],
              [128.3619, 2.50778],
              [128.4117, 2.53086],
              [128.459, 2.59769],
              [128.5255, 2.57971],
              [128.5646, 2.64851],
              [128.6355, 2.54578],
              [128.6942, 2.48003],
              [128.6958, 2.42752],
              [128.6547, 2.3753],
              [128.6571, 2.31508],
              [128.6307, 2.21973],
              [128.5306, 2.07179],
              [128.4704, 2.05684],
              [128.3987, 2.05641],
              [128.3047, 2.03752],
              [128.2792, 2.00001],
            ],
          ],
          [
            [
              [127.3592, -0.57986],
              [127.285, -0.46953],
              [127.3553, -0.32239],
              [127.4094, -0.35825],
              [127.4514, -0.41708],
              [127.481, -0.3414],
              [127.5376, -0.31027],
              [127.6123, -0.36417],
              [127.6887, -0.46297],
              [127.6677, -0.51201],
              [127.6037, -0.57387],
              [127.5993, -0.61639],
              [127.6448, -0.6237],
              [127.6496, -0.67974],
              [127.6861, -0.70913],
              [127.7163, -0.68805],
              [127.8006, -0.67786],
              [127.8704, -0.72379],
              [127.906, -0.77903],
              [127.8678, -0.82488],
              [127.7942, -0.85696],
              [127.693, -0.84385],
              [127.6567, -0.78787],
              [127.6537, -0.74391],
              [127.5675, -0.76966],
              [127.4728, -0.81791],
              [127.438, -0.74074],
              [127.4752, -0.65588],
              [127.4518, -0.62858],
              [127.3592, -0.57986],
            ],
          ],
          [
            [
              [125.9997, -1.91651],
              [125.9263, -1.94223],
              [125.8447, -1.89649],
              [125.7902, -1.9299],
              [125.5886, -1.93069],
              [125.5354, -1.94847],
              [125.4509, -1.94292],
              [125.4385, -1.89255],
              [125.3303, -1.83265],
              [125.372, -1.78057],
              [125.4305, -1.81122],
              [125.4809, -1.78226],
              [125.4913, -1.82035],
              [125.5512, -1.80585],
              [125.7029, -1.83132],
              [125.7522, -1.80754],
              [125.8765, -1.81093],
              [125.9168, -1.79541],
              [126.1406, -1.81564],
              [126.2095, -1.80953],
              [126.2828, -1.8179],
              [126.3354, -1.84281],
              [126.2614, -1.87942],
              [126.2178, -1.86881],
              [126.1545, -1.89238],
              [126.1065, -1.88032],
              [125.9997, -1.91651],
            ],
          ],
          [
            [
              [126, -2.44711],
              [125.9594, -2.37442],
              [125.9575, -2.32236],
              [125.8816, -2.21248],
              [125.8878, -2.17258],
              [125.8583, -2.08804],
              [125.8753, -2.02554],
              [125.9136, -1.9759],
              [125.9811, -2.01085],
              [125.9938, -2.05085],
              [125.9613, -2.12136],
              [125.9634, -2.18431],
              [126.0052, -2.26574],
              [126.0692, -2.41961],
              [126.0422, -2.47304],
              [126, -2.44711],
            ],
          ],
          [
            [
              [127.2619, -0.41957],
              [127.2517, -0.49356],
              [127.214, -0.51609],
              [127.1446, -0.52742],
              [127.1193, -0.51607],
              [127.1134, -0.37269],
              [127.1347, -0.32419],
              [127.1131, -0.29016],
              [127.1443, -0.2604],
              [127.2358, -0.24834],
              [127.2657, -0.28156],
              [127.2481, -0.31237],
              [127.2834, -0.37313],
              [127.2619, -0.41957],
            ],
          ],
          [
            [
              [127.1612, -0.72234],
              [127.2087, -0.64521],
              [127.2975, -0.68594],
              [127.2881, -0.72649],
              [127.3295, -0.78217],
              [127.2906, -0.79626],
              [127.2356, -0.7631],
              [127.191, -0.77644],
              [127.1612, -0.72234],
            ],
          ],
          [
            [
              [127.4999, -1.27043],
              [127.4654, -1.23999],
              [127.5378, -1.17793],
              [127.5999, -1.20978],
              [127.6935, -1.22769],
              [127.6721, -1.29242],
              [127.5595, -1.24815],
              [127.4999, -1.27043],
            ],
          ],
          [
            [
              [129.463684, -0.090001],
              [129.536438, -0.137458],
              [129.562119, -0.181339],
              [129.510452, -0.205838],
              [129.455719, -0.108978],
              [129.375412, -0.064561],
              [129.398895, -0.025581],
              [129.463684, -0.090001],
            ],
          ],
          [
            [
              [127.3835, 0.72289],
              [127.404, 0.75954],
              [127.4478, 0.74363],
              [127.4573, 0.67694],
              [127.4277, 0.63181],
              [127.3691, 0.64163],
              [127.3616, 0.67888],
              [127.3835, 0.72289],
            ],
          ],
          [
            [
              [127.2924, 0.82257],
              [127.3168, 0.86424],
              [127.3848, 0.83086],
              [127.3733, 0.7637],
              [127.3044, 0.76429],
              [127.2924, 0.82257],
            ],
          ],
          [
            [
              [127.3804, 0.28067],
              [127.3442, 0.33348],
              [127.4175, 0.37392],
              [127.4427, 0.33914],
              [127.4215, 0.28311],
              [127.3804, 0.28067],
            ],
          ],
          [
            [
              [127.4613, 0.00001],
              [127.4331, -0.00584],
              [127.4134, 0.05743],
              [127.4404, 0.10221],
              [127.4705, 0.03449],
              [127.4613, 0.00001],
            ],
          ],
          [
            [
              [127.3164, -1.43808],
              [127.2912, -1.40862],
              [127.347, -1.3666],
              [127.3989, -1.40281],
              [127.3713, -1.428],
              [127.3164, -1.43808],
            ],
          ],
          [
            [
              [128.1611, 2.41114],
              [128.1882, 2.36684],
              [128.184, 2.29051],
              [128.129, 2.32644],
              [128.1611, 2.41114],
            ],
          ],
          [
            [
              [128.350814, -1.033596],
              [128.3414, -0.97438],
              [128.4029, -0.99246],
              [128.4037, -1.03638],
              [128.350814, -1.033596],
            ],
          ],
          [
            [
              [128.4984, -0.56781],
              [128.5245, -0.58984],
              [128.5987, -0.61046],
              [128.5917, -0.64074],
              [128.5155, -0.62451],
              [128.4984, -0.56781],
            ],
          ],
          [
            [
              [128.3113, -0.54382],
              [128.3684, -0.51991],
              [128.4003, -0.57154],
              [128.3113, -0.54382],
            ],
          ],
          [
            [
              [127.3309, -0.62605],
              [127.3769, -0.6072],
              [127.4108, -0.633],
              [127.359, -0.6629],
              [127.3309, -0.62605],
            ],
          ],
          [
            [
              [124.303452, -2.000366],
              [124.313477, -1.971169],
              [124.366676, -1.967357],
              [124.365074, -2.013639],
              [124.328445, -2.02773],
              [124.303452, -2.000366],
            ],
          ],
          [
            [
              [126.375684, 1.346717],
              [126.43995, 1.344847],
              [126.412223, 1.282993],
              [126.375684, 1.346717],
            ],
          ],
        ],
      },
      properties: {
        ID: 9,
        kode: 81,
        Propinsi: "MALUKU UTARA",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [130.8313, -3.47141],
              [130.8059, -3.49896],
              [130.8796, -3.58872],
              [130.838, -3.65112],
              [130.8219, -3.70138],
              [130.8327, -3.78794],
              [130.8279, -3.87666],
              [130.7655, -3.83031],
              [130.6133, -3.7912],
              [130.5704, -3.72628],
              [130.4664, -3.69326],
              [130.4058, -3.6243],
              [130.3481, -3.61665],
              [130.2904, -3.58056],
              [130.1187, -3.52129],
              [130.1092, -3.50424],
              [130.0123, -3.47822],
              [129.9645, -3.41722],
              [129.9632, -3.37067],
              [129.9239, -3.33397],
              [129.8779, -3.33584],
              [129.7948, -3.31658],
              [129.6776, -3.31819],
              [129.5451, -3.30253],
              [129.4952, -3.30992],
              [129.5639, -3.40784],
              [129.532, -3.466],
              [129.4135, -3.41433],
              [129.2791, -3.40822],
              [129.1756, -3.37997],
              [129.0764, -3.33637],
              [128.9589, -3.35402],
              [128.918, -3.3311],
              [128.9624, -3.2814],
              [128.9612, -3.22166],
              [128.8912, -3.19893],
              [128.8309, -3.24359],
              [128.7788, -3.25702],
              [128.6635, -3.37588],
              [128.6643, -3.43326],
              [128.5858, -3.42633],
              [128.5119, -3.45503],
              [128.4506, -3.45653],
              [128.3948, -3.42432],
              [128.3877, -3.39445],
              [128.3251, -3.32516],
              [128.2827, -3.22196],
              [128.1976, -3.20564],
              [128.1589, -3.16823],
              [128.1945, -3.12455],
              [128.1891, -3.06464],
              [128.1415, -3.06945],
              [128.0849, -3.12556],
              [128.0515, -3.25021],
              [128.0454, -3.33848],
              [128.0058, -3.37754],
              [127.9723, -3.37984],
              [127.9351, -3.481],
              [127.946, -3.52314],
              [127.9101, -3.53896],
              [127.8864, -3.48258],
              [127.9325, -3.36832],
              [127.9104, -3.28972],
              [127.8799, -3.2655],
              [127.866, -3.18653],
              [127.8952, -3.15067],
              [127.9702, -3.09849],
              [127.9926, -3.06595],
              [128.0965, -3.06632],
              [128.0854, -3.01254],
              [128.1169, -2.9968],
              [128.1079, -2.95342],
              [128.1604, -2.8631],
              [128.2347, -2.84965],
              [128.271, -2.86299],
              [128.3525, -2.85581],
              [128.3975, -2.86835],
              [128.4305, -2.84402],
              [128.5049, -2.8312],
              [128.5428, -2.84749],
              [128.6275, -2.85114],
              [128.6526, -2.8384],
              [128.71, -2.85998],
              [128.7457, -2.84936],
              [128.797, -2.86403],
              [128.9475, -2.83844],
              [129.0271, -2.79063],
              [129.0634, -2.8102],
              [129.0813, -2.93207],
              [129.1264, -2.96181],
              [129.1735, -2.92563],
              [129.213, -2.92231],
              [129.3442, -2.85179],
              [129.3758, -2.78267],
              [129.4677, -2.77901],
              [129.6358, -2.82154],
              [129.6626, -2.81661],
              [129.7802, -2.86243],
              [129.7981, -2.90508],
              [129.8424, -2.88833],
              [129.8639, -2.92376],
              [130.0093, -2.99152],
              [130.0619, -3.00004],
              [130.1593, -2.98459],
              [130.2095, -3.00005],
              [130.2655, -2.96975],
              [130.34, -2.97073],
              [130.4287, -3.02831],
              [130.4896, -3.10319],
              [130.521, -3.1028],
              [130.5932, -3.15459],
              [130.6337, -3.29172],
              [130.6252, -3.31561],
              [130.6609, -3.40861],
              [130.8015, -3.41255],
              [130.8313, -3.47141],
            ],
          ],
          [
            [
              [126.0006, -3.3093],
              [126.0156, -3.18204],
              [126.1009, -3.12067],
              [126.1275, -3.16512],
              [126.2217, -3.18936],
              [126.2245, -3.14467],
              [126.2956, -3.09941],
              [126.3414, -3.10657],
              [126.4018, -3.08571],
              [126.4644, -3.08945],
              [126.5983, -3.06836],
              [126.6736, -3.07402],
              [126.7065, -3.05979],
              [126.7883, -3.06155],
              [126.8791, -3.10877],
              [126.9244, -3.14334],
              [126.9815, -3.14039],
              [127.0466, -3.17702],
              [127.1038, -3.22728],
              [127.076, -3.26191],
              [127.0297, -3.26615],
              [127.0424, -3.33172],
              [127.0728, -3.37474],
              [127.1212, -3.38484],
              [127.159, -3.33658],
              [127.1807, -3.35828],
              [127.2546, -3.37528],
              [127.2462, -3.45383],
              [127.2481, -3.59206],
              [127.2178, -3.66125],
              [127.1869, -3.67576],
              [127.1262, -3.65913],
              [127.045, -3.68912],
              [126.9586, -3.75261],
              [126.8914, -3.78843],
              [126.8629, -3.78245],
              [126.7496, -3.84348],
              [126.6818, -3.85857],
              [126.6689, -3.84196],
              [126.5525, -3.79009],
              [126.5235, -3.79459],
              [126.2828, -3.66723],
              [126.2323, -3.62291],
              [126.198, -3.62769],
              [126.163, -3.58489],
              [126.1605, -3.54538],
              [126.0465, -3.44963],
              [126.0398, -3.40619],
              [126.0059, -3.36454],
              [126.0006, -3.3093],
            ],
          ],
          [
            [
              [131.148111, -7.733321],
              [131.107111, -7.701931],
              [131.164311, -7.667571],
              [131.184811, -7.589211],
              [131.222611, -7.528451],
              [131.233511, -7.473591],
              [131.309711, -7.418691],
              [131.340311, -7.442291],
              [131.365311, -7.366271],
              [131.436911, -7.321361],
              [131.475911, -7.229681],
              [131.529611, -7.228881],
              [131.517611, -7.158241],
              [131.582211, -7.120791],
              [131.646611, -7.112811],
              [131.650311, -7.174691],
              [131.716911, -7.159711],
              [131.738411, -7.219041],
              [131.652611, -7.225321],
              [131.632611, -7.247311],
              [131.637311, -7.304361],
              [131.675411, -7.359271],
              [131.657511, -7.548331],
              [131.621311, -7.645961],
              [131.583311, -7.658131],
              [131.553011, -7.719511],
              [131.483211, -7.727291],
              [131.480311, -7.774321],
              [131.374111, -7.855401],
              [131.333411, -7.936211],
              [131.341811, -7.985141],
              [131.301711, -8.016321],
              [131.271711, -7.956001],
              [131.230311, -8.002691],
              [131.188511, -7.987851],
              [131.105011, -7.990961],
              [131.122011, -7.905691],
              [131.086611, -7.879891],
              [131.086911, -7.837771],
              [131.146711, -7.799841],
              [131.112411, -7.777601],
              [131.148111, -7.733321],
            ],
          ],
          [
            [
              [125.8047, -8.00014],
              [125.8214, -7.90089],
              [125.8037, -7.85616],
              [125.8663, -7.81319],
              [125.8594, -7.76665],
              [125.8904, -7.75561],
              [125.9467, -7.66017],
              [125.9843, -7.65647],
              [126.0316, -7.69334],
              [126.1872, -7.72471],
              [126.2498, -7.68395],
              [126.3278, -7.69185],
              [126.3889, -7.63476],
              [126.4243, -7.63154],
              [126.4899, -7.58823],
              [126.545, -7.56934],
              [126.6433, -7.56392],
              [126.645, -7.60431],
              [126.692, -7.6219],
              [126.7264, -7.66638],
              [126.8208, -7.66033],
              [126.8458, -7.73482],
              [126.8188, -7.75456],
              [126.7227, -7.74454],
              [126.5973, -7.80845],
              [126.5588, -7.81877],
              [126.5236, -7.86325],
              [126.4953, -7.94762],
              [126.4649, -7.97483],
              [126.4293, -7.93509],
              [126.3682, -7.93536],
              [126.3332, -7.91575],
              [126.177, -7.92564],
              [126.1508, -7.90081],
              [126.061, -7.89072],
              [125.9438, -7.9134],
              [125.8653, -7.9728],
              [125.8047, -8.00014],
            ],
          ],
          [
            [
              [134.4336, -6.68109],
              [134.3658, -6.78505],
              [134.3282, -6.86583],
              [134.2755, -6.88061],
              [134.2023, -6.92197],
              [134.1704, -6.9112],
              [134.1372, -6.8557],
              [134.0711, -6.83181],
              [134.0503, -6.77574],
              [134.0766, -6.71568],
              [134.0907, -6.63462],
              [134.1005, -6.48848],
              [134.1407, -6.46422],
              [134.117, -6.41609],
              [134.134, -6.34636],
              [134.1277, -6.25815],
              [134.097, -6.17273],
              [134.1377, -6.17052],
              [134.17, -6.21557],
              [134.2173, -6.22012],
              [134.3115, -6.30646],
              [134.298, -6.36197],
              [134.3474, -6.39878],
              [134.382, -6.40713],
              [134.4026, -6.40139],
              [134.4249, -6.41895],
              [134.4362, -6.4517],
              [134.4599, -6.43869],
              [134.4891, -6.53131],
              [134.4858, -6.58192],
              [134.5276, -6.60318],
              [134.4821, -6.66863],
              [134.4336, -6.68109],
            ],
          ],
          [
            [
              [134.2807, -6.05256],
              [134.3267, -6.02579],
              [134.3607, -6.04227],
              [134.3823, -6.02187],
              [134.4244, -6.02619],
              [134.4382, -6.01891],
              [134.4436, -6.00643],
              [134.4644, -5.98356],
              [134.5357, -5.92285],
              [134.5723, -5.94052],
              [134.6581, -5.94721],
              [134.6703, -5.98357],
              [134.7544, -6.0529],
              [134.7817, -6.09747],
              [134.7604, -6.14324],
              [134.7663, -6.18145],
              [134.7274, -6.3072],
              [134.6866, -6.34413],
              [134.6395, -6.35555],
              [134.5489, -6.3525],
              [134.5054, -6.30132],
              [134.4592, -6.27077],
              [134.3971, -6.25802],
              [134.3863, -6.22362],
              [134.3368, -6.21551],
              [134.3125, -6.17191],
              [134.2849, -6.14636],
              [134.2807, -6.05256],
            ],
          ],
          [
            [
              [134.7577, -5.85453],
              [134.7697, -5.89399],
              [134.7117, -5.97952],
              [134.6304, -5.92205],
              [134.5854, -5.93691],
              [134.5374, -5.92008],
              [134.4715, -5.9604],
              [134.4315, -6.02112],
              [134.419, -6.02472],
              [134.3792, -6.01837],
              [134.3546, -6.0354],
              [134.3167, -5.99975],
              [134.334, -5.95209],
              [134.3007, -5.90053],
              [134.3324, -5.86254],
              [134.3723, -5.85071],
              [134.3688, -5.77119],
              [134.2634, -5.76163],
              [134.2142, -5.72281],
              [134.2405, -5.67587],
              [134.3467, -5.73121],
              [134.355, -5.69253],
              [134.4186, -5.64292],
              [134.4543, -5.5571],
              [134.5289, -5.56647],
              [134.538, -5.63655],
              [134.5491, -5.65535],
              [134.5668, -5.65654],
              [134.5782, -5.68039],
              [134.5952, -5.70553],
              [134.619, -5.7287],
              [134.6405, -5.71887],
              [134.6549, -5.74994],
              [134.6972, -5.81007],
              [134.7577, -5.85453],
            ],
          ],
          [
            [
              [128.2655, -3.61441],
              [128.2877, -3.69248],
              [128.2518, -3.72545],
              [128.1829, -3.74662],
              [128.1211, -3.74962],
              [128.1484, -3.66138],
              [128.0116, -3.74275],
              [127.9853, -3.77568],
              [127.9318, -3.76827],
              [127.9068, -3.71281],
              [127.9109, -3.67892],
              [128.0169, -3.58674],
              [128.1683, -3.58184],
              [128.2057, -3.52147],
              [128.2809, -3.48812],
              [128.3348, -3.50381],
              [128.3097, -3.56217],
              [128.3452, -3.59693],
              [128.3374, -3.63375],
              [128.2655, -3.61441],
            ],
          ],
          [
            [
              [129.789611, -8.048421],
              [129.750711, -8.060451],
              [129.702611, -8.045571],
              [129.576011, -7.901111],
              [129.583011, -7.840591],
              [129.608311, -7.812481],
              [129.675811, -7.791501],
              [129.843311, -7.844541],
              [129.860811, -7.922381],
              [129.798611, -8.013341],
              [129.789611, -8.048421],
            ],
          ],
          [
            [
              [132.918211, -5.790491],
              [132.941011, -5.772471],
              [132.946411, -5.681341],
              [133.006711, -5.643821],
              [133.016311, -5.584311],
              [133.045011, -5.515811],
              [133.054611, -5.449861],
              [133.117011, -5.274701],
              [133.179011, -5.291151],
              [133.122111, -5.555371],
              [133.089711, -5.614901],
              [133.020311, -5.665601],
              [132.968511, -5.737791],
              [132.953611, -5.811861],
              [132.963011, -5.856341],
              [132.900311, -5.950561],
              [132.876411, -5.898311],
              [132.918211, -5.790491],
            ],
          ],
          [
            [
              [131.002945, -4.443814],
              [131.074814, -4.453882],
              [131.155197, -4.44084],
              [131.228928, -4.456224],
              [131.343292, -4.421241],
              [131.390854, -4.377274],
              [131.453949, -4.374352],
              [131.493683, -4.41465],
              [131.455231, -4.502821],
              [131.368332, -4.529637],
              [131.272049, -4.541338],
              [131.22493, -4.506531],
              [131.086044, -4.477498],
              [130.989182, -4.514464],
              [130.957397, -4.476324],
              [131.002945, -4.443814],
            ],
          ],
          [
            [
              [132.696911, -5.752801],
              [132.654111, -5.708561],
              [132.644411, -5.635551],
              [132.692111, -5.601881],
              [132.744211, -5.672451],
              [132.780011, -5.766551],
              [132.811311, -5.784241],
              [132.820311, -5.849941],
              [132.789511, -5.938101],
              [132.729911, -5.942501],
              [132.671911, -5.903691],
              [132.696211, -5.808051],
              [132.696911, -5.752801],
            ],
          ],
          [
            [
              [134.2773, -6.17358],
              [134.3066, -6.17218],
              [134.3385, -6.2284],
              [134.3702, -6.22986],
              [134.3955, -6.26218],
              [134.4229, -6.28203],
              [134.3477, -6.34415],
              [134.3152, -6.27647],
              [134.2868, -6.28248],
              [134.2305, -6.20123],
              [134.1807, -6.20193],
              [134.1708, -6.16644],
              [134.1176, -6.10945],
              [134.1245, -6.0387],
              [134.1945, -6.0387],
              [134.2289, -6.0174],
              [134.2461, -6.07542],
              [134.2339, -6.12277],
              [134.2773, -6.17358],
            ],
          ],
          [
            [
              [134.4041, -6.30569],
              [134.4624, -6.27898],
              [134.5012, -6.30226],
              [134.5468, -6.36615],
              [134.5967, -6.38789],
              [134.6113, -6.43346],
              [134.5735, -6.47648],
              [134.548, -6.53803],
              [134.5202, -6.53328],
              [134.493, -6.45866],
              [134.4547, -6.43217],
              [134.4437, -6.4401],
              [134.4075, -6.39784],
              [134.3848, -6.40067],
              [134.3625, -6.36106],
              [134.4041, -6.30569],
            ],
          ],
          [
            [
              [127.778111, -8.140231],
              [127.804411, -8.104221],
              [127.930311, -8.143591],
              [128.022411, -8.148441],
              [128.097211, -8.140591],
              [128.116711, -8.171331],
              [128.071911, -8.195261],
              [128.044211, -8.257411],
              [127.954811, -8.249611],
              [127.936211, -8.222341],
              [127.859311, -8.217701],
              [127.793211, -8.190191],
              [127.778111, -8.140231],
            ],
          ],
          [
            [
              [131.087511, -8.172361],
              [130.990911, -8.230581],
              [130.891211, -8.238591],
              [130.896411, -8.286951],
              [130.789911, -8.342211],
              [130.767511, -8.313871],
              [130.829211, -8.248821],
              [130.929811, -8.191911],
              [130.912811, -8.147011],
              [130.977011, -8.131751],
              [130.999811, -8.089501],
              [131.035511, -8.071791],
              [131.079511, -8.108431],
              [131.169111, -8.112971],
              [131.145511, -8.157911],
              [131.087511, -8.172361],
            ],
          ],
          [
            [
              [134.6442, -5.58602],
              [134.7514, -5.63225],
              [134.731, -5.72249],
              [134.7029, -5.73076],
              [134.6496, -5.7286],
              [134.6427, -5.71633],
              [134.6165, -5.72584],
              [134.596, -5.7018],
              [134.575, -5.67087],
              [134.576, -5.65725],
              [134.5488, -5.65219],
              [134.5368, -5.61611],
              [134.5345, -5.5749],
              [134.6442, -5.58602],
            ],
          ],
          [
            [
              [134.5938, -5.42747],
              [134.6513, -5.44671],
              [134.6511, -5.47534],
              [134.7046, -5.51648],
              [134.7011, -5.5677],
              [134.6341, -5.58476],
              [134.5092, -5.54835],
              [134.506, -5.42404],
              [134.5577, -5.44406],
              [134.5938, -5.42747],
            ],
          ],
          [
            [
              [131.897411, -7.176801],
              [131.845711, -7.156551],
              [131.723211, -7.169341],
              [131.716611, -7.131491],
              [131.752311, -7.110931],
              [131.884511, -7.102661],
              [131.929811, -7.111851],
              [131.971311, -7.199891],
              [131.949211, -7.248091],
              [131.906911, -7.223991],
              [131.897411, -7.176801],
            ],
          ],
          [
            [
              [128.690311, -7.103501],
              [128.655111, -7.150041],
              [128.674811, -7.177831],
              [128.636911, -7.222311],
              [128.534511, -7.168821],
              [128.519911, -7.132791],
              [128.587111, -7.069881],
              [128.690311, -7.103501],
            ],
          ],
          [
            [
              [127.369111, -7.502501],
              [127.485211, -7.525411],
              [127.481111, -7.583221],
              [127.409411, -7.607281],
              [127.370311, -7.661551],
              [127.325411, -7.631321],
              [127.366611, -7.577711],
              [127.369111, -7.502501],
            ],
          ],
          [
            [
              [128.4108, -3.55199],
              [128.4361, -3.50583],
              [128.4891, -3.50863],
              [128.5416, -3.53703],
              [128.5658, -3.58219],
              [128.5329, -3.61114],
              [128.453, -3.61128],
              [128.4048, -3.63179],
              [128.4108, -3.55199],
            ],
          ],
          [
            [
              [134.7075, -6.72872],
              [134.6705, -6.78497],
              [134.6232, -6.76017],
              [134.6723, -6.66742],
              [134.6857, -6.59563],
              [134.7244, -6.58593],
              [134.7601, -6.63249],
              [134.7382, -6.7057],
              [134.7075, -6.72872],
            ],
          ],
          [
            [
              [128.6085, -3.57854],
              [128.5457, -3.5238],
              [128.5962, -3.49247],
              [128.6756, -3.54095],
              [128.7227, -3.50944],
              [128.7303, -3.60021],
              [128.6678, -3.5731],
              [128.6368, -3.62703],
              [128.6085, -3.57854],
            ],
          ],
          [
            [
              [131.006851, -4.338299],
              [131.056335, -4.294552],
              [131.147736, -4.32551],
              [131.130356, -4.40931],
              [131.043167, -4.401302],
              [130.995056, -4.371799],
              [131.006851, -4.338299],
            ],
          ],
          [
            [
              [131.041011, -7.499981],
              [130.969711, -7.484171],
              [130.990111, -7.424961],
              [131.162611, -7.400661],
              [131.114011, -7.472901],
              [131.041011, -7.499981],
            ],
          ],
          [
            [
              [127.6479, -3.20179],
              [127.7043, -3.15873],
              [127.7537, -3.14518],
              [127.789, -3.18783],
              [127.786, -3.24502],
              [127.6987, -3.25287],
              [127.6609, -3.23991],
              [127.6479, -3.20179],
            ],
          ],
          [
            [
              [134.8759, -6.29141],
              [134.9079, -6.32475],
              [134.8507, -6.4427],
              [134.8068, -6.46157],
              [134.7981, -6.39306],
              [134.8759, -6.29141],
            ],
          ],
          [
            [
              [127.9522, -3.00023],
              [127.9018, -3.03362],
              [127.8416, -3.03203],
              [127.8735, -2.95421],
              [127.9352, -2.92238],
              [128.0001, -2.93658],
              [127.9522, -3.00023],
            ],
          ],
          [
            [
              [128.087511, -8.231111],
              [128.190711, -8.200321],
              [128.218911, -8.218541],
              [128.227411, -8.275191],
              [128.150311, -8.281711],
              [128.086211, -8.269521],
              [128.087511, -8.231111],
            ],
          ],
          [
            [
              [129.000611, -8.266451],
              [128.950511, -8.237551],
              [128.915111, -8.253071],
              [128.825211, -8.210451],
              [128.859611, -8.185831],
              [128.953211, -8.189821],
              [129.020211, -8.221081],
              [129.000611, -8.266451],
            ],
          ],
          [
            [
              [127.5001, -3.32302],
              [127.4816, -3.29371],
              [127.5485, -3.24207],
              [127.6241, -3.31356],
              [127.5758, -3.33546],
              [127.5001, -3.32302],
            ],
          ],
          [
            [
              [134.7314, -6.47601],
              [134.7625, -6.53462],
              [134.7352, -6.55801],
              [134.6709, -6.55956],
              [134.6397, -6.49611],
              [134.6917, -6.44599],
              [134.7314, -6.47601],
            ],
          ],
          [
            [
              [132.763011, -5.660381],
              [132.745511, -5.617491],
              [132.756311, -5.574361],
              [132.797511, -5.559731],
              [132.821311, -5.673781],
              [132.763011, -5.660381],
            ],
          ],
          [
            [
              [131.043911, -7.653431],
              [131.122811, -7.635551],
              [131.105911, -7.688891],
              [130.969211, -7.737981],
              [130.968111, -7.678161],
              [131.043911, -7.653431],
            ],
          ],
          [
            [
              [127.636911, -8.177851],
              [127.684011, -8.153111],
              [127.747611, -8.200721],
              [127.656011, -8.237481],
              [127.600011, -8.206561],
              [127.636911, -8.177851],
            ],
          ],
          [
            [
              [130.872111, -7.499971],
              [130.998211, -7.527311],
              [130.972711, -7.562581],
              [130.846511, -7.562131],
              [130.839211, -7.497951],
              [130.872111, -7.499971],
            ],
          ],
          [
            [
              [127.172, -8.11934],
              [127.1417, -8.11181],
              [127.1359, -8.04633],
              [127.2011, -8.02771],
              [127.2309, -8.11386],
              [127.172, -8.11934],
            ],
          ],
          [
            [
              [131.621311, -6.695521],
              [131.562011, -6.719951],
              [131.581311, -6.772751],
              [131.526611, -6.802571],
              [131.511911, -6.762721],
              [131.559711, -6.681411],
              [131.621311, -6.695521],
            ],
          ],
          [
            [
              [127.1987, -3.89045],
              [127.1543, -3.88116],
              [127.1369, -3.84772],
              [127.1915, -3.81556],
              [127.2362, -3.86309],
              [127.1987, -3.89045],
            ],
          ],
          [
            [
              [131.4279, -4.00002],
              [131.4501, -4.07044],
              [131.4216, -4.07687],
              [131.3673, -3.96939],
              [131.4184, -3.94902],
              [131.4279, -4.00002],
            ],
          ],
          [
            [
              [134.7166, -5.7494],
              [134.7493, -5.7861],
              [134.6982, -5.80898],
              [134.6595, -5.73844],
              [134.7166, -5.7494],
            ],
          ],
          [
            [
              [134.2541, -5.78689],
              [134.2099, -5.83785],
              [134.1901, -5.81428],
              [134.1989, -5.75416],
              [134.2541, -5.78689],
            ],
          ],
          [
            [
              [134.5586, -5.33935],
              [134.5999, -5.34036],
              [134.5386, -5.41645],
              [134.511, -5.37494],
              [134.5586, -5.33935],
            ],
          ],
        ],
      },
      properties: {
        ID: 10,
        kode: 82,
        Propinsi: "MALUKU",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [115.75, -1.314338],
              [115.750001, -1.314339],
              [115.751405, -1.316831],
              [115.73581, -1.366078],
              [115.653402, -1.497092],
              [115.653089, -1.497869],
              [115.63121, -1.533835],
              [115.636133, -1.603781],
              [115.668327, -1.631298],
              [115.665229, -1.706676],
              [115.691899, -1.750616],
              [115.726902, -1.805778],
              [115.730987, -1.867477],
              [115.706121, -1.962304],
              [115.710694, -2.048348],
              [115.710956, -2.048324],
              [115.813393, -2.070402],
              [115.793901, -2.13127],
              [115.785043, -2.215817],
              [115.813901, -2.25],
              [115.813632, -2.250004],
              [115.809844, -2.277218],
              [115.837873, -2.389611],
              [115.885309, -2.374997],
              [115.890968, -2.335292],
              [115.984661, -2.312577],
              [116.186227, -2.32084],
              [116.250001, -2.316857],
              [116.320487, -2.341474],
              [116.493124, -2.381091],
              [116.554062, -2.409357],
              [116.5088, -2.56185],
              [116.477155, -2.511811],
              [116.42694, -2.494954],
              [116.383386, -2.522825],
              [116.304234, -2.537069],
              [116.337746, -2.569175],
              [116.392311, -2.586137],
              [116.367549, -2.664821],
              [116.358986, -2.763994],
              [116.371806, -2.804421],
              [116.364408, -2.877151],
              [116.268831, -3.003408],
              [116.212625, -3.001885],
              [116.2059, -2.93782],
              [116.155156, -2.90048],
              [116.103854, -2.918623],
              [116.1262, -3.01424],
              [116.172072, -3.061749],
              [116.211546, -3.077393],
              [116.209133, -3.127579],
              [116.168473, -3.180757],
              [116.174596, -3.25247],
              [116.085972, -3.282887],
              [116.034042, -3.360676],
              [116.007613, -3.432879],
              [116.014801, -3.48442],
              [115.991253, -3.52718],
              [115.994862, -3.568975],
              [115.966095, -3.612225],
              [115.83994, -3.630036],
              [115.719, -3.7309],
              [115.657998, -3.743123],
              [115.400724, -3.852247],
              [115.055918, -3.991818],
              [114.674708, -4.180716],
              [114.628891, -4.15206],
              [114.638174, -4.037824],
              [114.610048, -3.887838],
              [114.608771, -3.756415],
              [114.622502, -3.711745],
              [114.610217, -3.639174],
              [114.555767, -3.55747],
              [114.513437, -3.543926],
              [114.483868, -3.503508],
              [114.4433, -3.50281],
              [114.349449, -3.456847],
              [114.358683, -3.386272],
              [114.347025, -3.350136],
              [114.383407, -3.26764],
              [114.450182, -3.209987],
              [114.429722, -3.174344],
              [114.545967, -3.067971],
              [114.496324, -3.015414],
              [114.546252, -2.947693],
              [114.567871, -2.866444],
              [114.617653, -2.883607],
              [114.598846, -2.815611],
              [114.664366, -2.800567],
              [114.672453, -2.747],
              [114.761401, -2.722209],
              [114.787004, -2.682251],
              [114.806774, -2.578846],
              [114.886746, -2.50283],
              [114.919861, -2.455716],
              [114.987968, -2.413859],
              [115.040507, -2.352388],
              [115.248471, -2.211515],
              [115.29124, -2.224027],
              [115.297228, -2.143794],
              [115.322177, -2.072651],
              [115.406859, -2.060095],
              [115.371055, -2.010274],
              [115.402261, -1.954247],
              [115.33758, -1.851955],
              [115.324479, -1.809402],
              [115.351248, -1.633594],
              [115.414142, -1.424074],
              [115.556666, -1.395118],
              [115.669192, -1.333779],
              [115.75, -1.314338],
            ],
          ],
          [
            [
              [116.31113, -3.902152],
              [116.266114, -3.913611],
              [116.210584, -3.981587],
              [116.142724, -4.036726],
              [116.096245, -4.059081],
              [116.039887, -4.024688],
              [116.079433, -3.924688],
              [116.064453, -3.903803],
              [116.082245, -3.834424],
              [116.006969, -3.681632],
              [116.01009, -3.613496],
              [116.036951, -3.582823],
              [116.045361, -3.470512],
              [116.070784, -3.437013],
              [116.1174, -3.302174],
              [116.185062, -3.267459],
              [116.253822, -3.210675],
              [116.28029, -3.221115],
              [116.250389, -3.378996],
              [116.308094, -3.45045],
              [116.313815, -3.503195],
              [116.27193, -3.523571],
              [116.305973, -3.591237],
              [116.300157, -3.712615],
              [116.339172, -3.729019],
              [116.272724, -3.821292],
              [116.31113, -3.902152],
            ],
          ],
          [
            [
              [116.417468, -3.500025],
              [116.399178, -3.626403],
              [116.362428, -3.651056],
              [116.343564, -3.568739],
              [116.312436, -3.556486],
              [116.344771, -3.452928],
              [116.389052, -3.414555],
              [116.434746, -3.422707],
              [116.417468, -3.500025],
            ],
          ],
        ],
      },
      properties: {
        ID: 11,
        kode: 63,
        Propinsi: "KALIMANTAN SELATAN",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.204971, 1.407545],
              [114.153831, 1.384724],
              [114.152161, 1.382885],
              [114.137711, 1.21268],
              [114.140587, 1.206963],
              [114.119576, 1.156378],
              [114.081848, 1.161817],
              [114.047989, 1.132663],
              [113.987701, 1.144419],
              [113.938293, 1.06126],
              [113.890594, 1.038177],
              [113.919106, 0.982897],
              [113.918137, 0.979328],
              [113.880379, 0.925269],
              [113.881111, 0.883935],
              [113.926888, 0.843717],
              [113.889313, 0.783661],
              [113.898712, 0.745404],
              [113.843277, 0.702985],
              [113.842865, 0.665291],
              [113.738724, 0.544104],
              [113.672333, 0.505215],
              [113.656631, 0.438104],
              [113.587898, 0.405597],
              [113.513199, 0.320208],
              [113.437424, 0.265147],
              [113.322639, 0.27277],
              [113.269646, 0.237864],
              [113.326904, 0.178387],
              [113.405693, 0.138666],
              [113.424301, 0.01172],
              [113.333961, -0.188953],
              [113.221898, -0.340063],
              [113.260048, -0.386946],
              [113.265274, -0.432963],
              [113.229156, -0.474128],
              [113.131927, -0.450832],
              [113.059326, -0.500177],
              [113.032639, -0.487246],
              [112.888245, -0.5348],
              [112.852104, -0.568786],
              [112.754036, -0.568419],
              [112.731289, -0.624087],
              [112.688972, -0.652312],
              [112.634483, -0.654555],
              [112.595421, -0.687516],
              [112.538094, -0.699546],
              [112.449364, -0.679314],
              [112.302696, -0.732715],
              [112.244011, -0.634966],
              [112.199463, -0.614976],
              [112.107172, -0.688612],
              [112.039477, -0.702334],
              [111.999829, -0.72664],
              [112.000516, -0.782459],
              [111.954544, -0.818529],
              [111.906593, -0.775333],
              [111.906021, -0.869211],
              [111.832191, -0.914291],
              [111.733315, -0.923568],
              [111.717972, -0.945031],
              [111.624825, -0.984459],
              [111.623978, -1.031922],
              [111.570969, -1.075839],
              [111.55349, -1.117017],
              [111.571541, -1.14573],
              [111.560516, -1.210964],
              [111.494591, -1.221164],
              [111.489815, -1.279386],
              [111.417722, -1.249998],
              [111.348486, -1.285983],
              [111.261569, -1.387914],
              [111.21228, -1.411965],
              [111.188332, -1.490991],
              [111.157883, -1.470608],
              [111.111994, -1.487714],
              [111.123306, -1.544106],
              [111.077415, -1.562303],
              [111.039024, -1.539377],
              [110.980354, -1.538065],
              [110.94973, -1.578825],
              [110.912148, -1.583816],
              [110.855583, -1.633108],
              [110.857147, -1.667797],
              [110.913635, -1.717645],
              [110.974731, -1.667775],
              [111.001907, -1.691915],
              [111.018458, -1.760494],
              [110.971994, -1.812174],
              [110.969867, -1.866475],
              [110.995881, -1.900829],
              [110.978226, -1.95324],
              [111.046007, -1.95951],
              [111.085697, -2.007968],
              [111.049252, -2.069462],
              [111.077835, -2.153214],
              [111.0326, -2.247475],
              [111.068977, -2.298775],
              [111.104942, -2.433837],
              [111.105408, -2.484712],
              [111.136215, -2.611802],
              [111.118469, -2.653161],
              [111.16468, -2.672753],
              [111.166374, -2.710246],
              [111.138382, -2.7492],
              [111.063812, -2.785731],
              [111.010681, -2.794681],
              [110.967964, -2.856296],
              [110.886566, -2.874295],
              [110.797234, -2.912337],
              [110.747238, -2.952027],
              [110.7221, -3.00548],
              [110.6576, -3.03914],
              [110.6336, -2.98782],
              [110.6128, -2.88084],
              [110.5698, -2.84296],
              [110.525, -2.8458],
              [110.4068, -2.90381],
              [110.2995, -2.99725],
              [110.2586, -2.96867],
              [110.22, -2.88738],
              [110.2455, -2.85244],
              [110.2533, -2.76538],
              [110.2148, -2.74003],
              [110.2275, -2.70112],
              [110.2077, -2.64912],
              [110.1552, -2.63672],
              [110.1941, -2.52439],
              [110.1754, -2.45821],
              [110.147, -2.41627],
              [110.137, -2.32053],
              [110.0881, -2.24693],
              [110.0796, -2.18218],
              [110.1071, -2.13802],
              [110.1123, -2.08129],
              [110.0942, -1.98356],
              [110.0508, -1.92568],
              [109.9995, -1.90751],
              [109.9017, -1.83527],
              [109.9364, -1.78015],
              [109.9808, -1.74154],
              [110.0278, -1.67139],
              [110.033, -1.53186],
              [110.0617, -1.46419],
              [110.0684, -1.37387],
              [110.0528, -1.33292],
              [109.9916, -1.27373],
              [109.9618, -1.27656],
              [109.9111, -1.19229],
              [109.9482, -1.12206],
              [109.8486, -1.09025],
              [109.774, -1.04261],
              [109.761, -0.99262],
              [109.7275, -0.98058],
              [109.6514, -0.99955],
              [109.6433, -0.98207],
              [109.6117, -0.9719],
              [109.5843, -0.90008],
              [109.4326, -0.85378],
              [109.4049, -0.93135],
              [109.2996, -0.90027],
              [109.239, -0.80772],
              [109.245, -0.6637],
              [109.3207, -0.70762],
              [109.3963, -0.70803],
              [109.4654, -0.75414],
              [109.5308, -0.7396],
              [109.6311, -0.80334],
              [109.6335, -0.75171],
              [109.5958, -0.70947],
              [109.6738, -0.69369],
              [109.6652, -0.68153],
              [109.5847, -0.68744],
              [109.588, -0.66158],
              [109.5804, -0.65017],
              [109.5137, -0.64136],
              [109.4929, -0.62301],
              [109.4754, -0.61456],
              [109.4189, -0.6312],
              [109.3774, -0.57662],
              [109.2616, -0.60186],
              [109.2036, -0.55585],
              [109.163, -0.57835],
              [109.1282, -0.54665],
              [109.1098, -0.49835],
              [109.0966, -0.36251],
              [109.1129, -0.33999],
              [109.088, -0.27689],
              [109.1198, -0.2587],
              [109.1392, -0.23692],
              [109.1936, -0.22256],
              [109.1786, -0.19141],
              [109.188, -0.1414],
              [109.1735, -0.0609],
              [109.1573, -0.04261],
              [109.1731, 0.07643],
              [109.1372, 0.13194],
              [109.1174, 0.21137],
              [109.0713, 0.26328],
              [108.9632, 0.31588],
              [108.9116, 0.3217],
              [108.9387, 0.38147],
              [108.9285, 0.46134],
              [108.906, 0.47842],
              [108.9224, 0.56979],
              [108.9105, 0.6628],
              [108.8679, 0.71447],
              [108.872, 0.78082],
              [108.8361, 0.8233],
              [108.9103, 0.8683],
              [108.9553, 0.91943],
              [108.9756, 1.00491],
              [108.9609, 1.05662],
              [108.909, 1.15188],
              [108.9456, 1.16483],
              [108.9905, 1.27642],
              [109.0304, 1.32147],
              [109.0512, 1.3894],
              [109.0411, 1.44732],
              [109.0592, 1.51973],
              [109.1307, 1.5864],
              [109.2182, 1.62901],
              [109.2566, 1.66235],
              [109.289, 1.74958],
              [109.3391, 1.82919],
              [109.351, 1.87508],
              [109.3308, 1.92828],
              [109.3548, 1.94679],
              [109.4278, 1.94949],
              [109.4634, 1.97743],
              [109.5912, 1.9991],
              [109.575401, 1.935634],
              [109.538345, 1.914961],
              [109.557236, 1.853854],
              [109.599236, 1.810235],
              [109.655716, 1.798155],
              [109.680099, 1.767307],
              [109.663406, 1.729619],
              [109.656738, 1.632067],
              [109.792725, 1.499817],
              [109.830124, 1.424426],
              [109.934013, 1.41802],
              [109.963875, 1.386595],
              [109.974075, 1.308176],
              [110.04628, 1.26457],
              [110.107086, 1.195396],
              [110.18927, 1.178725],
              [110.206352, 1.121725],
              [110.241669, 1.110338],
              [110.275497, 1.050082],
              [110.277237, 0.996843],
              [110.402931, 0.983606],
              [110.406189, 0.95233],
              [110.482376, 0.881211],
              [110.570297, 0.85522],
              [110.714661, 0.905033],
              [110.764175, 0.929846],
              [110.808174, 0.911536],
              [110.909386, 0.955599],
              [111.041229, 0.997548],
              [111.107147, 1.027512],
              [111.308907, 1.029509],
              [111.356682, 1.035687],
              [111.408791, 1.019521],
              [111.482697, 1.019521],
              [111.552612, 1.039497],
              [111.648499, 1.013529],
              [111.678459, 1.029509],
              [111.733223, 1.011347],
              [111.762688, 1.021632],
              [111.857185, 1.002252],
              [111.890572, 1.067516],
              [111.957893, 1.126365],
              [112.128143, 1.142361],
              [112.157951, 1.205362],
              [112.174301, 1.306329],
              [112.205956, 1.339872],
              [112.222633, 1.390472],
              [112.194519, 1.410984],
              [112.222755, 1.457148],
              [112.274704, 1.469801],
              [112.320335, 1.504941],
              [112.42939, 1.524687],
              [112.480782, 1.576551],
              [112.650337, 1.568992],
              [112.672386, 1.554553],
              [112.770721, 1.562787],
              [112.8377, 1.537369],
              [112.882736, 1.585829],
              [112.92025, 1.569736],
              [113.004318, 1.569513],
              [113.063759, 1.536398],
              [113.030952, 1.472237],
              [112.982132, 1.451602],
              [112.975845, 1.40725],
              [113.114716, 1.425778],
              [113.172112, 1.378784],
              [113.260704, 1.391758],
              [113.318687, 1.378034],
              [113.419991, 1.284333],
              [113.532501, 1.322036],
              [113.581009, 1.298786],
              [113.621231, 1.223979],
              [113.659279, 1.219476],
              [113.699623, 1.258209],
              [113.791924, 1.292841],
              [113.826965, 1.331964],
              [113.822708, 1.372153],
              [113.875908, 1.3915],
              [113.979195, 1.451969],
              [114.019402, 1.444113],
              [114.088257, 1.462753],
              [114.141617, 1.46217],
              [114.204971, 1.407545],
            ],
          ],
          [
            [
              [109.6986, -0.99995],
              [109.7573, -1.00449],
              [109.7685, -1.12241],
              [109.7808, -1.1439],
              [109.656, -1.22133],
              [109.543, -1.27298],
              [109.4999, -1.3094],
              [109.4137, -1.28393],
              [109.4512, -1.09149],
              [109.4502, -1.03477],
              [109.4866, -0.97418],
              [109.554, -1.00326],
              [109.5886, -0.98292],
              [109.637, -0.98789],
              [109.6436, -1.01161],
              [109.6721, -1.02069],
              [109.6986, -0.99995],
            ],
          ],
          [
            [
              [108.8904, -1.53854],
              [108.9347, -1.54254],
              [108.982, -1.59809],
              [108.953, -1.64399],
              [108.8571, -1.67404],
              [108.8187, -1.56793],
              [108.8773, -1.56155],
              [108.8904, -1.53854],
            ],
          ],
          [
            [
              [109.0795, -0.27425],
              [109.0473, -0.25387],
              [109.0858, -0.20704],
              [109.1542, -0.20969],
              [109.1176, -0.25515],
              [109.0795, -0.27425],
            ],
          ],
          [
            [
              [109.5, -0.68043],
              [109.4729, -0.64078],
              [109.4714, -0.62425],
              [109.4933, -0.6268],
              [109.5118, -0.64379],
              [109.5446, -0.65463],
              [109.5821, -0.65385],
              [109.583, -0.68458],
              [109.5408, -0.70285],
              [109.5, -0.68043],
            ],
          ],
          [
            [
              [109.1333, -0.0912],
              [109.1641, -0.09208],
              [109.1842, -0.1367],
              [109.1752, -0.19458],
              [109.1264, -0.12726],
              [109.1333, -0.0912],
            ],
          ],
          [
            [
              [110.0675, -2.71332],
              [110.108, -2.6754],
              [110.1238, -2.74506],
              [110.0769, -2.76368],
              [110.0675, -2.71332],
            ],
          ],
        ],
      },
      properties: {
        ID: 12,
        kode: 0,
        Propinsi: "KALIMANTAN BARAT",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [119.551262, -0.861102],
              [119.567673, -1.02293],
              [119.603714, -1.166935],
              [119.570869, -1.272736],
              [119.505157, -1.277503],
              [119.505386, -1.30987],
              [119.431, -1.336197],
              [119.450577, -1.395969],
              [119.497852, -1.394083],
              [119.516769, -1.436377],
              [119.639478, -1.405974],
              [119.696596, -1.459508],
              [119.659355, -1.496283],
              [119.669128, -1.545653],
              [119.732208, -1.607528],
              [119.775932, -1.693306],
              [119.762688, -1.764873],
              [119.865753, -1.770845],
              [119.902878, -1.796736],
              [119.907639, -1.835855],
              [119.869141, -1.892153],
              [119.848412, -1.965073],
              [119.815765, -2.015383],
              [119.83773, -2.051188],
              [119.887428, -2.045559],
              [119.963005, -2.000149],
              [119.994949, -1.963335],
              [120.101303, -1.966918],
              [120.11956, -2.008276],
              [120.181938, -2.029071],
              [120.26606, -2.022335],
              [120.329796, -2.054484],
              [120.354927, -1.999104],
              [120.383064, -1.890121],
              [120.401123, -1.888412],
              [120.469643, -1.962179],
              [120.480461, -1.998821],
              [120.595131, -2.138367],
              [120.70327, -2.212562],
              [120.74411, -2.228615],
              [120.821678, -2.207236],
              [120.953987, -2.237536],
              [121.15377, -2.328169],
              [121.22654, -2.352717],
              [121.304352, -2.365168],
              [121.391274, -2.409437],
              [121.510712, -2.495261],
              [121.56945, -2.49626],
              [121.639519, -2.535414],
              [121.700546, -2.548773],
              [121.74305, -2.608478],
              [121.797012, -2.710249],
              [121.760185, -2.71899],
              [121.724182, -2.759546],
              [121.703339, -2.811771],
              [121.645721, -2.883784],
              [121.568336, -2.914573],
              [121.486954, -2.992209],
              [121.355957, -3.019798],
              [121.315308, -3.065957],
              [121.255768, -3.057803],
              [121.210396, -2.97805],
              [121.167259, -2.970928],
              [121.133423, -2.87806],
              [121.078339, -2.778087],
              [120.98423, -2.835341],
              [121.079514, -2.746414],
              [121.076256, -2.70387],
              [121.036041, -2.697898],
              [120.888321, -2.643914],
              [120.8414, -2.653143],
              [120.80397, -2.618413],
              [120.670715, -2.645012],
              [120.604576, -2.675776],
              [120.451172, -2.824986],
              [120.404198, -2.841013],
              [120.292091, -2.903932],
              [120.276352, -2.928356],
              [120.189758, -2.962846],
              [120.262177, -3.081572],
              [120.259346, -3.163316],
              [120.379364, -3.200756],
              [120.399612, -3.286162],
              [120.405029, -3.427982],
              [120.380455, -3.500055],
              [120.418488, -3.574584],
              [120.414986, -3.668782],
              [120.442307, -3.741812],
              [120.357109, -3.854832],
              [120.337646, -3.90046],
              [120.348877, -4.046314],
              [120.327316, -4.095068],
              [120.371437, -4.167356],
              [120.358521, -4.21479],
              [120.358246, -4.299249],
              [120.385551, -4.336213],
              [120.338181, -4.424242],
              [120.393219, -4.481883],
              [120.369629, -4.518544],
              [120.388443, -4.604699],
              [120.440254, -4.661181],
              [120.391403, -4.710148],
              [120.376244, -4.772425],
              [120.376946, -4.841133],
              [120.329407, -4.844092],
              [120.293152, -4.881238],
              [120.285965, -4.928174],
              [120.295273, -5.012822],
              [120.260345, -5.152175],
              [120.342224, -5.324609],
              [120.389191, -5.351233],
              [120.419754, -5.4526],
              [120.448463, -5.617203],
              [120.415672, -5.622611],
              [120.355324, -5.575807],
              [120.336731, -5.529115],
              [120.296768, -5.518299],
              [120.268524, -5.543528],
              [120.204079, -5.550507],
              [120.132866, -5.591021],
              [120.070992, -5.596834],
              [119.918945, -5.553621],
              [119.887032, -5.597335],
              [119.829155, -5.644509],
              [119.84346, -5.662867],
              [119.775307, -5.706772],
              [119.691536, -5.706962],
              [119.630302, -5.677094],
              [119.641235, -5.632772],
              [119.577049, -5.617693],
              [119.485161, -5.564486],
              [119.435165, -5.599636],
              [119.411575, -5.550681],
              [119.426895, -5.524836],
              [119.356934, -5.422284],
              [119.35508, -5.348164],
              [119.379471, -5.230053],
              [119.381187, -5.166116],
              [119.404282, -5.123276],
              [119.447258, -5.103672],
              [119.474701, -5.058582],
              [119.472527, -5.000107],
              [119.522362, -4.923256],
              [119.498291, -4.85599],
              [119.489059, -4.7389],
              [119.591194, -4.576705],
              [119.579086, -4.533852],
              [119.597054, -4.475061],
              [119.594704, -4.41748],
              [119.623207, -4.368546],
              [119.634857, -4.305896],
              [119.609695, -4.273651],
              [119.633141, -4.163195],
              [119.613121, -4.148297],
              [119.629814, -4.001027],
              [119.541451, -3.914288],
              [119.488846, -3.769426],
              [119.451897, -3.718498],
              [119.449478, -3.675694],
              [119.492943, -3.605327],
              [119.504692, -3.535425],
              [119.454765, -3.477803],
              [119.387688, -3.444737],
              [119.287392, -3.42765],
              [119.27047, -3.454059],
              [119.193871, -3.493717],
              [119.072189, -3.502652],
              [119.016457, -3.515839],
              [118.923531, -3.564143],
              [118.873749, -3.472959],
              [118.840721, -3.376861],
              [118.853493, -3.301777],
              [118.827072, -3.226409],
              [118.825531, -3.167902],
              [118.77108, -3.101893],
              [118.8255, -3.088763],
              [118.856194, -3.031589],
              [118.845451, -2.987699],
              [118.884193, -2.889628],
              [118.858376, -2.855992],
              [118.806732, -2.848018],
              [118.76268, -2.866327],
              [118.756081, -2.777807],
              [118.781197, -2.7412],
              [118.776978, -2.685046],
              [118.817886, -2.616756],
              [118.870667, -2.678105],
              [118.960197, -2.63891],
              [119.033699, -2.578097],
              [119.043831, -2.506516],
              [119.125046, -2.480243],
              [119.144646, -2.387719],
              [119.123444, -2.320539],
              [119.151535, -2.241966],
              [119.145889, -2.203785],
              [119.185936, -2.152825],
              [119.209732, -2.000001],
              [119.285431, -1.953414],
              [119.330063, -1.962534],
              [119.352249, -1.898259],
              [119.35479, -1.827206],
              [119.327248, -1.81598],
              [119.298561, -1.707247],
              [119.27829, -1.688891],
              [119.323631, -1.571629],
              [119.284828, -1.453343],
              [119.293251, -1.382004],
              [119.328552, -1.350862],
              [119.299255, -1.276281],
              [119.339783, -1.168787],
              [119.436966, -1.102553],
              [119.462563, -1.060493],
              [119.52037, -0.911065],
              [119.551262, -0.861102],
            ],
            [
              [119.877861, -4.025625],
              [119.892097, -3.969825],
              [119.837746, -3.960938],
              [119.850861, -4.010532],
              [119.877861, -4.025625],
            ],
            [
              [119.921219, -4.143905],
              [119.962708, -4.17343],
              [120.014297, -4.09134],
              [120.009155, -4.052724],
              [119.943069, -4.068076],
              [119.902885, -4.060805],
              [119.887871, -4.104517],
              [119.921219, -4.143905],
            ],
          ],
          [
            [
              [120.539146, -5.999964],
              [120.550964, -6.062521],
              [120.505791, -6.233085],
              [120.518158, -6.336722],
              [120.473465, -6.406726],
              [120.451836, -6.386248],
              [120.460854, -6.288644],
              [120.425941, -6.273015],
              [120.427856, -6.171999],
              [120.446587, -6.101119],
              [120.434372, -6.079806],
              [120.426628, -5.967825],
              [120.441719, -5.817685],
              [120.458511, -5.783411],
              [120.507217, -5.844515],
              [120.514557, -5.920866],
              [120.539146, -5.999964],
            ],
          ],
          [
            [
              [120.686981, -7.125358],
              [120.671013, -7.145744],
              [120.600464, -7.100331],
              [120.65184, -7.011528],
              [120.674431, -7.042122],
              [120.782158, -7.100114],
              [120.769409, -7.132674],
              [120.686981, -7.125358],
            ],
          ],
          [
            [
              [120.915955, -7.31008],
              [120.807823, -7.289479],
              [120.817574, -7.264248],
              [120.917694, -7.286254],
              [121.014648, -7.283566],
              [121.002014, -7.333603],
              [120.915955, -7.31008],
            ],
          ],
          [
            [
              [121.803802, -7.496666],
              [121.774845, -7.41659],
              [121.802456, -7.370343],
              [121.848681, -7.382773],
              [121.827263, -7.489076],
              [121.803802, -7.496666],
            ],
          ],
          [
            [
              [121.084381, -7.349752],
              [121.119835, -7.315312],
              [121.169464, -7.349386],
              [121.099648, -7.397838],
              [121.084381, -7.349752],
            ],
          ],
          [
            [
              [119.270134, -5.455223],
              [119.313637, -5.512681],
              [119.258347, -5.535287],
              [119.270134, -5.455223],
            ],
          ],
        ],
      },
      properties: {
        ID: 13,
        kode: 73,
        Propinsi: "SULAWESI SELATAN",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [106.721344, -6.089998],
            [106.741844, -6.105995],
            [106.857552, -6.117222],
            [106.877823, -6.09872],
            [106.972267, -6.089283],
            [106.972893, -6.185318],
            [106.945602, -6.25469],
            [106.909164, -6.263964],
            [106.922569, -6.318879],
            [106.9132, -6.366885],
            [106.775894, -6.316725],
            [106.718864, -6.185875],
            [106.688759, -6.174209],
            [106.692719, -6.095602],
            [106.721344, -6.089998],
          ],
        ],
      },
      properties: {
        ID: 14,
        kode: 31,
        Propinsi: "DKI JAKARTA",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [106.396103, -6.979798],
            [106.390602, -6.90497],
            [106.427551, -6.865082],
            [106.43618, -6.818825],
            [106.511475, -6.765959],
            [106.429398, -6.695778],
            [106.431358, -6.583818],
            [106.401779, -6.529884],
            [106.404884, -6.453141],
            [106.45713, -6.419496],
            [106.432259, -6.359931],
            [106.466515, -6.313413],
            [106.500175, -6.356728],
            [106.532104, -6.333633],
            [106.599113, -6.361764],
            [106.767433, -6.361234],
            [106.775894, -6.316725],
            [106.9132, -6.366885],
            [106.922569, -6.318879],
            [106.909164, -6.263964],
            [106.945602, -6.25469],
            [106.972893, -6.185318],
            [106.972267, -6.089283],
            [107.018662, -6.078872],
            [107.008194, -5.994798],
            [107.038139, -5.911394],
            [107.103302, -5.932775],
            [107.139267, -5.978195],
            [107.191887, -5.985764],
            [107.302711, -5.956646],
            [107.387733, -6.01634],
            [107.465004, -6.141714],
            [107.531631, -6.174138],
            [107.621086, -6.189385],
            [107.654709, -6.242166],
            [107.722885, -6.232824],
            [107.822433, -6.178484],
            [107.893303, -6.238593],
            [107.940582, -6.249246],
            [108.103218, -6.334497],
            [108.156311, -6.331516],
            [108.194878, -6.30224],
            [108.19416, -6.261509],
            [108.301331, -6.245291],
            [108.361443, -6.274672],
            [108.368279, -6.329412],
            [108.429184, -6.416264],
            [108.484001, -6.460444],
            [108.538673, -6.485534],
            [108.539986, -6.577236],
            [108.554527, -6.676128],
            [108.575089, -6.735016],
            [108.605278, -6.770391],
            [108.677612, -6.768246],
            [108.683212, -6.80682],
            [108.763901, -6.815747],
            [108.829338, -6.74608],
            [108.824615, -6.829293],
            [108.76516, -6.896383],
            [108.761101, -6.98307],
            [108.798622, -7.004968],
            [108.777687, -7.104835],
            [108.726479, -7.116722],
            [108.696854, -7.151166],
            [108.628929, -7.137631],
            [108.556373, -7.171165],
            [108.582017, -7.240565],
            [108.555715, -7.290434],
            [108.560766, -7.334588],
            [108.603483, -7.350607],
            [108.662295, -7.340506],
            [108.714968, -7.421927],
            [108.724345, -7.486524],
            [108.753132, -7.543514],
            [108.738433, -7.609161],
            [108.804252, -7.673145],
            [108.76519, -7.696947],
            [108.715416, -7.6797],
            [108.657326, -7.69776],
            [108.586388, -7.684473],
            [108.513016, -7.699345],
            [108.505714, -7.760839],
            [108.48156, -7.801749],
            [108.430328, -7.822663],
            [108.261208, -7.806957],
            [108.123146, -7.780538],
            [107.928497, -7.730316],
            [107.839561, -7.7341],
            [107.782387, -7.677471],
            [107.679466, -7.660038],
            [107.673851, -7.627574],
            [107.602493, -7.571478],
            [107.39267, -7.492356],
            [107.35405, -7.498446],
            [107.242142, -7.487233],
            [107.077705, -7.451569],
            [106.857651, -7.432205],
            [106.788452, -7.434358],
            [106.519707, -7.405331],
            [106.473618, -7.371199],
            [106.421753, -7.357368],
            [106.370895, -7.310125],
            [106.376572, -7.237391],
            [106.398254, -7.188931],
            [106.455795, -7.184799],
            [106.456253, -7.127603],
            [106.539734, -7.056473],
            [106.543465, -6.982475],
            [106.441109, -6.952632],
            [106.396103, -6.979798],
          ],
        ],
      },
      properties: {
        ID: 15,
        kode: 32,
        Propinsi: "JAWA BARAT",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [134.659012, -2.825982],
              [134.6858, -2.89889],
              [134.6926, -2.97571],
              [134.7548, -2.99518],
              [134.7731, -2.94622],
              [134.8429, -2.89917],
              [134.8462, -3.002],
              [134.8123, -3.01963],
              [134.8264, -3.12314],
              [134.8647, -3.16691],
              [134.8594, -3.24819],
              [134.9498, -3.26457],
              [134.9791, -3.32348],
              [135.0478, -3.32651],
              [135.0972, -3.3711],
              [135.234448, -3.372148],
              [135.3201, -3.38682],
              [135.4153, -3.36354],
              [135.4586, -3.37349],
              [135.5006, -3.35486],
              [135.5573, -3.29628],
              [135.5535, -3.25933],
              [135.6098, -3.17585],
              [135.6995, -3.146],
              [135.7544, -3.11098],
              [135.78, -3.05702],
              [135.8263, -3.0096],
              [135.9259, -2.96781],
              [135.9353, -2.90831],
              [135.9146, -2.86814],
              [135.9557, -2.80506],
              [135.9489, -2.77408],
              [136.015, -2.71461],
              [136.0248, -2.68635],
              [136.1909, -2.6319],
              [136.2319, -2.60241],
              [136.2791, -2.50866],
              [136.2999, -2.40352],
              [136.3368, -2.36446],
              [136.3264, -2.27728],
              [136.4167, -2.2032],
              [136.4533, -2.21183],
              [136.5143, -2.18734],
              [136.5955, -2.24608],
              [136.6729, -2.25436],
              [136.703, -2.22582],
              [136.7354, -2.2461],
              [136.8185, -2.21661],
              [136.8541, -2.18543],
              [136.9097, -2.17136],
              [136.9423, -2.11753],
              [136.9781, -2.12748],
              [137.0683, -2.09501],
              [137.0976, -2.10581],
              [137.164, -2.09246],
              [137.2263, -2.05548],
              [137.2343, -1.99891],
              [137.1861, -1.9387],
              [137.095, -1.85465],
              [137.1023, -1.80166],
              [137.1404, -1.77393],
              [137.2308, -1.75041],
              [137.3825, -1.66239],
              [137.4796, -1.59677],
              [137.5278, -1.58513],
              [137.6434, -1.5187],
              [137.7376, -1.49499],
              [137.8021, -1.46379],
              [137.8869, -1.46115],
              [137.916656, -1.49852],
              [137.872772, -1.667199],
              [137.890228, -1.725458],
              [137.878708, -1.76878],
              [137.90004, -1.865164],
              [137.854721, -1.960676],
              [137.838348, -2.073237],
              [137.865494, -2.115197],
              [137.938522, -2.177074],
              [137.955231, -2.230669],
              [138.026489, -2.316186],
              [138.080307, -2.321883],
              [138.119553, -2.373802],
              [138.174255, -2.418834],
              [138.205627, -2.481592],
              [138.341919, -2.605156],
              [138.441696, -2.768596],
              [138.456223, -2.865694],
              [138.421799, -2.927594],
              [138.299744, -2.95566],
              [138.234619, -3.023306],
              [137.90802, -3.043166],
              [137.785339, -3.060651],
              [137.676544, -3.04416],
              [137.620621, -3.017284],
              [137.568817, -3.016699],
              [137.526779, -3.056891],
              [137.42363, -3.054462],
              [137.351212, -3.084761],
              [137.283081, -3.153617],
              [137.219757, -3.176061],
              [137.165848, -3.175012],
              [137.152542, -3.328905],
              [137.084717, -3.585353],
              [137.064789, -3.699162],
              [137.046936, -3.865869],
              [137.000381, -4.041937],
              [136.984039, -4.168376],
              [137.360977, -4.177821],
              [137.454086, -4.172867],
              [137.571579, -4.179802],
              [137.649048, -4.171586],
              [137.791611, -4.178586],
              [137.754501, -4.287362],
              [137.726715, -4.397165],
              [137.741455, -4.483698],
              [137.812637, -4.593312],
              [137.802963, -4.649354],
              [137.754623, -4.787083],
              [137.707184, -4.882552],
              [137.643494, -4.991616],
              [137.5754, -4.97274],
              [137.5749, -5.02392],
              [137.5459, -5.05635],
              [137.5493, -5.11608],
              [137.4957, -5.14497],
              [137.4295, -5.08173],
              [137.4352, -5.04886],
              [137.4227, -5.00131],
              [137.4426, -4.99766],
              [137.4559, -4.99099],
              [137.4512, -4.96007],
              [137.4606, -4.91816],
              [137.437, -4.92056],
              [137.4276, -4.94727],
              [137.4042, -4.95342],
              [137.358, -5.00455],
              [137.3392, -5.04048],
              [137.2933, -5.00227],
              [137.3231, -4.96738],
              [137.3761, -4.95532],
              [137.3802, -4.89758],
              [137.3718, -4.90639],
              [137.3694, -4.88779],
              [137.3599, -4.88295],
              [137.3299, -4.88794],
              [137.2874, -4.89557],
              [137.1937, -4.99794],
              [137.1275, -4.96503],
              [137.1414, -4.94367],
              [137.1335, -4.90498],
              [137.1466, -4.88588],
              [137.118, -4.86611],
              [137.0629, -4.79453],
              [137.0403, -4.88397],
              [137.0064, -4.93848],
              [136.9285, -4.90049],
              [136.9526, -4.8644],
              [136.8839, -4.85878],
              [136.8848, -4.84588],
              [136.8364, -4.81556],
              [136.7471, -4.81085],
              [136.6968, -4.79487],
              [136.6101, -4.81935],
              [136.5387, -4.78693],
              [136.4556, -4.71422],
              [136.4237, -4.71704],
              [136.362, -4.67861],
              [136.2956, -4.67493],
              [136.115, -4.62324],
              [135.94652, -4.503829],
              [136.034225, -4.415998],
              [136.082077, -4.355084],
              [136.156723, -4.188222],
              [136.001068, -4.197879],
              [135.796555, -4.188828],
              [135.777115, -4.163929],
              [135.728455, -3.993873],
              [135.699463, -3.917633],
              [135.62262, -3.875976],
              [135.579041, -3.867941],
              [135.248428, -3.750056],
              [135.187973, -3.739861],
              [135.11734, -3.703091],
              [134.961925, -3.650414],
              [134.938074, -3.644094],
              [134.525314, -3.505847],
              [134.41301, -3.474069],
              [134.237518, -3.397094],
              [134.209564, -3.393168],
              [134.237625, -3.31085],
              [134.32309, -3.184835],
              [134.426544, -3.112016],
              [134.439407, -3.066184],
              [134.427872, -3.008884],
              [134.493637, -2.971269],
              [134.598938, -2.891298],
              [134.659012, -2.825982],
            ],
          ],
          [
            [
              [135.8203, -1.03004],
              [135.7901, -0.97918],
              [135.751, -0.8276],
              [135.6973, -0.82765],
              [135.6508, -0.89001],
              [135.5839, -0.84537],
              [135.5323, -0.78764],
              [135.4871, -0.80759],
              [135.4171, -0.75624],
              [135.3572, -0.63355],
              [135.4097, -0.65253],
              [135.444, -0.64149],
              [135.5323, -0.66498],
              [135.6369, -0.65996],
              [135.6809, -0.68231],
              [135.6861, -0.71373],
              [135.786, -0.72102],
              [135.8069, -0.68588],
              [135.8676, -0.70161],
              [135.9243, -0.78479],
              [136.0377, -0.84037],
              [136.0992, -0.93096],
              [136.1096, -0.97037],
              [136.1527, -0.99671],
              [136.168, -1.04173],
              [136.26, -1.01474],
              [136.327, -1.07566],
              [136.3868, -1.08441],
              [136.3166, -1.14142],
              [136.2406, -1.17273],
              [136.1752, -1.18243],
              [136.1435, -1.20381],
              [136.0726, -1.19032],
              [136.0294, -1.14978],
              [135.9416, -1.15075],
              [135.9035, -1.18099],
              [135.8675, -1.17632],
              [135.8192, -1.137],
              [135.8203, -1.03004],
            ],
          ],
          [
            [
              [136.392276, -1.718573],
              [136.4572, -1.69894],
              [136.5898, -1.73049],
              [136.6877, -1.72906],
              [136.8, -1.74064],
              [136.8744, -1.77647],
              [136.8549, -1.82772],
              [136.8063, -1.81319],
              [136.7315, -1.8176],
              [136.6339, -1.87459],
              [136.5189, -1.86918],
              [136.4433, -1.88988],
              [136.3141, -1.86483],
              [136.2675, -1.8921],
              [136.1496, -1.85554],
              [136.0714, -1.85143],
              [136.029, -1.82191],
              [135.9817, -1.82025],
              [135.9133, -1.75939],
              [135.8686, -1.74268],
              [135.8322, -1.7576],
              [135.7611, -1.71081],
              [135.7095, -1.69274],
              [135.6659, -1.7064],
              [135.6212, -1.68596],
              [135.472, -1.67714],
              [135.4395, -1.61121],
              [135.4796, -1.59801],
              [135.6222, -1.62803],
              [135.688, -1.6224],
              [135.7854, -1.62997],
              [135.8704, -1.66133],
              [135.9178, -1.63767],
              [136.008, -1.65002],
              [136.2118, -1.65203],
              [136.3261, -1.70238],
              [136.392276, -1.718573],
            ],
          ],
          [
            [
              [134.960559, -1.112146],
              [134.8994, -1.13256],
              [134.8446, -1.09685],
              [134.7993, -1.02348],
              [134.8011, -0.97169],
              [134.8475, -0.92392],
              [134.887, -0.92589],
              [134.9576, -0.96448],
              [134.9691, -1.04007],
              [134.9922, -1.06263],
              [134.960559, -1.112146],
            ],
          ],
          [
            [
              [137.4066, -5.10619],
              [137.3425, -5.04355],
              [137.3813, -4.99907],
              [137.3965, -4.96199],
              [137.4496, -4.95517],
              [137.4457, -4.99547],
              [137.4177, -5.00039],
              [137.4281, -5.04272],
              [137.4066, -5.10619],
            ],
          ],
          [
            [
              [137.2848, -5.00061],
              [137.2375, -4.99203],
              [137.2807, -4.90879],
              [137.3096, -4.91623],
              [137.3337, -4.89132],
              [137.3574, -4.88517],
              [137.3682, -4.88886],
              [137.3703, -4.92277],
              [137.3638, -4.95254],
              [137.3083, -4.96068],
              [137.2848, -5.00061],
            ],
          ],
          [
            [
              [135.2005, -1.51525],
              [135.1572, -1.52035],
              [135.1407, -1.47125],
              [135.2169, -1.47743],
              [135.2405, -1.49947],
              [135.2946, -1.47126],
              [135.3245, -1.50552],
              [135.2549, -1.5251],
              [135.2005, -1.51525],
            ],
          ],
          [
            [
              [136.8761, -4.88497],
              [136.8243, -4.90832],
              [136.7907, -4.88613],
              [136.8093, -4.8353],
              [136.8517, -4.8325],
              [136.8817, -4.84502],
              [136.8761, -4.88497],
            ],
          ],
          [
            [
              [137.1156, -4.95927],
              [137.0597, -4.93759],
              [137.0977, -4.87618],
              [137.1344, -4.88006],
              [137.1271, -4.94609],
              [137.1156, -4.95927],
            ],
          ],
          [
            [
              [136.6864, -4.82538],
              [136.7664, -4.81189],
              [136.7528, -4.87211],
              [136.6864, -4.82538],
            ],
          ],
          [
            [
              [137.597585, -5.095956],
              [137.596909, -5.097713],
              [137.5517, -5.07024],
              [137.58, -5.03798],
              [137.614728, -5.051398],
              [137.597585, -5.095956],
            ],
          ],
        ],
      },
      properties: {
        ID: 16,
        kode: 84,
        Propinsi: "IRIAN JAYA TENGAH",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [122.6195, -8.40327],
              [122.6706, -8.39535],
              [122.729, -8.36376],
              [122.7693, -8.36789],
              [122.8147, -8.30079],
              [122.877, -8.29701],
              [122.8978, -8.27617],
              [122.9248, -8.19796],
              [122.8688, -8.17643],
              [122.8155, -8.19872],
              [122.7905, -8.23832],
              [122.7432, -8.21051],
              [122.7834, -8.16046],
              [122.7859, -8.11148],
              [122.8321, -8.10133],
              [122.8691, -8.06508],
              [122.9764, -8.14032],
              [122.9647, -8.19849],
              [123.0159, -8.27969],
              [123.0215, -8.32064],
              [122.9648, -8.3543],
              [122.9302, -8.33062],
              [122.8828, -8.38951],
              [122.8928, -8.45255],
              [122.7916, -8.43101],
              [122.783, -8.46163],
              [122.8367, -8.52308],
              [122.8381, -8.59432],
              [122.73, -8.6417],
              [122.6701, -8.63274],
              [122.5684, -8.67269],
              [122.5333, -8.71705],
              [122.3794, -8.76204],
              [122.3508, -8.75008],
              [122.2867, -8.764],
              [122.1999, -8.75926],
              [122.1256, -8.73763],
              [122.0858, -8.75021],
              [122.0352, -8.80331],
              [121.9284, -8.8417],
              [121.8651, -8.84578],
              [121.8184, -8.88101],
              [121.7381, -8.88868],
              [121.718, -8.86764],
              [121.6379, -8.85644],
              [121.5642, -8.81199],
              [121.5405, -8.82249],
              [121.3854, -8.79636],
              [121.3637, -8.82703],
              [121.3611, -8.89566],
              [121.3111, -8.92514],
              [121.2515, -8.90006],
              [121.1267, -8.90792],
              [121.0708, -8.9397],
              [120.9975, -8.9596],
              [120.9069, -8.92252],
              [120.8565, -8.87337],
              [120.827, -8.82198],
              [120.7782, -8.83222],
              [120.7668, -8.87329],
              [120.7266, -8.87758],
              [120.6248, -8.83975],
              [120.6032, -8.80507],
              [120.5406, -8.79251],
              [120.5014, -8.80494],
              [120.3745, -8.79988],
              [120.3207, -8.83812],
              [120.2396, -8.83066],
              [120.1793, -8.77632],
              [120.1168, -8.78019],
              [120.0637, -8.80548],
              [119.9371, -8.8341],
              [119.8216, -8.78411],
              [119.7994, -8.73838],
              [119.8127, -8.65842],
              [119.8019, -8.57549],
              [119.9154, -8.46571],
              [119.9588, -8.44277],
              [120.0002, -8.45481],
              [120.0043, -8.40762],
              [120.034, -8.37535],
              [120.0896, -8.38059],
              [120.1253, -8.35436],
              [120.1542, -8.37109],
              [120.2008, -8.29826],
              [120.26, -8.27393],
              [120.4055, -8.27102],
              [120.4419, -8.23714],
              [120.4648, -8.28285],
              [120.5241, -8.2609],
              [120.5603, -8.29137],
              [120.6582, -8.28036],
              [120.6896, -8.32071],
              [120.7431, -8.34189],
              [120.8114, -8.3341],
              [120.8595, -8.35319],
              [120.9799, -8.34873],
              [120.9947, -8.39561],
              [121.1606, -8.44687],
              [121.1876, -8.44131],
              [121.2386, -8.47818],
              [121.306, -8.49264],
              [121.3439, -8.5619],
              [121.4223, -8.58897],
              [121.4573, -8.56206],
              [121.5044, -8.61735],
              [121.6049, -8.54726],
              [121.6083, -8.49767],
              [121.6751, -8.51677],
              [121.7317, -8.50041],
              [121.808, -8.51648],
              [121.8425, -8.48957],
              [121.9275, -8.49713],
              [121.9504, -8.45808],
              [122.0267, -8.44553],
              [122.0162, -8.52998],
              [122.1068, -8.53397],
              [122.1615, -8.55955],
              [122.227, -8.62796],
              [122.2939, -8.65004],
              [122.3636, -8.61847],
              [122.4794, -8.61589],
              [122.5221, -8.55195],
              [122.4879, -8.51239],
              [122.4979, -8.46913],
              [122.5472, -8.46883],
              [122.6013, -8.39854],
              [122.6195, -8.40327],
            ],
          ],
          [
            [
              [124.046476, -9.342838],
              [124.0983, -9.41876],
              [124.157, -9.42968],
              [124.2065, -9.37529],
              [124.2687, -9.41875],
              [124.292, -9.50619],
              [124.3536, -9.47901],
              [124.3785, -9.35658],
              [124.4542, -9.31137],
              [124.472284, -9.18241],
              [124.5359, -9.19723],
              [124.6204, -9.16331],
              [124.6696, -9.12933],
              [124.7107, -9.05728],
              [124.7393, -9.06466],
              [124.8162, -9.01365],
              [124.8541, -9.01698],
              [124.8877, -8.98574],
              [124.949953, -8.967377],
              [124.9422, -9.04616],
              [124.9889, -9.06706],
              [125.0678, -9.02986],
              [125.1141, -8.97605],
              [125.182, -9.02754],
              [125.1877, -9.05588],
              [125.1722, -9.17896],
              [125.1018, -9.2008],
              [125.0577, -9.17553],
              [125.0155, -9.17832],
              [124.9847, -9.21434],
              [125.0001, -9.298],
              [125.0505, -9.33069],
              [125.0769, -9.38772],
              [125.092, -9.46447],
              [125.0071, -9.53962],
              [124.9834, -9.64711],
              [124.888, -9.70606],
              [124.8459, -9.74556],
              [124.7576, -9.86098],
              [124.7463, -9.89112],
              [124.6665, -9.93153],
              [124.6529, -9.95679],
              [124.5237, -10.03156],
              [124.4956, -10.09939],
              [124.4146, -10.1587],
              [124.379, -10.17015],
              [124.2336, -10.16353],
              [124.1707, -10.15307],
              [124.0928, -10.18045],
              [124.0698, -10.22167],
              [124.015, -10.26787],
              [123.9107, -10.29561],
              [123.8572, -10.3338],
              [123.7969, -10.3545],
              [123.7041, -10.36645],
              [123.6575, -10.3507],
              [123.6052, -10.36942],
              [123.5448, -10.3308],
              [123.4568, -10.3559],
              [123.5192, -10.22132],
              [123.5297, -10.17631],
              [123.6574, -10.13518],
              [123.7442, -10.09523],
              [123.7477, -10.0302],
              [123.6479, -10.00887],
              [123.5803, -10.03243],
              [123.5828, -9.92561],
              [123.6492, -9.89468],
              [123.6688, -9.81791],
              [123.6432, -9.77904],
              [123.6799, -9.75675],
              [123.6675, -9.64933],
              [123.7317, -9.57434],
              [123.7766, -9.55646],
              [123.7921, -9.4971],
              [123.8279, -9.50221],
              [123.9361, -9.42633],
              [123.9833, -9.35745],
              [124.046476, -9.342838],
            ],
          ],
          [
            [
              [119.6801, -9.82812],
              [119.6782, -9.794],
              [119.6154, -9.79994],
              [119.5839, -9.77393],
              [119.5296, -9.76832],
              [119.4709, -9.74123],
              [119.4114, -9.76779],
              [119.389, -9.79727],
              [119.3501, -9.76086],
              [119.3025, -9.74462],
              [119.1805, -9.74634],
              [119.0334, -9.68038],
              [118.9853, -9.60762],
              [118.9281, -9.55858],
              [118.9442, -9.51227],
              [118.9939, -9.45514],
              [119.0562, -9.42444],
              [119.0918, -9.42535],
              [119.1876, -9.37625],
              [119.2117, -9.39543],
              [119.3002, -9.36271],
              [119.3813, -9.38571],
              [119.4637, -9.35966],
              [119.5257, -9.38095],
              [119.5944, -9.34502],
              [119.6311, -9.3427],
              [119.6837, -9.37922],
              [119.7638, -9.38235],
              [119.7973, -9.39664],
              [119.9419, -9.28043],
              [119.9617, -9.32425],
              [120.1028, -9.47526],
              [120.1738, -9.46924],
              [120.2226, -9.4938],
              [120.2371, -9.59441],
              [120.2569, -9.644],
              [120.3607, -9.64701],
              [120.4517, -9.6284],
              [120.4984, -9.70313],
              [120.5523, -9.71446],
              [120.6071, -9.79198],
              [120.6418, -9.87525],
              [120.696, -9.91756],
              [120.7276, -9.91889],
              [120.7962, -9.97115],
              [120.8238, -10.02323],
              [120.8192, -10.08719],
              [120.7595, -10.13127],
              [120.7342, -10.17339],
              [120.6687, -10.22046],
              [120.6116, -10.24588],
              [120.5584, -10.23103],
              [120.4863, -10.26146],
              [120.4706, -10.30381],
              [120.4281, -10.30201],
              [120.3543, -10.24881],
              [120.2091, -10.25562],
              [120.1432, -10.22396],
              [120.0313, -10.1239],
              [119.9956, -10.0374],
              [119.9365, -9.98009],
              [119.8393, -9.92739],
              [119.7904, -9.92132],
              [119.7625, -9.89042],
              [119.7087, -9.86856],
              [119.6801, -9.82812],
            ],
          ],
          [
            [
              [124.3539, -8.38033],
              [124.4209, -8.29541],
              [124.4662, -8.25809],
              [124.5091, -8.26198],
              [124.5163, -8.22269],
              [124.4021, -8.27409],
              [124.3938, -8.22276],
              [124.4739, -8.13038],
              [124.5972, -8.12891],
              [124.5932, -8.19252],
              [124.6703, -8.16927],
              [124.7815, -8.15302],
              [124.8305, -8.17319],
              [125.0117, -8.16488],
              [125.0878, -8.15317],
              [125.1393, -8.22922],
              [125.1257, -8.34491],
              [125.0513, -8.36068],
              [124.8983, -8.37272],
              [124.7889, -8.40916],
              [124.6638, -8.39523],
              [124.5907, -8.4354],
              [124.5477, -8.44335],
              [124.5042, -8.41943],
              [124.4346, -8.45537],
              [124.3665, -8.44637],
              [124.3329, -8.4252],
              [124.3539, -8.38033],
            ],
          ],
          [
            [
              [123.5276, -8.36233],
              [123.5595, -8.36871],
              [123.5963, -8.33362],
              [123.5953, -8.28771],
              [123.6222, -8.2463],
              [123.7002, -8.25179],
              [123.6976, -8.21665],
              [123.7785, -8.16919],
              [123.8399, -8.21125],
              [123.9044, -8.21861],
              [123.911, -8.25849],
              [123.8382, -8.28234],
              [123.7639, -8.28847],
              [123.6734, -8.42844],
              [123.6204, -8.4061],
              [123.5531, -8.4716],
              [123.5829, -8.54344],
              [123.5455, -8.57183],
              [123.5049, -8.52237],
              [123.4487, -8.53498],
              [123.4261, -8.57759],
              [123.3883, -8.58505],
              [123.3148, -8.52816],
              [123.2864, -8.5495],
              [123.234, -8.51171],
              [123.2912, -8.4659],
              [123.3403, -8.39887],
              [123.4284, -8.37077],
              [123.4715, -8.3368],
              [123.4363, -8.27322],
              [123.5372, -8.23502],
              [123.5591, -8.25389],
              [123.5023, -8.33801],
              [123.5276, -8.36233],
            ],
          ],
          [
            [
              [123.1958, -10.58802],
              [123.2635, -10.51636],
              [123.4078, -10.45521],
              [123.3768, -10.55145],
              [123.4286, -10.65562],
              [123.3069, -10.70394],
              [123.2572, -10.73333],
              [123.2161, -10.81699],
              [123.1347, -10.81048],
              [123.0742, -10.84782],
              [122.968, -10.86041],
              [122.9112, -10.91875],
              [122.8847, -10.9029],
              [122.8195, -10.91942],
              [122.8198, -10.82761],
              [122.8044, -10.80657],
              [122.8534, -10.75584],
              [122.983, -10.7299],
              [123.0472, -10.72532],
              [123.1265, -10.64254],
              [123.178, -10.63356],
              [123.1958, -10.58802],
            ],
          ],
          [
            [
              [123.9428, -8.40262],
              [123.9618, -8.34731],
              [124.057, -8.294],
              [124.1009, -8.36503],
              [124.1639, -8.29119],
              [124.2086, -8.25939],
              [124.2258, -8.21369],
              [124.2778, -8.17424],
              [124.3162, -8.18454],
              [124.3197, -8.23096],
              [124.2932, -8.27001],
              [124.2955, -8.33138],
              [124.2429, -8.36111],
              [124.2035, -8.41588],
              [124.2077, -8.4605],
              [124.1385, -8.53854],
              [124.0619, -8.55243],
              [124.0617, -8.50392],
              [124.0329, -8.42446],
              [123.9428, -8.40262],
            ],
          ],
          [
            [
              [123.165, -8.39172],
              [123.1103, -8.40389],
              [123.0019, -8.40353],
              [123.0336, -8.31035],
              [123.1511, -8.23307],
              [123.2464, -8.2287],
              [123.3056, -8.29655],
              [123.308, -8.36417],
              [123.2869, -8.39409],
              [123.165, -8.39172],
            ],
          ],
          [
            [
              [121.8719, -10.6122],
              [121.7565, -10.6104],
              [121.6963, -10.57643],
              [121.8228, -10.5064],
              [121.8566, -10.44256],
              [121.8946, -10.42433],
              [121.9928, -10.43927],
              [121.9944, -10.5213],
              [121.8719, -10.6122],
            ],
          ],
          [
            [
              [119.4425, -8.75035],
              [119.3824, -8.72088],
              [119.4106, -8.6473],
              [119.3781, -8.58177],
              [119.4252, -8.54893],
              [119.4231, -8.44954],
              [119.4765, -8.45059],
              [119.5122, -8.49349],
              [119.5754, -8.4895],
              [119.5624, -8.58388],
              [119.5005, -8.58569],
              [119.4598, -8.61698],
              [119.4358, -8.67357],
              [119.4581, -8.69916],
              [119.4425, -8.75035],
            ],
          ],
          [
            [
              [123.3282, -10.3451],
              [123.3042, -10.26902],
              [123.3686, -10.20903],
              [123.3919, -10.14384],
              [123.4535, -10.11986],
              [123.4662, -10.16577],
              [123.4525, -10.22088],
              [123.4184, -10.2343],
              [123.4236, -10.29639],
              [123.3737, -10.30972],
              [123.3282, -10.3451],
            ],
          ],
          [
            [
              [123, -8.49739],
              [122.9458, -8.59493],
              [122.8954, -8.61934],
              [122.8738, -8.5781],
              [122.8848, -8.53292],
              [122.9503, -8.48984],
              [122.9816, -8.43728],
              [123.12, -8.4369],
              [123.1484, -8.46317],
              [123.0802, -8.49089],
              [123.0229, -8.47906],
              [123, -8.49739],
            ],
          ],
          [
            [
              [119.6352, -8.60281],
              [119.666, -8.61178],
              [119.6742, -8.68424],
              [119.7117, -8.65867],
              [119.7274, -8.61727],
              [119.8027, -8.61757],
              [119.8027, -8.65049],
              [119.7245, -8.68237],
              [119.7173, -8.79128],
              [119.6133, -8.74851],
              [119.6432, -8.65507],
              [119.6352, -8.60281],
            ],
          ],
          [
            [
              [125.5443, -8.30659],
              [125.5038, -8.26816],
              [125.5505, -8.22926],
              [125.6169, -8.14468],
              [125.6448, -8.17069],
              [125.6089, -8.23812],
              [125.5862, -8.31465],
              [125.5443, -8.30659],
            ],
          ],
          [
            [
              [122.3898, -8.50424],
              [122.3571, -8.49548],
              [122.3385, -8.44026],
              [122.3804, -8.43406],
              [122.4208, -8.48212],
              [122.3898, -8.50424],
            ],
          ],
          [
            [
              [121.7251, -8.36166],
              [121.681, -8.3275],
              [121.7321, -8.30295],
              [121.7251, -8.36166],
            ],
          ],
        ],
      },
      properties: {
        ID: 17,
        kode: 53,
        Propinsi: "NUSA TENGGARA TIMUR",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [115.194942, -8.732323],
              [115.180252, -8.757741],
              [115.23497, -8.799568],
              [115.170486, -8.847422],
              [115.087341, -8.835617],
              [115.089859, -8.811961],
              [115.161148, -8.779349],
              [115.166382, -8.717985],
              [115.140114, -8.66686],
              [115.097687, -8.638191],
              [115.068596, -8.591315],
              [114.929085, -8.474201],
              [114.801537, -8.414222],
              [114.714836, -8.394822],
              [114.627663, -8.407767],
              [114.575851, -8.388434],
              [114.522606, -8.33624],
              [114.445526, -8.21899],
              [114.428268, -8.168071],
              [114.438026, -8.0937],
              [114.48764, -8.09358],
              [114.536934, -8.138295],
              [114.582436, -8.116596],
              [114.692444, -8.144426],
              [114.744499, -8.167377],
              [114.853981, -8.195267],
              [114.997383, -8.17379],
              [115.114624, -8.079416],
              [115.179657, -8.058906],
              [115.338425, -8.111131],
              [115.452835, -8.162612],
              [115.564995, -8.233503],
              [115.634735, -8.322159],
              [115.701965, -8.354779],
              [115.716316, -8.397886],
              [115.680428, -8.443698],
              [115.644066, -8.459964],
              [115.609451, -8.505023],
              [115.543221, -8.499585],
              [115.499054, -8.547023],
              [115.44661, -8.566829],
              [115.359718, -8.577768],
              [115.264145, -8.655357],
              [115.260429, -8.703996],
              [115.194942, -8.732323],
            ],
          ],
          [
            [
              [115.623787, -8.773455],
              [115.588226, -8.818159],
              [115.484924, -8.767053],
              [115.445183, -8.722188],
              [115.488922, -8.672191],
              [115.565613, -8.670997],
              [115.623749, -8.750002],
              [115.623787, -8.773455],
            ],
          ],
        ],
      },
      properties: {
        ID: 18,
        kode: 51,
        Propinsi: "BALI",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [100.33075, 2.534235],
              [100.362923, 2.389775],
              [100.434563, 2.274679],
              [100.564941, 2.163499],
              [100.676041, 2.129844],
              [100.794281, 2.066174],
              [100.871442, 1.975428],
              [100.881836, 2.042196],
              [100.789459, 2.12244],
              [100.765831, 2.22986],
              [100.803841, 2.273186],
              [100.93557, 2.299736],
              [101.056686, 2.283606],
              [101.066132, 2.229236],
              [101.164253, 2.139924],
              [101.263565, 2.08419],
              [101.320526, 1.995129],
              [101.333084, 1.895614],
              [101.359558, 1.824198],
              [101.361557, 1.76134],
              [101.408241, 1.700851],
              [101.477303, 1.686206],
              [101.546852, 1.641194],
              [101.603996, 1.641009],
              [101.659866, 1.667564],
              [101.756844, 1.664385],
              [101.826736, 1.599705],
              [102.047836, 1.43117],
              [102.1455, 1.381307],
              [102.145378, 1.295651],
              [102.159187, 1.234358],
              [102.202477, 1.201452],
              [102.184792, 1.104687],
              [102.242256, 0.973765],
              [102.320862, 0.917839],
              [102.392334, 0.820976],
              [102.492737, 0.755373],
              [102.581657, 0.739578],
              [102.692642, 0.755734],
              [102.768555, 0.732514],
              [102.875893, 0.734737],
              [102.908607, 0.713944],
              [103.013893, 0.589782],
              [103.050728, 0.527877],
              [103.112839, 0.460048],
              [103.177315, 0.464677],
              [103.235756, 0.5002],
              [103.292587, 0.511416],
              [103.327034, 0.548835],
              [103.408028, 0.526535],
              [103.495544, 0.45212],
              [103.580421, 0.397274],
              [103.602257, 0.349138],
              [103.651825, 0.340746],
              [103.726089, 0.279325],
              [103.765465, 0.19601],
              [103.771179, 0.110799],
              [103.802605, -0.003097],
              [103.677681, -0.0214],
              [103.594849, -0.021255],
              [103.572601, -0.061704],
              [103.588943, -0.09978],
              [103.499954, -0.120131],
              [103.500122, -0.186988],
              [103.531258, -0.223075],
              [103.622711, -0.229643],
              [103.760757, -0.304756],
              [103.773033, -0.342722],
              [103.693321, -0.351822],
              [103.611321, -0.391451],
              [103.597794, -0.438134],
              [103.50383, -0.506391],
              [103.425537, -0.521972],
              [103.415939, -0.673044],
              [103.401497, -0.728957],
              [103.459114, -0.741822],
              [103.482338, -0.771407],
              [103.342834, -0.785774],
              [103.126038, -0.746429],
              [103.008949, -0.755179],
              [102.90477, -0.894179],
              [102.808495, -0.96665],
              [102.780602, -1.023676],
              [102.687668, -1.078578],
              [102.638, -1.12041],
              [102.616814, -1.094634],
              [102.495422, -1.084733],
              [102.44252, -1.025452],
              [102.393372, -1.03016],
              [102.38623, -0.993385],
              [102.324387, -0.960805],
              [102.304298, -0.916958],
              [102.254326, -0.921539],
              [102.217072, -0.946344],
              [102.16272, -0.898907],
              [102.116615, -0.921174],
              [102.057976, -0.889922],
              [101.983551, -0.880408],
              [101.924278, -0.941475],
              [101.845284, -0.982566],
              [101.821533, -0.977189],
              [101.747887, -0.967631],
              [101.6539, -0.935173],
              [101.595917, -0.8512],
              [101.500015, -0.807856],
              [101.407272, -0.736848],
              [101.359833, -0.659254],
              [101.256569, -0.626754],
              [101.181267, -0.549494],
              [101.06768, -0.498227],
              [101.024826, -0.398442],
              [101.031708, -0.340868],
              [100.996368, -0.314363],
              [100.94133, -0.312437],
              [100.910423, -0.370743],
              [100.833672, -0.318377],
              [100.838776, -0.290596],
              [100.781006, -0.23042],
              [100.748398, -0.065219],
              [100.770103, -0.033094],
              [100.753342, 0.048535],
              [100.792191, 0.120829],
              [100.778122, 0.169268],
              [100.727119, 0.193165],
              [100.687881, 0.177849],
              [100.605743, 0.183473],
              [100.464088, 0.222191],
              [100.43718, 0.247852],
              [100.394623, 0.339763],
              [100.361946, 0.354324],
              [100.281677, 0.332958],
              [100.282326, 0.408069],
              [100.219559, 0.524918],
              [100.205925, 0.593234],
              [100.25573, 0.709395],
              [100.183716, 0.761895],
              [100.140305, 0.797662],
              [100.132614, 0.891546],
              [100.092819, 0.915075],
              [100.136681, 0.949117],
              [100.161377, 0.999156],
              [100.108658, 1.071883],
              [100.16082, 1.151491],
              [100.130478, 1.174596],
              [100.123497, 1.254924],
              [100.086632, 1.337513],
              [100.077881, 1.387209],
              [100.242752, 1.392655],
              [100.27504, 1.421141],
              [100.315857, 1.416456],
              [100.408829, 1.470363],
              [100.434021, 1.51129],
              [100.415886, 1.537141],
              [100.455269, 1.629147],
              [100.408333, 1.777267],
              [100.292068, 1.802315],
              [100.2864, 1.830302],
              [100.322128, 1.919015],
              [100.322708, 2.014011],
              [100.360146, 2.220377],
              [100.309578, 2.34065],
              [100.302208, 2.413565],
              [100.33075, 2.534235],
            ],
          ],
          [
            [
              [108.213249, 3.660967],
              [108.145363, 3.656269],
              [108.090088, 3.689779],
              [108.121384, 3.746899],
              [108.193169, 3.782784],
              [108.057747, 3.840364],
              [107.993073, 3.944517],
              [107.992523, 4.001115],
              [108.024544, 4.05512],
              [108.159416, 4.156719],
              [108.219208, 4.23136],
              [108.235611, 4.142495],
              [108.353798, 3.995702],
              [108.39946, 3.961394],
              [108.363083, 3.906998],
              [108.397324, 3.843829],
              [108.383583, 3.793785],
              [108.332558, 3.764783],
              [108.303047, 3.711598],
              [108.298058, 3.657955],
              [108.213249, 3.660967],
            ],
          ],
          [
            [
              [102.741936, 0.999997],
              [102.875, 0.950768],
              [102.947105, 0.880741],
              [103.044373, 0.81963],
              [103.043564, 0.715239],
              [102.987938, 0.688592],
              [102.917938, 0.77083],
              [102.862343, 0.790303],
              [102.649368, 0.792709],
              [102.586555, 0.76779],
              [102.549171, 0.771829],
              [102.437584, 0.82304],
              [102.401031, 0.885348],
              [102.440208, 0.94073],
              [102.494484, 0.944173],
              [102.455399, 1.080998],
              [102.477661, 1.129279],
              [102.553482, 1.106387],
              [102.610809, 1.043692],
              [102.60675, 0.999961],
              [102.645287, 0.992393],
              [102.716949, 1.021772],
              [102.741936, 0.999997],
            ],
          ],
          [
            [
              [101.550018, 2.049355],
              [101.584991, 2.093398],
              [101.650665, 2.129003],
              [101.700661, 2.101626],
              [101.775391, 1.987949],
              [101.781464, 1.939942],
              [101.74881, 1.886655],
              [101.725937, 1.765926],
              [101.614433, 1.691191],
              [101.54435, 1.700312],
              [101.452621, 1.72754],
              [101.380241, 1.910578],
              [101.404243, 2.02781],
              [101.458221, 2.066609],
              [101.514557, 2.038702],
              [101.550018, 2.049355],
            ],
          ],
          [
            [
              [104.441788, 1.000026],
              [104.452881, 1.052393],
              [104.405533, 1.049243],
              [104.375732, 1.007681],
              [104.256355, 1.000437],
              [104.218933, 1.059426],
              [104.246948, 1.119971],
              [104.330986, 1.135926],
              [104.328674, 1.17625],
              [104.40876, 1.200025],
              [104.502739, 1.166343],
              [104.579559, 1.190691],
              [104.601936, 1.123461],
              [104.637215, 1.106006],
              [104.661194, 1.028653],
              [104.634094, 0.985276],
              [104.661095, 0.913697],
              [104.620926, 0.900784],
              [104.596199, 0.809995],
              [104.520187, 0.825029],
              [104.469894, 0.895247],
              [104.438522, 0.905684],
              [104.413925, 0.978132],
              [104.441788, 1.000026],
            ],
          ],
          [
            [
              [102.484398, 1.230953],
              [102.435005, 1.082111],
              [102.480042, 0.952238],
              [102.431023, 0.951212],
              [102.383041, 0.913335],
              [102.269463, 1.011383],
              [102.229759, 1.100537],
              [102.244522, 1.175666],
              [102.232033, 1.228805],
              [102.200645, 1.281],
              [102.224716, 1.371547],
              [102.205742, 1.40446],
              [102.289612, 1.414108],
              [102.390457, 1.311438],
              [102.484398, 1.230953],
            ],
          ],
          [
            [
              [102.795776, 1.000016],
              [102.737602, 1.040723],
              [102.66552, 1.019954],
              [102.643684, 1.029967],
              [102.682579, 1.116312],
              [102.752777, 1.160637],
              [102.896072, 1.125253],
              [102.977699, 1.089758],
              [103.157661, 0.899007],
              [103.155228, 0.854442],
              [103.118317, 0.829402],
              [103.072906, 0.829355],
              [102.950806, 0.904578],
              [102.883041, 0.966831],
              [102.795776, 1.000016],
            ],
          ],
          [
            [
              [102.000031, 1.593896],
              [102.000038, 1.608987],
              [102.071304, 1.605067],
              [102.153107, 1.576183],
              [102.391418, 1.540815],
              [102.456825, 1.522157],
              [102.494095, 1.468971],
              [102.490135, 1.376907],
              [102.50473, 1.254684],
              [102.37912, 1.338947],
              [102.292534, 1.428501],
              [102.130852, 1.456275],
              [102.028893, 1.531343],
              [102.000031, 1.593896],
            ],
          ],
          [
            [
              [104.587982, -0.001046],
              [104.634552, -0.036573],
              [104.714676, -0.129949],
              [104.757545, -0.194323],
              [104.821968, -0.198356],
              [104.84967, -0.154723],
              [104.931068, -0.282113],
              [104.884857, -0.32986],
              [104.851585, -0.323027],
              [104.755508, -0.254594],
              [104.703392, -0.2068],
              [104.519646, -0.277426],
              [104.49527, -0.243854],
              [104.444038, -0.245061],
              [104.427109, -0.189364],
              [104.506004, -0.130405],
              [104.510231, -0.062901],
              [104.555153, 0.02059],
              [104.587982, -0.001046],
            ],
          ],
          [
            [
              [104.360847, -0.43435],
              [104.430298, -0.386733],
              [104.436867, -0.345575],
              [104.531227, -0.37152],
              [104.567345, -0.44569],
              [104.587936, -0.458311],
              [104.533531, -0.541552],
              [104.49633, -0.649201],
              [104.439606, -0.585404],
              [104.394119, -0.604232],
              [104.386627, -0.668835],
              [104.344971, -0.655768],
              [104.32827, -0.555694],
              [104.25972, -0.498662],
              [104.276909, -0.39852],
              [104.330231, -0.444767],
              [104.360847, -0.43435],
            ],
          ],
          [
            [
              [103.500015, 0.644402],
              [103.487564, 0.623711],
              [103.399925, 0.647164],
              [103.350235, 0.738552],
              [103.340958, 0.805172],
              [103.372414, 0.898962],
              [103.449806, 0.857083],
              [103.496262, 0.839627],
              [103.530487, 0.796562],
              [103.500023, 0.706684],
              [103.500015, 0.644402],
            ],
          ],
          [
            [
              [103.961716, 1.001489],
              [103.904411, 1.068974],
              [103.939903, 1.079649],
              [103.920464, 1.13549],
              [103.993462, 1.143957],
              [104.010437, 1.182228],
              [104.050423, 1.156895],
              [104.103302, 1.196005],
              [104.153152, 1.135705],
              [104.138786, 1.034731],
              [104.087837, 1.025422],
              [104.007751, 0.983712],
              [103.961716, 1.001489],
            ],
          ],
          [
            [
              [103.201187, 0.704497],
              [103.270828, 0.663601],
              [103.299568, 0.598013],
              [103.280022, 0.528634],
              [103.210289, 0.521106],
              [103.157341, 0.500907],
              [103.131508, 0.582041],
              [103.153038, 0.684642],
              [103.201187, 0.704497],
            ],
          ],
          [
            [
              [105.818298, 2.972512],
              [105.826279, 2.917557],
              [105.775833, 2.894894],
              [105.768372, 2.833267],
              [105.702179, 2.836195],
              [105.689255, 2.878287],
              [105.713905, 2.983173],
              [105.818298, 2.972512],
            ],
          ],
          [
            [
              [104.152252, 0.902583],
              [104.18232, 0.86875],
              [104.224663, 0.878497],
              [104.267601, 0.860529],
              [104.243988, 0.818371],
              [104.179512, 0.781623],
              [104.147415, 0.852907],
              [104.094467, 0.870801],
              [104.099106, 0.958045],
              [104.141182, 0.940019],
              [104.152252, 0.902583],
            ],
          ],
          [
            [
              [108.828003, 2.948363],
              [108.838348, 2.995603],
              [108.890297, 2.992378],
              [108.879936, 2.892699],
              [108.827293, 2.84555],
              [108.775284, 2.881889],
              [108.828003, 2.948363],
            ],
          ],
          [
            [
              [104.549065, 0.136858],
              [104.506966, 0.187671],
              [104.52755, 0.225329],
              [104.60331, 0.153135],
              [104.665977, 0.070721],
              [104.59832, 0.087786],
              [104.549065, 0.136858],
            ],
          ],
          [
            [
              [103.377495, 1.00013],
              [103.327568, 1.01739],
              [103.306648, 1.075354],
              [103.349747, 1.132412],
              [103.393684, 1.057489],
              [103.420174, 1.043256],
              [103.433502, 0.988381],
              [103.377495, 1.00013],
            ],
          ],
          [
            [
              [106.20549, 3.15344],
              [106.197922, 3.215262],
              [106.226326, 3.229937],
              [106.275688, 3.193676],
              [106.275162, 3.122286],
              [106.23584, 3.096201],
              [106.20549, 3.15344],
            ],
          ],
          [
            [
              [103.89566, 0.999959],
              [103.934631, 1.000947],
              [103.966568, 0.964751],
              [103.916908, 0.93108],
              [103.838715, 0.969961],
              [103.855774, 1.029005],
              [103.89566, 0.999959],
            ],
          ],
          [
            [
              [103.813248, 0.835613],
              [103.836075, 0.764949],
              [103.772415, 0.792564],
              [103.715935, 0.870147],
              [103.746658, 0.884946],
              [103.813248, 0.835613],
            ],
          ],
          [
            [
              [104.263321, 0.748047],
              [104.240288, 0.698573],
              [104.188644, 0.73583],
              [104.186783, 0.778754],
              [104.251213, 0.799483],
              [104.263321, 0.748047],
            ],
          ],
          [
            [
              [106.2948, 3.328293],
              [106.293205, 3.281317],
              [106.264931, 3.225839],
              [106.237892, 3.266971],
              [106.240891, 3.343322],
              [106.2948, 3.328293],
            ],
          ],
          [
            [
              [104.466652, 0.049549],
              [104.391472, 0.097313],
              [104.432144, 0.134945],
              [104.490623, 0.065836],
              [104.466652, 0.049549],
            ],
          ],
          [
            [
              [108.974663, 2.537747],
              [109.010582, 2.562433],
              [109.082298, 2.489227],
              [109.008202, 2.491124],
              [109.022507, 2.52988],
              [108.974663, 2.537747],
            ],
          ],
          [
            [
              [103.884483, 0.864362],
              [103.90596, 0.842443],
              [103.876915, 0.800219],
              [103.828407, 0.847679],
              [103.884483, 0.864362],
            ],
          ],
          [
            [
              [103.475075, 0.904986],
              [103.484459, 0.857593],
              [103.44976, 0.860306],
              [103.40403, 0.897627],
              [103.434608, 0.926009],
              [103.475075, 0.904986],
            ],
          ],
          [
            [
              [104.353249, 0.361903],
              [104.419655, 0.32974],
              [104.403542, 0.292146],
              [104.351334, 0.31419],
              [104.353249, 0.361903],
            ],
          ],
        ],
      },
      properties: {
        ID: 19,
        kode: 14,
        Propinsi: "RIAU",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [119.551262, -0.861102],
              [119.620102, -0.827921],
              [119.628067, -0.795006],
              [119.680214, -0.767804],
              [119.672073, -0.701232],
              [119.725906, -0.65805],
              [119.761536, -0.681504],
              [119.810547, -0.824481],
              [119.844048, -0.881364],
              [119.877937, -0.860083],
              [119.876678, -0.800739],
              [119.857048, -0.776077],
              [119.858978, -0.711951],
              [119.82312, -0.687051],
              [119.759331, -0.489319],
              [119.755516, -0.43625],
              [119.777512, -0.278784],
              [119.817886, -0.188819],
              [119.793221, -0.1203],
              [119.730637, -0.144836],
              [119.675064, -0.120693],
              [119.606422, -0.011014],
              [119.719109, -0.007734],
              [119.767677, -0.076805],
              [119.777496, -0.112435],
              [119.836311, -0.110336],
              [119.888161, -0.012285],
              [119.869308, 0.103943],
              [119.773888, 0.195469],
              [119.803307, 0.235098],
              [119.886375, 0.210903],
              [119.906456, 0.230215],
              [119.849724, 0.35105],
              [119.889595, 0.39088],
              [119.87677, 0.437173],
              [119.912537, 0.474808],
              [119.999992, 0.48103],
              [120.045471, 0.508192],
              [120.036507, 0.605598],
              [120.017326, 0.662879],
              [120.07708, 0.73949],
              [120.131271, 0.739655],
              [120.231522, 0.792128],
              [120.245316, 0.836357],
              [120.234756, 0.882558],
              [120.246323, 0.957992],
              [120.347801, 0.986282],
              [120.328423, 0.85386],
              [120.375938, 0.833208],
              [120.388908, 0.801644],
              [120.475189, 0.778041],
              [120.500801, 0.794271],
              [120.577087, 0.769187],
              [120.600052, 0.806312],
              [120.606506, 0.872798],
              [120.67897, 0.960633],
              [120.721901, 0.959311],
              [120.78904, 1.036012],
              [120.797989, 1.093499],
              [120.776329, 1.112198],
              [120.824081, 1.183676],
              [120.808014, 1.271],
              [120.83316, 1.320694],
              [120.92836, 1.329284],
              [120.976059, 1.348508],
              [121.065201, 1.308341],
              [121.100578, 1.321008],
              [121.232872, 1.241835],
              [121.301094, 1.256887],
              [121.355553, 1.253551],
              [121.407257, 1.269449],
              [121.44651, 1.318008],
              [121.470947, 1.272841],
              [121.43441, 1.230314],
              [121.431061, 1.172257],
              [121.483253, 1.112933],
              [121.561028, 1.069681],
              [121.707085, 1.060967],
              [121.858894, 1.099807],
              [121.888367, 1.086147],
              [121.936829, 1.106721],
              [121.960297, 1.046818],
              [121.991379, 1.027292],
              [122.105263, 1.073977],
              [122.166107, 1.037744],
              [122.188141, 1.045298],
              [122.067818, 0.997213],
              [122.02533, 0.949058],
              [121.993927, 0.94102],
              [121.968292, 0.894658],
              [121.925529, 0.946584],
              [121.882187, 0.9727],
              [121.831047, 0.963612],
              [121.754608, 0.926077],
              [121.673096, 0.914232],
              [121.620621, 0.883505],
              [121.499672, 0.850688],
              [121.386032, 0.870693],
              [121.294579, 0.82408],
              [121.260277, 0.78299],
              [121.225578, 0.708211],
              [121.168579, 0.690562],
              [121.247383, 0.597502],
              [121.35453, 0.571319],
              [121.345665, 0.474487],
              [121.296829, 0.496804],
              [121.246132, 0.462963],
              [121.183212, 0.459422],
              [121.152977, 0.409079],
              [121.088135, 0.397582],
              [121.032486, 0.439164],
              [120.905403, 0.393952],
              [120.861519, 0.438808],
              [120.730331, 0.473514],
              [120.698936, 0.508861],
              [120.606247, 0.518789],
              [120.528976, 0.514094],
              [120.364449, 0.452948],
              [120.304108, 0.413597],
              [120.24424, 0.338138],
              [120.211548, 0.269989],
              [120.1577, 0.222198],
              [120.121338, 0.157835],
              [120.132538, 0.125113],
              [120.103745, 0.087791],
              [120.104767, 0.006305],
              [120.089226, -0.040358],
              [120.031914, -0.088148],
              [120.040031, -0.140389],
              [120.008202, -0.176373],
              [120.00975, -0.29075],
              [120.029526, -0.303267],
              [120.039871, -0.39192],
              [120.070221, -0.443157],
              [120.046967, -0.490172],
              [120.054924, -0.630386],
              [120.102646, -0.729293],
              [120.218369, -0.845644],
              [120.245003, -0.892794],
              [120.31295, -0.967754],
              [120.40947, -0.924922],
              [120.458672, -0.993287],
              [120.505188, -0.999639],
              [120.591782, -1.168508],
              [120.569252, -1.203504],
              [120.570587, -1.253602],
              [120.637337, -1.313295],
              [120.6409, -1.37013],
              [120.690422, -1.420703],
              [120.729805, -1.384694],
              [120.842125, -1.368169],
              [120.858665, -1.41577],
              [120.959259, -1.414844],
              [120.978638, -1.403112],
              [121.050652, -1.441153],
              [121.117386, -1.423487],
              [121.201614, -1.279033],
              [121.21608, -1.211487],
              [121.277924, -1.157306],
              [121.300293, -1.103074],
              [121.391403, -1.007929],
              [121.446655, -0.974778],
              [121.472191, -0.905653],
              [121.537224, -0.876336],
              [121.587563, -0.869395],
              [121.606941, -0.829822],
              [121.65081, -0.806444],
              [121.667122, -0.85892],
              [121.654663, -0.911058],
              [121.768211, -0.973061],
              [121.79818, -0.96249],
              [121.949707, -0.997026],
              [122.005463, -0.941112],
              [122.101791, -0.963619],
              [122.140907, -0.926582],
              [122.151131, -0.864294],
              [122.202255, -0.790099],
              [122.312874, -0.79761],
              [122.370705, -0.778494],
              [122.417145, -0.786604],
              [122.455696, -0.763],
              [122.526512, -0.787241],
              [122.627403, -0.767298],
              [122.685989, -0.814966],
              [122.72934, -0.814772],
              [122.77961, -0.777899],
              [122.914139, -0.768896],
              [122.879929, -0.722777],
              [122.762383, -0.702591],
              [122.811676, -0.634691],
              [122.838959, -0.646788],
              [122.936707, -0.627541],
              [122.956444, -0.648756],
              [123.014275, -0.603136],
              [123.113899, -0.574524],
              [123.17347, -0.589315],
              [123.19577, -0.574752],
              [123.273453, -0.591004],
              [123.409821, -0.660818],
              [123.381783, -0.675253],
              [123.452087, -0.765581],
              [123.383072, -0.931395],
              [123.390602, -0.960441],
              [123.34938, -1.03729],
              [123.263397, -1.05125],
              [123.228004, -1.006776],
              [123.144241, -0.943434],
              [123.147484, -0.889334],
              [123.101006, -0.840044],
              [123.061485, -0.869743],
              [123.056534, -0.902925],
              [122.978981, -0.905142],
              [122.890213, -0.933499],
              [122.859497, -0.927227],
              [122.790009, -0.950041],
              [122.794479, -0.999178],
              [122.723732, -1.085598],
              [122.708473, -1.134077],
              [122.666412, -1.135592],
              [122.63237, -1.171871],
              [122.618118, -1.238488],
              [122.560532, -1.262113],
              [122.556595, -1.30897],
              [122.495201, -1.351943],
              [122.483078, -1.388393],
              [122.415749, -1.437588],
              [122.390541, -1.48866],
              [122.316772, -1.522827],
              [122.220932, -1.595521],
              [122.023979, -1.613491],
              [121.959595, -1.675115],
              [121.896263, -1.692171],
              [121.8778, -1.675358],
              [121.809525, -1.730459],
              [121.806778, -1.760933],
              [121.749054, -1.887227],
              [121.684959, -1.943017],
              [121.634857, -1.956984],
              [121.565193, -1.949181],
              [121.525887, -1.893084],
              [121.462646, -1.864683],
              [121.451309, -1.804206],
              [121.372246, -1.809819],
              [121.322395, -1.77549],
              [121.295853, -1.7989],
              [121.291077, -1.858329],
              [121.333565, -1.87836],
              [121.317474, -1.937669],
              [121.375557, -1.963591],
              [121.418983, -1.939971],
              [121.447121, -1.985441],
              [121.525383, -2.049364],
              [121.549866, -2.171898],
              [121.609505, -2.189377],
              [121.680809, -2.181712],
              [121.724426, -2.20448],
              [121.795708, -2.291534],
              [121.821411, -2.294693],
              [121.839111, -2.348679],
              [121.901405, -2.429927],
              [121.957466, -2.521174],
              [121.986511, -2.601621],
              [122.033119, -2.65964],
              [122.004349, -2.712314],
              [122.085533, -2.788894],
              [122.13887, -2.790327],
              [122.181404, -2.823496],
              [122.180618, -2.869903],
              [122.292221, -2.890996],
              [122.319016, -2.914855],
              [122.305008, -2.956492],
              [122.25071, -3.022647],
              [122.33329, -3.083526],
              [122.383438, -3.143221],
              [122.412956, -3.128048],
              [122.486099, -3.180516],
              [122.425407, -3.203624],
              [122.428352, -3.248899],
              [122.384857, -3.258627],
              [122.323967, -3.240767],
              [122.312798, -3.271106],
              [122.285965, -3.253934],
              [122.294449, -3.195179],
              [122.274086, -3.138374],
              [122.241173, -3.138603],
              [122.190437, -3.094346],
              [122.191582, -3.032609],
              [122.130539, -3.015782],
              [122.090057, -2.961801],
              [122.000359, -2.962439],
              [121.940231, -2.932159],
              [121.899376, -2.80432],
              [121.872299, -2.777285],
              [121.81871, -2.763949],
              [121.797012, -2.710249],
              [121.74305, -2.608478],
              [121.700546, -2.548773],
              [121.639519, -2.535414],
              [121.56945, -2.49626],
              [121.510712, -2.495261],
              [121.391274, -2.409437],
              [121.304352, -2.365168],
              [121.22654, -2.352717],
              [121.15377, -2.328169],
              [120.953987, -2.237536],
              [120.821678, -2.207236],
              [120.74411, -2.228615],
              [120.70327, -2.212562],
              [120.595131, -2.138367],
              [120.480461, -1.998821],
              [120.469643, -1.962179],
              [120.401123, -1.888412],
              [120.383064, -1.890121],
              [120.354927, -1.999104],
              [120.329796, -2.054484],
              [120.26606, -2.022335],
              [120.181938, -2.029071],
              [120.11956, -2.008276],
              [120.101303, -1.966918],
              [119.994949, -1.963335],
              [119.963005, -2.000149],
              [119.887428, -2.045559],
              [119.83773, -2.051188],
              [119.815765, -2.015383],
              [119.848412, -1.965073],
              [119.869141, -1.892153],
              [119.907639, -1.835855],
              [119.902878, -1.796736],
              [119.865753, -1.770845],
              [119.762688, -1.764873],
              [119.775932, -1.693306],
              [119.732208, -1.607528],
              [119.669128, -1.545653],
              [119.659355, -1.496283],
              [119.696596, -1.459508],
              [119.639478, -1.405974],
              [119.516769, -1.436377],
              [119.497852, -1.394083],
              [119.450577, -1.395969],
              [119.431, -1.336197],
              [119.505386, -1.30987],
              [119.505157, -1.277503],
              [119.570869, -1.272736],
              [119.603714, -1.166935],
              [119.567673, -1.02293],
              [119.551262, -0.861102],
            ],
            [
              [120.531853, -1.873765],
              [120.528267, -1.913569],
              [120.564026, -1.967635],
              [120.606293, -1.995662],
              [120.631844, -2.059787],
              [120.685783, -2.066975],
              [120.707199, -2.024403],
              [120.672348, -1.972287],
              [120.657585, -1.881136],
              [120.599411, -1.790897],
              [120.530083, -1.7894],
              [120.531853, -1.873765],
            ],
          ],
          [
            [
              [123.169479, -1.634564],
              [123.106308, -1.605504],
              [123.103592, -1.583008],
              [123.164406, -1.543593],
              [123.185196, -1.513129],
              [123.157669, -1.31768],
              [123.103828, -1.349413],
              [123.032669, -1.453553],
              [123.021568, -1.495429],
              [122.952698, -1.550409],
              [122.909073, -1.605003],
              [122.865974, -1.600239],
              [122.820557, -1.480622],
              [122.78537, -1.451908],
              [122.791542, -1.383733],
              [122.833527, -1.271164],
              [122.8769, -1.236489],
              [122.904968, -1.184323],
              [122.97934, -1.202721],
              [123.109581, -1.169781],
              [123.145302, -1.208792],
              [123.186867, -1.162364],
              [123.2453, -1.232014],
              [123.191849, -1.327573],
              [123.248268, -1.394754],
              [123.284081, -1.361707],
              [123.360313, -1.231369],
              [123.440392, -1.235385],
              [123.48597, -1.2709],
              [123.556793, -1.288018],
              [123.534393, -1.366252],
              [123.539093, -1.397947],
              [123.51049, -1.458997],
              [123.459213, -1.518635],
              [123.407463, -1.52446],
              [123.367729, -1.503777],
              [123.300964, -1.414419],
              [123.26075, -1.470734],
              [123.229568, -1.599808],
              [123.169479, -1.634564],
            ],
          ],
          [
            [
              [123.476669, -1.656222],
              [123.498703, -1.588354],
              [123.477654, -1.574449],
              [123.501411, -1.507485],
              [123.547562, -1.509387],
              [123.571487, -1.607135],
              [123.627731, -1.62471],
              [123.615463, -1.694854],
              [123.576256, -1.726763],
              [123.492409, -1.71136],
              [123.476669, -1.656222],
            ],
          ],
          [
            [
              [121.914711, -0.453557],
              [121.917236, -0.519645],
              [121.834412, -0.496036],
              [121.73484, -0.512026],
              [121.701439, -0.564406],
              [121.662193, -0.558711],
              [121.694901, -0.492329],
              [121.754837, -0.431368],
              [121.783325, -0.451543],
              [121.843658, -0.414951],
              [121.914711, -0.453557],
            ],
          ],
          [
            [
              [121.96521, -0.420374],
              [121.916389, -0.440287],
              [121.8722, -0.416031],
              [121.872589, -0.35888],
              [122.054825, -0.352623],
              [122.053284, -0.389249],
              [122.059982, -0.404818],
              [121.96521, -0.420374],
            ],
          ],
          [
            [
              [123.135948, -1.780573],
              [123.154221, -1.820214],
              [123.140778, -1.866415],
              [123.063591, -1.920046],
              [123.062195, -1.831136],
              [123.092239, -1.770375],
              [123.135948, -1.780573],
            ],
          ],
          [
            [
              [122.096016, -0.40342],
              [122.077263, -0.429949],
              [122.056259, -0.36745],
              [122.0662, -0.311042],
              [122.098, -0.295803],
              [122.149895, -0.39855],
              [122.096016, -0.40342],
            ],
          ],
          [
            [
              [123.86039, -1.997033],
              [123.820366, -2.012111],
              [123.789017, -1.979887],
              [123.772453, -1.903466],
              [123.80835, -1.893784],
              [123.850426, -1.945709],
              [123.86039, -1.997033],
            ],
          ],
          [
            [
              [122.362381, -0.362636],
              [122.29538, -0.268201],
              [122.306877, -0.23959],
              [122.375923, -0.284257],
              [122.362381, -0.362636],
            ],
          ],
          [
            [
              [121.620766, -0.211886],
              [121.562981, -0.181929],
              [121.601471, -0.129324],
              [121.644028, -0.13074],
              [121.649246, -0.193653],
              [121.620766, -0.211886],
            ],
          ],
          [
            [
              [123.31263, -1.720765],
              [123.360176, -1.677891],
              [123.39093, -1.708563],
              [123.314911, -1.784292],
              [123.267799, -1.787167],
              [123.31263, -1.720765],
            ],
          ],
          [
            [
              [123.325485, -1.200815],
              [123.313316, -1.265722],
              [123.274651, -1.221891],
              [123.325485, -1.200815],
            ],
          ],
        ],
      },
      properties: {
        ID: 20,
        kode: 72,
        Propinsi: "SULAWESI TENGAH",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [113.918137, 0.979328],
              [113.919106, 0.982897],
              [113.890594, 1.038177],
              [113.938293, 1.06126],
              [113.987701, 1.144419],
              [114.047989, 1.132663],
              [114.081848, 1.161817],
              [114.119576, 1.156378],
              [114.140587, 1.206963],
              [114.137711, 1.21268],
              [114.152161, 1.382885],
              [114.153831, 1.384724],
              [114.204971, 1.407545],
              [114.235268, 1.4426],
              [114.273003, 1.448177],
              [114.377747, 1.491683],
              [114.409302, 1.514634],
              [114.538544, 1.435658],
              [114.571404, 1.435368],
              [114.604622, 1.491522],
              [114.614647, 1.579356],
              [114.649025, 1.595495],
              [114.706841, 1.675499],
              [114.712097, 1.782442],
              [114.696716, 1.813631],
              [114.740334, 1.867859],
              [114.794128, 1.85701],
              [114.821785, 1.896762],
              [114.877747, 1.917274],
              [114.853745, 1.97312],
              [114.8937, 2.010891],
              [114.868645, 2.035361],
              [114.806808, 2.040769],
              [114.813965, 2.108023],
              [114.783752, 2.140973],
              [114.803024, 2.243819],
              [114.917473, 2.260886],
              [114.951164, 2.284624],
              [114.956024, 2.362647],
              [114.995422, 2.353104],
              [115.139877, 2.470896],
              [115.192932, 2.470649],
              [115.237038, 2.493989],
              [115.237389, 2.532009],
              [115.162666, 2.608151],
              [115.11335, 2.589236],
              [115.119492, 2.688252],
              [115.109703, 2.727942],
              [115.142548, 2.752551],
              [115.139648, 2.813438],
              [115.095024, 2.826418],
              [115.149261, 2.882345],
              [115.14698, 2.912522],
              [115.240479, 2.98812],
              [115.287224, 3.053157],
              [115.328346, 2.9866],
              [115.395432, 2.989984],
              [115.430275, 3.029384],
              [115.486443, 3.032288],
              [115.515167, 3.066232],
              [115.513947, 3.108752],
              [115.56398, 3.170708],
              [115.522049, 3.210068],
              [115.536972, 3.327598],
              [115.566292, 3.416362],
              [115.592957, 3.454614],
              [115.625664, 3.419666],
              [115.65303, 3.442058],
              [115.610542, 3.530491],
              [115.605888, 3.580764],
              [115.577797, 3.613187],
              [115.572044, 3.674318],
              [115.593979, 3.792897],
              [115.619545, 3.867123],
              [115.570374, 3.911689],
              [115.579765, 3.944401],
              [115.643265, 3.972893],
              [115.679153, 4.087063],
              [115.68808, 4.156209],
              [115.77063, 4.24003],
              [115.827927, 4.228227],
              [115.836105, 4.261403],
              [115.878021, 4.296675],
              [115.878632, 4.382408],
              [115.936905, 4.352961],
              [116.000031, 4.347539],
              [116.040352, 4.291376],
              [116.083778, 4.276591],
              [116.106705, 4.321579],
              [116.147995, 4.337818],
              [116.167274, 4.383142],
              [116.269272, 4.36123],
              [116.360413, 4.382039],
              [116.472488, 4.298682],
              [116.49086, 4.332563],
              [116.537659, 4.328381],
              [116.535728, 4.370454],
              [116.569977, 4.401027],
              [116.62394, 4.331343],
              [116.716843, 4.340905],
              [116.742256, 4.378986],
              [116.789459, 4.337498],
              [116.828949, 4.326515],
              [116.900375, 4.361372],
              [116.956917, 4.339827],
              [117.046944, 4.343885],
              [117.105186, 4.330876],
              [117.200363, 4.336202],
              [117.248161, 4.372694],
              [117.302917, 4.319976],
              [117.420616, 4.24595],
              [117.468033, 4.176103],
              [117.523514, 4.164959],
              [117.5222, 4.09901],
              [117.4156, 4.14167],
              [117.4309, 4.09446],
              [117.4719, 4.08227],
              [117.5483, 4.01734],
              [117.6014, 3.92868],
              [117.6522, 3.90561],
              [117.734, 3.83082],
              [117.7301, 3.81011],
              [117.7784, 3.78007],
              [117.729195, 3.751446],
              [117.64064, 3.778506],
              [117.517782, 3.778473],
              [117.479153, 3.742878],
              [117.469542, 3.704592],
              [117.5154, 3.61392],
              [117.4949, 3.59062],
              [117.3894, 3.6337],
              [117.261, 3.61355],
              [117.1716, 3.63874],
              [117.0915, 3.63283],
              [117.045083, 3.602293],
              [117.1396, 3.61612],
              [117.2156, 3.60271],
              [117.232579, 3.585285],
              [117.1983, 3.53071],
              [117.2431, 3.47226],
              [117.3577, 3.44279],
              [117.3729, 3.40771],
              [117.4161, 3.37927],
              [117.417, 3.33373],
              [117.3884, 3.31951],
              [117.3555, 3.26435],
              [117.3765, 3.22384],
              [117.3092, 3.20452],
              [117.3211, 3.17175],
              [117.4309, 3.16839],
              [117.4787, 3.09913],
              [117.4449, 3.04862],
              [117.5431, 3.06382],
              [117.5638, 3.04382],
              [117.6202, 3.05427],
              [117.6179, 3.01247],
              [117.574, 2.97733],
              [117.5555, 2.90229],
              [117.7158, 2.88863],
              [117.6173, 2.79039],
              [117.6916, 2.77908],
              [117.7781, 2.74167],
              [117.7851, 2.66235],
              [117.8066, 2.60683],
              [117.8888, 2.52976],
              [117.9305, 2.47401],
              [118.0643, 2.34815],
              [118.0901, 2.2974],
              [118.074, 2.24401],
              [118.014, 2.18865],
              [117.9275, 2.17502],
              [117.8148, 2.19708],
              [117.7299, 2.1846],
              [117.7016, 2.1581],
              [117.7213, 2.13207],
              [117.7173, 2.09919],
              [117.7235, 2.0835],
              [117.7375, 2.07845],
              [117.7956, 2.10288],
              [117.8195, 2.0615],
              [117.7884, 2.00816],
              [117.8379, 1.99989],
              [117.8649, 1.96299],
              [117.8629, 1.87479],
              [117.9103, 1.81216],
              [117.9595, 1.79128],
              [118.0344, 1.78855],
              [118.0499, 1.72371],
              [118.1197, 1.63926],
              [118.158, 1.62829],
              [118.2416, 1.55893],
              [118.2908, 1.54345],
              [118.38, 1.4839],
              [118.4058, 1.48783],
              [118.4336, 1.39582],
              [118.4934, 1.37311],
              [118.5563, 1.37154],
              [118.6434, 1.30613],
              [118.7667, 1.20048],
              [118.7625, 1.16377],
              [118.8728, 1.07013],
              [118.9472, 1.06371],
              [118.9908, 1.02751],
              [118.989792, 0.989455],
              [118.95948, 0.941661],
              [118.888748, 0.874728],
              [118.867989, 0.88197],
              [118.791107, 0.800822],
              [118.708488, 0.829904],
              [118.67495, 0.858952],
              [118.589439, 0.81014],
              [118.547104, 0.808872],
              [118.4441, 0.84539],
              [118.3716, 0.80407],
              [118.3167, 0.85014],
              [118.203391, 0.867535],
              [118.062884, 0.90385],
              [118.048734, 0.94618],
              [118.012575, 0.956512],
              [117.983957, 1.05784],
              [117.890268, 1.127162],
              [117.872475, 1.096863],
              [117.973052, 0.979862],
              [117.969661, 0.954896],
              [118.02739, 0.830761],
              [118.029101, 0.78147],
              [117.972238, 0.774156],
              [117.924001, 0.805105],
              [117.913526, 0.836769],
              [117.82257, 0.802413],
              [117.7508, 0.75383],
              [117.7084, 0.68087],
              [117.7138, 0.63162],
              [117.6745, 0.6152],
              [117.6294, 0.50554],
              [117.603751, 0.484174],
              [117.613058, 0.433703],
              [117.573731, 0.406656],
              [117.552847, 0.335006],
              [117.483113, 0.156458],
              [117.5216, 0.1229],
              [117.474096, 0.088355],
              [117.5198, -0.02957],
              [117.4843, -0.06922],
              [117.4439, -0.20578],
              [117.4173, -0.2454],
              [117.451693, -0.303003],
              [117.452628, -0.35487],
              [117.429409, -0.39466],
              [117.454981, -0.45189],
              [117.410957, -0.539794],
              [117.364597, -0.570177],
              [117.280968, -0.577501],
              [117.302875, -0.675327],
              [117.281934, -0.790019],
              [117.255953, -0.804343],
              [117.248973, -0.906305],
              [117.177134, -0.944905],
              [117.051588, -1.118463],
              [117.02264, -1.140105],
              [117.000952, -1.198912],
              [116.926496, -1.256976],
              [116.809888, -1.281959],
              [116.814287, -1.218122],
              [116.782896, -1.21137],
              [116.777922, -1.143471],
              [116.727644, -1.101795],
              [116.755748, -1.000008],
              [116.720194, -1.049158],
              [116.701447, -1.120656],
              [116.744535, -1.15446],
              [116.761161, -1.360439],
              [116.599933, -1.420734],
              [116.552587, -1.451015],
              [116.528, -1.50367],
              [116.5591, -1.59553],
              [116.487276, -1.645292],
              [116.444372, -1.64974],
              [116.32481, -1.701486],
              [116.278167, -1.734543],
              [116.252894, -1.77868],
              [116.260992, -1.809839],
              [116.379064, -1.761773],
              [116.448673, -1.76839],
              [116.453828, -1.803322],
              [116.416647, -1.86614],
              [116.458097, -1.918914],
              [116.46, -2.03306],
              [116.451, -2.06408],
              [116.3786, -2.09133],
              [116.4309, -2.13318],
              [116.5028, -2.10942],
              [116.5211, -2.15574],
              [116.5928, -2.19593],
              [116.5742, -2.33618],
              [116.554062, -2.409357],
              [116.493124, -2.381091],
              [116.320487, -2.341474],
              [116.250001, -2.316857],
              [116.186227, -2.32084],
              [115.984661, -2.312577],
              [115.890968, -2.335292],
              [115.885309, -2.374997],
              [115.837873, -2.389611],
              [115.809844, -2.277218],
              [115.813632, -2.250004],
              [115.813901, -2.25],
              [115.785043, -2.215817],
              [115.793901, -2.13127],
              [115.813393, -2.070402],
              [115.710956, -2.048324],
              [115.710694, -2.048348],
              [115.706121, -1.962304],
              [115.730987, -1.867477],
              [115.726902, -1.805778],
              [115.691899, -1.750616],
              [115.665229, -1.706676],
              [115.668327, -1.631298],
              [115.636133, -1.603781],
              [115.63121, -1.533835],
              [115.653089, -1.497869],
              [115.653402, -1.497092],
              [115.73581, -1.366078],
              [115.751405, -1.316831],
              [115.776921, -1.230763],
              [115.844508, -1.162429],
              [115.800606, -1.113091],
              [115.749481, -1.124644],
              [115.743721, -1.090938],
              [115.693001, -1.054689],
              [115.65976, -1.000104],
              [115.582848, -0.947266],
              [115.495659, -0.848081],
              [115.39312, -0.82201],
              [115.367661, -0.749212],
              [115.352211, -0.579602],
              [115.324715, -0.557164],
              [115.303055, -0.501086],
              [115.246429, -0.4417],
              [115.227875, -0.305798],
              [115.239098, -0.244186],
              [115.26992, -0.185243],
              [115.265564, -0.157903],
              [115.320732, -0.093379],
              [115.297623, -0.03339],
              [115.155769, -0.103698],
              [115.149078, -0.136886],
              [115.082069, -0.152977],
              [115.044418, -0.190297],
              [114.980179, -0.178222],
              [114.948639, -0.1902],
              [114.89846, -0.035127],
              [114.882278, 0.053212],
              [114.792274, 0.093691],
              [114.827431, 0.196637],
              [114.888893, 0.297505],
              [114.94455, 0.330936],
              [114.964165, 0.315193],
              [115.025604, 0.325491],
              [115.040817, 0.303441],
              [115.125549, 0.369575],
              [115.136154, 0.429964],
              [115.093254, 0.47235],
              [115.010025, 0.617119],
              [114.992355, 0.71701],
              [114.944992, 0.775121],
              [114.911812, 0.784313],
              [114.846718, 0.715452],
              [114.827667, 0.751281],
              [114.766129, 0.742571],
              [114.713394, 0.705227],
              [114.653, 0.685335],
              [114.589912, 0.626305],
              [114.54274, 0.631714],
              [114.51963, 0.564921],
              [114.492722, 0.57297],
              [114.429817, 0.545453],
              [114.412323, 0.495826],
              [114.353485, 0.53131],
              [114.280472, 0.540742],
              [114.211525, 0.53291],
              [114.150124, 0.584852],
              [114.172874, 0.628675],
              [114.149551, 0.67515],
              [114.105927, 0.698564],
              [114.047279, 0.67466],
              [113.962456, 0.657218],
              [113.842865, 0.665291],
              [113.843277, 0.702985],
              [113.898712, 0.745404],
              [113.889313, 0.783661],
              [113.926888, 0.843717],
              [113.881111, 0.883935],
              [113.880379, 0.925269],
              [113.918137, 0.979328],
            ],
          ],
          [
            [
              [117.735307, 3.741319],
              [117.8202, 3.71651],
              [117.773, 3.67986],
              [117.7458, 3.63324],
              [117.6693, 3.61266],
              [117.5928, 3.6191],
              [117.5217, 3.66706],
              [117.483339, 3.714001],
              [117.519964, 3.769611],
              [117.639856, 3.769512],
              [117.735307, 3.741319],
            ],
          ],
          [
            [
              [117.9081, 4.166],
              [117.9295, 4.12521],
              [117.9293, 4.06491],
              [117.8668, 4.03088],
              [117.7258, 4.1273],
              [117.7035, 4.15333],
              [117.8374, 4.19882],
              [117.9081, 4.166],
            ],
          ],
          [
            [
              [117.284, 3.578187],
              [117.4018, 3.54291],
              [117.5191, 3.47023],
              [117.4584, 3.44747],
              [117.3287, 3.48716],
              [117.2348, 3.49181],
              [117.2071, 3.53601],
              [117.2219, 3.56569],
              [117.284, 3.578187],
            ],
          ],
          [
            [
              [117.7285, 4],
              [117.6711, 3.9614],
              [117.6013, 4.01686],
              [117.5884, 4.08314],
              [117.6351, 4.14141],
              [117.6766, 4.14007],
              [117.7531, 4.06601],
              [117.7285, 4],
            ],
          ],
          [
            [
              [117.571336, 3.432597],
              [117.6196, 3.4339],
              [117.666, 3.40938],
              [117.6548, 3.24906],
              [117.6126, 3.26639],
              [117.5458, 3.31948],
              [117.5175, 3.39243],
              [117.571336, 3.432597],
            ],
          ],
          [
            [
              [117.284722, -0.583047],
              [117.350362, -0.589675],
              [117.465791, -0.555884],
              [117.505521, -0.570556],
              [117.485323, -0.609513],
              [117.455531, -0.630099],
              [117.408543, -0.694381],
              [117.284722, -0.583047],
            ],
          ],
          [
            [
              [117.478676, -0.709411],
              [117.518743, -0.736203],
              [117.552795, -0.725524],
              [117.617752, -0.745762],
              [117.612911, -0.776006],
              [117.554837, -0.784862],
              [117.520822, -0.812279],
              [117.481489, -0.798026],
              [117.441019, -0.755536],
              [117.422027, -0.708756],
              [117.478676, -0.709411],
            ],
          ],
          [
            [
              [117.328086, -0.634215],
              [117.368036, -0.663977],
              [117.375031, -0.718214],
              [117.427001, -0.831578],
              [117.401959, -0.860938],
              [117.333803, -0.758161],
              [117.300443, -0.63267],
              [117.328086, -0.634215],
            ],
          ],
          [
            [
              [118.793643, 1.373142],
              [118.849082, 1.390535],
              [118.846873, 1.264392],
              [118.868597, 1.206755],
              [118.84141, 1.157823],
              [118.799051, 1.273097],
              [118.806679, 1.344867],
              [118.793643, 1.373142],
            ],
          ],
          [
            [
              [117.8503, 3.52706],
              [117.873, 3.46497],
              [117.8436, 3.44712],
              [117.7598, 3.50474],
              [117.7535, 3.55367],
              [117.8002, 3.58004],
              [117.8503, 3.52706],
            ],
          ],
          [
            [
              [117.301046, -0.6952],
              [117.328931, -0.766548],
              [117.373548, -0.84016],
              [117.3574, -0.897701],
              [117.314924, -0.878245],
              [117.290932, -0.782899],
              [117.301046, -0.6952],
            ],
          ],
          [
            [
              [117.5268, 3.483],
              [117.4571, 3.50896],
              [117.4863, 3.54168],
              [117.6184, 3.49164],
              [117.6091, 3.46713],
              [117.5268, 3.483],
            ],
          ],
          [
            [
              [117.487074, -0.562406],
              [117.477669, -0.527304],
              [117.548595, -0.528936],
              [117.584949, -0.568485],
              [117.517488, -0.602572],
              [117.511297, -0.571849],
              [117.487074, -0.562406],
            ],
          ],
          [
            [
              [117.8831, 2.148],
              [117.9406, 2.12806],
              [117.9146, 2.08978],
              [117.8615, 2.14024],
              [117.8102, 2.11437],
              [117.8007, 2.17909],
              [117.8753, 2.17145],
              [117.8831, 2.148],
            ],
          ],
          [
            [
              [117.414723, -0.538925],
              [117.542898, -0.441968],
              [117.580298, -0.485445],
              [117.454401, -0.535222],
              [117.414723, -0.538925],
            ],
          ],
          [
            [
              [117.4578, 3.27099],
              [117.3947, 3.27317],
              [117.4018, 3.31067],
              [117.4496, 3.33527],
              [117.5084, 3.31657],
              [117.5201, 3.27196],
              [117.4578, 3.27099],
            ],
          ],
          [
            [
              [117.7183, 3.86904],
              [117.7415, 3.8705],
              [117.8322, 3.80283],
              [117.8272, 3.77821],
              [117.7471, 3.80087],
              [117.7183, 3.86904],
            ],
          ],
          [
            [
              [117.7864, 2.10276],
              [117.762, 2.09673],
              [117.7361, 2.0812],
              [117.7277, 2.0873],
              [117.728, 2.1219],
              [117.7225, 2.14282],
              [117.7329, 2.16196],
              [117.8016, 2.1653],
              [117.7864, 2.10276],
            ],
          ],
          [
            [
              [117.6719, 2.96311],
              [117.6894, 2.93645],
              [117.6007, 2.92052],
              [117.5818, 2.96184],
              [117.6676, 2.98306],
              [117.6719, 2.96311],
            ],
          ],
          [
            [
              [117.5386, 3.5684],
              [117.5755, 3.57872],
              [117.64, 3.56106],
              [117.6197, 3.53016],
              [117.5386, 3.5684],
            ],
          ],
          [
            [
              [117.303908, 3.575188],
              [117.3973, 3.60582],
              [117.4086, 3.58156],
              [117.303908, 3.575188],
            ],
          ],
          [
            [
              [117.5041, 3.3812],
              [117.503, 3.3371],
              [117.4524, 3.34706],
              [117.4489, 3.39335],
              [117.5041, 3.3812],
            ],
          ],
          [
            [
              [117.493822, -0.346391],
              [117.453203, -0.437958],
              [117.434913, -0.396641],
              [117.493822, -0.346391],
            ],
          ],
          [
            [
              [117.6295, 2.89943],
              [117.6911, 2.92742],
              [117.6996, 2.8961],
              [117.6295, 2.89943],
            ],
          ],
        ],
      },
      properties: {
        ID: 21,
        kode: 64,
        Propinsi: "KALIMANTAN TIMUR",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [123.465736, 0.330308],
              [123.51918, 0.449638],
              [123.527229, 0.57175],
              [123.494171, 0.636227],
              [123.368813, 0.661965],
              [123.257477, 0.749624],
              [123.240799, 0.83596],
              [123.1465, 0.881753],
              [123.117294, 0.92862],
              [123.183815, 0.906404],
              [123.222198, 0.951997],
              [123.266968, 0.919489],
              [123.381279, 0.916597],
              [123.395981, 0.90392],
              [123.553619, 0.891023],
              [123.605904, 0.906905],
              [123.732872, 0.865901],
              [123.784668, 0.835112],
              [123.920517, 0.849172],
              [124.075935, 0.923523],
              [124.137665, 0.928344],
              [124.197304, 0.986574],
              [124.2836, 1.003263],
              [124.308136, 1.021709],
              [124.357475, 1.136346],
              [124.345238, 1.158185],
              [124.41391, 1.196132],
              [124.489716, 1.18741],
              [124.544029, 1.214211],
              [124.583717, 1.194596],
              [124.610794, 1.234678],
              [124.589989, 1.269574],
              [124.51638, 1.302175],
              [124.533218, 1.356623],
              [124.57637, 1.400202],
              [124.626411, 1.418952],
              [124.674355, 1.396016],
              [124.746124, 1.4518],
              [124.829369, 1.468621],
              [124.845001, 1.523865],
              [124.806114, 1.5772],
              [124.86174, 1.604014],
              [124.923584, 1.665811],
              [124.961983, 1.730148],
              [124.994385, 1.754779],
              [125.026825, 1.735152],
              [125.014381, 1.693482],
              [125.111847, 1.663243],
              [125.154076, 1.579512],
              [125.210403, 1.550894],
              [125.242546, 1.505849],
              [125.229156, 1.461808],
              [125.192459, 1.438576],
              [125.147789, 1.439471],
              [125.07621, 1.367611],
              [125.063248, 1.296257],
              [125.020775, 1.146533],
              [124.969742, 1.069223],
              [124.898026, 0.999992],
              [124.883057, 0.963803],
              [124.788437, 0.943151],
              [124.763794, 0.888915],
              [124.725716, 0.893974],
              [124.689034, 0.829098],
              [124.625069, 0.7633],
              [124.63192, 0.712631],
              [124.599297, 0.706245],
              [124.554062, 0.635617],
              [124.560966, 0.58909],
              [124.495369, 0.548974],
              [124.512131, 0.473545],
              [124.376762, 0.446159],
              [124.32338, 0.442996],
              [124.281578, 0.404371],
              [124.08503, 0.38917],
              [124.065895, 0.36997],
              [123.998734, 0.376837],
              [123.978569, 0.360976],
              [123.814941, 0.337797],
              [123.781235, 0.312628],
              [123.74054, 0.324472],
              [123.683281, 0.30451],
              [123.590683, 0.310139],
              [123.548042, 0.324057],
              [123.49649, 0.30875],
              [123.465736, 0.330308],
            ],
          ],
          [
            [
              [126.688919, 4.37135],
              [126.715935, 4.46499],
              [126.720451, 4.525645],
              [126.750732, 4.560368],
              [126.805336, 4.546466],
              [126.855713, 4.498095],
              [126.868073, 4.445125],
              [126.851578, 4.38552],
              [126.9142, 4.283608],
              [126.792336, 4.169112],
              [126.80732, 4.123262],
              [126.799889, 4.058125],
              [126.761673, 4.012424],
              [126.699921, 4.021832],
              [126.664978, 4.057411],
              [126.733093, 4.127583],
              [126.788681, 4.253698],
              [126.714409, 4.282143],
              [126.688919, 4.37135],
            ],
          ],
          [
            [
              [125.650261, 3.509186],
              [125.678253, 3.424114],
              [125.640015, 3.37788],
              [125.523735, 3.433616],
              [125.485748, 3.47762],
              [125.51297, 3.5587],
              [125.482903, 3.598102],
              [125.442543, 3.615681],
              [125.398689, 3.663957],
              [125.422775, 3.724515],
              [125.509193, 3.716769],
              [125.597404, 3.60578],
              [125.573135, 3.580348],
              [125.623062, 3.517487],
              [125.650261, 3.509186],
            ],
          ],
          [
            [
              [125.379097, 2.797428],
              [125.422615, 2.805127],
              [125.449631, 2.75286],
              [125.407181, 2.726557],
              [125.357231, 2.737662],
              [125.379097, 2.797428],
            ],
          ],
          [
            [
              [126.723343, 3.903965],
              [126.678856, 3.872737],
              [126.667656, 3.946121],
              [126.704918, 3.95399],
              [126.723343, 3.903965],
            ],
          ],
          [
            [
              [126.82341, 3.745728],
              [126.790398, 3.759194],
              [126.753197, 3.806978],
              [126.742836, 3.864257],
              [126.798347, 3.854081],
              [126.834442, 3.81489],
              [126.849304, 3.770062],
              [126.82341, 3.745728],
            ],
          ],
          [
            [
              [125.37558, 2.35449],
              [125.434448, 2.386659],
              [125.4674, 2.352841],
              [125.439598, 2.319543],
              [125.37558, 2.35449],
            ],
          ],
          [
            [
              [125.129044, 1.830128],
              [125.181602, 1.774388],
              [125.132607, 1.753805],
              [125.129044, 1.830128],
            ],
          ],
        ],
      },
      properties: {
        ID: 22,
        kode: 71,
        Propinsi: "SULAWESI UTARA",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [134.659012, -2.825982],
              [134.598938, -2.891298],
              [134.493637, -2.971269],
              [134.427872, -3.008884],
              [134.439407, -3.066184],
              [134.426544, -3.112016],
              [134.32309, -3.184835],
              [134.237625, -3.31085],
              [134.209564, -3.393168],
              [134.237518, -3.397094],
              [134.41301, -3.474069],
              [134.525314, -3.505847],
              [134.938074, -3.644094],
              [134.961925, -3.650414],
              [135.11734, -3.703091],
              [135.187973, -3.739861],
              [135.248428, -3.750056],
              [135.579041, -3.867941],
              [135.62262, -3.875976],
              [135.699463, -3.917633],
              [135.728455, -3.993873],
              [135.777115, -4.163929],
              [135.796555, -4.188828],
              [136.001068, -4.197879],
              [136.156723, -4.188222],
              [136.082077, -4.355084],
              [136.034225, -4.415998],
              [135.94652, -4.503829],
              [135.9067, -4.48447],
              [135.793, -4.48425],
              [135.722, -4.49621],
              [135.5927, -4.47134],
              [135.516, -4.44734],
              [135.3994, -4.4277],
              [135.212, -4.46369],
              [135.0978, -4.41016],
              [134.9579, -4.32704],
              [134.8906, -4.25589],
              [134.8144, -4.25363],
              [134.7668, -4.19608],
              [134.7211, -4.19805],
              [134.7094, -4.14171],
              [134.645, -4.13287],
              [134.6367, -4.09427],
              [134.6884, -4.06812],
              [134.6573, -3.98589],
              [134.7615, -3.93236],
              [134.8285, -3.94969],
              [134.8412, -3.98184],
              [134.8954, -3.97303],
              [134.8872, -3.93093],
              [134.8116, -3.93563],
              [134.7748, -3.91646],
              [134.736, -3.93638],
              [134.673, -3.91801],
              [134.6252, -3.95032],
              [134.6046, -3.99702],
              [134.5714, -3.99755],
              [134.5096, -4.04246],
              [134.4697, -3.99429],
              [134.4763, -3.96602],
              [134.4393, -3.91694],
              [134.3496, -3.89176],
              [134.3488, -3.96293],
              [134.2984, -3.96339],
              [134.3347, -4.01864],
              [134.2929, -4.02742],
              [134.2575, -3.9825],
              [134.1949, -3.9603],
              [134.1496, -3.89474],
              [134.1909, -3.83272],
              [134.161, -3.77248],
              [134.0715, -3.82177],
              [134.0354, -3.78634],
              [133.9632, -3.8644],
              [133.9149, -3.78295],
              [133.9095, -3.70468],
              [133.8446, -3.63772],
              [133.8371, -3.55954],
              [133.7879, -3.60184],
              [133.8012, -3.70813],
              [133.7114, -3.609],
              [133.6266, -3.47927],
              [133.7046, -3.41208],
              [133.6893, -3.37682],
              [133.6693, -3.24633],
              [133.7207, -3.1805],
              [133.7748, -3.16848],
              [133.8378, -3.08285],
              [133.8329, -3.03776],
              [133.8679, -3.02807],
              [133.846, -2.93888],
              [133.8132, -2.96255],
              [133.7986, -3.03001],
              [133.7358, -3.03707],
              [133.6552, -3.15572],
              [133.6746, -3.21606],
              [133.6544, -3.23727],
              [133.6683, -3.34271],
              [133.6399, -3.41486],
              [133.5422, -3.43123],
              [133.5995, -3.5254],
              [133.5847, -3.5726],
              [133.4959, -3.62269],
              [133.4418, -3.68365],
              [133.3979, -3.69229],
              [133.3961, -3.73691],
              [133.4166, -3.80041],
              [133.4619, -3.85886],
              [133.4063, -3.90566],
              [133.3151, -4.01876],
              [133.2328, -4.07898],
              [133.1551, -4.07587],
              [133.0773, -4.05848],
              [133.0231, -4.09695],
              [132.961, -4.11024],
              [132.9173, -4.0854],
              [132.8999, -4.02968],
              [132.8179, -3.96126],
              [132.8222, -3.89005],
              [132.7757, -3.76902],
              [132.7334, -3.68637],
              [132.7281, -3.64051],
              [132.8922, -3.6474],
              [132.9086, -3.53624],
              [132.8896, -3.46803],
              [132.8535, -3.48012],
              [132.8161, -3.44805],
              [132.7996, -3.36363],
              [132.8308, -3.32924],
              [132.8037, -3.26565],
              [132.7394, -3.28254],
              [132.7277, -3.33892],
              [132.6747, -3.32547],
              [132.6394, -3.26918],
              [132.6374, -3.1857],
              [132.5807, -3.16399],
              [132.561, -3.11212],
              [132.4329, -3.00639],
              [132.3491, -2.96585],
              [132.3429, -2.94433],
              [132.2054, -2.92303],
              [132.0929, -2.95951],
              [132.0631, -2.90848],
              [132.0199, -2.87781],
              [132.0493, -2.77579],
              [132.0229, -2.73548],
              [132.0885, -2.7195],
              [132.1425, -2.66224],
              [132.1898, -2.65253],
              [132.3551, -2.66515],
              [132.4482, -2.70394],
              [132.4884, -2.67956],
              [132.5683, -2.68665],
              [132.6387, -2.72538],
              [132.6785, -2.77561],
              [132.7307, -2.80439],
              [132.8038, -2.7699],
              [132.9603, -2.6124],
              [133.0446, -2.49784],
              [133.1278, -2.43718],
              [133.2218, -2.41132],
              [133.3083, -2.44262],
              [133.3885, -2.52259],
              [133.3789, -2.59606],
              [133.4072, -2.69349],
              [133.4448, -2.68407],
              [133.4695, -2.63361],
              [133.5366, -2.64552],
              [133.5746, -2.61126],
              [133.5792, -2.57001],
              [133.6479, -2.53426],
              [133.6798, -2.56222],
              [133.673, -2.61264],
              [133.761, -2.62498],
              [133.7585, -2.65908],
              [133.7671, -2.67705],
              [133.8099, -2.65085],
              [133.8211, -2.58386],
              [133.7996, -2.48594],
              [133.8216, -2.47279],
              [133.8302, -2.46046],
              [133.832, -2.4486],
              [133.8404, -2.4471],
              [133.862, -2.46619],
              [133.8845, -2.4579],
              [133.9152, -2.48362],
              [133.9736, -2.46983],
              [133.9992, -2.45077],
              [133.9625, -2.423],
              [134.0032, -2.362],
              [133.9638, -2.32703],
              [134.0054, -2.30027],
              [134.0139, -2.23934],
              [133.9589, -2.22965],
              [133.9499, -2.12745],
              [133.9606, -2.07947],
              [133.8853, -2.10943],
              [133.8178, -2.0845],
              [133.6998, -2.14086],
              [133.6484, -2.12859],
              [133.6253, -2.17939],
              [133.594, -2.17505],
              [133.5731, -2.2193],
              [133.5345, -2.23507],
              [133.4386, -2.22661],
              [133.4097, -2.20591],
              [133.34, -2.21391],
              [133.2842, -2.19078],
              [133.2316, -2.21352],
              [133.1906, -2.19277],
              [133.1178, -2.22457],
              [133.042, -2.23488],
              [132.9636, -2.29077],
              [132.9043, -2.27824],
              [132.8558, -2.24065],
              [132.8126, -2.23983],
              [132.7363, -2.26879],
              [132.7129, -2.29568],
              [132.6471, -2.28286],
              [132.5936, -2.18887],
              [132.5363, -2.18823],
              [132.3643, -2.26631],
              [132.3077, -2.27383],
              [132.2491, -2.19888],
              [132.1922, -2.15635],
              [132.119, -2.12698],
              [132.0376, -2.06574],
              [132.0428, -2.02122],
              [131.9689, -1.96418],
              [131.9309, -1.87902],
              [131.95, -1.84215],
              [131.9249, -1.80969],
              [131.9989, -1.74139],
              [132.0282, -1.69969],
              [131.9129, -1.71929],
              [131.886, -1.65216],
              [131.9017, -1.59433],
              [131.8544, -1.5784],
              [131.8144, -1.61344],
              [131.7685, -1.57267],
              [131.7292, -1.58311],
              [131.6974, -1.55298],
              [131.6344, -1.5366],
              [131.5802, -1.46913],
              [131.5517, -1.48549],
              [131.4818, -1.47709],
              [131.4538, -1.50286],
              [131.3939, -1.4596],
              [131.4057, -1.42313],
              [131.3334, -1.3451],
              [131.2675, -1.3811],
              [131.3358, -1.43336],
              [131.2875, -1.49644],
              [131.2047, -1.51696],
              [131.1665, -1.45953],
              [131.0956, -1.44814],
              [130.9962, -1.45502],
              [130.9322, -1.42056],
              [130.9942, -1.38382],
              [131.0202, -1.28279],
              [131.0454, -1.25362],
              [131.1649, -1.22412],
              [131.2432, -1.08339],
              [131.237, -1.02343],
              [131.2808, -0.98976],
              [131.2682, -0.92256],
              [131.2295, -0.8143],
              [131.3896, -0.77528],
              [131.4172, -0.75457],
              [131.5368, -0.72487],
              [131.5551, -0.76239],
              [131.8326, -0.70912],
              [131.886, -0.68157],
              [131.9226, -0.62468],
              [131.9872, -0.56769],
              [132.0673, -0.53781],
              [132.0881, -0.47947],
              [132.2557, -0.38049],
              [132.3337, -0.38245],
              [132.4135, -0.34333],
              [132.4789, -0.35542],
              [132.5892, -0.3574],
              [132.715, -0.37825],
              [132.9031, -0.46212],
              [132.9446, -0.45027],
              [132.9586, -0.48584],
              [133.0876, -0.5412],
              [133.1416, -0.53873],
              [133.1573, -0.56832],
              [133.2255, -0.59439],
              [133.3206, -0.69609],
              [133.3949, -0.7408],
              [133.5336, -0.74994],
              [133.589, -0.73111],
              [133.7271, -0.74676],
              [133.7372, -0.71691],
              [133.829, -0.73384],
              [133.9456, -0.71167],
              [134.0324, -0.79334],
              [134.0834, -0.81158],
              [134.0799, -0.86667],
              [134.0442, -0.9268],
              [134.0104, -0.9535],
              [134.0742, -1.03662],
              [134.0707, -1.06369],
              [134.1074, -1.13024],
              [134.2017, -1.20914],
              [134.2022, -1.22499],
              [134.2757, -1.33108],
              [134.2128, -1.40696],
              [134.1984, -1.46338],
              [134.2108, -1.49385],
              [134.1877, -1.5594],
              [134.1188, -1.63627],
              [134.0711, -1.65034],
              [134.1003, -1.76311],
              [134.1028, -1.86186],
              [134.1222, -1.8866],
              [134.1387, -2.01026],
              [134.104, -2.07418],
              [134.117, -2.15425],
              [134.1439, -2.15948],
              [134.1533, -2.27869],
              [134.176, -2.35627],
              [134.2158, -2.42451],
              [134.2611, -2.43375],
              [134.274, -2.47407],
              [134.3333, -2.54846],
              [134.3421, -2.60123],
              [134.4042, -2.73315],
              [134.4078, -2.77082],
              [134.452, -2.81069],
              [134.4652, -2.87027],
              [134.5122, -2.83846],
              [134.4931, -2.66117],
              [134.4731, -2.64267],
              [134.4602, -2.55217],
              [134.507, -2.48937],
              [134.5699, -2.47544],
              [134.6258, -2.49379],
              [134.6506, -2.53337],
              [134.6583, -2.59406],
              [134.6545, -2.77634],
              [134.659012, -2.825982],
            ],
          ],
          [
            [
              [130.214269, -0.210644],
              [130.29326, -0.218491],
              [130.297375, -0.174061],
              [130.276333, -0.109586],
              [130.34388, -0.103638],
              [130.393805, -0.083113],
              [130.407838, -0.112004],
              [130.476267, -0.07229],
              [130.584336, -0.041472],
              [130.623454, -0.061654],
              [130.779154, -0.010915],
              [130.868568, -0.019841],
              [130.964235, -0.059479],
              [131.027806, -0.023145],
              [131.043726, -0.066017],
              [131.107481, -0.064949],
              [131.217648, -0.13985],
              [131.258639, -0.135922],
              [131.300729, -0.173222],
              [131.290694, -0.224539],
              [131.302742, -0.27108],
              [131.334128, -0.294225],
              [131.241449, -0.363354],
              [131.07364, -0.316787],
              [130.983151, -0.358623],
              [130.937509, -0.34271],
              [130.89545, -0.271988],
              [130.852531, -0.227068],
              [130.795599, -0.2366],
              [130.765331, -0.178273],
              [130.715973, -0.159845],
              [130.695358, -0.095374],
              [130.631474, -0.097614],
              [130.61884, -0.147762],
              [130.678611, -0.21991],
              [130.758257, -0.289937],
              [130.796286, -0.307765],
              [130.914347, -0.307771],
              [130.930513, -0.351025],
              [130.890721, -0.417766],
              [130.809945, -0.447446],
              [130.722176, -0.44749],
              [130.673863, -0.343928],
              [130.67452, -0.296248],
              [130.612315, -0.32141],
              [130.543265, -0.329499],
              [130.512501, -0.281903],
              [130.506518, -0.227416],
              [130.446143, -0.279049],
              [130.425826, -0.258564],
              [130.365499, -0.261043],
              [130.282005, -0.225898],
              [130.214269, -0.210644],
            ],
          ],
          [
            [
              [130.3817, -1.97972],
              [130.3173, -1.9786],
              [130.261444, -2.022175],
              [130.253677, -2.053535],
              [130.123917, -2.061388],
              [130.076752, -2.019191],
              [130.041199, -2.025936],
              [129.945572, -2.01037],
              [129.8562, -1.96866],
              [129.7998, -1.92677],
              [129.7258, -1.89095],
              [129.7329, -1.8517],
              [129.8423, -1.8189],
              [129.8971, -1.77531],
              [129.9724, -1.76054],
              [130.0636, -1.71666],
              [130.1931, -1.71117],
              [130.2592, -1.7205],
              [130.2712, -1.69027],
              [130.3299, -1.67783],
              [130.3473, -1.72452],
              [130.4176, -1.77427],
              [130.4398, -1.83084],
              [130.3506, -1.91538],
              [130.3817, -1.97972],
            ],
          ],
          [
            [
              [130.634, -0.96141],
              [130.7904, -0.93109],
              [130.8345, -0.89812],
              [130.9247, -0.89328],
              [130.9443, -0.94147],
              [130.986, -0.91858],
              [131.0479, -0.92832],
              [131.0812, -1.01236],
              [131.03, -1.20109],
              [131.0395, -1.24211],
              [131.0011, -1.27618],
              [130.9919, -1.32662],
              [130.9639, -1.35755],
              [130.8989, -1.31993],
              [130.8583, -1.34675],
              [130.808, -1.26873],
              [130.7575, -1.25613],
              [130.7369, -1.22079],
              [130.7352, -1.16638],
              [130.7058, -1.14777],
              [130.7031, -1.07468],
              [130.634, -0.96141],
            ],
          ],
          [
            [
              [130.500001, -0.802195],
              [130.543, -0.84191],
              [130.6308, -0.80009],
              [130.6472, -0.82251],
              [130.7196, -0.82903],
              [130.8326, -0.7717],
              [130.89, -0.79218],
              [130.8352, -0.86585],
              [130.7558, -0.87348],
              [130.6759, -0.90051],
              [130.5343, -0.9198],
              [130.5171, -0.89831],
              [130.4509, -0.90216],
              [130.4476, -0.82914],
              [130.500001, -0.802195],
            ],
          ],
          [
            [
              [130.4395, -0.511482],
              [130.475187, -0.443113],
              [130.499611, -0.477376],
              [130.565207, -0.429813],
              [130.625905, -0.412933],
              [130.690355, -0.448068],
              [130.662679, -0.506549],
              [130.598006, -0.530273],
              [130.561897, -0.48944],
              [130.536529, -0.51589],
              [130.4395, -0.511482],
            ],
          ],
          [
            [
              [133.4998, -4.25291],
              [133.3902, -4.20763],
              [133.3287, -4.15485],
              [133.3674, -4.11583],
              [133.4394, -4.17827],
              [133.5616, -4.2245],
              [133.4998, -4.25291],
            ],
          ],
          [
            [
              [129.7393, -1.17687],
              [129.7623, -1.15978],
              [129.8638, -1.13086],
              [129.9217, -1.18198],
              [129.8502, -1.23003],
              [129.7919, -1.19284],
              [129.7393, -1.17687],
            ],
          ],
          [
            [
              [134.3594, -2.00101],
              [134.4059, -2.00285],
              [134.4058, -2.04549],
              [134.3707, -2.14096],
              [134.3467, -2.16647],
              [134.3197, -2.08618],
              [134.3594, -2.00101],
            ],
          ],
          [
            [
              [134.167, -1.94999],
              [134.147, -1.89109],
              [134.1483, -1.82643],
              [134.1811, -1.79425],
              [134.2171, -1.79453],
              [134.1963, -1.89107],
              [134.167, -1.94999],
            ],
          ],
          [
            [
              [133.8718, -2.3865],
              [133.9542, -2.39667],
              [133.9733, -2.46548],
              [133.9157, -2.47853],
              [133.8537, -2.4004],
              [133.8718, -2.3865],
            ],
          ],
          [
            [
              [133.5045, -2.44471],
              [133.5276, -2.4232],
              [133.6086, -2.45944],
              [133.6283, -2.50118],
              [133.6019, -2.52457],
              [133.5269, -2.4822],
              [133.5045, -2.44471],
            ],
          ],
          [
            [
              [133.7851, -2.48195],
              [133.7418, -2.42935],
              [133.8476, -2.41005],
              [133.8472, -2.44717],
              [133.8353, -2.44403],
              [133.8287, -2.44759],
              [133.823, -2.46409],
              [133.7851, -2.48195],
            ],
          ],
          [
            [
              [133.7054, -2.55503],
              [133.7859, -2.5857],
              [133.8036, -2.63625],
              [133.7952, -2.6589],
              [133.7638, -2.66193],
              [133.7659, -2.6248],
              [133.7035, -2.61333],
              [133.7054, -2.55503],
            ],
          ],
          [
            [
              [134.146, -4.0001],
              [134.0769, -3.97105],
              [134.0917, -3.91349],
              [134.1642, -3.98163],
              [134.146, -4.0001],
            ],
          ],
          [
            [
              [129.999542, 0.191739],
              [130.093445, 0.238161],
              [130.093475, 0.179727],
              [130.035904, 0.171585],
              [129.999542, 0.191739],
            ],
          ],
          [
            [
              [129.8829, -0.50098],
              [129.8412, -0.45476],
              [129.8799, -0.40881],
              [129.9161, -0.45292],
              [129.8829, -0.50098],
            ],
          ],
          [
            [
              [134.5705, -2.30029],
              [134.5883, -2.39847],
              [134.562, -2.44376],
              [134.5316, -2.43073],
              [134.5019, -2.36808],
              [134.557, -2.37238],
              [134.5705, -2.30029],
            ],
          ],
          [
            [
              [133.8823, -2.36668],
              [133.9587, -2.33113],
              [133.9823, -2.38368],
              [133.9113, -2.38244],
              [133.8823, -2.36668],
            ],
          ],
          [
            [
              [130.108762, 0.00055],
              [130.151144, -0.05625],
              [130.131055, -0.102458],
              [130.091533, -0.071162],
              [130.108762, 0.00055],
            ],
          ],
          [
            [
              [131.1405, -1.00016],
              [131.1104, -1.0648],
              [131.0744, -1.05223],
              [131.093219, -0.999222],
              [131.1405, -1.00016],
            ],
          ],
        ],
      },
      properties: {
        ID: 23,
        kode: 83,
        Propinsi: "IRIAN JAYA BARAT",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [98.25574, 4.290953],
              [98.251678, 4.20263],
              [98.168282, 4.159943],
              [98.171654, 4.108615],
              [98.234329, 4.129492],
              [98.272324, 4.119934],
              [98.30899, 4.072206],
              [98.387726, 4.076143],
              [98.435066, 4.039406],
              [98.528557, 4.011974],
              [98.558937, 3.939592],
              [98.609138, 3.914186],
              [98.672783, 3.9084],
              [98.697937, 3.874467],
              [98.70237, 3.811335],
              [98.788483, 3.718319],
              [98.991402, 3.652537],
              [99.073792, 3.610853],
              [99.131668, 3.565329],
              [99.205673, 3.535731],
              [99.272652, 3.473173],
              [99.372841, 3.402371],
              [99.485054, 3.348217],
              [99.487228, 3.322032],
              [99.546608, 3.244231],
              [99.670906, 3.209138],
              [99.737755, 3.170621],
              [99.828384, 3.06429],
              [99.917145, 2.979943],
              [99.998863, 2.93651],
              [99.990746, 2.856591],
              [100.000282, 2.799706],
              [99.981476, 2.742654],
              [100.006645, 2.724051],
              [100.060226, 2.733359],
              [100.098732, 2.676424],
              [100.136818, 2.672004],
              [100.176842, 2.706297],
              [100.222885, 2.703859],
              [100.241371, 2.637462],
              [100.33075, 2.534235],
              [100.302208, 2.413565],
              [100.309578, 2.34065],
              [100.360146, 2.220377],
              [100.322708, 2.014011],
              [100.322128, 1.919015],
              [100.2864, 1.830302],
              [100.292068, 1.802315],
              [100.408333, 1.777267],
              [100.455269, 1.629147],
              [100.415886, 1.537141],
              [100.434021, 1.51129],
              [100.408829, 1.470363],
              [100.315857, 1.416456],
              [100.27504, 1.421141],
              [100.242752, 1.392655],
              [100.077881, 1.387209],
              [100.086632, 1.337513],
              [100.123497, 1.254924],
              [100.130478, 1.174596],
              [100.16082, 1.151491],
              [100.108658, 1.071883],
              [100.161377, 0.999156],
              [100.136681, 0.949117],
              [100.092819, 0.915075],
              [100.132614, 0.891546],
              [100.140305, 0.797662],
              [100.183716, 0.761895],
              [100.083336, 0.791869],
              [99.957077, 0.875112],
              [99.881943, 0.90572],
              [99.835236, 0.889678],
              [99.845215, 0.83873],
              [99.845169, 0.686254],
              [99.914688, 0.680979],
              [99.919365, 0.605901],
              [99.964767, 0.554445],
              [99.908188, 0.474805],
              [99.82309, 0.461878],
              [99.765022, 0.481917],
              [99.716553, 0.454064],
              [99.670876, 0.481021],
              [99.640007, 0.547226],
              [99.587463, 0.516625],
              [99.499702, 0.538817],
              [99.407265, 0.493468],
              [99.331474, 0.362899],
              [99.297096, 0.337691],
              [99.235527, 0.327712],
              [99.16198, 0.231812],
              [99.153839, 0.308747],
              [99.109512, 0.342637],
              [99.135689, 0.435732],
              [99.093124, 0.60576],
              [99.071304, 0.629137],
              [99.057587, 0.726479],
              [99.017548, 0.813711],
              [98.991776, 0.822795],
              [98.969215, 0.931165],
              [98.927269, 1.041666],
              [98.910728, 1.122399],
              [98.839851, 1.286661],
              [98.844856, 1.333901],
              [98.777878, 1.434891],
              [98.759827, 1.528909],
              [98.772934, 1.561786],
              [98.840958, 1.628664],
              [98.82769, 1.677764],
              [98.797264, 1.683911],
              [98.769341, 1.7595],
              [98.707138, 1.789281],
              [98.67894, 1.740839],
              [98.611458, 1.838434],
              [98.501572, 1.941459],
              [98.466949, 1.987465],
              [98.389442, 2.007457],
              [98.338593, 2.045849],
              [98.271065, 2.049494],
              [98.224586, 2.096319],
              [98.153148, 2.146027],
              [98.18766, 2.319383],
              [98.155571, 2.413039],
              [98.112091, 2.424448],
              [98.109344, 2.47093],
              [98.083992, 2.50935],
              [98.075233, 2.57553],
              [98.085129, 2.63422],
              [98.12426, 2.655123],
              [98.086838, 2.7349],
              [98.088196, 2.821929],
              [98.042084, 2.828039],
              [98.006783, 2.889557],
              [97.954605, 2.900333],
              [97.958275, 2.961847],
              [97.939224, 3.071936],
              [97.983467, 3.083911],
              [97.978615, 3.127329],
              [97.942329, 3.164997],
              [97.925278, 3.226437],
              [97.872261, 3.249049],
              [97.984924, 3.275573],
              [98.029823, 3.329871],
              [98.003082, 3.369784],
              [97.949715, 3.391531],
              [97.906899, 3.553616],
              [97.869896, 3.57131],
              [97.843933, 3.660525],
              [97.803185, 3.731739],
              [97.908562, 3.820508],
              [97.89991, 3.89141],
              [97.944946, 3.89686],
              [97.95816, 3.928891],
              [98.023132, 3.969775],
              [98.059311, 4.190727],
              [98.090927, 4.19672],
              [98.145401, 4.287369],
              [98.25574, 4.290953],
            ],
            [
              [98.92707, 2.662123],
              [98.922714, 2.694872],
              [98.833435, 2.747945],
              [98.79274, 2.79301],
              [98.692108, 2.850813],
              [98.625786, 2.878684],
              [98.531593, 2.892692],
              [98.527939, 2.792798],
              [98.593155, 2.730381],
              [98.624992, 2.681004],
              [98.616501, 2.648208],
              [98.683029, 2.637003],
              [98.695267, 2.72581],
              [98.723587, 2.754783],
              [98.777992, 2.739367],
              [98.854713, 2.665531],
              [98.921768, 2.541882],
              [98.971985, 2.50286],
              [98.98819, 2.455078],
              [98.922638, 2.428574],
              [98.874153, 2.425913],
              [98.778, 2.504612],
              [98.72554, 2.532148],
              [98.69825, 2.601211],
              [98.667595, 2.545009],
              [98.733681, 2.51731],
              [98.804596, 2.424474],
              [98.817467, 2.378578],
              [98.854034, 2.333492],
              [98.917976, 2.348454],
              [98.991257, 2.324367],
              [99.029228, 2.357742],
              [99.055664, 2.329031],
              [99.151482, 2.391701],
              [99.148209, 2.430922],
              [99.062157, 2.391961],
              [99.04792, 2.48477],
              [99.027946, 2.516789],
              [98.924484, 2.584452],
              [98.909531, 2.623648],
              [98.92707, 2.662123],
            ],
          ],
          [
            [
              [97.500496, 0.916113],
              [97.471382, 0.935922],
              [97.399849, 0.942146],
              [97.358147, 1.075364],
              [97.253624, 1.231068],
              [97.182999, 1.289336],
              [97.136726, 1.3638],
              [97.073105, 1.417242],
              [97.122917, 1.424893],
              [97.19696, 1.395284],
              [97.257141, 1.410369],
              [97.340027, 1.4905],
              [97.386353, 1.493314],
              [97.428909, 1.468561],
              [97.476486, 1.468499],
              [97.530159, 1.407647],
              [97.530495, 1.382853],
              [97.583405, 1.330732],
              [97.678436, 1.177108],
              [97.787994, 1.149787],
              [97.854446, 1.053744],
              [97.889046, 1.051632],
              [97.935623, 0.960954],
              [97.893776, 0.898828],
              [97.885437, 0.823092],
              [97.89946, 0.793721],
              [97.875778, 0.743668],
              [97.8918, 0.628914],
              [97.827011, 0.568907],
              [97.795334, 0.558244],
              [97.733276, 0.577961],
              [97.704224, 0.559856],
              [97.658752, 0.659734],
              [97.645798, 0.737644],
              [97.58802, 0.838179],
              [97.500496, 0.916113],
            ],
          ],
          [
            [
              [98.409668, -0.596789],
              [98.341278, -0.512249],
              [98.370155, -0.452895],
              [98.367058, -0.377264],
              [98.380356, -0.337618],
              [98.436165, -0.295887],
              [98.442184, -0.244816],
              [98.516144, -0.35539],
              [98.501068, -0.394909],
              [98.498665, -0.490648],
              [98.476936, -0.518796],
              [98.498367, -0.555216],
              [98.409668, -0.596789],
            ],
          ],
          [
            [
              [98.371108, 0.005866],
              [98.414833, -0.025506],
              [98.465797, -0.0922],
              [98.488098, -0.203953],
              [98.568024, -0.27773],
              [98.56321, -0.328114],
              [98.51564, -0.321089],
              [98.427185, -0.20239],
              [98.384697, -0.132913],
              [98.356728, -0.054339],
              [98.314896, -0.011829],
              [98.371108, 0.005866],
            ],
          ],
          [
            [
              [98.598198, 0.094976],
              [98.5308, 0.106037],
              [98.522354, 0.144102],
              [98.60891, 0.183891],
              [98.717834, 0.159357],
              [98.796844, 0.171431],
              [98.850594, 0.117946],
              [98.844627, 0.082099],
              [98.777519, 0.098079],
              [98.74559, 0.073061],
              [98.598198, 0.094976],
            ],
          ],
          [
            [
              [98.460686, 1.702748],
              [98.486298, 1.698948],
              [98.50116, 1.625],
              [98.444466, 1.652204],
              [98.460686, 1.702748],
            ],
          ],
        ],
      },
      properties: {
        ID: 24,
        kode: 12,
        Propinsi: "SUMATERA UTARA",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.106688, -2.022016],
              [105.124608, -1.957248],
              [105.165632, -1.925504],
              [105.221056, -1.913472],
              [105.34688, -1.841024],
              [105.393152, -1.779392],
              [105.369664, -1.717056],
              [105.334016, -1.693568],
              [105.360896, -1.649792],
              [105.464384, -1.560448],
              [105.534464, -1.563456],
              [105.600512, -1.545152],
              [105.644352, -1.641792],
              [105.714752, -1.655552],
              [105.65472, -1.716672],
              [105.674432, -1.761728],
              [105.731264, -1.780544],
              [105.792768, -1.755264],
              [105.744704, -1.68576],
              [105.764864, -1.648128],
              [105.72064, -1.57952],
              [105.753664, -1.531264],
              [105.876224, -1.51872],
              [105.929792, -1.54816],
              [106.008896, -1.567616],
              [106.029824, -1.616448],
              [106.04224, -1.698368],
              [106.146688, -1.85952],
              [106.192512, -1.896128],
              [106.163776, -1.92672],
              [106.153344, -2.016064],
              [106.175616, -2.150848],
              [106.225216, -2.258176],
              [106.23424, -2.328064],
              [106.281152, -2.397888],
              [106.340864, -2.45632],
              [106.380672, -2.47392],
              [106.444928, -2.470464],
              [106.515712, -2.511936],
              [106.689408, -2.541632],
              [106.798336, -2.5664],
              [106.705984, -2.641472],
              [106.65664, -2.72064],
              [106.587264, -2.916224],
              [106.595136, -2.953152],
              [106.737536, -3.004288],
              [106.742592, -3.053824],
              [106.704064, -3.09504],
              [106.647424, -3.081152],
              [106.562496, -3.0832],
              [106.520832, -3.112192],
              [106.497728, -3.064704],
              [106.412288, -2.966656],
              [106.330944, -2.963264],
              [106.276224, -2.902592],
              [106.236928, -2.899648],
              [106.176896, -2.868672],
              [106.140864, -2.8736],
              [106.097408, -2.833472],
              [105.996096, -2.820352],
              [105.962816, -2.805312],
              [105.91584, -2.735872],
              [105.884544, -2.635776],
              [105.932224, -2.473024],
              [105.909376, -2.44128],
              [105.831104, -2.40768],
              [105.801792, -2.35872],
              [105.82368, -2.30688],
              [105.785856, -2.244864],
              [105.776192, -2.156288],
              [105.733952, -2.128384],
              [105.655808, -2.114432],
              [105.590464, -2.119936],
              [105.55904, -2.08192],
              [105.503616, -2.080512],
              [105.437184, -2.133312],
              [105.358336, -2.12],
              [105.301184, -2.142848],
              [105.253312, -2.083648],
              [105.217216, -2.06432],
              [105.128064, -2.082688],
              [105.106688, -2.022016],
            ],
          ],
          [
            [
              [107.62494, -2.74277],
              [107.618688, -2.695936],
              [107.648512, -2.627072],
              [107.630976, -2.59584],
              [107.662464, -2.5584],
              [107.72224, -2.547584],
              [107.769664, -2.556928],
              [107.81536, -2.53632],
              [107.909824, -2.575872],
              [107.994752, -2.57472],
              [108.00992, -2.596864],
              [108.083328, -2.606464],
              [108.183744, -2.6768],
              [108.26048, -2.766976],
              [108.290432, -2.85312],
              [108.245184, -2.908544],
              [108.235328, -2.95712],
              [108.199616, -3.011008],
              [108.209472, -3.048064],
              [108.173632, -3.109184],
              [108.169472, -3.14816],
              [108.135808, -3.1664],
              [108.073408, -3.166144],
              [108.062976, -3.235776],
              [107.96832, -3.237568],
              [107.961664, -3.149376],
              [107.85152, -3.053248],
              [107.839552, -3.146784],
              [107.758144, -3.1864],
              [107.713856, -3.18752],
              [107.642048, -3.23136],
              [107.589952, -3.197696],
              [107.628416, -3.142848],
              [107.58528, -3.079872],
              [107.569728, -3.027648],
              [107.610816, -2.926144],
              [107.545408, -2.902208],
              [107.600064, -2.858496],
              [107.598656, -2.788224],
              [107.62494, -2.74277],
            ],
          ],
          [
            [
              [106.881216, -2.999872],
              [106.872896, -3.028288],
              [106.793344, -3.019648],
              [106.707712, -2.962624],
              [106.729344, -2.9296],
              [106.803776, -2.885888],
              [106.900608, -2.935168],
              [106.881216, -2.999872],
            ],
          ],
          [
            [
              [107.488, -2.853568],
              [107.472128, -2.908416],
              [107.416256, -2.94496],
              [107.362176, -2.896256],
              [107.38624, -2.850304],
              [107.426752, -2.828096],
              [107.488, -2.853568],
            ],
          ],
          [
            [
              [107.094272, -2.87296],
              [107.0608, -2.907392],
              [107.020736, -2.873088],
              [107.054208, -2.81888],
              [107.094272, -2.87296],
            ],
          ],
        ],
      },
      properties: {
        ID: 25,
        kode: 19,
        Propinsi: "BANGKA BELITUNG",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [99.16198, 0.231812],
              [99.235527, 0.327712],
              [99.297096, 0.337691],
              [99.331474, 0.362899],
              [99.407265, 0.493468],
              [99.499702, 0.538817],
              [99.587463, 0.516625],
              [99.640007, 0.547226],
              [99.670876, 0.481021],
              [99.716553, 0.454064],
              [99.765022, 0.481917],
              [99.82309, 0.461878],
              [99.908188, 0.474805],
              [99.964767, 0.554445],
              [99.919365, 0.605901],
              [99.914688, 0.680979],
              [99.845169, 0.686254],
              [99.845215, 0.83873],
              [99.835236, 0.889678],
              [99.881943, 0.90572],
              [99.957077, 0.875112],
              [100.083336, 0.791869],
              [100.183716, 0.761895],
              [100.25573, 0.709395],
              [100.205925, 0.593234],
              [100.219559, 0.524918],
              [100.282326, 0.408069],
              [100.281677, 0.332958],
              [100.361946, 0.354324],
              [100.394623, 0.339763],
              [100.43718, 0.247852],
              [100.464088, 0.222191],
              [100.605743, 0.183473],
              [100.687881, 0.177849],
              [100.727119, 0.193165],
              [100.778122, 0.169268],
              [100.792191, 0.120829],
              [100.753342, 0.048535],
              [100.770103, -0.033094],
              [100.748398, -0.065219],
              [100.781006, -0.23042],
              [100.838776, -0.290596],
              [100.833672, -0.318377],
              [100.910423, -0.370743],
              [100.94133, -0.312437],
              [100.996368, -0.314363],
              [101.031708, -0.340868],
              [101.024826, -0.398442],
              [101.06768, -0.498227],
              [101.181267, -0.549494],
              [101.256569, -0.626754],
              [101.359833, -0.659254],
              [101.407272, -0.736848],
              [101.500015, -0.807856],
              [101.595917, -0.8512],
              [101.6539, -0.935173],
              [101.747887, -0.967631],
              [101.821533, -0.977189],
              [101.830315, -1.10193],
              [101.878525, -1.110225],
              [101.858885, -1.158166],
              [101.815407, -1.209807],
              [101.709229, -1.237597],
              [101.689585, -1.295043],
              [101.719172, -1.344921],
              [101.691318, -1.397239],
              [101.720899, -1.42113],
              [101.694035, -1.480637],
              [101.567154, -1.572305],
              [101.526581, -1.616804],
              [101.492464, -1.681019],
              [101.431198, -1.696775],
              [101.415512, -1.671203],
              [101.319626, -1.693206],
              [101.258324, -1.693337],
              [101.21579, -1.714355],
              [101.18763, -1.677177],
              [101.125816, -1.679094],
              [101.124443, -1.78474],
              [101.144699, -1.917723],
              [101.194092, -1.989151],
              [101.243668, -2.021798],
              [101.291611, -2.133106],
              [101.306076, -2.23891],
              [101.294159, -2.280203],
              [101.244644, -2.344946],
              [101.155373, -2.390157],
              [101.027755, -2.482182],
              [100.992081, -2.431542],
              [100.897308, -2.337769],
              [100.877205, -2.247514],
              [100.831245, -2.188847],
              [100.82914, -2.147044],
              [100.869568, -2.104535],
              [100.884331, -2.042843],
              [100.858192, -1.919218],
              [100.78273, -1.819229],
              [100.684204, -1.661846],
              [100.64151, -1.618727],
              [100.633499, -1.562898],
              [100.555435, -1.403785],
              [100.576469, -1.376961],
              [100.544067, -1.308304],
              [100.492241, -1.299992],
              [100.458786, -1.26039],
              [100.402206, -1.268252],
              [100.417633, -1.200178],
              [100.382729, -1.178114],
              [100.410194, -1.037552],
              [100.364952, -0.995286],
              [100.342545, -0.882115],
              [100.269402, -0.782622],
              [100.117134, -0.631379],
              [100.080696, -0.549656],
              [99.971771, -0.443441],
              [99.918625, -0.404366],
              [99.886421, -0.349305],
              [99.820641, -0.306837],
              [99.806374, -0.245461],
              [99.750984, -0.153287],
              [99.765541, -0.065272],
              [99.735329, -0.027319],
              [99.610626, 0.069696],
              [99.471992, 0.134715],
              [99.394257, 0.152445],
              [99.353981, 0.230505],
              [99.312424, 0.238678],
              [99.253555, 0.211412],
              [99.16198, 0.231812],
            ],
            [
              [100.148926, -0.294198],
              [100.165466, -0.310519],
              [100.165375, -0.388144],
              [100.218018, -0.396025],
              [100.226418, -0.288869],
              [100.194832, -0.252879],
              [100.148926, -0.294198],
            ],
            [
              [100.500008, -0.534535],
              [100.487389, -0.577913],
              [100.540764, -0.666713],
              [100.59713, -0.680994],
              [100.544914, -0.55475],
              [100.500008, -0.534535],
            ],
          ],
          [
            [
              [99.263031, -1.718946],
              [99.241554, -1.791817],
              [99.170258, -1.775342],
              [99.104195, -1.809051],
              [99.009583, -1.762335],
              [98.872444, -1.675564],
              [98.81971, -1.61324],
              [98.831772, -1.58409],
              [98.798141, -1.519863],
              [98.686104, -1.357201],
              [98.628815, -1.283929],
              [98.597008, -1.222869],
              [98.64505, -1.10431],
              [98.65641, -0.979386],
              [98.715179, -0.94198],
              [98.786461, -0.954942],
              [98.905327, -0.907312],
              [98.901535, -0.943195],
              [98.930374, -1.056429],
              [98.997421, -1.109993],
              [99.003639, -1.159117],
              [99.029007, -1.183758],
              [99.069817, -1.264657],
              [99.095047, -1.360714],
              [99.162605, -1.427637],
              [99.159073, -1.492668],
              [99.192131, -1.505583],
              [99.193535, -1.554393],
              [99.22258, -1.605247],
              [99.258133, -1.590373],
              [99.280853, -1.628746],
              [99.263031, -1.718946],
            ],
          ],
          [
            [
              [100.432472, -3.000107],
              [100.465523, -3.033659],
              [100.468422, -3.144241],
              [100.394447, -3.149384],
              [100.389984, -3.197419],
              [100.475174, -3.334633],
              [100.421425, -3.310643],
              [100.342789, -3.24575],
              [100.325836, -3.212034],
              [100.332291, -3.129642],
              [100.296883, -3.080192],
              [100.228333, -3.045524],
              [100.186508, -2.986326],
              [100.200531, -2.933076],
              [100.17469, -2.801497],
              [100.23597, -2.778423],
              [100.261101, -2.820208],
              [100.354225, -2.895449],
              [100.432472, -3.000107],
            ],
          ],
          [
            [
              [99.995766, -2.749434],
              [100.004059, -2.723478],
              [99.970131, -2.509001],
              [99.994774, -2.498358],
              [100.070412, -2.575727],
              [100.168579, -2.640264],
              [100.221405, -2.740431],
              [100.11644, -2.831824],
              [100.015343, -2.805559],
              [99.995766, -2.749434],
            ],
          ],
          [
            [
              [99.822586, -2.306719],
              [99.833534, -2.360941],
              [99.793526, -2.368245],
              [99.74221, -2.344413],
              [99.673538, -2.286497],
              [99.598953, -2.281999],
              [99.52739, -2.152481],
              [99.571342, -2.143682],
              [99.545197, -2.067696],
              [99.565895, -2.036215],
              [99.618942, -2.025629],
              [99.689621, -2.074452],
              [99.710625, -2.154402],
              [99.783386, -2.28467],
              [99.822586, -2.306719],
            ],
          ],
        ],
      },
      properties: {
        ID: 26,
        kode: 13,
        Propinsi: "SUMATERA BARAT",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.7221, -3.00548],
            [110.747238, -2.952027],
            [110.797234, -2.912337],
            [110.886566, -2.874295],
            [110.967964, -2.856296],
            [111.010681, -2.794681],
            [111.063812, -2.785731],
            [111.138382, -2.7492],
            [111.166374, -2.710246],
            [111.16468, -2.672753],
            [111.118469, -2.653161],
            [111.136215, -2.611802],
            [111.105408, -2.484712],
            [111.104942, -2.433837],
            [111.068977, -2.298775],
            [111.0326, -2.247475],
            [111.077835, -2.153214],
            [111.049252, -2.069462],
            [111.085697, -2.007968],
            [111.046007, -1.95951],
            [110.978226, -1.95324],
            [110.995881, -1.900829],
            [110.969867, -1.866475],
            [110.971994, -1.812174],
            [111.018458, -1.760494],
            [111.001907, -1.691915],
            [110.974731, -1.667775],
            [110.913635, -1.717645],
            [110.857147, -1.667797],
            [110.855583, -1.633108],
            [110.912148, -1.583816],
            [110.94973, -1.578825],
            [110.980354, -1.538065],
            [111.039024, -1.539377],
            [111.077415, -1.562303],
            [111.123306, -1.544106],
            [111.111994, -1.487714],
            [111.157883, -1.470608],
            [111.188332, -1.490991],
            [111.21228, -1.411965],
            [111.261569, -1.387914],
            [111.348486, -1.285983],
            [111.417722, -1.249998],
            [111.489815, -1.279386],
            [111.494591, -1.221164],
            [111.560516, -1.210964],
            [111.571541, -1.14573],
            [111.55349, -1.117017],
            [111.570969, -1.075839],
            [111.623978, -1.031922],
            [111.624825, -0.984459],
            [111.717972, -0.945031],
            [111.733315, -0.923568],
            [111.832191, -0.914291],
            [111.906021, -0.869211],
            [111.906593, -0.775333],
            [111.954544, -0.818529],
            [112.000516, -0.782459],
            [111.999829, -0.72664],
            [112.039477, -0.702334],
            [112.107172, -0.688612],
            [112.199463, -0.614976],
            [112.244011, -0.634966],
            [112.302696, -0.732715],
            [112.449364, -0.679314],
            [112.538094, -0.699546],
            [112.595421, -0.687516],
            [112.634483, -0.654555],
            [112.688972, -0.652312],
            [112.731289, -0.624087],
            [112.754036, -0.568419],
            [112.852104, -0.568786],
            [112.888245, -0.5348],
            [113.032639, -0.487246],
            [113.059326, -0.500177],
            [113.131927, -0.450832],
            [113.229156, -0.474128],
            [113.265274, -0.432963],
            [113.260048, -0.386946],
            [113.221898, -0.340063],
            [113.333961, -0.188953],
            [113.424301, 0.01172],
            [113.405693, 0.138666],
            [113.326904, 0.178387],
            [113.269646, 0.237864],
            [113.322639, 0.27277],
            [113.437424, 0.265147],
            [113.513199, 0.320208],
            [113.587898, 0.405597],
            [113.656631, 0.438104],
            [113.672333, 0.505215],
            [113.738724, 0.544104],
            [113.842865, 0.665291],
            [113.962456, 0.657218],
            [114.047279, 0.67466],
            [114.105927, 0.698564],
            [114.149551, 0.67515],
            [114.172874, 0.628675],
            [114.150124, 0.584852],
            [114.211525, 0.53291],
            [114.280472, 0.540742],
            [114.353485, 0.53131],
            [114.412323, 0.495826],
            [114.429817, 0.545453],
            [114.492722, 0.57297],
            [114.51963, 0.564921],
            [114.54274, 0.631714],
            [114.589912, 0.626305],
            [114.653, 0.685335],
            [114.713394, 0.705227],
            [114.766129, 0.742571],
            [114.827667, 0.751281],
            [114.846718, 0.715452],
            [114.911812, 0.784313],
            [114.944992, 0.775121],
            [114.992355, 0.71701],
            [115.010025, 0.617119],
            [115.093254, 0.47235],
            [115.136154, 0.429964],
            [115.125549, 0.369575],
            [115.040817, 0.303441],
            [115.025604, 0.325491],
            [114.964165, 0.315193],
            [114.94455, 0.330936],
            [114.888893, 0.297505],
            [114.827431, 0.196637],
            [114.792274, 0.093691],
            [114.882278, 0.053212],
            [114.89846, -0.035127],
            [114.948639, -0.1902],
            [114.980179, -0.178222],
            [115.044418, -0.190297],
            [115.082069, -0.152977],
            [115.149078, -0.136886],
            [115.155769, -0.103698],
            [115.297623, -0.03339],
            [115.320732, -0.093379],
            [115.265564, -0.157903],
            [115.26992, -0.185243],
            [115.239098, -0.244186],
            [115.227875, -0.305798],
            [115.246429, -0.4417],
            [115.303055, -0.501086],
            [115.324715, -0.557164],
            [115.352211, -0.579602],
            [115.367661, -0.749212],
            [115.39312, -0.82201],
            [115.495659, -0.848081],
            [115.582848, -0.947266],
            [115.65976, -1.000104],
            [115.693001, -1.054689],
            [115.743721, -1.090938],
            [115.749481, -1.124644],
            [115.800606, -1.113091],
            [115.844508, -1.162429],
            [115.776921, -1.230763],
            [115.751405, -1.316831],
            [115.750001, -1.314339],
            [115.75, -1.314338],
            [115.669192, -1.333779],
            [115.556666, -1.395118],
            [115.414142, -1.424074],
            [115.351248, -1.633594],
            [115.324479, -1.809402],
            [115.33758, -1.851955],
            [115.402261, -1.954247],
            [115.371055, -2.010274],
            [115.406859, -2.060095],
            [115.322177, -2.072651],
            [115.297228, -2.143794],
            [115.29124, -2.224027],
            [115.248471, -2.211515],
            [115.040507, -2.352388],
            [114.987968, -2.413859],
            [114.919861, -2.455716],
            [114.886746, -2.50283],
            [114.806774, -2.578846],
            [114.787004, -2.682251],
            [114.761401, -2.722209],
            [114.672453, -2.747],
            [114.664366, -2.800567],
            [114.598846, -2.815611],
            [114.617653, -2.883607],
            [114.567871, -2.866444],
            [114.546252, -2.947693],
            [114.496324, -3.015414],
            [114.545967, -3.067971],
            [114.429722, -3.174344],
            [114.450182, -3.209987],
            [114.383407, -3.26764],
            [114.347025, -3.350136],
            [114.358683, -3.386272],
            [114.349449, -3.456847],
            [114.3211, -3.44867],
            [114.244545, -3.386611],
            [114.1825, -3.39577],
            [114.075844, -3.350414],
            [113.9525, -3.36466],
            [113.8419, -3.4356],
            [113.8007, -3.45275],
            [113.6978, -3.46773],
            [113.5807, -3.44965],
            [113.6295, -3.3223],
            [113.6281, -3.26023],
            [113.596, -3.16723],
            [113.5531, -3.15856],
            [113.4589, -3.17963],
            [113.4371, -3.22352],
            [113.3492, -3.28269],
            [113.2787, -3.19723],
            [113.2133, -3.13482],
            [113.1866, -3.0871],
            [113.1096, -3.04946],
            [113.0286, -2.98744],
            [113.0193, -3.02321],
            [112.9457, -3.10261],
            [112.955, -3.1394],
            [113.0109, -3.16073],
            [112.9319, -3.23328],
            [112.8091, -3.30329],
            [112.6798, -3.36703],
            [112.6415, -3.40226],
            [112.5488, -3.44531],
            [112.4781, -3.4142],
            [112.4349, -3.36454],
            [112.3207, -3.31361],
            [112.2506, -3.31554],
            [112.1808, -3.34316],
            [112.0923, -3.39754],
            [111.9173, -3.5453],
            [111.8821, -3.5622],
            [111.8101, -3.55057],
            [111.7749, -3.5178],
            [111.8024, -3.47417],
            [111.8246, -3.39013],
            [111.8225, -3.31093],
            [111.8031, -3.19602],
            [111.832, -3.07153],
            [111.8056, -2.9831],
            [111.7367, -2.87741],
            [111.6985, -2.86289],
            [111.6686, -2.89805],
            [111.6874, -2.94647],
            [111.6336, -2.96257],
            [111.6012, -2.99998],
            [111.5433, -3.01695],
            [111.5187, -2.97853],
            [111.456, -2.93012],
            [111.3598, -2.91315],
            [111.2952, -2.93317],
            [111.2114, -2.98162],
            [111.0547, -3.04916],
            [110.9794, -3.06996],
            [110.9077, -3.04345],
            [110.8092, -2.98021],
            [110.7221, -3.00548],
          ],
        ],
      },
      properties: {
        ID: 27,
        kode: 0,
        Propinsi: "KALIMANTAN TENGAH",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [105.785599, -4.117105],
            [105.736504, -4.14257],
            [105.705254, -4.098131],
            [105.651817, -4.087366],
            [105.609012, -3.941194],
            [105.51181, -3.870661],
            [105.476404, -3.821803],
            [105.432106, -3.826865],
            [105.414685, -3.776846],
            [105.353288, -3.794026],
            [105.339727, -3.729057],
            [105.281975, -3.798395],
            [105.290791, -3.839618],
            [105.245973, -3.907812],
            [105.201302, -3.892022],
            [105.173737, -3.91232],
            [105.133827, -3.991517],
            [105.112688, -3.999992],
            [105.0635, -4.119611],
            [105.063462, -4.11991],
            [105.011985, -4.173651],
            [104.966435, -4.170873],
            [104.815692, -4.241028],
            [104.788078, -4.261929],
            [104.737687, -4.251162],
            [104.734169, -4.251686],
            [104.655515, -4.250796],
            [104.601004, -4.232642],
            [104.448685, -4.298732],
            [104.330181, -4.424722],
            [104.311634, -4.521019],
            [104.332608, -4.612251],
            [104.294205, -4.647204],
            [104.303461, -4.764368],
            [104.325085, -4.806065],
            [104.32542, -4.808589],
            [104.347231, -4.822632],
            [104.348493, -4.822822],
            [104.349944, -4.85207],
            [104.348405, -4.853417],
            [104.311096, -4.885782],
            [104.309891, -4.88541],
            [104.190925, -4.865033],
            [104.160093, -4.922752],
            [104.110733, -4.923104],
            [104.060074, -4.894125],
            [104.020423, -4.905076],
            [104.019723, -4.90522],
            [103.955718, -4.904517],
            [103.95543, -4.904254],
            [103.93436, -4.886683],
            [103.86399, -4.918034],
            [103.864089, -4.847539],
            [103.823976, -4.802083],
            [103.777597, -4.796539],
            [103.777253, -4.79643],
            [103.762881, -4.7421],
            [103.704562, -4.697992],
            [103.693762, -4.652768],
            [103.638473, -4.598905],
            [103.630332, -4.554627],
            [103.590699, -4.52343],
            [103.521138, -4.57475],
            [103.476627, -4.564854],
            [103.405136, -4.499798],
            [103.428073, -4.40753],
            [103.468517, -4.348306],
            [103.453716, -4.3315],
            [103.329801, -4.292813],
            [103.307721, -4.256566],
            [103.200666, -4.247289],
            [103.185598, -4.163748],
            [103.155336, -4.110564],
            [103.128459, -4.025171],
            [102.956093, -4.012947],
            [102.914916, -3.97235],
            [102.886803, -3.989239],
            [102.79337, -3.954387],
            [102.746036, -3.892661],
            [102.686069, -3.858048],
            [102.63594, -3.812298],
            [102.655215, -3.772596],
            [102.728362, -3.75001],
            [102.735313, -3.702301],
            [102.7655, -3.644093],
            [102.809992, -3.608159],
            [102.813889, -3.534643],
            [102.847676, -3.536874],
            [102.921399, -3.593359],
            [102.952011, -3.531126],
            [102.981817, -3.526327],
            [102.998046, -3.466969],
            [102.989653, -3.370434],
            [102.888499, -3.375246],
            [102.881726, -3.345414],
            [102.816825, -3.333799],
            [102.773751, -3.280437],
            [102.742687, -3.315027],
            [102.686779, -3.317826],
            [102.640813, -3.349581],
            [102.596323, -3.333274],
            [102.512752, -3.274333],
            [102.491776, -3.244821],
            [102.485085, -3.1067],
            [102.441376, -3.057934],
            [102.389915, -3.044319],
            [102.322517, -3.068047],
            [102.247734, -3.027864],
            [102.223949, -2.929184],
            [102.132041, -2.87452],
            [102.12852, -2.834456],
            [102.070271, -2.779745],
            [102.070121, -2.779601],
            [102.066829, -2.766654],
            [102.125106, -2.729362],
            [102.23631, -2.687767],
            [102.25, -2.631226],
            [102.279852, -2.615552],
            [102.33295, -2.65582],
            [102.387126, -2.648317],
            [102.405707, -2.688557],
            [102.490935, -2.70477],
            [102.517768, -2.677439],
            [102.597958, -2.677392],
            [102.64112, -2.609328],
            [102.73603, -2.577646],
            [102.79447, -2.533695],
            [102.793521, -2.505252],
            [102.875275, -2.407647],
            [102.839739, -2.358138],
            [102.878348, -2.304883],
            [102.98499, -2.338936],
            [103.035798, -2.33815],
            [103.054261, -2.312252],
            [103.123599, -2.357747],
            [103.211938, -2.312515],
            [103.194719, -2.25453],
            [103.164581, -2.219417],
            [103.219055, -2.171653],
            [103.273361, -2.154748],
            [103.303619, -2.238668],
            [103.392789, -2.295459],
            [103.409337, -2.332503],
            [103.449943, -2.343645],
            [103.465737, -2.249964],
            [103.441574, -2.213186],
            [103.453247, -2.177541],
            [103.526903, -2.167123],
            [103.513543, -2.084383],
            [103.55055, -2.049371],
            [103.56171, -2.000056],
            [103.441719, -1.913766],
            [103.500085, -1.857687],
            [103.552617, -1.891252],
            [103.597851, -1.813841],
            [103.683861, -1.804445],
            [103.863641, -1.803907],
            [103.925284, -1.750187],
            [103.980489, -1.809214],
            [104.072833, -1.805048],
            [104.11097, -1.786794],
            [104.272829, -1.743732],
            [104.30012, -1.774048],
            [104.351075, -1.741535],
            [104.362201, -1.642867],
            [104.418398, -1.628021],
            [104.515165, -1.67627],
            [104.544899, -1.725683],
            [104.536062, -1.796749],
            [104.482233, -1.837572],
            [104.475063, -1.913292],
            [104.519772, -1.925953],
            [104.552302, -1.854772],
            [104.649983, -1.913998],
            [104.657544, -1.963975],
            [104.686527, -1.992045],
            [104.847588, -2.007696],
            [104.899483, -2.091442],
            [104.908005, -2.147511],
            [104.853889, -2.239455],
            [104.853081, -2.288282],
            [104.913132, -2.283812],
            [104.934425, -2.343508],
            [104.997681, -2.337891],
            [105.122139, -2.382225],
            [105.227684, -2.334802],
            [105.294975, -2.370025],
            [105.415565, -2.389337],
            [105.531751, -2.395946],
            [105.55791, -2.419285],
            [105.615726, -2.390048],
            [105.625537, -2.432843],
            [105.601795, -2.553382],
            [105.633308, -2.645045],
            [105.701469, -2.686577],
            [105.756975, -2.689149],
            [105.787621, -2.712982],
            [105.78997, -2.874425],
            [105.811295, -2.915317],
            [105.858688, -2.955325],
            [106.007011, -2.965389],
            [106.043472, -2.992686],
            [106.056297, -3.072323],
            [106.046761, -3.109661],
            [106.079933, -3.193721],
            [106.07856, -3.250618],
            [106.015045, -3.287257],
            [105.912909, -3.384527],
            [105.843343, -3.525817],
            [105.815506, -3.649148],
            [105.827038, -3.712559],
            [105.92809, -3.777244],
            [105.956879, -3.848718],
            [105.866005, -4.018025],
            [105.840332, -4.159925],
            [105.82164, -4.166204],
            [105.814331, -4.153293],
            [105.812897, -4.150985],
            [105.792374, -4.119071],
            [105.785599, -4.117105],
          ],
        ],
      },
      properties: {
        ID: 28,
        kode: 16,
        Propinsi: "SUMATERA SELATAN",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [101.294159, -2.280203],
            [101.306076, -2.23891],
            [101.291611, -2.133106],
            [101.243668, -2.021798],
            [101.194092, -1.989151],
            [101.144699, -1.917723],
            [101.124443, -1.78474],
            [101.125816, -1.679094],
            [101.18763, -1.677177],
            [101.21579, -1.714355],
            [101.258324, -1.693337],
            [101.319626, -1.693206],
            [101.415512, -1.671203],
            [101.431198, -1.696775],
            [101.492464, -1.681019],
            [101.526581, -1.616804],
            [101.567154, -1.572305],
            [101.694035, -1.480637],
            [101.720899, -1.42113],
            [101.691318, -1.397239],
            [101.719172, -1.344921],
            [101.689585, -1.295043],
            [101.709229, -1.237597],
            [101.815407, -1.209807],
            [101.858885, -1.158166],
            [101.878525, -1.110225],
            [101.830315, -1.10193],
            [101.821533, -0.977189],
            [101.845284, -0.982566],
            [101.924278, -0.941475],
            [101.983551, -0.880408],
            [102.057976, -0.889922],
            [102.116615, -0.921174],
            [102.16272, -0.898907],
            [102.217072, -0.946344],
            [102.254326, -0.921539],
            [102.304298, -0.916958],
            [102.324387, -0.960805],
            [102.38623, -0.993385],
            [102.393372, -1.03016],
            [102.44252, -1.025452],
            [102.495422, -1.084733],
            [102.616814, -1.094634],
            [102.638, -1.12041],
            [102.687668, -1.078578],
            [102.780602, -1.023676],
            [102.808495, -0.96665],
            [102.90477, -0.894179],
            [103.008949, -0.755179],
            [103.126038, -0.746429],
            [103.342834, -0.785774],
            [103.482338, -0.771407],
            [103.489655, -0.798365],
            [103.544289, -0.81498],
            [103.549156, -0.858699],
            [103.588211, -0.875501],
            [103.669304, -0.952914],
            [103.714386, -0.95547],
            [103.748962, -0.987215],
            [103.870873, -1.000657],
            [103.921692, -0.98653],
            [104.05471, -1.011177],
            [104.127846, -1.049528],
            [104.209656, -1.071357],
            [104.264328, -1.046509],
            [104.336029, -1.043973],
            [104.369728, -1.022185],
            [104.413643, -1.130576],
            [104.40168, -1.230084],
            [104.46064, -1.336228],
            [104.46386, -1.401982],
            [104.444763, -1.469791],
            [104.465553, -1.596184],
            [104.515165, -1.67627],
            [104.418398, -1.628021],
            [104.362201, -1.642867],
            [104.351075, -1.741535],
            [104.30012, -1.774048],
            [104.272829, -1.743732],
            [104.11097, -1.786794],
            [104.072833, -1.805048],
            [103.980489, -1.809214],
            [103.925284, -1.750187],
            [103.863641, -1.803907],
            [103.683861, -1.804445],
            [103.597851, -1.813841],
            [103.552617, -1.891252],
            [103.500085, -1.857687],
            [103.441719, -1.913766],
            [103.56171, -2.000056],
            [103.55055, -2.049371],
            [103.513543, -2.084383],
            [103.526903, -2.167123],
            [103.453247, -2.177541],
            [103.441574, -2.213186],
            [103.465737, -2.249964],
            [103.449943, -2.343645],
            [103.409337, -2.332503],
            [103.392789, -2.295459],
            [103.303619, -2.238668],
            [103.273361, -2.154748],
            [103.219055, -2.171653],
            [103.164581, -2.219417],
            [103.194719, -2.25453],
            [103.211938, -2.312515],
            [103.123599, -2.357747],
            [103.054261, -2.312252],
            [103.035798, -2.33815],
            [102.98499, -2.338936],
            [102.878348, -2.304883],
            [102.839739, -2.358138],
            [102.875275, -2.407647],
            [102.793521, -2.505252],
            [102.79447, -2.533695],
            [102.73603, -2.577646],
            [102.64112, -2.609328],
            [102.597958, -2.677392],
            [102.517768, -2.677439],
            [102.490935, -2.70477],
            [102.405707, -2.688557],
            [102.387126, -2.648317],
            [102.33295, -2.65582],
            [102.279852, -2.615552],
            [102.25, -2.631226],
            [102.23631, -2.687767],
            [102.125106, -2.729362],
            [102.066829, -2.766654],
            [101.989708, -2.723145],
            [101.915871, -2.738421],
            [101.867928, -2.728184],
            [101.733284, -2.607907],
            [101.674995, -2.532605],
            [101.601181, -2.537571],
            [101.546486, -2.560535],
            [101.559204, -2.451228],
            [101.330421, -2.309457],
            [101.294159, -2.280203],
          ],
          [
            [101.466292, -2.163241],
            [101.528671, -2.17382],
            [101.524849, -2.128504],
            [101.46859, -2.129224],
            [101.466292, -2.163241],
          ],
        ],
      },
      properties: {
        ID: 29,
        kode: 15,
        Propinsi: "JAMBI",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [103.777539, -4.79652],
            [103.777597, -4.796539],
            [103.823976, -4.802083],
            [103.864089, -4.847539],
            [103.86399, -4.918034],
            [103.93436, -4.886683],
            [103.95543, -4.904254],
            [103.955718, -4.904517],
            [104.019723, -4.90522],
            [104.020423, -4.905076],
            [104.060074, -4.894125],
            [104.110733, -4.923104],
            [104.160093, -4.922752],
            [104.190925, -4.865033],
            [104.309891, -4.88541],
            [104.311096, -4.885782],
            [104.348405, -4.853417],
            [104.349944, -4.85207],
            [104.348493, -4.822822],
            [104.347231, -4.822632],
            [104.32542, -4.808589],
            [104.325085, -4.806065],
            [104.303461, -4.764368],
            [104.294205, -4.647204],
            [104.332608, -4.612251],
            [104.311634, -4.521019],
            [104.330181, -4.424722],
            [104.448685, -4.298732],
            [104.601004, -4.232642],
            [104.655515, -4.250796],
            [104.734169, -4.251686],
            [104.737687, -4.251162],
            [104.788078, -4.261929],
            [104.815692, -4.241028],
            [104.966435, -4.170873],
            [105.011985, -4.173651],
            [105.063462, -4.11991],
            [105.0635, -4.119611],
            [105.112688, -3.999992],
            [105.133827, -3.991517],
            [105.173737, -3.91232],
            [105.201302, -3.892022],
            [105.245973, -3.907812],
            [105.290791, -3.839618],
            [105.281975, -3.798395],
            [105.339727, -3.729057],
            [105.353288, -3.794026],
            [105.414685, -3.776846],
            [105.432106, -3.826865],
            [105.476404, -3.821803],
            [105.51181, -3.870661],
            [105.609012, -3.941194],
            [105.651817, -4.087366],
            [105.705254, -4.098131],
            [105.736504, -4.14257],
            [105.785599, -4.117105],
            [105.792374, -4.119071],
            [105.812897, -4.150985],
            [105.814331, -4.153293],
            [105.82164, -4.166204],
            [105.817635, -4.316465],
            [105.858208, -4.376366],
            [105.898064, -4.465971],
            [105.903992, -4.543446],
            [105.884163, -4.593911],
            [105.912933, -4.647402],
            [105.879227, -4.69676],
            [105.859253, -4.776061],
            [105.870087, -4.876098],
            [105.903664, -4.938873],
            [105.86618, -4.98373],
            [105.855354, -5.079322],
            [105.863403, -5.259794],
            [105.819687, -5.336089],
            [105.821823, -5.593658],
            [105.809662, -5.68149],
            [105.789368, -5.721141],
            [105.791489, -5.787411],
            [105.774124, -5.837541],
            [105.710793, -5.856688],
            [105.603989, -5.830646],
            [105.581818, -5.802879],
            [105.587814, -5.742107],
            [105.552834, -5.69501],
            [105.407997, -5.623131],
            [105.368057, -5.549149],
            [105.292465, -5.446177],
            [105.250793, -5.471121],
            [105.265236, -5.517141],
            [105.24472, -5.589002],
            [105.205086, -5.576796],
            [105.178093, -5.668644],
            [105.194298, -5.706558],
            [105.157707, -5.747686],
            [105.185173, -5.804526],
            [105.148201, -5.810163],
            [104.986458, -5.715514],
            [104.927589, -5.696409],
            [104.824852, -5.621506],
            [104.775848, -5.605471],
            [104.709152, -5.522681],
            [104.595329, -5.497139],
            [104.529739, -5.541902],
            [104.570694, -5.609401],
            [104.639206, -5.688042],
            [104.646019, -5.748027],
            [104.709442, -5.845267],
            [104.695053, -5.875686],
            [104.721489, -5.934022],
            [104.592552, -5.937325],
            [104.561798, -5.865867],
            [104.45295, -5.764961],
            [104.412384, -5.706299],
            [104.29969, -5.641016],
            [104.318222, -5.60884],
            [104.297874, -5.573801],
            [104.166809, -5.476431],
            [104.119209, -5.456481],
            [103.998734, -5.32204],
            [103.996071, -5.26727],
            [103.905228, -5.207043],
            [103.933052, -5.170211],
            [103.908859, -5.127353],
            [103.864105, -5.110247],
            [103.835388, -5.065404],
            [103.770218, -5.028835],
            [103.715195, -4.967688],
            [103.65519, -4.954104],
            [103.598244, -4.922405],
            [103.603287, -4.89334],
            [103.660652, -4.85998],
            [103.663071, -4.858659],
            [103.71843, -4.802312],
            [103.746193, -4.796777],
            [103.772308, -4.802989],
            [103.77594, -4.798273],
            [103.777539, -4.79652],
          ],
        ],
      },
      properties: {
        ID: 30,
        kode: 18,
        Propinsi: "LAMPUNG",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.777253, -4.79643],
              [103.777539, -4.79652],
              [103.77594, -4.798273],
              [103.772308, -4.802989],
              [103.746193, -4.796777],
              [103.71843, -4.802312],
              [103.663071, -4.858659],
              [103.660652, -4.85998],
              [103.603287, -4.89334],
              [103.598244, -4.922405],
              [103.535866, -4.914454],
              [103.500389, -4.875894],
              [103.429695, -4.87312],
              [103.401039, -4.816718],
              [103.309036, -4.781792],
              [103.277702, -4.704457],
              [103.244278, -4.672636],
              [103.113358, -4.578361],
              [103.040985, -4.538898],
              [102.900131, -4.49004],
              [102.883217, -4.442],
              [102.792618, -4.35461],
              [102.692581, -4.272606],
              [102.347687, -4.018148],
              [102.277046, -3.951083],
              [102.307587, -3.900745],
              [102.300446, -3.841087],
              [102.254799, -3.797299],
              [102.265976, -3.764632],
              [102.214775, -3.653597],
              [102.087273, -3.550713],
              [101.933876, -3.477239],
              [101.899567, -3.425596],
              [101.799576, -3.372551],
              [101.620651, -3.239907],
              [101.523598, -3.11352],
              [101.48774, -3.022807],
              [101.405663, -2.925677],
              [101.325256, -2.739109],
              [101.292618, -2.709787],
              [101.154854, -2.639229],
              [101.027755, -2.482182],
              [101.155373, -2.390157],
              [101.244644, -2.344946],
              [101.294159, -2.280203],
              [101.330421, -2.309457],
              [101.559204, -2.451228],
              [101.546486, -2.560535],
              [101.601181, -2.537571],
              [101.674995, -2.532605],
              [101.733284, -2.607907],
              [101.867928, -2.728184],
              [101.915871, -2.738421],
              [101.989708, -2.723145],
              [102.066829, -2.766654],
              [102.070121, -2.779601],
              [102.070271, -2.779745],
              [102.12852, -2.834456],
              [102.132041, -2.87452],
              [102.223949, -2.929184],
              [102.247734, -3.027864],
              [102.322517, -3.068047],
              [102.389915, -3.044319],
              [102.441376, -3.057934],
              [102.485085, -3.1067],
              [102.491776, -3.244821],
              [102.512752, -3.274333],
              [102.596323, -3.333274],
              [102.640813, -3.349581],
              [102.686779, -3.317826],
              [102.742687, -3.315027],
              [102.773751, -3.280437],
              [102.816825, -3.333799],
              [102.881726, -3.345414],
              [102.888499, -3.375246],
              [102.989653, -3.370434],
              [102.998046, -3.466969],
              [102.981817, -3.526327],
              [102.952011, -3.531126],
              [102.921399, -3.593359],
              [102.847676, -3.536874],
              [102.813889, -3.534643],
              [102.809992, -3.608159],
              [102.7655, -3.644093],
              [102.735313, -3.702301],
              [102.728362, -3.75001],
              [102.655215, -3.772596],
              [102.63594, -3.812298],
              [102.686069, -3.858048],
              [102.746036, -3.892661],
              [102.79337, -3.954387],
              [102.886803, -3.989239],
              [102.914916, -3.97235],
              [102.956093, -4.012947],
              [103.128459, -4.025171],
              [103.155336, -4.110564],
              [103.185598, -4.163748],
              [103.200666, -4.247289],
              [103.307721, -4.256566],
              [103.329801, -4.292813],
              [103.453716, -4.3315],
              [103.468517, -4.348306],
              [103.428073, -4.40753],
              [103.405136, -4.499798],
              [103.476627, -4.564854],
              [103.521138, -4.57475],
              [103.590699, -4.52343],
              [103.630332, -4.554627],
              [103.638473, -4.598905],
              [103.693762, -4.652768],
              [103.704562, -4.697992],
              [103.762881, -4.7421],
              [103.777253, -4.79643],
            ],
          ],
          [
            [
              [102.156013, -5.285942],
              [102.169739, -5.277181],
              [102.400116, -5.390039],
              [102.370514, -5.503264],
              [102.294418, -5.47437],
              [102.273949, -5.497576],
              [102.215576, -5.409292],
              [102.104958, -5.351578],
              [102.156013, -5.285942],
            ],
          ],
        ],
      },
      properties: {
        ID: 31,
        kode: 17,
        Propinsi: "BENGKULU",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [98.25574, 4.290953],
              [98.145401, 4.287369],
              [98.090927, 4.19672],
              [98.059311, 4.190727],
              [98.023132, 3.969775],
              [97.95816, 3.928891],
              [97.944946, 3.89686],
              [97.89991, 3.89141],
              [97.908562, 3.820508],
              [97.803185, 3.731739],
              [97.843933, 3.660525],
              [97.869896, 3.57131],
              [97.906899, 3.553616],
              [97.949715, 3.391531],
              [98.003082, 3.369784],
              [98.029823, 3.329871],
              [97.984924, 3.275573],
              [97.872261, 3.249049],
              [97.925278, 3.226437],
              [97.942329, 3.164997],
              [97.978615, 3.127329],
              [97.983467, 3.083911],
              [97.939224, 3.071936],
              [97.958275, 2.961847],
              [97.954605, 2.900333],
              [98.006783, 2.889557],
              [98.042084, 2.828039],
              [98.088196, 2.821929],
              [98.086838, 2.7349],
              [98.12426, 2.655123],
              [98.085129, 2.63422],
              [98.075233, 2.57553],
              [98.083992, 2.50935],
              [98.109344, 2.47093],
              [98.112091, 2.424448],
              [98.155571, 2.413039],
              [98.18766, 2.319383],
              [98.153148, 2.146027],
              [98.100174, 2.193217],
              [97.949188, 2.268416],
              [97.847656, 2.247731],
              [97.807373, 2.267888],
              [97.786621, 2.235493],
              [97.660049, 2.402731],
              [97.650513, 2.668232],
              [97.614929, 2.833094],
              [97.58123, 2.890189],
              [97.540428, 2.885374],
              [97.491165, 2.921352],
              [97.446991, 2.924328],
              [97.391426, 2.965523],
              [97.31015, 3.068522],
              [97.265831, 3.209728],
              [97.193413, 3.258642],
              [97.169327, 3.256543],
              [97.124481, 3.346619],
              [97.084641, 3.394672],
              [97.001579, 3.545805],
              [96.904762, 3.60113],
              [96.863861, 3.693694],
              [96.768112, 3.739015],
              [96.669891, 3.745795],
              [96.527695, 3.732057],
              [96.416214, 3.818041],
              [96.358871, 3.885816],
              [96.287979, 3.992574],
              [96.208038, 4.088209],
              [96.143906, 4.141568],
              [96.124184, 4.13581],
              [96.054756, 4.206528],
              [96.014725, 4.214571],
              [95.882767, 4.362245],
              [95.804428, 4.431233],
              [95.730766, 4.513642],
              [95.594887, 4.626799],
              [95.550133, 4.654496],
              [95.503654, 4.70863],
              [95.492639, 4.750018],
              [95.423939, 4.817422],
              [95.380723, 4.964445],
              [95.300005, 5.116235],
              [95.308858, 5.162571],
              [95.268826, 5.198396],
              [95.213608, 5.311016],
              [95.251068, 5.368226],
              [95.243118, 5.460232],
              [95.195138, 5.528332],
              [95.236732, 5.557039],
              [95.267699, 5.550661],
              [95.325329, 5.593556],
              [95.359057, 5.597326],
              [95.440803, 5.657204],
              [95.487781, 5.613286],
              [95.606527, 5.628805],
              [95.684627, 5.585057],
              [95.74082, 5.572229],
              [95.890839, 5.499509],
              [95.904404, 5.441279],
              [95.945007, 5.401387],
              [96.05159, 5.325978],
              [96.085419, 5.287781],
              [96.186943, 5.260334],
              [96.264411, 5.260112],
              [96.392037, 5.209993],
              [96.460292, 5.234842],
              [96.537407, 5.195907],
              [96.635086, 5.209005],
              [96.717148, 5.242064],
              [96.824829, 5.271552],
              [96.911911, 5.249453],
              [97.010216, 5.258295],
              [97.143356, 5.20007],
              [97.156075, 5.147558],
              [97.192665, 5.1418],
              [97.428207, 5.221479],
              [97.494469, 5.248788],
              [97.553452, 5.213005],
              [97.577263, 5.168141],
              [97.647247, 5.083181],
              [97.822609, 4.919947],
              [97.900314, 4.886347],
              [97.960312, 4.727588],
              [97.959435, 4.672001],
              [98.012398, 4.615899],
              [97.970482, 4.559347],
              [97.994522, 4.527931],
              [98.088074, 4.522916],
              [98.169907, 4.50053],
              [98.286339, 4.41748],
              [98.283165, 4.34913],
              [98.25574, 4.290953],
            ],
          ],
          [
            [
              [96.000046, 2.587204],
              [95.937935, 2.571712],
              [95.885063, 2.602638],
              [95.862289, 2.637353],
              [95.813866, 2.644832],
              [95.757629, 2.694828],
              [95.745186, 2.73048],
              [95.690453, 2.772583],
              [95.718544, 2.821893],
              [95.762878, 2.857512],
              [95.77079, 2.901087],
              [95.804932, 2.927299],
              [95.903023, 2.889753],
              [95.866943, 2.850055],
              [95.998611, 2.782477],
              [96.10466, 2.737498],
              [96.093048, 2.651589],
              [96.170616, 2.62701],
              [96.197578, 2.642474],
              [96.245384, 2.584614],
              [96.335121, 2.548106],
              [96.36515, 2.485914],
              [96.424355, 2.488191],
              [96.462692, 2.455348],
              [96.490204, 2.362194],
              [96.424072, 2.32803],
              [96.379951, 2.350147],
              [96.308983, 2.357318],
              [96.300812, 2.422866],
              [96.221466, 2.440987],
              [96.209869, 2.463408],
              [96.123451, 2.517214],
              [96.068642, 2.567691],
              [96.000046, 2.587204],
            ],
          ],
          [
            [
              [97.293701, 2.079459],
              [97.256218, 2.133173],
              [97.133141, 2.200681],
              [97.162033, 2.235634],
              [97.215462, 2.202798],
              [97.271385, 2.222774],
              [97.336807, 2.15933],
              [97.329483, 2.118278],
              [97.293701, 2.079459],
            ],
          ],
          [
            [
              [95.32904, 5.771702],
              [95.286805, 5.779638],
              [95.287225, 5.841259],
              [95.32245, 5.877151],
              [95.375166, 5.846886],
              [95.32904, 5.771702],
            ],
          ],
          [
            [
              [97.111031, 2.111826],
              [97.153557, 2.066705],
              [97.104469, 2.014735],
              [97.069054, 2.070064],
              [97.111031, 2.111826],
            ],
          ],
          [
            [
              [96.629654, 2.119773],
              [96.679573, 2.087339],
              [96.639084, 2.057627],
              [96.607002, 2.083901],
              [96.629654, 2.119773],
            ],
          ],
        ],
      },
      properties: {
        ID: 32,
        kode: 11,
        Propinsi: "DI. ACEH",
        SUMBER: "Peta Dasar BAKOSURTANAL Skala 1 : 250.000",
      },
    },
  ],
};
