import React, { useState, useEffect, useCallback } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import { Loader } from "../../components";

import {
  useAllRewards,
  useCreate,
  useUpdate,
  useShow,
} from "../../store/actions/rewards";

import Layout from "../../layouts";
import withAuth from "../../hoc/withAuth";
import { useForm } from "react-hook-form";
import { Spinner } from "../../components";
import { useParams } from "react-router-dom";

import CKEditors from "react-ckeditor-component";

const Form = (props) => {
  const { t } = props;
  const { id: dataId } = useParams();
  const pageTitle = t(dataId ? "update" : "new", { name: t("rewards") });
  const [loadingData, setLoadingData] = useState(false);
  const [errorLoad, setErrorLoad] = useState(false);
  const [imageReward, setImageReward] = useState({ preview: "", raw: "" });
  const [selectedType, setSelectedType] = useState("");
  const [categories, setCategories] = useState([]);

  const {
    register: registerForm,
    handleSubmit: handleSubmitForm,
    setValue: setDataForm,
    reset: resetDataForm,
  } = useForm();

  const [
    loadDetail,
    { data: dataDetail, loading: loadingDetail, error: errorDetail },
  ] = useShow();

  const [
    updateData,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useUpdate();

  const [
    dataCategory,
    { data: categoryData, loading: loadingCategory, error: errorCategory },
  ] = useAllRewards();

  const [createData, { data: dataAdd, loading: loadingAdd, error: errorAdd }] =
    useCreate();

  const loadDetailData = async (id = dataId) => {
    await loadDetail(id);
  };

  const loadCategory = async () => {
    await dataCategory();
  };

  useEffect(() => {
    loadCategory();
  }, []);

  useEffect(() => {
    if (categoryData && categoryData.data) {
      const dataResults = Object.values(categoryData.data).sort(
        (a, b) => b.id - a.id
      );
      setCategories(dataResults);
    }
  }, [categoryData]);

  useEffect(() => {
    if (dataId) {
      loadDetailData(dataId);
    }
  }, []);

  useEffect(() => {
    if (dataId && errorDetail) {
      setErrorLoad(true);
    }
  }, [errorDetail]);

  useEffect(() => {
    if (dataId) {
      setLoadingData(loadingDetail);
    }
  }, [loadingDetail]);

  useEffect(() => {
    if (dataAdd) {
      resetDataForm();
    }
  }, [dataAdd]);

  useEffect(() => {
    registerForm({ name: "category" });
  }, [registerForm]);

  const submitHandler = async (data) => {
    if (dataId) {
      const datas = { ...data, description: dataDescription };
      await updateData(dataId, datas);
    } else {
      const datas = { ...data, description: dataDescription };
      await createData(datas);
    }
  };

  const handleChangePhoto = (e) => {
    if (e.target.files.length) {
      setImageReward({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const typeChange = useCallback(
    (event) => {
      setSelectedType(event.target.value);
      setDataForm("category", event.target.value);
    },
    [selectedType]
  );

  const [dataDescription, setDataDescription] = useState("");

  useEffect(() => {
    if (dataDetail && dataDetail.data) {
      setDataDescription(dataDetail.data.description);
      setImageReward({ ...imageReward, preview: dataDetail.data.photo });
      // console.log(dataDetail.data.photo);
    }
  }, [dataDetail]);

  const dataChanges = (event) => {
    const newContent = event.editor.getData();
    setDataDescription(newContent);
  };

  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="row">
        {dataId && errorDetail && (
          <div className="alert alert-danger dark" role="alert">
            {errorDetail}
          </div>
        )}
        <div className="col-12">
          <Loader loading={loadingData} />
        </div>
        {!loadingData && !errorLoad && (
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="card-header">
                <h4>{pageTitle}</h4>
              </div>
              <div className="card-body">
                {errorAdd && typeof errorAdd === "string" && (
                  <div className="alert alert-danger dark" role="alert">
                    {errorAdd}
                  </div>
                )}
                {errorUpdate && typeof errorUpdate === "string" && (
                  <div className="alert alert-danger dark" role="alert">
                    {errorUpdate}
                  </div>
                )}
                {dataAdd && typeof dataAdd.message === "string" && (
                  <div className="alert alert-success dark" role="alert">
                    {dataAdd.message}
                  </div>
                )}
                {dataUpdate && typeof dataUpdate.message === "string" && (
                  <div className="alert alert-success dark" role="alert">
                    {dataUpdate.message}
                  </div>
                )}
                <form
                  action="#"
                  method="post"
                  onSubmit={handleSubmitForm(submitHandler)}
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("name")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="name"
                          type="text"
                          required
                          defaultValue={
                            dataId && dataDetail && dataDetail.data
                              ? dataDetail.data.name
                              : ""
                          }
                          placeholder={t("name")}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("author")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="author"
                          type="text"
                          required
                          defaultValue={
                            dataId && dataDetail && dataDetail.data
                              ? dataDetail.data.author
                              : ""
                          }
                          placeholder={t("author")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t("category")}</label>
                        <span className="text-danger">(*)</span>
                        <select
                          name="parent"
                          value={selectedType}
                          onChange={typeChange}
                          required
                          className="form-control"
                        >
                          <option value="" selected disabled>
                            {t("select", { name: "category" })}
                          </option>
                          {categories.map((dats, index) => {
                            return (
                              <option key={index} value={dats.id}>
                                {dats.name}
                              </option>
                            );
                          })}

                          {/* <option value="artikel">{t("article")}</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t("point")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="point"
                          type="number"
                          required
                          defaultValue={
                            dataId && dataDetail && dataDetail.data
                              ? dataDetail.data.point
                              : ""
                          }
                          placeholder={t("point")}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t("stock")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="stock"
                          type="number"
                          required
                          defaultValue={
                            dataId && dataDetail && dataDetail.data
                              ? dataDetail.data.stock
                              : ""
                          }
                          placeholder={t("stock")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("description")}</label>
                        <span className="text-danger">(*)</span>
                        <CKEditors
                          activeclassName="p10"
                          content={dataDescription}
                          events={{
                            change: dataChanges,
                          }}
                        />
                        {/* <textarea
                          ref={registerForm}
                          className="form-control"
                          name="description"
                          type="text"
                          required
                          defaultValue={
                            dataId && dataDetail && dataDetail.data
                              ? dataDetail.data.description
                              : ""
                          }
                          placeholder={t("description")}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <label>{t("image")}</label>
                      <span className="text-danger">(*)</span>
                      {imageReward.preview ? (
                        <img
                          src={imageReward.preview}
                          alt="preview"
                          style={{
                            margin: "5%",
                            width: "80%",
                            height: "auto",
                          }}
                          aria-hidden
                        />
                      ) : null}
                      <input
                        ref={registerForm}
                        className="form-control"
                        name="image"
                        type="file"
                        onChange={handleChangePhoto}
                        accept=".jpg, .png"
                        required={
                          dataId && dataDetail && dataDetail.data ? false : true
                        }
                        defaultValue={
                          dataId && dataDetail && dataDetail.data
                            ? dataDetail.data.image
                            : ""
                        }
                        placeholder={t("image")}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button
                        disabled={loadingAdd || loadingUpdate}
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        <Spinner loading={loadingAdd || loadingUpdate} />
                        {!loadingAdd && !loadingUpdate && pageTitle}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default withAuth(translate(Form))(["admin"]);
