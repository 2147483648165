import React, { Fragment, useState, useEffect } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import withAuth from "../../hoc/withAuth";
import Layout from "../../layouts";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import DataTable from "react-data-table-component";
import { Loader } from "../../components";
import { useIndex, useDelete, useShow } from "../../store/actions/posts";
import NumberFormat from "react-number-format";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Progress,
} from "reactstrap";
import moment from "moment";

const Posts = (props) => {
  const { t } = props;
  const pageTitle = t("posts");
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [detailSelected, setDetailSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedDataShow, setSelectedDataShow] = useState(null);
  const [listData, setListData] = useState([]);
  const [totalListData, setTotalListData] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedData, setSelectedData] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [offPage, setOffPage] = useState(0);
  const {
    register: registerSearch,
    handleSubmit: handleSearch,
    reset: resetSearchForm,
  } = useForm();

  const [getData, { data: datas, loading: loadingData }] = useIndex();

  const [
    getDetail,
    { data: dataDetail, error: errorDetail, loading: loadingDetail },
  ] = useShow();

  const [deleteData, { data: dataDelete, loading: loadingDelete }] =
    useDelete();

  const tableColumns = [
    {
      name: "#",
      cell: (row, index) => {
        return offPage + index + 1;
      },
      sortable: true,
      center: true,
      width: "10%",
    },
    {
      name: t("posts"),
      cell: (row) => {
        let date = row.publish_at;
        if (date) {
          date = moment(date).format("DD MMM YYYY HH:mm:ss") ?? "";
        } else {
          date = "";
        }
        return (
          <div>
            <div>{row.title}</div>
            <small>
              <b>{date ?? ""}</b>
            </small>
          </div>
        );
      },
      sortable: true,
      center: false,
    },
    {
      name: t("user"),
      cell: (row) => {
        if (!row.user) {
          return null;
        }
        return (
          <div>
            <div>
              {row.user.first_name} {row.user.last_name}
            </div>
          </div>
        );
      },
      sortable: true,
      center: false,
    },
    {
      name: t("status"),
      cell: (row) => {
        return (
          <div>
            <div
              className={`btn btn-sm btn-${
                row.status === "1" ? "success" : "danger"
              }`}
            >
              {row.status === "1" ? t("active") : t("draft")}
            </div>
          </div>
        );
      },
      sortable: true,
      center: false,
    },
    {
      name: t("option"),
      cell: (row) => rowOption(row),
      center: false,
    },
  ];

  const rowOption = (row) => {
    return (
      <Fragment>
        {/* <button
          disabled={loadingDelete}
          onClick={() => deleteDataHandler(row.id)}
          className="btn btn-outline-danger btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-trash-fill"></i>
          </span>
        </button> */}

        {row.status === "1" && (
          <button
            onClick={() => viewDetail(row)}
            className="btn btn-outline-info btn-sm px-2 mx-1"
          >
            <span>
              <i className="bi bi-eye-fill"></i>
            </span>
          </button>
        )}
      </Fragment>
    );
  };

  const loadDetail = async (id) => {
    await getDetail(id);
  };

  const viewDetail = (data) => {
    setShowModal(true);
    setSelectedDataShow(data);
    // loadDetail(data.id);
  };

  const modalToggle = () => setShowModal(!showModal);

  const refreshSearchHandler = () => {
    setSearchText("");
    resetSearchForm();
    loadData(1, perPage, "");
    setIsSearch(false);
  };

  const searchAction = async (data) => {
    setSearchText(data.search);
    loadData(1, perPage, data.search);
    setIsSearch(true);
  };

  const deleteDataHandler = async (id) => {
    Swal.fire({
      input: "textarea",
      inputLabel: t("reason"),
      inputPlaceholder: t("deleteReason"),
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
      confirmButtonText: t("delete"),
      cancelButtonText: t("cancel"),
    }).then(async (result) => {
      if (!result.value) {
        return;
      }
      // setDeleteReason(result.value);
      if (result.isConfirmed) {
        setSelectedData(id);
        await deleteHandler(id, result.value);
      }
    });
  };

  const loadData = async (page = 1, size = perPage, search = searchText) => {
    let offset = (Number(page) - 1) * Number(size);
    setOffPage(offset);
    await getData(offset, size, search);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    loadData(page, newPerPage);
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    loadData(page);
    setCurrentPage(page);
  };

  const deleteHandler = async (id, reason) => {
    const data = { reason: reason };
    await deleteData(id, data);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (datas && datas.data) {
      const dataResults = Object.values(datas.data).sort((a, b) => b.id - a.id);
      setListData(dataResults);
      setTotalListData(datas.total);
    }
  }, [datas]);

  useEffect(() => {
    if (dataDelete && dataDelete.status === 200) {
      const newData = listData.filter((item) => {
        return item.id !== selectedData;
      });
      setListData(newData);
      setTotalListData(totalListData - 1);
    }
  }, [dataDelete]);

  useEffect(() => {
    if (dataDetail && dataDetail.data) {
      setDetailSelected(dataDetail.data);
    }
  }, [dataDetail]);

  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Modal isOpen={showModal} toggle={modalToggle} size="lg">
        <ModalHeader toggle={modalToggle}>
          {!loadingDetail && !errorDetail && detailSelected
            ? detailSelected.title
            : t("detail")}
        </ModalHeader>
        <ModalBody>
          {loadingDetail && (
            <div className="row">
              <Loader loading={true} />
            </div>
          )}
          {!loadingDetail && errorDetail && (
            <div className="alert alert-danger dark" role="alert">
              {errorDetail}
            </div>
          )}
          {!loadingDetail && !errorDetail && (
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-3">
                    <label>{t("users")} </label>
                  </div>
                  <div className="col-1">
                    <label>:</label>
                  </div>
                  <div className="col-8">
                    {selectedDataShow && selectedDataShow.user && (
                      <label className="text-success">
                        {selectedDataShow.user.first_name}{" "}
                        {selectedDataShow.user.last_name ?? ""} (
                        {selectedDataShow.user.id})
                      </label>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <label>{t("email")}</label>
                  </div>
                  <div className="col-1">
                    <label>:</label>
                  </div>
                  <div className="col-8">
                  {selectedDataShow && selectedDataShow.updated_at && (
                      <label className="text-success">
                        {selectedDataShow.user.email}
                      </label>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <label>{t("createDate")}</label>
                  </div>
                  <div className="col-1">
                    <label>:</label>
                  </div>
                  <div className="col-8">
                    {selectedDataShow && selectedDataShow.created_at && (
                      <label className="text-success">
                        {moment(selectedDataShow.created_at).format(
                          "HH:mm:ss DD MMM YYYY"
                        ) ?? ""}
                      </label>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <label>{t("editDate")}</label>
                  </div>
                  <div className="col-1">
                    <label>:</label>
                  </div>
                  <div className="col-8">
                    {selectedDataShow && selectedDataShow.updated_at && (
                      <label className="text-success">
                        {selectedDataShow.updated_at}
                      </label>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <label>{t("publishDate")}</label>
                  </div>
                  <div className="col-1">
                    <label>:</label>
                  </div>
                  <div className="col-8">
                    {selectedDataShow && selectedDataShow.publish_at && (
                      <label className="text-success">
                        {moment(selectedDataShow.publish_at).format(
                          "HH:mm:ss DD MMM YYYY"
                        ) ?? ""}
                      </label>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <label>{t("post_type")}</label>
                  </div>
                  <div className="col-1">
                    <label>:</label>
                  </div>
                  <div className="col-8">
                    {selectedDataShow && selectedDataShow.post_type && (
                      <label className="text-success">
                        {selectedDataShow.post_type}
                      </label>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <label>{t("postType")}</label>
                  </div>
                  <div className="col-1">
                    <label>:</label>
                  </div>
                  <div className="col-8">
                    {selectedDataShow && selectedDataShow.jenis && (
                      <label className="text-success">
                        {selectedDataShow.jenis}
                      </label>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <label>{t("visibility")}</label>
                  </div>
                  <div className="col-1">
                    <label>:</label>
                  </div>
                  <div className="col-8">
                    {selectedDataShow && selectedDataShow.visibility && (
                      <label className="text-success">
                        {t(selectedDataShow.visibility)}
                      </label>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <label>{t("comment")}</label>
                  </div>
                  <div className="col-1">
                    <label>:</label>
                  </div>
                  <div className="col-8">
                    {selectedDataShow && selectedDataShow.comment && (
                      <label className="text-success">
                        {t(
                          selectedDataShow.comment === "1"
                            ? "active"
                            : "inactive"
                        )}
                      </label>
                    )}
                  </div>
                </div>
                {selectedDataShow &&
                (selectedDataShow.post_type === "polling" ||
                  selectedDataShow.post_type === "petisi") ? (
                  <Fragment>
                    <div className="row">
                      <div className="col-3">
                        <label>{t("expiredDate")}</label>
                      </div>
                      <div className="col-1">
                        <label>:</label>
                      </div>
                      <div className="col-8">
                        {selectedDataShow && selectedDataShow.expired_date && (
                          <label className="text-danger">
                            {selectedDataShow.post_type === "petisi"
                              ? selectedDataShow.expired_date
                              : moment(selectedDataShow.expired_date).format(
                                  "DD MMM YYYY"
                                ) ?? ""}
                          </label>
                        )}
                      </div>
                    </div>

                    {selectedDataShow.post_type === "petisi" && (
                      <div className="row">
                        <div className="col-3">
                          <label>{t("maxVote")}</label>
                        </div>
                        <div className="col-1">
                          <label>:</label>
                        </div>
                        <div className="col-8">
                          {selectedDataShow && selectedDataShow.max_votes && (
                            <NumberFormat
                              className="text-danger"
                              displayType={"text"}
                              thousandSeparator="."
                              decimalSeparator=","
                              value={Number(
                                selectedDataShow.max_votes ?? 0
                              ).toFixed(0)}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-3">
                        <label>{t("responder")}</label>
                      </div>
                      <div className="col-1">
                        <label>:</label>
                      </div>
                      <div className="col-8">
                        <NumberFormat
                          className="text-success"
                          displayType={"text"}
                          thousandSeparator="."
                          decimalSeparator=","
                          value={Number(
                            selectedDataShow.post_type === "petisi"
                              ? selectedDataShow.total_sign
                              : selectedDataShow.total_vote
                          ).toFixed(0)}
                        />
                      </div>
                    </div>
                  </Fragment>
                ) : null}
                <div className="col-12">
                  <hr />
                </div>
                <div className="row text-center my-4">
                  <div className="col-3">
                    <h3>
                      <i className="bi bi-hand-thumbs-up"></i>
                    </h3>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={Number(
                        selectedDataShow && selectedDataShow.like
                          ? selectedDataShow.like
                          : 0
                      ).toFixed(0)}
                    />
                  </div>
                  <div className="col-3">
                    <h3>
                      <i className="bi bi-hand-thumbs-down"></i>
                    </h3>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={Number(
                        selectedDataShow && selectedDataShow.dislike
                          ? selectedDataShow.dislike
                          : 0
                      ).toFixed(0)}
                    />
                  </div>
                  <div className="col-3">
                    <h3>
                      <i className="bi bi-chat-left-dots"></i>
                    </h3>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={Number(
                        selectedDataShow && selectedDataShow.message
                          ? selectedDataShow.message
                          : 0
                      ).toFixed(0)}
                    />
                  </div>
                  <div className="col-3">
                    <h3>
                      <i className="bi bi-eye"></i>
                    </h3>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={Number(
                        selectedDataShow && selectedDataShow.view
                          ? selectedDataShow.view
                          : 0
                      ).toFixed(0)}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <hr />
                </div>
                <div className="row text-center">
                  <img
                    src={
                      selectedDataShow &&
                      selectedDataShow.files &&
                      selectedDataShow.files.length > 0
                        ? selectedDataShow.files[0].file_path
                        : null
                    }
                  />
                </div>
                <div className="col-12 mt-2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        selectedDataShow && selectedDataShow.description
                          ? selectedDataShow.description
                          : "",
                    }}
                  />
                  {selectedDataShow &&
                  selectedDataShow.is_repost &&
                  selectedDataShow.is_repost === "1" ? (
                    <div className={`border p-3 my-2 rounded`}>
                      <div className="row">
                        <div className="col-1">
                          <div className="avatar avatar-lg">
                            <img
                              src={
                                selectedDataShow.repost_data &&
                                selectedDataShow.repost_data.user &&
                                selectedDataShow.repost_data.user.photo
                                  ? selectedDataShow.repost_data.user.photo
                                  : "/icon.png"
                              }
                            />
                          </div>
                        </div>
                        <div className="col-11">
                          <div className="row">
                            <div className="col-12">
                              <b>
                                {selectedDataShow.repost_data &&
                                selectedDataShow.repost_data.user
                                  ? selectedDataShow.repost_data.user
                                      .first_name +
                                    " " +
                                    selectedDataShow.repost_data.user.last_name
                                  : null}
                              </b>
                            </div>
                            <div className="col-12">
                              <small>
                                {selectedDataShow.repost_data &&
                                  selectedDataShow.repost_data.publish_at && (
                                    <label className="text-success">
                                      {moment(
                                        selectedDataShow.repost_data.publish_at
                                      ).format("HH:mm:ss DD MMM YYYY") ?? ""}
                                    </label>
                                  )}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row text-center">
                        <img
                          src={
                            selectedDataShow.repost_data &&
                            selectedDataShow.repost_data.files &&
                            selectedDataShow.repost_data.files.length > 0
                              ? selectedDataShow.repost_data.files[0].file_path
                              : null
                          }
                        />
                      </div>
                      <div className="row my-3">
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              selectedDataShow.repost_data &&
                              selectedDataShow.repost_data.description
                                ? selectedDataShow.repost_data.description.slice(
                                    0,
                                    200
                                  ) + "..."
                                : "",
                          }}
                        />
                        {selectedDataShow &&
                        selectedDataShow.repost_data.is_link &&
                        selectedDataShow.repost_data.is_link === "1" ? (
                          <div className={`border p-3 my-2 rounded`}>
                            <div className="row text-center">
                              <a
                                target="_blank"
                                href={selectedDataShow.repost_data.link_path}
                              >
                                <img
                                  src={
                                    selectedDataShow.repost_data.link_image ??
                                    null
                                  }
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                target="_blank"
                                href={selectedDataShow.repost_data.link_path}
                              >
                                <h5>
                                  {selectedDataShow.repost_data.link_title ??
                                    null}
                                </h5>
                              </a>
                            </div>
                            <p>
                              {selectedDataShow.repost_data.link_description ??
                                null}
                            </p>
                            <small className="fs-6">
                              {selectedDataShow.repost_data.link_name ?? null}
                            </small>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {selectedDataShow &&
                  selectedDataShow.is_link &&
                  selectedDataShow.is_link === "1" ? (
                    <div className={`border p-3 my-2 rounded`}>
                      <div className="row text-center">
                        <a target="_blank" href={selectedDataShow.link_path}>
                          <img src={selectedDataShow.link_image ?? null} />
                        </a>
                      </div>
                      <div>
                        <a target="_blank" href={selectedDataShow.link_path}>
                          <h5>{selectedDataShow.link_title ?? null}</h5>
                        </a>
                      </div>
                      <p>{selectedDataShow.link_description ?? null}</p>
                      <small className="fs-6">
                        {selectedDataShow.link_name ?? null}
                      </small>
                    </div>
                  ) : null}
                  {selectedDataShow &&
                  selectedDataShow.post_type === "polling" ? (
                    <div className="my-2 border p-3 my-2 rounded">
                      {selectedDataShow.pooling_option
                        .sort((a, b) => {
                          if (a.order < b.order) {
                            return -1;
                          }
                          if (a.order > b.order) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((item, index) => {
                          let votes = Number(item.vote ?? 0).toFixed(0);
                          let total = Number(
                            selectedDataShow.total_vote ?? 0
                          ).toFixed(0);

                          return (
                            <div className="my-2" key={index.toString()}>
                              <label>{item.option}</label>
                              <Progress value={votes} max={total} min={0}>
                                <NumberFormat
                                  className="text-white"
                                  displayType={"text"}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  value={Number(votes).toFixed(0)}
                                />
                              </Progress>
                            </div>
                          );
                        })}
                    </div>
                  ) : null}
                  {selectedDataShow &&
                  selectedDataShow.post_type === "petisi" ? (
                    <div className="my-2 border p-3 my-2 rounded">
                      <div className="my-2">
                        <label>
                          <NumberFormat
                            className="text-success"
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            value={Number(selectedDataShow.total_sign).toFixed(
                              0
                            )}
                          />{" "}
                          {t("responder")}
                        </label>
                        <Progress
                          value={selectedDataShow.total_sign}
                          max={selectedDataShow.max_votes}
                          min={0}
                        >
                          <NumberFormat
                            className="text-white"
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            value={Number(selectedDataShow.total_sign).toFixed(
                              0
                            )}
                          />
                        </Progress>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {selectedDataShow && selectedDataShow.visibility && (
            <Link
              to={`${process.env.PUBLIC_URL}/addpoint/new`}
              className="btn btn-primary"
            >
              <i className="fa fa-user-plus mr-2" aria-hidden="true"></i>
              Tambah Poin Baru
            </Link>
          )}
          {selectedDataShow && selectedDataShow.user && (
            <a
              href={`https://link.opinia.id/profile/${selectedDataShow.user.id}`}
              target="_blank"
              className="btn-secondary btn"
            >
              Lihat Profil
            </a>
          )}
          <button className="btn btn-secondary" onClick={modalToggle}>
            {t("close")}
          </button>
        </ModalFooter>
      </Modal>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2 flex-row-reverse">
                <div className="col-sm-12 col-md-3">
                  <form
                    onSubmit={handleSearch(searchAction)}
                    className="form-inline theme-form "
                    noValidate=""
                  >
                    <div className="form-group ml-auto mr-3">
                      <div className="input-group">
                        <input
                          ref={registerSearch}
                          className="form-control"
                          type="text"
                          name="search"
                          placeholder={t("search")}
                          autoComplete="off"
                        />
                        {!isSearch && (
                          <button
                            type="submit"
                            className="btn btn-primary px-3"
                          >
                            <i className="bi bi-search" aria-hidden="true"></i>
                          </button>
                        )}
                        {isSearch && (
                          <button
                            onClick={refreshSearchHandler}
                            type="button"
                            className="btn btn-danger ml-1 px-3"
                          >
                            <i className="bi bi-x" aria-hidden="true"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-sm-12 col-md-9"></div>
              </div>
              <DataTable
                data={listData}
                columns={tableColumns}
                progressPending={loadingData}
                subHeader={false}
                fixedHeader={true}
                center={true}
                persistTableHead
                highlightOnHover={true}
                pagination
                paginationServer={true}
                noHeader={true}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={(val) => handlePageChange(val)}
                paginationTotalRows={totalListData}
                progressComponent={
                  <div className="d-block my-5">
                    <Loader loading />
                  </div>
                }
                noDataComponent={<div className="my-3">{t("emptyTable")}</div>}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withAuth(translate(Posts))(["admin"]);
