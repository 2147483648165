import React, { Fragment } from "react";
import PropTypes from "prop-types";
const Alert = (props) => {
  const { visible, type, className, message } = props;
  if (!visible) {
    return null;
  }
  return (
    <Fragment>
      <div className={`alert alert-${type} ${className} `} role="alert">
        {`${message} `}
      </div>
    </Fragment>
  );
};

Alert.propTypes = {
  visible: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.string,
};
Alert.defaultProps = {
  visible: false,
  message: "",
  type: "primary",
  className: "",
};
export default Alert;
