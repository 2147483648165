import React, { Fragment } from "react";
import Loader from "react-loader";
const PageLoader = (props) => {
  const { loading } = props;
  return (
    <Fragment>
      <Loader
        loaded={!loading}
        lines={12}
        length={8}
        width={3}
        radius={5}
        loadedClassName="loadedContent"
      />
    </Fragment>
  );
};

export default PageLoader;
