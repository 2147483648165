import { useApiHandler, fetcher } from "./index";
import useSWR from "swr";
import Api from "../connection";
import qs from "qs";

const dataForm = new FormData();

// content creator
export const contentCreator = async (offset, size, search) => {
  const posts = await Api.get(
    `/contentcreator?all=all&offset=${offset}&limit=${size}&search=${search}`
  );

  // const posts = await Api.get(`/contentcreator`);
  return posts;
};

export const totalContentCreator = async () => {
  const posts = await Api.get(`/contentcreator`);
  return posts;
};

export const useGetContentCreator = () => {
  return useApiHandler(contentCreator);
};

export const deleteContentCreator = async (id) => {
  const posts = await Api.delete(`/contentcreator/${id}`);
  return posts;
};

export const useDeleteContentCreator = () => {
  return useApiHandler(deleteContentCreator);
};

export const detailContentCreator = async (id) => {
  const posts = await Api.get(`/user/${id}`);
  return posts;
};

export const useDetailContentCreator = () => {
  return useApiHandler(detailContentCreator);
};
//

export const indexUsers = async (offset, size, search) => {
  const posts = await Api.get(
    `/users?all=all&offset=${offset}&limit=${size}&search=${search}`
  );
  return posts;
};
export const totalToday = async () => {
  const posts = await Api.get(`/user/total-today`);
  return posts;
};

export const activateUsers = async (id) => {
  const posts = await Api.get(`/users/activate/${id}`);
  return posts;
};

export const updateUser = async (id, data) => {
  let datas = {
    first_name: data.nama,
    last_name: "",
    phone: data.ponsel,
    email: data.email,
  };
  if (data.password) {
    datas = { ...datas, password: data.password };
  }

  const datass = qs.stringify(datas);
  const posts = await Api.patch(`/users/${id}`, datass);
  return posts;
};

export const newUser = async (data) => {
  dataForm.append("first_name", data.nama);
  dataForm.append("last_name", "");
  dataForm.append("phone", data.ponsel);
  dataForm.append("email", data.email);
  dataForm.append("role", "2");
  dataForm.append("password", data.password);

  const posts = await Api.post(`/users`, dataForm);
  return posts;
};

export const useGetUsers = () => {
  return useApiHandler(indexUsers);
};

export const useActivateUsers = () => {
  return useApiHandler(activateUsers);
};
export const useUpdateUsers = () => {
  return useApiHandler(updateUser);
};
export const useNewUsers = () => {
  return useApiHandler(newUser);
};

export const useTotalToday = () => {
  return useApiHandler(totalToday);
};

export const useTotalContentCreator =()=>{
  return useApiHandler(totalContentCreator)
};

export const useDetailUsers = (id, bool = true) => {
  const { data, error, ...rest } = useSWR(bool ? `/user/${id}` : null, fetcher);
  return { data, error, loading: !data && !error, ...rest };
};
export const useListRole = () => {
  const { data, error, ...rest } = useSWR(
    `/role?order=[{"field": "role_name", "direction": "ASC"}]`,
    fetcher
  );
  return { data, error, loading: !data && !error, ...rest };
};
