import { useApiHandler } from "./index";
import Api from "../connection";
const dataForm = new FormData();

export const profile = async (data) => {
  dataForm.append("nama", data.nama);
  dataForm.append("email", data.email);
  dataForm.append("ponsel", data.ponsel);
  dataForm.append("kelamin", data.kelamin);
  let me = "0";
  const userData = localStorage.getItem(process.env.REACT_APP_STORAGE_NAME);
  if (userData) {
    const transformedData = JSON.parse(userData);
    const { data } = transformedData;
    me = data.id;
  }
  const posts = await Api.post(`/user/${me}`, dataForm);
  return posts;
};
export const password = async (data) => {
  const dataForm = new FormData();
  dataForm.append("old_password", data.old_password);
  dataForm.append("password", data.password);
  dataForm.append("confirm_password", data.password_confirmation);
  const posts = await Api.post("/account/update-password", dataForm);
  return posts;
};

export const useProfile = () => {
  return useApiHandler(profile);
};
export const usePassword = () => {
  return useApiHandler(password);
};
