import { useApiHandler, fetcher } from "./index";
import useSWR from "swr";
import Api from "../connection";
import qs from "qs";

const dataForm = new FormData();

export const indexTopic = async (offset, size, search) => {
  const posts = await Api.get(
    `/categories?all=all&offset=${offset}&limit=${size}&search=${search}`
  );
  return posts;
};

export const detailTopics = async (id) => {
  const posts = await Api.get(`/categories/${id}`);
  return posts;
};

export const deleteTopics = async (id) => {
  const posts = await Api.delete(`/categories/${id}`);
  return posts;
};
export const addTopics = async (data) => {
  const dataForm = new FormData();
  dataForm.append("name", data.name);
  dataForm.append("image", data.image[0]);

  const posts = await Api.post(`/categories`, dataForm,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return posts;
};

export const updateTopics = async (id, data) => {
  const dataForm = new FormData();
  dataForm.append("name", data.name);
  dataForm.append("image", data.image[0]);
  
  const posts = await Api.post(`/category/update-category/${id}`, dataForm,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return posts;
};

export const useGetTopics = () => {
  return useApiHandler(indexTopic);
};
export const useDetailTopics = () => {
  return useApiHandler(detailTopics);
};
export const useDeleteTopics = () => {
  return useApiHandler(deleteTopics);
};
export const useAddTopics = () => {
  return useApiHandler(addTopics);
};
export const useUpdateTopics = () => {
  return useApiHandler(updateTopics);
};
