import { useState } from "react";
import Api from "../connection";
export const fetcher = (url) =>
  Api.get(url).then(async (res) => {
    const results = await res.data;
    if (res.status !== 200) {
      return Promise.reject(results);
    }

    if (results.status !== 200) {
      return Promise.reject(results);
    }

    return results;
  });

export const useApiHandler = (apiCall) => {
  const [reqState, setReqState] = useState({
    error: null,
    data: null,
    loading: false,
  });

  const handler = async (...data) => {
    setReqState({
      error: null,
      data: null,
      loading: true,
    });
    try {
      const json = await apiCall(...data);
      setReqState({
        error: null,
        data: json.data,
        loading: false,
      });
    } catch (e) {
      let message = e.message || "Oops. Something went wrong...";
      if (
        e.response &&
        e.response.data &&
        e.response.data.messages &&
        e.response.data.messages.error
      ) {
        message = e.response.data.messages.error;
      }
      setReqState({
        error: message,
        data: null,
        loading: false,
      });
    }
  };

  return [handler, { ...reqState }];
};
