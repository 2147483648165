import { useApiHandler, fetcher } from "./index";
import useSWR from "swr";
import Api from "../connection";
import qs from "qs";

const dataForm = new FormData();

export const indexUsers = async (offset, size, search) => {
  const posts = await Api.get(
    `/adminusers?all=all&offset=${offset}&limit=${size}&search=${search}`
  );
  return posts;
};

export const totalToday = async () => {
  const posts = await Api.get(`/user/total-today`);
  return posts;
};

export const detailUsers = async (id) => {
  const posts = await Api.get(`/adminusersdetail/${id}`);
  return posts;
};

export const deleteUsers = async (id) => {
  const posts = await Api.delete(`/users/${id}`);
  return posts;
};
export const activateUsers = async (id) => {
  const posts = await Api.get(`/users/activate/${id}`);
  return posts;
};

export const addToContentCreator = async (user) => {
  try {
    dataForm.append("user", user);
    const posts = await Api.post(`/contentcreator`, dataForm);
    alert("Berhasil menambahkan user");
    return posts;
  } catch (error) {
    alert("User sudah tersedia di Konten Kreator");
  }
};

export const updateUser = async (id, data) => {
  let datas = {
    first_name: data.nama,
    last_name: "",
    phone: data.ponsel,
    email: data.email,
  };
  if (data.password) {
    datas = { ...datas, password: data.password };
  }

  const datass = qs.stringify(datas);
  const posts = await Api.patch(`/users/${id}`, datass);
  return posts;
};

export const newUser = async (data) => {
  dataForm.append("first_name", data.nama);
  dataForm.append("last_name", "");
  dataForm.append("phone", data.ponsel);
  dataForm.append("email", data.email);
  dataForm.append("role", "2");
  dataForm.append("password", data.password);

  const posts = await Api.post(`/users`, dataForm);
  return posts;
};

export const addPoint= async (data)=>{
  dataForm.append("user", data.user);
  dataForm.append("description", data.description);
  dataForm.append("point", data.point);
  dataForm.append("type", data.user);

  const posts = await Api.post(`manual-point`, dataForm);
  return posts;
};

export const useAddPoint =() =>{
  return useApiHandler(addPoint);
}

export const useGetUsers = () => {
  return useApiHandler(indexUsers);
};

export const useDeleteUsers = () => {
  return useApiHandler(deleteUsers);
};

export const useActivateUsers = () => {
  return useApiHandler(activateUsers);
};
export const useUpdateUsers = () => {
  return useApiHandler(updateUser);
};
export const useNewUsers = () => {
  return useApiHandler(newUser);
};
export const useDetailUser = () => {
  return useApiHandler(detailUsers);
};
export const useTotalToday = () => {
  return useApiHandler(totalToday);
};
export const useAddToContentCreator = () => {
  return useApiHandler(addToContentCreator);
};

export const useDetailUsers = (id, bool = true) => {
  const { data, error, ...rest } = useSWR(bool ? `/user/${id}` : null, fetcher);
  return { data, error, loading: !data && !error, ...rest };
};
export const useListRole = () => {
  const { data, error, ...rest } = useSWR(
    `/role?order=[{"field": "role_name", "direction": "ASC"}]`,
    fetcher
  );
  return { data, error, loading: !data && !error, ...rest };
};
