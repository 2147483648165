import { combineReducers } from "redux";
import AppReducer from "./app";
// import UsersReducer from "./reducers/users";
// import RolesReducer from "./reducers/roles";
const reducers = combineReducers({
  app: AppReducer,
  // users: UsersReducer,
  // roles: RolesReducer,
});

export default reducers;
