import Api from "../connection";
import { useApiHandler } from "./index";

export const indexAds = async (offset, size, search) => {
  const posts = await Api.get(
    `/bannerpopreward?all=all&offset=${offset}&limit=${size}&search=${search}`
  );
  return posts;
};

export const addAds = async (data) => {
  const dataForm = new FormData();
  dataForm.append("title", data.title);
  dataForm.append("image", data.image[0]);

  const posts = await Api.post(`/bannerpopreward`, dataForm, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return posts;
};

export const updateAds = async (id, data) => {
  const dataForm = new FormData();
  dataForm.append("title", data.title);
  dataForm.append("image", data.image[0]);

  const posts = await Api.post(
    `/bannerpopreward/update-bannerpopreward/${id}`,
    dataForm,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return posts;
};

export const deleteAds = async (id) => {
    const posts = await Api.delete(`/bannerpopreward/${id}`);
    return posts;
  };

export const detailAds = async (id) => {
  const posts = await Api.get(`/bannerpoprewards/${id}`);
  return posts;
};

export const useGetAds = () => {
  return useApiHandler(indexAds);
};

export const useUpdateAds = () => {
  return useApiHandler(updateAds);
};

export const useAddAds = () => {
  return useApiHandler(addAds);
};

export const useDetailAds = () => {
    return useApiHandler(detailAds);
  };

  export const useDeleteAds = () => {
    return useApiHandler(deleteAds);
  };
