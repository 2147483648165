import React, { Fragment, useState, useCallback, useEffect } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import withAuth from "../../hoc/withAuth";
import Layout from "../../layouts";
import {
  useGetOptions,
  useUpdateOptions,
  useGetHelp,
  useDeleteHelp,
  useDeleteKL,
  useGetKetentuan,
} from "../../store/actions/options";
import CKEditors from "react-ckeditor-component";
import { Button, Form, Modal } from "react-bootstrap";
import { Loader } from "../../components";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Redaction = (props) => {
  const { t } = props;
  const pageTitle = t("redaction");
  const [termContent, setTermContent] = useState("");
  const [cgContent, setCgContent] = useState("");
  const [aboutContent, setAboutContent] = useState("");
  const [pointContent, setPointContent] = useState("");
  const [helpContent, setHelpContent] = useState([]);
  const [ketentuanContent, setKetentuanContent] = useState([]);
  // const [helpDetailedContent, setHelpDetailedContent] = useState([]);
  const [offPage, setOffPage] = useState(0);
  const [selectedDataShow, setSelectedDataShow] = useState(null);
  const [editBantuan, setEditBantuan] = useState(false);
  const [selectedData, setSelectedData] = useState("0");
  const [dataPost, setdataPost] = useState({ question: "", answer: "" });
  // Table Bantuan

  const tableBantuan = [
    {
      name: "#",
      cell: (row, index) => {
        return offPage + index + 1;
      },
      sortable: true,
      center: true,
      width: "10%",
    },
    {
      name: "Question",
      selector: "question",
      sortable: true,
      center: false,
    },
    {
      name: "Answer",
      selector: "answer",
      sortable: true,
      center: false,
      width: "300px",
    },
    {
      name: t("option"),
      cell: (row) => (row.index !== "0" ? optionBantuan(row) : null),
      center: false,
    },
  ];

  const optionBantuan = (row) => {
    const stat = row.status === "1" ? true : false;
    return (
      <Fragment>
        <button
          onClick={() => deleteUserHandlerBantuan(row.id)}
          className="btn btn-outline-danger btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-trash-fill"></i>
          </span>
        </button>
        <Link
          to={`${process.env.PUBLIC_URL}/post-help/${row.id}`}
          className="btn-outline-primary btn  btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-pencil-square"></i>
          </span>
        </Link>

        <button
          onClick={() => viewDetailBantuan(row)}
          className="btn btn-outline-info btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-eye-fill"></i>
          </span>
        </button>
      </Fragment>
    );
  };

  // end Table Bantuan

  // Table Ketentuan Layanan

  const tableKetentuan = [
    {
      name: "#",
      cell: (row, index) => {
        return offPage + index + 1;
      },
      sortable: true,
      center: true,
      width: "10%",
    },
    {
      name: "Title",
      selector: "title",
      sortable: true,
      center: false,
    },
    {
      name: "ParentID",
      selector: "parent_name",
      sortable: true,
      center: false,
    },
    {
      name: "Deskripsi",
      selector: "content",
      sortable: true,
      center: false,
      width: "300px",
    },
    {
      name: t("option"),
      cell: (row) => (row.index !== "0" ? optionKetentuan(row) : null),
      center: false,
    },
  ];

  const optionKetentuan = (row) => {
    const stat = row.status === "1" ? true : false;
    return (
      <Fragment>
        <button
          onClick={() => deleteUserHandlerKL(row.id)}
          className="btn btn-outline-danger btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-trash-fill"></i>
          </span>
        </button>
        <Link
          to={`${process.env.PUBLIC_URL}/ketentuan-layanan/${row.id}`}
          className="btn-outline-primary btn  btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-pencil-square"></i>
          </span>
        </Link>

        <button
          onClick={() => viewDetailKetentuan(row)}
          className="btn btn-outline-info btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-eye-fill"></i>
          </span>
        </button>
      </Fragment>
    );
  };

  // end Table Ketentuan Layanan

  // Modal Detail
  const [showModalDetail, setshowModalDetail] = useState(false);
  const [showModalDetailKL, setshowModalDetailKL] = useState(false);

  const viewDetailBantuan = (data) => {
    setshowModalDetail(!showModalDetail);
    setSelectedDataShow(data);
  };

  const viewDetailKetentuan = (data) => {
    setshowModalDetailKL(!showModalDetailKL);
    setSelectedDataShow(data);
  };

  const aboutChange = (event) => {
    const newContent = event.editor.getData();
    setAboutContent(newContent);
  };
  const termChange = (event) => {
    const newContent = event.editor.getData();
    setTermContent(newContent);
  };
  const cgChange = (event) => {
    const newContent = event.editor.getData();
    setCgContent(newContent);
  };
  const pointChange = (event) => {
    const newContent = event.editor.getData();
    setPointContent(newContent);
  };

  const [getHelp, { data: dataHelp, error: errorHelp, loading: loadingHelp }] =
    useGetHelp();

  const [
    getKetentuan,
    { data: dataKetentuan, error: errorKetentuan, loading: loadingKetentuan },
  ] = useGetKetentuan();

  const [deleteDataHelp, { data: dataDeleteHelp, loading: loadingDeleteHelp }] =
    useDeleteHelp();

  const [deleteDataKL, { data: dataDeleteKL, loading: loadingDeleteKL }] =
    useDeleteKL();

  const [
    getPoint,
    { data: dataPoint, error: errorPoint, loading: loadingPoint },
  ] = useGetOptions();

  const [getTerm, { data: dataTerm, error: errorTerm, loading: loadingTerm }] =
    useGetOptions();

  const [getCG, { data: dataCG, error: errorCG, loading: loadingCG }] =
    useGetOptions();

  const [
    getAbout,
    { data: dataAbout, error: errorAbout, loading: loadingAbout },
  ] = useGetOptions();

  const [
    updateAbout,
    {
      data: dataUpdateAbout,
      error: errorUpdateAbout,
      loading: loadingUpdateAbout,
    },
  ] = useUpdateOptions();
  
  const [
    updateTerm,
    {
      data: dataUpdateTerm,
      error: errorUpdateTerm,
      loading: loadingUpdateTerm,
    },
  ] = useUpdateOptions();
  const [
    updateCg,
    { data: dataUpdateCg, error: errorUpdateCg, loading: loadingUpdateCg },
  ] = useUpdateOptions();
  const [
    updatePoint,
    {
      data: dataUpdatePoint,
      error: errorUpdatePoint,
      loading: loadingUpdatePoint,
    },
  ] = useUpdateOptions();

  const loadAbout = async () => {
    await getAbout("about_us");
  };
  const loadTerm = async () => {
    await getTerm("terms");
  };
  const loadCG = async () => {
    await getCG("comunity_guide");
  };
  const loadPoint = async () => {
    await getPoint("point_description");
  };
  const loadHelp = async () => {
    await getHelp();
  };
  const loadKetentuan = async () => {
    await getKetentuan();
  };

  useEffect(() => {
    loadAbout();
    loadTerm();
    loadCG();
    loadPoint();
    loadHelp();
    loadKetentuan();
  }, []);

  useEffect(() => {
    if (dataAbout && dataAbout.data) {
      setAboutContent(dataAbout.data);
    }
  }, [dataAbout]);

  useEffect(() => {
    if (dataHelp && dataHelp.data) {
      const dataResults = Object.values(dataHelp.data).sort(
        (a, b) => b.id - a.id
      );
      setHelpContent(dataResults);
    }
  }, [dataHelp]);

  useEffect(() => {
    if (dataKetentuan && dataKetentuan.data) {
      const datas = dataKetentuan.data;
      const dataResults = Object.values(datas).sort((a, b) => b.id - a.id);
      setKetentuanContent(dataResults);
    } else {
  
    }
  }, [dataKetentuan]);

  useEffect(() => {
    if (dataDeleteKL && dataDeleteKL.status === 200) {
      const newData = ketentuanContent.filter((item) => {
        return item.id !== selectedData;
      });
      setKetentuanContent(newData);
    }
  }, [dataDeleteKL]);

  useEffect(() => {
    if (dataDeleteHelp && dataDeleteHelp.status === 200) {
      const newData = helpContent.filter((item) => {
        return item.id !== selectedData;
      });
      setHelpContent(newData);
    }
  }, [dataDeleteHelp]);

  useEffect(() => {
    if (dataTerm && dataTerm.data) {
      setTermContent(dataTerm.data);
    }
  }, [dataTerm]);

  useEffect(() => {
    if (dataPoint && dataPoint.data) {
      setPointContent(dataPoint.data);
    }
  }, [dataPoint]);

  useEffect(() => {
    if (dataCG && dataCG.data) {
      setCgContent(dataCG.data);
    }
  }, [dataCG]);

  const submitAbout = async () => {
    const data = { key: "about_us", value: aboutContent };
    await updateAbout(data);
  };
  const submitTerm = async () => {
    const data = { key: "terms", value: termContent };
    await updateTerm(data);
  };
  const submitCG = async () => {
    const data = { key: "comunity_guide", value: cgContent };
    await updateCg(data);
  };
  const submitPoint = async () => {
    // const data = {
    //   question: selectedDataShow.question,
    //   answer: selectedDataShow.answer,
    // };
    // await updatePoint(data);
  };

  const deleteHandlerBantuan = async (id) => {
    await deleteDataHelp(id);
  };

  const deleteHandlerKL = async (id) => {
    await deleteDataKL(id);
  };

  const deleteUserHandlerKL = async (id) => {
    Swal.fire({
      title: t("confirmAction"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#05b1a1",
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSelectedData(id);
        await deleteHandlerKL(id);
      }
    });
  };

  const deleteUserHandlerBantuan = async (id) => {
    Swal.fire({
      title: t("confirmAction"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#05b1a1",
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSelectedData(id);
        await deleteHandlerBantuan(id);
      }
    });
  };

  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              {/* <div className="col-md-12">
                <div className="form-group">
                  <label>{t("aboutUs")}</label>
                  {!loadingAbout ? (
                    <Fragment>
                      {errorUpdateAbout &&
                        typeof errorUpdateAbout === "string" && (
                          <div className="alert alert-danger dark" role="alert">
                            {errorUpdateAbout}
                          </div>
                        )}
                      {dataUpdateAbout &&
                        typeof dataUpdateAbout.message === "string" && (
                          <div
                            className="alert alert-success dark"
                            role="alert"
                          >
                            {dataUpdateAbout.message}
                          </div>
                        )}
                      <CKEditors
                        activeclassName="p10"
                        content={aboutContent}
                        events={{
                          change: aboutChange,
                        }}
                      />

                      <div className="row mt-2">
                        <div className="col">
                          <button
                            disabled={loadingUpdateAbout}
                            type="button"
                            onClick={submitAbout}
                            className="btn btn-success"
                          >
                            {t("update", { name: t("aboutUs") })}
                          </button>
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>{t("terms")}</label>
                  {!loadingTerm ? (
                    <Fragment>
                      {errorUpdateTerm && typeof errorUpdateTerm === "string" && (
                        <div className="alert alert-danger dark" role="alert">
                          {errorUpdateTerm}
                        </div>
                      )}
                      {dataUpdateTerm &&
                        typeof dataUpdateTerm.message === "string" && (
                          <div
                            className="alert alert-success dark"
                            role="alert"
                          >
                            {dataUpdateTerm.message}
                          </div>
                        )}
                      <CKEditors
                        activeclassName="p10"
                        content={termContent}
                        events={{
                          change: termChange,
                        }}
                      />
                      <div className="row mt-2">
                        <div className="col">
                          <button
                            disabled={loadingUpdateTerm}
                            type="button"
                            onClick={submitTerm}
                            className="btn btn-success"
                          >
                            {t("update", { name: t("terms") })}
                          </button>
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>{t("comunityGuide")}</label>
                  {!loadingCG ? (
                    <Fragment>
                      {errorUpdateCg && typeof errorUpdateCg === "string" && (
                        <div className="alert alert-danger dark" role="alert">
                          {errorUpdateCg}
                        </div>
                      )}
                      {dataUpdateCg &&
                        typeof dataUpdateCg.message === "string" && (
                          <div
                            className="alert alert-success dark"
                            role="alert"
                          >
                            {dataUpdateCg.message}
                          </div>
                        )}
                      <CKEditors
                        activeclassName="p10"
                        content={cgContent}
                        events={{
                          change: cgChange,
                        }}
                      />
                      <div className="row mt-2">
                        <div className="col">
                          <button
                            disabled={loadingUpdateCg}
                            type="button"
                            onClick={submitCG}
                            className="btn btn-success"
                          >
                            {t("update", { name: t("comunityGuide") })}
                          </button>
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>{t("pointDescription")}</label>
                  {!loadingPoint ? (
                    <Fragment>
                      {errorUpdatePoint &&
                        typeof errorUpdatePoint === "string" && (
                          <div className="alert alert-danger dark" role="alert">
                            {errorUpdatePoint}
                          </div>
                        )}
                      {dataUpdatePoint &&
                        typeof dataUpdatePoint.message === "string" && (
                          <div
                            className="alert alert-success dark"
                            role="alert"
                          >
                            {dataUpdatePoint.message}
                          </div>
                        )}
                      <CKEditors
                        activeclassName="p10"
                        content={pointContent}
                        events={{
                          change: pointChange,
                        }}
                      />
                      <div className="row mt-2">
                        <div className="col">
                          <button
                            disabled={loadingUpdatePoint}
                            type="button"
                            onClick={submitPoint}
                            className="btn btn-success"
                          >
                            {t("update", { name: t("pointDescription") })}
                          </button>
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                </div>
              </div> */}
              {/* bantuan */}
              <div className="col-md-12">
                <label>Bantuan</label>
                <DataTable
                  data={helpContent}
                  columns={tableBantuan}
                  progressPending={loadingHelp}
                  subHeader={false}
                  fixedHeader={true}
                  center={true}
                  persistTableHead
                  highlightOnHover={true}
                  noHeader={true}
                  progressComponent={
                    <div className="d-block my-5">
                      <Loader loading />
                    </div>
                  }
                  noDataComponent={
                    <div className="my-3">{t("emptyTable")}</div>
                  }
                />

                <Button
                  className="mt-3 bg-success"
                  as={Link}
                  to={`${process.env.PUBLIC_URL}/post-help/new`}
                >
                  Tambah Bantuan
                </Button>

                {/* Modal Bantuan */}
                <Modal show={showModalDetail} onHide={viewDetailBantuan}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {selectedDataShow && selectedDataShow.question && (
                        <label className="text-danger">
                          {selectedDataShow.question}
                        </label>
                      )}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {selectedDataShow && selectedDataShow.answer && (
                      <label>{selectedDataShow.answer}</label>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="danger" onClick={viewDetailBantuan}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* end Modal Bantuan */}
              </div>
              {/* ketentuan layanan */}
              <div className="col-md-12 mt-3">
                <label>Informasi</label>
                <DataTable
                  data={ketentuanContent}
                  columns={tableKetentuan}
                  progressPending={loadingKetentuan}
                  subHeader={false}
                  fixedHeader={true}
                  center={true}
                  persistTableHead
                  highlightOnHover={true}
                  noHeader={true}
                  progressComponent={
                    <div className="d-block my-5">
                      <Loader loading />
                    </div>
                  }
                  noDataComponent={
                    <div className="my-3">{t("emptyTable")}</div>
                  }
                />

                <Button
                  className="mt-3 bg-success"
                  as={Link}
                  to={`${process.env.PUBLIC_URL}/ketentuan-layanan/new`}
                >
                  Tambah Informasi
                </Button>

                {/* Modal Ketentuan */}
                <Modal show={showModalDetailKL} onHide={viewDetailKetentuan}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {selectedDataShow && selectedDataShow.title && (
                        <label className="text-danger">
                          {selectedDataShow.title}
                        </label>
                      )}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {selectedDataShow && selectedDataShow.content && (
                      <label>{selectedDataShow.content}</label>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="danger" onClick={viewDetailKetentuan}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* end Modal Ketentuan */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withAuth(translate(Redaction))(["admin"]);
