import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useGetUser } from "../store/actions/me";
import { Loader } from "../components";
const GuestRoute = (props) => {
  const { children, ...rest } = props;
  const { data, loading, error } = useGetUser();

  if (loading) {
    return <Loader loading={true} />;
  }
  if (error && typeof window !== "undefined") {
    return (
      <Route
        {...rest}
        render={() => {
          return children;
        }}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={() => {
          return (
            <Redirect
              to={{
                pathname: process.env.PUBLIC_URL + "/",
                state: { from: props.location },
              }}
            />
          );
        }}
      />
    );
  }
};
export default GuestRoute;
