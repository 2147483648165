import React, { Fragment, useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import withAuth from "../../../hoc/withAuth";
import Layout from "../../../layouts";
import NumberFormat from "react-number-format";
import { useGetBackground } from "../../../store/actions/analytic";
import { Chart } from "react-chartjs-2";
import "chart.js/auto";
import { Button, Table } from "react-bootstrap";
import Choropleth from "react-leaflet-choropleth";
import { MapContainer, Polygon, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { dataIndonesia } from "../dataIndo";

const BackgroundAnalytic = (props) => {
  const { t } = props;
  const pageTitle = t("background");
  // gender
  const [dataGender, setDataGender] = useState([]);
  const [gendersLabels, setGendersLabels] = useState(null);
  const [gendersValue, setGendersValue] = useState(null);
  // relationship
  const [dataRelationships, setDataRelationships] = useState([]);
  const [relationshipsLabels, setRelationshipsLabels] = useState(null);
  const [relationshipsValue, setRelationshipsValue] = useState(null);
  // profesi
  const [dataProfession, setDataProfession] = useState([]);
  const [professionLabels, setProfessionLabels] = useState(null);
  const [professionValue, setProfessionValue] = useState(null);
  // education
  const [dataLastEducation, setDataLastEducation] = useState([]);
  const [lastEducationLabels, setLastEducationLabels] = useState(null);
  const [lastEducationValue, setLastEducationValue] = useState(null);
  // ages
  const [dataAges, setDataAges] = useState([]);
  const [agesLabels, setAgesLabels] = useState(null);
  const [agesValue, setAgesValue] = useState(null);

  //  get background
  const [
    getBackground,
    { data: dataBackground, loading: loadingDataBackground },
  ] = useGetBackground();

  const loadDataBackground = async () => {
    await getBackground(0, 10, "");
  };

  useEffect(() => {
    loadDataBackground();
  }, []);

  // data Gender
  useEffect(() => {
    if (dataBackground && dataBackground.total_user) {
      const results = dataBackground.gender;
      const dataResults = results.push({
        gender: "tidak_diketahui",
        total: dataBackground.unknown_gender,
      });
      const mapData = results.map((item) => {
        return {
          ...item,
          gender:
            item.gender === "p"
              ? "Perempuan"
              : item.gender === "l"
              ? "Laki-laki"
              : "Tidak Diketahui",
        };
      });
      const genderLabels = mapData.map(({ gender }) => gender);
      const genderTotal = mapData.map(({ total }) => total);
      setGendersLabels(genderLabels);
      setGendersValue(genderTotal);
      setDataGender(mapData);
    }
  }, [dataBackground]);

  // data Relationships
  useEffect(() => {
    if (dataBackground && dataBackground.total_user) {
      const results = dataBackground.relationship;
      const dataResults = results.push({
        relationship: "tidak_diketahui",
        total: dataBackground.unknown_relationship,
      });
      const mapData = results.map((item) => {
        return {
          ...item,
          relationship:
            item.relationship === "married"
              ? "Menikah"
              : item.relationship === "single"
              ? "Lajang"
              : "Tidak Diketahui",
        };
      });
      const relationshipsLabels = results.map(
        ({ relationship }) => relationship
      );
      const relationshipsTotal = results.map(({ total }) => total);
      setRelationshipsLabels(relationshipsLabels);
      setRelationshipsValue(relationshipsTotal);
      setDataRelationships(mapData);
    }
  }, [dataBackground]);

  // data Profession
  useEffect(() => {
    if (dataBackground && dataBackground.total_user) {
      const results = Object.values(dataBackground.profession);
      const dataResults = results.push({
        profession: "tidak_diketahui",
        total: dataBackground.unknown_profession,
      });
      const mapData = results.map((item) => {
        return {
          ...item,
          profession:
            item.profession !== "tidak_diketahui"
              ? item.profession
              : "Tidak diketahui",
        };
      });

      const professionLabels = mapData.map(({ profession }) => profession);
      const professionTotal = results.map(({ total }) => total);
      setProfessionLabels(professionLabels);
      setProfessionValue(professionTotal);
      setDataProfession(mapData);
    }
  }, [dataBackground]);

  // data Last Education
  useEffect(() => {
    if (dataBackground && dataBackground.total_user) {
      const results = Object.values(dataBackground.last_education);
      const dataResults = results.push({
        last_education: "tidak_diketahui",
        total: dataBackground.unknown_last_education,
      });
      const mapData = results.map((item) => {
        return {
          ...item,
          last_education:
            item.last_education !== "tidak_diketahui"
              ? item.last_education
              : "Tidak diketahui",
        };
      });

      const lastEducationLabels = mapData.map(
        ({ last_education }) => last_education
      );
      const lastEducationTotal = results.map(({ total }) => total);
      setLastEducationLabels(lastEducationLabels);
      setLastEducationValue(lastEducationTotal);
      setDataLastEducation(mapData);
    }
  }, [dataBackground]);

  // data age
  useEffect(() => {
    if (dataBackground && dataBackground.total_user) {
      const data = dataBackground.age;
      const results = Object.keys(data).map((key) => ({
        age: key,
        total: data[key],
      }));
      const mapData = results.map((item) => {
        return {
          ...item,
          age:
            item.age === "u13"
              ? "<13"
              : item.age === "a55"
              ? ">55"
              : item.age === "unknown"
              ? "Tidak diketahui"
              : item.age,
        };
      });

      const ageLabels = mapData.map(({ age }) => age);
      const ageTotal = results.map(({ total }) => total);
      setAgesLabels(ageLabels);
      setAgesValue(ageTotal);
      setDataAges(mapData);
    }
  }, [dataBackground]);

  const dataGenders = {
    labels: gendersLabels,
    datasets: [
      {
        data: gendersValue,
        backgroundColor: ["#05b1a1", "#ffc107", "#6c757d"],
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const dataStatus = {
    labels: relationshipsLabels,
    datasets: [
      {
        label: `${t("relationships")}`,
        data: relationshipsValue,
        backgroundColor: ["red", "blue", "#6c757d"],
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const dataProfessions = {
    labels: professionLabels,
    datasets: [
      {
        label: `${t("profession")}`,
        data: professionValue,
        backgroundColor: ["red", "blue", "#6c757d"],
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const dataLastEducations = {
    labels: lastEducationLabels,
    datasets: [
      {
        label: `${t("last_education")}`,
        data: lastEducationValue,
        backgroundColor: ["red", "blue", "#6c757d"],
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const dataAge = {
    labels: agesLabels,
    datasets: [
      {
        label: `${t("ages")}`,
        data: agesValue,
        backgroundColor: ["red", "blue", "#6c757d"],
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const barChartsConfig = {
    plugins: {
      legend: {
        labels: {
          boxWidth: 0,
        },
      },
    },
  };

  // MAP charts
  const center = [-4.4147748,120.8017172];

  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="row">
        {/* gender */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  {/* <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div> */}
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">{t("genders")}</h6>
                  {loadingDataBackground ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <Chart type="doughnut" data={dataGenders} options={barChartsConfig} />
              <div>
                {!loadingDataBackground ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{t("genders")}</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataGender.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.gender}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
        {/* relationships */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  {/* <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div> */}
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">
                    {t("relationships")}
                  </h6>
                  {loadingDataBackground ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <Chart
                type="doughnut"
                data={dataStatus}
                options={barChartsConfig}
              />
              <div>
                {!loadingDataBackground ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{t("relationships")}</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataRelationships.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.relationship}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
        {/* profession */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  {/* <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div> */}
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">
                    {t("profession")}
                  </h6>
                  {loadingDataBackground ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <Chart
                type="bar"
                data={dataProfessions}
                options={barChartsConfig}
              />
              <div>
                {!loadingDataBackground ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{t("profession")}</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataProfession.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.profession}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
        {/* last education */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  {/* <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div> */}
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">
                    {t("last_education")}
                  </h6>
                  {loadingDataBackground ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <Chart
                type="bar"
                data={dataLastEducations}
                options={barChartsConfig}
              />
              <div>
                {!loadingDataBackground ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{t("last_education")}</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLastEducation.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.last_education}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
        {/* age */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  {/* <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div> */}
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">{t("ages")}</h6>
                  {loadingDataBackground ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <Chart type="bar" data={dataAge} options={barChartsConfig} />
              <div>
                {!loadingDataBackground ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{t("ages")}</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataAges.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.age}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
        {/* domicile */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  {/* <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div> */}
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">{t("domicile")}</h6>
                  {loadingDataBackground ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <MapContainer
                center={center}
                zoom={3}
                style={{ width: 450, height: 300 }}
              >
                <TileLayer url="https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=YO1eAi98eyiU80INdLXL" />
                {dataIndonesia.features.map((state) => {
                  const koordinat = state.geometry.coordinates[0].map(
                    (item) => [item[1], item[0]]
                  );
                  return (
                    <Polygon
                      pathOptions={{
                        fillColor: "#800026",
                        fillOpacity: 0.7,
                        weight: 2,
                        opacity: 1,
                        dashArray: 3,
                        color: "white",
                      }}
                      positions={koordinat}
                      eventHandlers={{
                        mouseover: (e) => {
                          const layer = e.target;
                          layer.setStyle({
                            fillOpacity: 0.7,
                            weight: 5,
                            dashArray: "",
                            color: "white",
                            fillColor:"red"
                          });
                        },
                        mouseout: (e) => {
                          const layer = e.target;
                          layer.setStyle({
                            fillOpacity: 0.7,
                            weight: 2,
                            dashArray: "3",
                            color: "white",
                            fillColor:"#800026"
                          });
                        },
                        click: (e) => {},
                      }}
                    />
                  );
                })}
              </MapContainer>
              <div>
                {!loadingDataBackground ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{t("genders")}</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataGender.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.gender}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withAuth(translate(BackgroundAnalytic))([]);
