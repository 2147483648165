// dashbaord
import Dashboard from "../pages/dashboard";
import Profile from "../pages/profile/password";
import Users from "../pages/users";
import FormUser from "../pages/users/form";

import Post from "../pages/post";
import Notification from "../pages/notification";
import Rewards from "../pages/rewards";
import FormRewards from "../pages/rewards/form";
import RewardCategory from "../pages/rewards/category";
import FormRewardCategory from "../pages/rewards/formCategory";

// import Report from "../pages/report";

import Settings from "../pages/settings";
import SettingEmail from "../pages/settings/email";
import PostType from "../pages/postType";
import FormPostType from "../pages/postType/form";
import SettingRedaction from "../pages/settings/redaction";
import SettingTopic from "../pages/topic";
import FormSetingTopic from "../pages/topic/form";
import Point from "../pages/point";
import contentCreator from "../pages/contentCreator";
import Validation from "../pages/rewards/Validation";
import RewardHistory from "../pages/rewards/RewardHistory";
import FormBantuan from "../pages/settings/FormBantuan";
import formKetentuanLayanan from "../pages/settings/formKetentuanLayanan";
import Laporan from "../pages/laporan";
import Ads from '../pages/Ads/Ads';
import FormAds from '../pages/Ads/form';
import addPoint from "../pages/addPoint/addPoint";
import formAddPoint from "../pages/addPoint/formAddPoint";
import Backgrounds from "../pages/analitik/backgrounds";
import Interest from "../pages/analitik/interests";
import Behaviour from "../pages/analitik/behaviors";
import AdvancedAnalytic from "../pages/analitik/advanced";
import Analytic from "../pages/analitik";

export const routes = [
  { path: `${process.env.PUBLIC_URL}/`, Component: Dashboard },
  { path: `${process.env.PUBLIC_URL}/account`, Component: Profile },
  { path: `${process.env.PUBLIC_URL}/users`, Component: Users },

  { path: `${process.env.PUBLIC_URL}/posts`, Component: Post },
  { path: `${process.env.PUBLIC_URL}/notification`, Component: Notification },

  { path: `${process.env.PUBLIC_URL}/rewards`, Component: Rewards },
  {
    path: `${process.env.PUBLIC_URL}/rewards/:id(\\d+)`,
    Component: FormRewards,
  },
  {
    path: `${process.env.PUBLIC_URL}/rewards/new`,
    Component: FormRewards,
  },

  {
    path: `${process.env.PUBLIC_URL}/reward-category`,
    Component: RewardCategory,
  },
  {
    path: `${process.env.PUBLIC_URL}/reward-validation`,
    Component: Validation,
  },
  {
    path: `${process.env.PUBLIC_URL}/reward-history`,
    Component: RewardHistory,
  },
  {
    path: `${process.env.PUBLIC_URL}/reward-category/:id(\\d+)`,
    Component: FormRewardCategory,
  },
  {
    path: `${process.env.PUBLIC_URL}/reward-category/new`,
    Component: FormRewardCategory,
  },
  {
    path: `${process.env.PUBLIC_URL}/ketentuan-layanan/:id(\\d+)`,
    Component: formKetentuanLayanan,
  },
  {
    path: `${process.env.PUBLIC_URL}/ketentuan-layanan/new`,
    Component: formKetentuanLayanan,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/:id(\\d+)`,
    Component: FormUser,
  },
  { path: `${process.env.PUBLIC_URL}/users/new`, Component: FormUser },

  // { path: `${process.env.PUBLIC_URL}/report`, Component: Report },  
  // { path: `${process.env.PUBLIC_URL}/input/format`, Component: Format },

  { path: `${process.env.PUBLIC_URL}/settings`, Component: Settings },
  { path: `${process.env.PUBLIC_URL}/settings/email`, Component: SettingEmail },
  { path: `${process.env.PUBLIC_URL}/redaction`, Component: SettingRedaction },

  { path: `${process.env.PUBLIC_URL}/post-type`, Component: PostType },
  {
    path: `${process.env.PUBLIC_URL}/post-type/:id(\\d+)`,
    Component: FormPostType,
  },
  {
    path: `${process.env.PUBLIC_URL}/post-type/new`,
    Component: FormPostType,
  },
  {
    path: `${process.env.PUBLIC_URL}/post-help/new/`,
    Component: FormBantuan,
  },
  {
    path: `${process.env.PUBLIC_URL}/post-help/:id(\\d+)`,
    Component: FormBantuan,
  },
  { path: `${process.env.PUBLIC_URL}/topics`, Component: SettingTopic },
  {
    path: `${process.env.PUBLIC_URL}/topics/:id(\\d+)`,
    Component: FormSetingTopic,
  },
  { path: `${process.env.PUBLIC_URL}/topics/new`, Component: FormSetingTopic },

  { path: `${process.env.PUBLIC_URL}/point`, Component: Point },
  {
    path: `${process.env.PUBLIC_URL}/contentcreator`,
    Component: contentCreator,
  },
  {
    path: `${process.env.PUBLIC_URL}/laporan`,
    Component: Laporan,
  },
  {
    path: `${process.env.PUBLIC_URL}/ads`,
    Component: Ads,
  },
  { path: `${process.env.PUBLIC_URL}/ads/new`, Component: FormAds },
  {
    path: `${process.env.PUBLIC_URL}/ads/:id(\\d+)`,
    Component: FormAds,
  },
  {
    path: `${process.env.PUBLIC_URL}/addpoint`,
    Component: addPoint,
  },
  { path: `${process.env.PUBLIC_URL}/addpoint/new`, Component: formAddPoint },
  {
    path: `${process.env.PUBLIC_URL}/addpoint/:id(\\d+)`,
    Component: formAddPoint,
  },
  {
    path: `${process.env.PUBLIC_URL}/background`,
    Component: Backgrounds,
  },
  {
    path: `${process.env.PUBLIC_URL}/interests`,
    Component: Interest,
  },
  {
    path: `${process.env.PUBLIC_URL}/behaviour`,
    Component: Behaviour,
  },
  {
    path: `${process.env.PUBLIC_URL}/advanced-analytic`,
    Component: AdvancedAnalytic,
  },
];
