import React, { Fragment } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import withAuth from "../../hoc/withAuth";
import Layout from "../../layouts";

const Notification = (props) => {
  const { t } = props;
  const pageTitle = t("notification");
  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
    </Layout>
  );
};

export default withAuth(translate(Notification))(["admin"]);
