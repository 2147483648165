import React, { Fragment } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import Layout from "../../layouts";
import withAuth from "../../hoc/withAuth";
import Tab from "./tabNav";

const Settings = (props) => {
  const { t } = props;
  return (
    <Layout {...props}>
      <Helmet>
        <title>{t("general")}</title>
      </Helmet>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <Tab tab="general" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withAuth(translate(Settings))(["admin"]);
