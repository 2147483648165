import { useApiHandler, fetcher } from "../index";
import useSWR from "swr";
import Api from "../../connection";
import qs from "qs";
import "moment";
import moment from "moment";

export const getBackground = async () => {
  const posts = await Api.get(`/analytic/background`);
  return posts;
};

// export const getInterest = async () => {
//   const posts = await Api.get(`/analytic/interest`);
//   return posts;
// };

export const getInterest = async (data1, data2) => {
  const posts = await Api.get(
    `/analytic/interest/date?start=${data1}&finish=${data2}`
  );
  return posts;
};

export const getBehaviour = async (data1, data2) => {
  const posts = await Api.get(
    `/analytic/behaviour/date?start=${data1}&finish=${data2}`,
  );
  return posts;
};

export const getAdvancedHobby = async (data1, data2) => {
  const posts = await Api.get(
    `/analytic/advanced-hobby`,
  );
  return posts;
};

export const getAdvancedTopic = async (data1, data2) => {
  const posts = await Api.get(
    `/analytic/advanced-topic`,
  );
  // console.log(posts)
  return posts;
};

export const useGetBackground = () => {
  return useApiHandler(getBackground);
};

export const useGetInterest = () => {
  return useApiHandler(getInterest);
};

export const useGetBehaviour = () => {
  return useApiHandler(getBehaviour);
};

export const useGetAdvancedHobby = () => {
  return useApiHandler(getAdvancedHobby);
};

export const useGetAdvancedTopic = () => {
  return useApiHandler(getAdvancedTopic);
};
