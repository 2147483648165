import React, { Fragment, useState, useEffect, useCallback } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import withAuth from "../../hoc/withAuth";
import Layout from "../../layouts";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import DataTable from "react-data-table-component";
import { Loader } from "../../components";
import {
  useIndex,
  useReject,
  useAprrove,
} from "../../store/actions/Validation";
import { useDetailUser } from "../../store/actions/users";
import NumberFormat from "react-number-format";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import moment from "moment";
import * as AiIcons from "react-icons/ai";

const Validation = (props) => {
  const { t } = props;
  const pageTitle = t("rewards");
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDataShow, setSelectedDataShow] = useState(null);
  const [listData, setListData] = useState([]);
  const [totalListData, setTotalListData] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedData, setSelectedData] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [offPage, setOffPage] = useState(0);
  const [allRewards, setallRewards] = useState([]);
  const [detailSelected, setDetailSelected] = useState(null);
  const [dataValidasi, setDataValidasi] = useState([]);

  const {
    register: registerSearch,
    handleSubmit: handleSearch,
    reset: resetSearchForm,
  } = useForm();
  const [getData, { data: datas, loading: loadingData }] = useIndex();

  const [
    approveData,
    { data: dataApprove, loading: loadingApprove, error: errorApprove },
  ] = useAprrove();

  const [
    rejectData,
    { data: dataReject, loading: loadingReject, error: errorReject },
  ] = useReject();

  const [
    detailData,
    { data: dataDetail, loading: loadingDetail, error: errorDetail },
  ] = useDetailUser();

  const tableColumns = [
    {
      name: "#",
      cell: (row, index) => {
        return offPage + index + 1;
      },
      sortable: true,
      center: true,
      width: "10%",
    },
    {
      name: t("name"),
      cell: (row) => {
        let date = row.created_at;
        if (date) {
          date = moment(date).format("DD MMM YYYY HH:mm:ss") ?? "";
        } else {
          date = "";
        }
        return (
          <div>
            <div>{row.name}</div>
            <small>
              <b>{date ?? ""}</b>
            </small>
          </div>
        );
      },
      sortable: true,
      center: false,
    },
    {
      name: t("type"),
      cell: (row) => {
          return (
            <div>
              <div>
                {row.tipe === "radeem non moneter" ? "POP Merchant" : "POP Cash"}
              </div>
            </div>
          );
        },
      sortable: false,
      center: true,
      width: "15%",

    },
    // {
    //   name: t("date"),
    //   selector: "postDate",
    //   sortable: true,
    //   center: false,
    // },
    {
      name: t("rewards"),
      selector: "description",
      sortable: true,
      center: false,
    },
    {
      name: "Qty",
      selector: "qty",
      sortable: false,
      center: true,
      width: "8%",
    },
    {
      name: t("option"),
      cell: (row) => rowOption(row),
      center: false,
    },
  ];
  const rowCategory = (row) => {
    if (!row.reward_category) return null;
    return <Fragment>{row.reward_category.name}</Fragment>;
  };
  const rowOption = (row) => {
    return (
      <Fragment>
        {/* <button
          disabled={loadingDelete}
          onClick={() => deleteDataHandler(row.id)}
          className="btn btn-outline-danger btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-trash-fill"></i>
          </span>
        </button> */}
        <button
          onClick={() => viewDetail(row)}
          className="btn btn-outline-info btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-eye-fill"></i>
          </span>
        </button>
        <button
          className="btn btn-outline-success btn-sm px-2 mx-1"
          onClick={() => approveHandler(row.id)}
        >
          <AiIcons.AiOutlineCheck />
        </button>
        <button
          className="btn btn-outline-danger btn-sm px-2 mx-1"
          onClick={() => rejectHandler(row.id)}
        >
          <AiIcons.AiOutlineClose />
        </button>
      </Fragment>
    );
  };

  const approveHandler = useCallback(async (id) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#05b1a1",
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSelectedData(id);
        await approved(id);
        alert("permintaan diterima")
        loadData()
      }
    });
  }, []);

  useEffect(() => {
    if (dataReject && dataReject.status === 200) {
      const newData = listData.filter((item) => {
        return item.id !== selectedData;
      });
      setListData(newData);
    }
  }, [dataReject]);

  const rejectHandler = useCallback(async (id) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#05b1a1",
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSelectedData(id);
        await rejected(id);
        alert("pemintaan ditolak")
        loadData();
      }
    });
  }, []);

  const rejected = async (id) => {
    await rejectData(id);
  };

  const approved = async (id) => {
    await approveData(id);
  };

  useEffect(() => {
    if (dataApprove && dataApprove.status === 200) {
      const newData = listData.filter((item) => {
        return item.id !== selectedData;
      });
      setListData(newData);

    }
  }, [dataApprove]);

  useEffect(() => {
    if (dataDetail && dataDetail.data) {
      setDetailSelected(dataDetail.data);
      console.log("testing",dataDetail.data);
    }
  }, [dataDetail]);

  const viewDetail = (data) => {
    setShowModal(true);
    setSelectedDataShow(data);
    loadDetail(data.user);
  };

  const modalToggle = () => setShowModal(!showModal);

  const refreshSearchHandler = () => {
    setSearchText("");
    resetSearchForm();
    loadData(1, perPage, "");
    setIsSearch(false);
  };

  const searchAction = async (data) => {
    setSearchText(data.search);
    loadData(1, perPage, data.search);
    setIsSearch(true);
  };

  const deleteDataHandler = async (id) => {
    Swal.fire({
      title: t("confirmAction"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#05b1a1",
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSelectedData(id);
      }
    });
  };
  const loadData = async (page = 1, size = perPage, search = searchText) => {
    let offset = (Number(page) - 1) * Number(size);
    setOffPage(offset);
    await getData(offset, size, search);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    loadData(page, newPerPage);
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    loadData(page);
    setCurrentPage(page);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (datas && datas.data) {
      const dataResults = Object.values(datas.data).sort((a, b) => b.id - a.id);
      const mapData = dataResults.map((item) => {
        return { ...item, name: item.first_name + " " + item.last_name };
      });
      setListData(mapData);
      setTotalListData(datas.total);
    }
  }, [datas]);

  // useEffect(() => {
  //   setDataValidasi(listData)
  // }, [])

  const loadDetail = async (id) => {
    await detailData(id);
  };

  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Modal isOpen={showModal} toggle={modalToggle} size="lg">
        <ModalHeader toggle={modalToggle}>{t("detail")}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <label>{t("name")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow.name && (
                    <>
                      {detailSelected && detailSelected && (
                        <label className="text-success">
                          {detailSelected.first_name === null
                            ? "Belum diisi"
                            : detailSelected.first_name +
                              " " +
                              detailSelected.last_name}
                        </label>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <label>{t("email")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow && (
                    <>
                      {detailSelected && detailSelected && (
                        <label className="text-success">
                          {detailSelected.email}
                        </label>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <label>{t("phone")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow && (
                    <>
                      {detailSelected && detailSelected && (
                        <label className="text-success">
                          {detailSelected.phone}
                        </label>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <label>{t("addresses")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow && (
                    <>
                      {detailSelected && detailSelected && (
                        <label className="text-success">
                          {detailSelected.address}
                        </label>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <label>{t("bank_name")} / {t("bank_account")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow && (
                    <>
                      {detailSelected && detailSelected && (
                        <label className="text-success">
                          {detailSelected.bank_name} / {detailSelected.bank_account_number}
                        </label>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <label>{t("pop_cash")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow && (
                    <>
                      {detailSelected && detailSelected && (
                        <label className="text-success">
                          {detailSelected.point_popcash}
                        </label>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <label>{t("pop_merchant")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow && (
                    <>
                      {detailSelected && detailSelected && (
                        <label className="text-success">
                          {detailSelected.point_popmerchant}
                        </label>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <label>{t("pop_reward")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow && (
                    <>
                      {detailSelected && detailSelected && (
                        <label className="text-success">
                          {detailSelected.point_popreward}
                        </label>
                      )}
                    </>
                  )}
                </div>
              </div>
              
              {/* hr */}
              <div className="col-12">
                <hr />
              </div>
              {/* end hr */}
              <div className="row">
                <div className="col-3">
                  <label>{t("type")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow && (
                    <>
                      {detailSelected && detailSelected && (
                        <label className="text-success">
                          {selectedDataShow.tipe === "radeem moneter" ? "POP Cash" : "POP Merchant"}
                        </label>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <label>{t("rewards")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow && (
                    <>
                      {detailSelected && detailSelected && (
                        <label className="text-success">
                          {selectedDataShow.description}
                        </label>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {selectedDataShow && selectedDataShow && (
            <>
              {detailSelected && detailSelected && (
                <a
                href={`https://link.opinia.id/profile/${detailSelected.id}`}
                target="_blank"
                className="btn-secondary btn"
              >
                Lihat Profil
              </a>
              )}
            </>
          )}
          <button className="btn btn-secondary" onClick={modalToggle}>
            {t("close")}
          </button>
          {/* {selectedDataShow && selectedDataShow.id && (
            <Link
              to={`${process.env.PUBLIC_URL}/rewards/${selectedDataShow.id}`}
              className="btn-success btn"
            >
              {t("edit")}
            </Link>
          )} */}
        </ModalFooter>
      </Modal>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body mt-4">
              {/* <div className="row mb-2 flex-row-reverse">
                <div className="col-sm-12 col-md-3">
                  <form
                    onSubmit={handleSearch(searchAction)}
                    className="form-inline theme-form "
                    noValidate=""
                  >
                    <div className="form-group ml-auto mr-3">
                      <div className="input-group">
                        <input
                          ref={registerSearch}
                          className="form-control"
                          type="text"
                          name="search"
                          placeholder={t("search")}
                          autoComplete="off"
                        />
                        {!isSearch && (
                          <button
                            type="submit"
                            className="btn btn-primary px-3"
                          >
                            <i className="bi bi-search" aria-hidden="true"></i>
                          </button>
                        )}
                        {isSearch && (
                          <button
                            onClick={refreshSearchHandler}
                            type="button"
                            className="btn btn-danger ml-1 px-3"
                          >
                            <i className="bi bi-x" aria-hidden="true"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}
              <DataTable
                data={listData}
                columns={tableColumns}
                progressPending={loadingData}
                subHeader={false}
                fixedHeader={true}
                center={true}
                persistTableHead
                highlightOnHover={true}
                pagination
                paginationServer={true}
                noHeader={true}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={(val) => handlePageChange(val)}
                paginationTotalRows={totalListData}
                progressComponent={
                  <div className="d-block my-5">
                    <Loader loading />
                  </div>
                }
                noDataComponent={<div className="my-3">{t("emptyTable")}</div>}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withAuth(translate(Validation))(["admin"]);
