import { useApiHandler, fetcher } from "./index";
import useSWR from "swr";
import Api from "../connection";
import qs from "qs";

const dataForm = new FormData();

export const allRewards = async () => {
  const posts = await Api.get(`/rewardcategory`);
  return posts;
};

export const index = async (offset, size, search) => {
  const posts = await Api.get(
    `/claimrequesthistory?offset=${offset}&limit=${size}&search=${search}`
  );
  return posts;
};
export const shows = async (id) => {
  const posts = await Api.get(`/rewards/${id}`);
  return posts;
};
export const deletes = async (id) => {
  const posts = await Api.delete(`/rewards/${id}`);
  return posts;
};

export const creates = async (data) => {
  const dataForm = new FormData();
  dataForm.append("name", data.name);
  dataForm.append("category", data.category);
  dataForm.append("point", data.point);
  dataForm.append("author", data.author);
  dataForm.append("stock", data.stock);
  dataForm.append("description", data.description);
  dataForm.append("image", data.image[0]);

  const posts = await Api.post(`/rewards`, dataForm,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return posts;
};

export const updates = async (id, data) => {
  const dataForm = new FormData();
  dataForm.append("name", data.name);
  dataForm.append("category", data.category);
  dataForm.append("point", data.point);
  dataForm.append("author", data.author);
  dataForm.append("stock", data.stock);
  dataForm.append("description", data.description);
  dataForm.append("image", data.image[0]);

  const posts = await Api.post(`/reward/update-reward/${id}`, dataForm,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return posts;
};

export const useAllRewards = () => {
  return useApiHandler(allRewards);
};
export const useIndex = () => {
  return useApiHandler(index);
};
export const useShow = () => {
  return useApiHandler(shows);
};
export const useDelete = () => {
  return useApiHandler(deletes);
};
export const useCreate = () => {
  return useApiHandler(creates);
};
export const useUpdate = () => {
  return useApiHandler(updates);
};
