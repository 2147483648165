import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { routes } from "./routes";
import GuestRoute from "./routes/GuestRoute";

import { Provider } from "react-redux";
import { AppProvider } from "./store/context";
import reduxStore from "./redux";

import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Error404 from "./pages/errors/error404";

const Root = () => {
  const abortController = new AbortController();

  useEffect(() => {
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <AppProvider>
        <Provider store={reduxStore}>
          <BrowserRouter basename={`/`}>
            <Switch>
              <GuestRoute exact path={`${process.env.PUBLIC_URL}/auth/login`}>
                <Login />
              </GuestRoute>
              <Route exact path={`${process.env.PUBLIC_URL}/`}>
                <Dashboard />
              </Route>
              {routes.map(({ path, Component }) => (
                <Route
                  key={path}
                  exact
                  path={`${process.env.PUBLIC_URL}${path}`}
                >
                  <Component />
                </Route>
              ))}
              <Route path="*">
                <Error404 />
              </Route>
            </Switch>
          </BrowserRouter>
        </Provider>
      </AppProvider>
    </Fragment>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
