import React, { createContext, useEffect, useState } from "react";
import Api from "./connection";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from "react-switch-lang";

import en from "../assets/i18n/en.json";
import id from "../assets/i18n/id.json";

setTranslations({ en, id });
setDefaultLanguage(process.env.REACT_APP_LANGUAGE);
setLanguageCookie();

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [user, setUser] = useState({
    userName: "",
    userRole: "",
    avatar: "",
    language: null,
    userId: "0",
    access: [],
  });

  const setUserLogin = (data) => {
    setUser({
      ...user,
      userName: data.name,
      userRole: data.role,
      avatar: data.photo,
      language: data.lang,
      userId: data.id,
      access: [],
    });
  };
  const changeLanguage = (lang) => {
    setUser({ ...user, language: lang });
    setLanguage(lang);
  };

  return (
    <AppContext.Provider
      value={{
        setUserLogin,
        user,
        changeLanguage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
