import { useApiHandler, fetcher } from "./index";
import useSWR from "swr";
import Api from "../connection";
import qs from "qs";

const dataForm = new FormData();

export const getOption = async (option) => {
  const posts = await Api.get(`/options/${option}`);
  return posts;
};
export const getAllOption = async (option) => {
  const posts = await Api.get(`/general-settings`);
  return posts;
};
export const updateOption = async (option) => {
  const dataForm = new FormData();
  dataForm.append("key", option.key);
  dataForm.append("value", option.value);
  const posts = await Api.post(`/update-option`, dataForm);
  return posts;
};
export const updateOptionEmail = async (option) => {
  const dataForm = new FormData();

  dataForm.append("mail_driver", option.mailDriver);
  dataForm.append("mail_host", option.mailHost);
  dataForm.append("mail_port", option.mailPort);
  dataForm.append("mail_encryption", option.mailEncryption);
  dataForm.append("mail_username", option.mailUsername);
  dataForm.append("mail_password", option.mailPassword);
  dataForm.append("mail_from_address", option.mailFromAddress);
  dataForm.append("mail_from_name", option.mailFromName);
  const posts = await Api.post(`/settings/smtp`, dataForm);
  return posts;
};

export const deleteHelp = async (id) => {
  const posts = await Api.delete(`/helps/${id}`);
  return posts;
};

//Bantuan

export const getHelp = async () => {
  const posts = await Api.get(`/help`);
  return posts;
};

export const creates = async (data) => {
  const dataForm = new FormData();
  dataForm.append("question", data.question);
  dataForm.append("answer", data.answer);

  const posts = await Api.post(`/helps`, dataForm);

  return posts;
};

export const shows = async (id) => {
  const posts = await Api.get(`/help/${id}`);
  return posts;
};

export const updates = async (id, data) => {
  let dataForm = {
    question: data.question,
    answer: data.answer,
  };
  const datas = qs.stringify(dataForm);
  const posts = await Api.patch(`/helps/${id}`, datas);
  return posts;
};
// end Bantuan

// get information
export const getInformation = async () => {
  const posts = await Api.get(`/informations`);
  return posts;
};
// end get information

// Ketentuan Layanan

export const getKL = async () => {
  const posts = await Api.get(`/informations`);
  return posts;
};

export const createsKL = async (data) => {
  const dataForm = new FormData();
  dataForm.append("title", data.title);
  dataForm.append("content", data.content);
  dataForm.append("parent", data.parent);


  const posts = await Api.post(`/informations`, dataForm);

  return posts;
};

export const showsKL = async (id) => {
  const posts = await Api.get(`/informations/${id}`);
  const results = posts.data.data;
  return posts;
};

export const updatesKL = async (id, data) => {
  let dataForm = {
    title: data.title,
    content: data.content,
    parent: data.parent,
  };
  const datas = qs.stringify(dataForm);
  const posts = await Api.patch(`/informations/${id}`, datas);
  return posts;
};

export const deleteKL = async (id) => {
  const posts = await Api.delete(`/informations/${id}`);
  return posts;
};

// end Ketentuan Layanan


export const useGetInformation = () => {
  return useApiHandler(getInformation);
};

export const useGetKetentuan = () => {
  return useApiHandler(getKL);
};

export const useCreatesKL = () => {
  return useApiHandler(createsKL);
};

export const useShowKL = () => {
  return useApiHandler(showsKL);
};

export const useUpdateKL = () => {
  return useApiHandler(updatesKL);
};

export const useDeleteKL = () => {
  return useApiHandler(deleteKL);
};

export const useGetHelp = () => {
  return useApiHandler(getHelp);
};
export const useShow = () => {
  return useApiHandler(shows);
};

export const useUpdate = () => {
  return useApiHandler(updates);
};

export const useCreate = () => {
  return useApiHandler(creates);
};

export const useDeleteHelp = () => {
  return useApiHandler(deleteHelp);
};

export const useGetOptions = () => {
  return useApiHandler(getOption);
};
export const useGetAllOptions = () => {
  return useApiHandler(getAllOption);
};

export const useUpdateOptions = () => {
  return useApiHandler(updateOption);
};
export const useUpdateEmail = () => {
  return useApiHandler(updateOptionEmail);
};
