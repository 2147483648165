import React, { useState, useEffect, useCallback } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import { Loader } from "../../components";
import {
  useDetailTopics,
  useAddTopics,
  useUpdateTopics,
} from "../../store/actions/topic";
import Layout from "../../layouts";
import withAuth from "../../hoc/withAuth";
import { useForm } from "react-hook-form";
import { Spinner } from "../../components";
import { useParams } from "react-router-dom";

const FormUser = (props) => {
  const { t } = props;
  const { id: dataId } = useParams();
  const pageTitle = t(dataId ? "update" : "new", { name: t("topic") });
  const [loadingData, setLoadingData] = useState(false);
  const [errorLoad, setErrorLoad] = useState(false);
  const [imageTopic,setImageTopic] = useState({preview:'', raw:''});

  const {
    register: registerForm,
    handleSubmit: handleSubmitForm,
    setValue: setDataForm,
    reset: resetDataForm,
  } = useForm();

  const [
    loadDetail,
    { data: dataDetail, loading: loadingDetail, error: errorDetail },
  ] = useDetailTopics();

  const [
    updateTopic,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useUpdateTopics();

  const [addTopic, { data: dataAdd, loading: loadingAdd, error: errorAdd }] =
    useAddTopics();

  

  const loadDetaiTopic = async (id = dataId) => {
    await loadDetail(id);
  };

  useEffect(() => {
    if (dataId) {
      loadDetaiTopic(dataId);
    }
  }, []);

  useEffect(() => {
    if (dataId && errorDetail) {
      setErrorLoad(true);
    }
  }, [errorDetail]);

  useEffect(() => {
    if (dataId) {
      setLoadingData(loadingDetail);
    }
  }, [loadingDetail]);

  useEffect(() => {
    if (dataAdd) {
      resetDataForm();
    }
  }, [dataAdd]);

  const submitHandler = async (data) => {
    if (dataId) {
      await updateTopic(dataId, data);
    } else {
      await addTopic(data);
    }
  };

  const handleChangePhoto = (e) => {
   
    if (e.target.files.length) {
      setImageTopic({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    };
    
  };

  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="row">
        {dataId && errorDetail && (
          <div className="alert alert-danger dark" role="alert">
            {errorDetail}
          </div>
        )}
        <div className="col-12">
          <Loader loading={loadingData} />
        </div>
        {!loadingData && !errorLoad && (
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="card-header">
                <h4>{pageTitle}</h4>
              </div>
              <div className="card-body">
                {errorAdd && typeof errorAdd === "string" && (
                  <div className="alert alert-danger dark" role="alert">
                    {errorAdd}
                  </div>
                )}
                {errorUpdate && typeof errorUpdate === "string" && (
                  <div className="alert alert-danger dark" role="alert">
                    {errorUpdate}
                  </div>
                )}
                {dataAdd && typeof dataAdd.message === "string" && (
                  <div className="alert alert-success dark" role="alert">
                    {dataAdd.message}
                  </div>
                )}
                {dataUpdate && typeof dataUpdate.message === "string" && (
                  <div className="alert alert-success dark" role="alert">
                    {dataUpdate.message}
                  </div>
                )}
                <form
                  action="#"
                  method="post"
                  onSubmit={handleSubmitForm(submitHandler)}
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("topic")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="name"
                          type="text"
                          required
                          defaultValue={
                            dataId && dataDetail && dataDetail.data
                              ? dataDetail.data.name
                              : ""
                          }
                          placeholder={t("topic")}
                        />
                      </div>
                      <div className="form-group">
                        <label>{t("image")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="image"
                          type="file"
                          onChange={handleChangePhoto}
                          accept=".jpg, .png"
                          required
                          defaultValue={
                            dataId && dataDetail && dataDetail.data
                              ? dataDetail.data.image
                              : ""
                          }
                          placeholder={t("image")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button
                        disabled={loadingAdd || loadingUpdate}
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        <Spinner loading={loadingAdd || loadingUpdate} />
                        {!loadingAdd && !loadingUpdate && pageTitle}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default withAuth(translate(FormUser))(["admin"]);
