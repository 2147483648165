import { useApiHandler, fetcher } from "./index";
import useSWR from "swr";
import Api from "../connection";
import qs from "qs";

const dataForm = new FormData();

// get information
export const getLaporan = async (offset, size, search) => {
  const posts = await Api.get(`/reports?all=all&offset=${offset}&limit=${size}&search=${search}`);
  return posts;
};



export const useGetLaporan = () => {
  return useApiHandler(getLaporan);
};
