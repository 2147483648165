import { useApiHandler, fetcher } from "./index";
import useSWR from "swr";
import Api from "../connection";
import qs from "qs";

const dataForm = new FormData();

export const index = async (offset, size, search) => {
  const posts = await Api.get(
    `/adminposts?all=all&offset=${offset}&limit=${size}&search=${search}`
  );
  return posts;
};
export const shows = async (id) => {
  const posts = await Api.get(`/adminpostsdetail/${id}`);
  return posts;
};
export const totalToday = async () => {
  const posts = await Api.get(`/post/total-today`);
  return posts;
};
export const deletes = async (id, data) => {
  const dataForm = new FormData();
  if (data.reason) dataForm.append("reason", data.reason);
  const posts = await Api.post(`/post/delete-admin/${id}`, dataForm);
  return posts;
};
// export const creates = async (data) => {
//   const dataForm = new FormData();
//   dataForm.append("jenis", data.jenis);
//   dataForm.append("type", data.type);
//   dataForm.append("point", data.point);
//   dataForm.append("min", data.minChar);
//   dataForm.append("max", data.maxChar);
//   dataForm.append("duration", data.duration);
//   const posts = await Api.post(`/posts`, dataForm);
//   return posts;
// };
// export const updates = async (id, data) => {
//   let dataForm = {
//     jenis: data.jenis,
//     type: data.type,
//     point: data.point,
//     min: data.minChar,
//     max: data.maxChar,
//     duration: data.duration,
//   };
//   const datas = qs.stringify(dataForm);
//   const posts = await Api.patch(`/posts/${id}`, datas);
//   return posts;
// };

export const useIndex = () => {
  return useApiHandler(index);
};
export const useShow = () => {
  return useApiHandler(shows);
};
export const useDelete = () => {
  return useApiHandler(deletes);
};
export const useTotalToday = () => {
  return useApiHandler(totalToday);
};
// export const useCreate = () => {
//   return useApiHandler(creates);
// };
// export const useUpdate = () => {
//   return useApiHandler(updates);
// };
