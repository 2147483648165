import React, { Fragment, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { AppContext } from "../../store/context";
import { translate } from "react-switch-lang";

const Header = (props) => {
  const { t, user } = props;

  const [dd, setDD] = useState(null);

  const [isLogout, setIsLogout] = useState(false);
  const openSidebar = () => {
    document.getElementById("sidebar").classList.toggle("active");
  };
  const menuHandler = (name) => {
    if (dd === name) {
      setDD(null);
    } else {
      setDD(name);
    }
  };

  const logoutHandler = () => {
    window.localStorage.clear();
    setIsLogout(true);
  };
  if (isLogout) {
    return <Redirect to={`${process.env.PUBLIC_URL}/auth/login`} />;
  }

  return (
    <Fragment>
      <header>
        <nav className="navbar navbar-expand navbar-light ">
          <div className="container-fluid">
            <a href="#" onClick={openSidebar} className="burger-btn d-block">
              <i className="bi bi-justify fs-3"></i>
            </a>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0"></ul>
              <div className="dropdown">
                <a
                  href="#"
                  onClick={() => {
                    menuHandler("profile");
                  }}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="user-menu d-flex">
                    <div className="user-img d-flex align-items-center">
                      <div className="avatar avatar-md">
                        <img src={(user && user.photo) || "/icon.png"} />
                      </div>
                    </div>
                  </div>
                </a>
                <ul
                  className={`${
                    dd === "profile" ? "show" : ""
                  } dropdown-menu dropdown-menu-end mt-2`}
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <h6 className="dropdown-header">
                      Hello, {user && user.first_name}
                    </h6>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/account`}
                      className="dropdown-item"
                    >
                      <i className="icon-mid bi bi-key me-2"></i>{" "}
                      {t("password")}
                    </Link>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li onClick={logoutHandler}>
                    <a className="dropdown-item" href="">
                      <i className="icon-mid bi bi-box-arrow-left me-2"></i>{" "}
                      {t("logout")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </Fragment>
  );
};

export default translate(Header);
