import React, { Fragment, useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import withAuth from "../../hoc/withAuth";
import Layout from "../../layouts";
import Tab from "./tabNav";
import { useUpdateEmail, useGetAllOptions } from "../../store/actions/options";
import { useForm } from "react-hook-form";
import { Spinner } from "../../components";
import { Loader } from "../../components";

const Email = (props) => {
  const { t } = props;
  const pageTitle = t("email");
  const [input, setInput] = useState({
    mail_driver: "",
    mail_host: "",
    mail_port: "",
    mail_encryption: "",
    mail_username: "",
    mail_password: "",
    mail_from_address: "",
    mail_from_name: "",
  });
  const {
    register: registerForm,
    handleSubmit: handleSubmitForm,
    setValue: setDataForm,
    reset: resetDataForm,
  } = useForm();
  const [
    getOptions,
    { data: dataOption, error: errorOption, loading: loadingOption },
  ] = useGetAllOptions();
  const [
    updateEmail,
    { data: dataEmail, error: errorEmail, loading: loadingEmail },
  ] = useUpdateEmail();

  const loadOption = async () => {
    await getOptions();
  };

  useEffect(() => {
    loadOption();
  }, []);

  useEffect(() => {
    if (dataOption && dataOption.data) {
      const listOption = dataOption.data;
      let oldInput = { ...input };
      listOption.map((item) => {
        if (item.name === "mail_driver") {
          oldInput = { ...oldInput, mail_driver: item.value };
        } else if (item.name === "mail_host") {
          oldInput = { ...oldInput, mail_host: item.value };
        } else if (item.name === "mail_port") {
          oldInput = { ...oldInput, mail_port: item.value };
        } else if (item.name === "mail_encryption") {
          oldInput = { ...oldInput, mail_encryption: item.value };
        } else if (item.name === "mail_username") {
          oldInput = { ...oldInput, mail_username: item.value };
        } else if (item.name === "mail_password") {
          oldInput = { ...oldInput, mail_password: item.value };
        } else if (item.name === "mail_from_address") {
          oldInput = { ...oldInput, mail_from_address: item.value };
        } else if (item.name === "mail_from_name") {
          oldInput = { ...oldInput, mail_from_name: item.value };
        }
      });
      setInput(oldInput);
    }
  }, [dataOption]);

  const submitHandler = async (data) => {
    await updateEmail(data);
  };

  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="row">
        <div className="col-12">
          <Loader loading={loadingOption} />
        </div>
        {!loadingOption && (
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                {/* <Tab tab="email" /> */}
                {errorEmail && typeof errorEmail === "string" && (
                  <div className="alert alert-danger dark" role="alert">
                    {errorEmail}
                  </div>
                )}
                {dataEmail && typeof dataEmail.message === "string" && (
                  <div className="alert alert-success dark" role="alert">
                    {dataEmail.message}
                  </div>
                )}
                <form
                  className="mt-4"
                  action="#"
                  method="post"
                  onSubmit={handleSubmitForm(submitHandler)}
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("mailDriver")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="mailDriver"
                          type="text"
                          required
                          defaultValue={input.mail_driver}
                          placeholder={t("mailDriver")}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("mailHost")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="mailHost"
                          type="text"
                          required
                          defaultValue={input.mail_host}
                          placeholder={t("mailHost")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("mailPort")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="mailPort"
                          type="text"
                          required
                          defaultValue={input.mail_port}
                          placeholder={t("mailPort")}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("mailEncryption")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="mailEncryption"
                          type="text"
                          required
                          defaultValue={input.mail_encryption}
                          placeholder={t("mailEncryption")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("mailUsername")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="mailUsername"
                          type="text"
                          required
                          defaultValue={input.mail_username}
                          placeholder={t("mailUsername")}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("mailPassword")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="mailPassword"
                          type="text"
                          required
                          defaultValue={input.mail_password}
                          placeholder={t("mailPassword")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("mailFromAddress")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="mailFromAddress"
                          type="email"
                          required
                          defaultValue={input.mail_from_address}
                          placeholder={t("mailFromAddress")}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("mailFromName")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerForm}
                          className="form-control"
                          name="mailFromName"
                          type="text"
                          required
                          defaultValue={input.mail_from_name}
                          placeholder={t("mailFromName")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button
                        disabled={loadingEmail || loadingOption}
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        <Spinner loading={loadingEmail} />
                        {!loadingEmail && t("update", { name: "email" })}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default withAuth(translate(Email))(["admin"]);
