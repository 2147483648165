import React, { Fragment, useState, useEffect, useCallback } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import { useGetUser } from "../../store/actions/me";
import { Spinner } from "../../components";
import { useForm } from "react-hook-form";
import * as ProfileAction from "../../store/actions/profile";
import Layout from "../../layouts";
import withAuth from "../../hoc/withAuth";

const Password = (props) => {
  const { t } = props;
  const {
    register: registerProfile,
    handleSubmit: handleProfile,
    setValue: setDataForm,
  } = useForm();
  const {
    register: registerPassword,
    handleSubmit: handlePassword,

    reset: resetPasswordForm,
  } = useForm();

  useEffect(() => {
    registerProfile({ name: "kelamin" });
  }, [registerProfile]);

  const { data: dataUser, loading: loadingUser } = useGetUser();
  const [selectedKelamin, setSelectedKelamin] = useState("");

  const genderChange = useCallback(
    (event) => {
      
      setSelectedKelamin(event.target.value);
      setDataForm("kelamin", event.target.value);
    },
    [selectedKelamin]
  );

  const [profile, setProfile] = useState({
    nama: null,
    email: null,
    ponsel: null,
  });

  useEffect(() => {
    if (dataUser && dataUser.data) {
      const user = dataUser.data;
      setProfile({
        ...profile,
        nama: user.user_nama,
        email: user.user_email,
        ponsel: user.user_ponsel,
      });
      setSelectedKelamin(user.user_kelamin);
      setDataForm("kelamin", user.user_kelamin);
    }
  }, [dataUser]);

  const [
    profileAction,
    { data: dataProfile, loading: loadingProfile, error: errorProfile },
  ] = ProfileAction.useProfile();

  const [
    passwordAction,
    { data: dataPassword, loading: loadingPassword, error: errorPassword },
  ] = ProfileAction.usePassword();

  const updateProfile = async (data) => {
    await profileAction(data);
  };

  const updatePassword = async (data) => {
    await passwordAction(data);
  };
  useEffect(() => {
    if (dataPassword) {
      resetPasswordForm();
    }
  }, [dataPassword]);
  return (
    <Layout {...props}>
      <Helmet>
        <title>{t("account")}</title>
      </Helmet>
      <div className="row">
        <div className="col-md-6">
          <div className="card mb-3">
            <div className="card-header">
              <h4>{t("password")}</h4>
            </div>
            <div className="card-body">
              {errorPassword && typeof errorPassword === "string" && (
                <div className="alert alert-danger dark" role="alert">
                  {errorPassword}
                </div>
              )}
              {dataPassword && typeof dataPassword.message === "string" && (
                <div className="alert alert-success dark" role="alert">
                  {dataPassword.message}
                </div>
              )}
              <form
                action="#"
                method="post"
                onSubmit={handlePassword(updatePassword)}
                encType="multipart/form-data"
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>{t("oldPassword")}</label>
                      <input
                        ref={registerPassword}
                        className="form-control"
                        name="old_password"
                        type="password"
                        required
                        placeholder={t("oldPassword")}
                      />
                      {errorPassword &&
                        errorPassword.message &&
                        errorPassword.message.old_password &&
                        errorPassword.message.old_password.map(
                          (item, index) => {
                            return (
                              <div key={index.toString()} className="mx-0">
                                <small className="form-text text-danger">
                                  {item}
                                </small>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>{t("newPassword")}</label>
                      <input
                        ref={registerPassword}
                        className="form-control"
                        name="password"
                        type="password"
                        required
                        placeholder={t("newPassword")}
                      />
                      {errorPassword &&
                        errorPassword.message &&
                        errorPassword.message.password &&
                        errorPassword.message.password.map((item, index) => {
                          return (
                            <div key={index.toString()} className="mx-0">
                              <small className="form-text text-danger">
                                {item}
                              </small>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>{t("rePassword")}</label>
                      <input
                        ref={registerPassword}
                        className="form-control"
                        name="password_confirmation"
                        type="password"
                        required
                        placeholder={t("rePassword")}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <button
                      disabled={loadingPassword}
                      type="submit"
                      className="btn btn-primary btn-block"
                    >
                      <Spinner loading={loadingPassword} />
                      {!loadingPassword && t("update", { name: t("password") })}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withAuth(translate(Password))();
