

/* CUSTOMIZER */
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIX_BACKGROUND_LAYOUT = "ADD_MIX_BACKGROUND_LAYOUT";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";

// app
export const APP_SETTINGS = "APP_SETTINGS";

//authentication
export const AUTHENTICATE = "AUTHENTICATE";
export const lOGOUT = "LOGOUT";
export const SET_LANGUAGE = "SET_LANGUAGE";

//users
export const LOAD_USERS = "LOAD_USERS";

//users
export const LOAD_ROLES = "LOAD_ROLES";

//content creator
export const FETCH_CONTENT_CREATOR_REQUEST = 'FETCH_CONTENT_CREATOR_REQUEST';
export const FETCH_CONTENT_CREATOR_SUCCESS = 'FETCH_CONTENT_CREATOR_SUCCESS';
export const FETCH_CONTENT_CREATOR_FAILURE = 'FETCH_CONTENT_CREATOR_FAILURE';
export const FETCH_CONTENT_CREATOR = 'FETCH_CONTENT_CREATOR';
