import React, { Fragment, useEffect, useState } from "react";
import Auth from "../../layouts/auth";
import styles from "../../layouts/styles/auth.module.scss";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { Spinner, Loader } from "../../components";
import * as AuthAction from "../../store/actions/AuthAction";
import { translate, setLanguage } from "react-switch-lang";
import { Helmet } from "react-helmet";

const Login = (props) => {
  useEffect(() => {
    setLanguage(localStorage.getItem("lang") || process.env.REACT_APP_LANGUAGE);
  }, []);
  const { t } = props;
  const { register, handleSubmit } = useForm();
  const [loginAction, { data, loading, error }] = AuthAction.useLogin();

  if (data) {
    const dataStorageSave = { data: data.data, token: data.data.token };
    localStorage.setItem(
      process.env.REACT_APP_STORAGE_NAME,
      JSON.stringify(dataStorageSave)
    );
    const redRectLogin =
      props.location.state &&
      props.location.state.from &&
      props.location.state.from.pathname
        ? props.location.state.from.pathname || "/"
        : "/";
    props.history.push(`${redRectLogin}`);

    return <Loader loading={true} />;
  }

  const loginHandler = async (dataForm) => {
    await loginAction(dataForm);
  };
  return (
    <Fragment>
      <Helmet>
        <title>{t("login")}</title>
      </Helmet>
      <Auth>
        <div className="row h-100">
          <div className="col-lg-5 col-12">
            <div className={styles.authLeft}>
              <div className="mb-3">
                {/* <img width="200" src={"/logo.png"} /> */}
              </div>
              <h1 className={`${styles.authTitle}`}>{t("login")}</h1>
              <div className={`${styles.authSubtitle}  mb-5`}>
                {process.env.REACT_APP_NAME}
              </div>
              {error && typeof error === "string" && (
                <div className="alert alert-danger dark" role="alert">
                  {error}
                </div>
              )}
              <form
                method="POST"
                action="#"
                onSubmit={handleSubmit(loginHandler)}
              >
                <div className="form-group position-relative mb-4">
                  <input
                    ref={register}
                    type="email"
                    name="email"
                    required
                    className={`form-control form-control-xl`}
                    placeholder={t("email")}
                  />
                </div>
                <div className="form-group position-relative mb-4">
                  <input
                    ref={register}
                    type="password"
                    name="password"
                    required
                    className={`form-control form-control-xl`}
                    placeholder={t("password")}
                  />
                </div>
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-lg btn-primary btn-block shadow-lg mt-5"
                >
                  <Spinner loading={loading} />
                  {loading ? "" : "Login"}
                </button>
              </form>
            </div>
          </div>
          <div className="col-lg-7 d-none d-lg-block">
            <div className={styles.authRight}></div>
          </div>
        </div>
      </Auth>
    </Fragment>
  );
};

export default withRouter(translate(Login));
