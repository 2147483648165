import React, { Fragment } from "react";
import PropTypes from "prop-types";
const Spinner = (props) => {
  const { loading, className } = props;

  if (!loading) {
    return null;
  }
  return (
    <Fragment>
      <div
        className={`spinner-border spinner-border-sm ${className}`}
        role="status"
      >
        <span className="sr-only"></span>
      </div>
    </Fragment>
  );
};

Spinner.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
};
Spinner.defaultProps = {
  loading: false,
  className: "text-white",
};
export default Spinner;
