import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import reducers from "../store/reducers/index";

const Redux = createStore(
  reducers,
  compose(
    applyMiddleware(ReduxThunk),
    window.devToolsExtension
      ? window.devToolsExtension()
      : function (f) {
          return f;
        }
  )
);

export default Redux;
