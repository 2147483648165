import React, { Fragment, useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import withAuth from "../../hoc/withAuth";
import Layout from "../../layouts";
import NumberFormat from "react-number-format";
import {
  useIndex as useGetTotalPosts,
  useTotalToday as usePostDay,
} from "../../store/actions/posts";
import {
  useGetUsers as useGetTotalusers,
  useTotalToday as useUserToDay,
} from "../../store/actions/users";
import {
  useTotalContentCreator 
} from '../../store/actions/ContentCreator';

const Analytic = (props) => {
  const { t } = props;
  const pageTitle = t("analytic");
  const [totalUsers, setTotalUsers] = useState(0);
  const [userToday, setUserToday] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const [postToday, setPostToday] = useState(0);
  const [contentCreator, setContentCreator] = useState(0);

  const [getTotalUsers, { data: dataTotalUser, loading: loadingTotalUser }] =
    useGetTotalusers();
  const [
    getTotalUsersToday,
    { data: dataTotalUserToday, loading: loadingTotalUserToday },
  ] = useUserToDay();
  const [getTotalPosts, { data: dataTotalPost, loading: loadingTotalPost }] =
    useGetTotalPosts();
  const [
    getTotalPostToday,
    { data: dataTotalPostToday, loading: loadingTotalPostToday },
  ] = usePostDay();
  const [
    getContentCreator,
    { data: dataContentCreator, loading: loadingContentCreator },
  ] = useTotalContentCreator();
  

  const loadTotalUser = async () => {
    await getTotalUsers(0, 10, "");
  };
  const loadTotalPost = async () => {
    await getTotalPosts(0, 10, "");
  };
  const loadTotalPostToday = async () => {
    await getTotalPostToday();
  };
  const loadTotalUserToday = async () => {
    await getTotalUsersToday();
  };
  const loadContentCreator = async () => {
    await getContentCreator();
  };

  useEffect(() => {
    loadTotalUser();
    loadTotalUserToday();
    loadTotalPost();
    loadTotalPostToday();
    loadContentCreator();
  }, []);

  useEffect(() => {
    if (dataTotalUser && dataTotalUser.total) {
      setTotalUsers(dataTotalUser.total);
    }
  }, [dataTotalUser]);
  
  useEffect(() => {
    if (dataTotalUserToday && dataTotalUserToday.total) {
      setUserToday(dataTotalUserToday.total);
    }
  }, [dataTotalUserToday]);

  useEffect(() => {
    if (dataTotalPost && dataTotalPost.total) {
      setTotalPosts(dataTotalPost.total);
    }
  }, [dataTotalPost]);

  useEffect(() => {
    if (dataTotalPostToday && dataTotalPostToday.total) {
      setPostToday(dataTotalPostToday.total);
    }
  }, [dataTotalPostToday]);

  useEffect(() => {
    if (dataContentCreator && dataContentCreator.total) {
      setContentCreator(dataContentCreator.total);
 
    }
  }, [dataContentCreator]);

  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="row">
        <div className="col-6 col-lg-3 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div>
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">
                    {t("total")} {t("gender")}
                  </h6>
                  {loadingTotalUser ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                  {!loadingTotalUser ? (
                    <NumberFormat
                      className="h6"
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={Number(totalUsers).toFixed(0)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-3 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  <div className="stats-icon blue">
                    <i className="bi bi-people"></i>
                  </div>
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">
                    {t("users")} {t("today")}
                  </h6>
                  {loadingTotalUserToday ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                  {!loadingTotalUserToday ? (
                    <NumberFormat
                      className="h6"
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={Number(userToday).toFixed(0)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-3 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  <div className="stats-icon green">
                    <i className="bi bi-files"></i>
                  </div>
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">
                    {t("total")} {t("post")}
                  </h6>
                  {loadingTotalPost ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                  {!loadingTotalPost ? (
                    <NumberFormat
                      className="h6"
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={Number(totalPosts).toFixed(0)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-3 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  <div className="stats-icon blue">
                    <i className="bi bi-files-alt"></i>
                  </div>
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">
                    {t("post")} {t("today")}
                  </h6>
                  {loadingTotalPostToday ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                  {!loadingTotalPostToday ? (
                    <NumberFormat
                      className="h6"
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={Number(postToday).toFixed(0)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-3 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  <div className="stats-icon green">
                    <i className="bi bi-files"></i>
                  </div>
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">
                    Konten Kreator
                  </h6>
                  {loadingContentCreator ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                  {!loadingContentCreator ? (
                    <NumberFormat
                      className="h6"
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={Number(contentCreator).toFixed(0)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withAuth(translate(Analytic))([]);
