import axios from "axios";
import { useApiHandler } from "./index";

const dataForm = new FormData();

const server = axios.create({
  baseURL: process.env.REACT_APP_API_URL || null,
});

export const login = async (data) => {
  const dataForm = new FormData();
  dataForm.append("email", data.email);
  dataForm.append("password", data.password);
  const posts = await server.post("/auth/login", dataForm);
  return posts;
};

export const useLogin = () => {
  return useApiHandler(login);
};

export const refreshToken = async () => {
  const dataForm = new FormData();
  const userData = localStorage.getItem(process.env.REACT_APP_STORAGE_NAME);
  let tokenDataRefresh = null;
  let tokenData = null;
  if (userData) {
    const transformedData = JSON.parse(userData);
    const { data, token } = transformedData;
    tokenDataRefresh = data.refreshToken;
    tokenData = token;
    dataForm.append("token", tokenDataRefresh);
  }

  const response = await server.post(`/auth/token`, dataForm, {
    headers: {
      Authorization: "Bearer " + tokenData,
    },
  });

  const dataRespon = response.data.data;

  const dataStorageSave = { data: dataRespon, token: dataRespon.token };
  localStorage.setItem(
    process.env.REACT_APP_STORAGE_NAME,
    JSON.stringify(dataStorageSave)
  );
  // localStorage.setItem(
  //   process.env.REACT_APP_STORAGE_NAME,
  //   JSON.stringify(dataRespon)
  // );
};
