import React, { Fragment } from "react";
import styles from "./styles/auth.module.scss";

const Auth = (props) => {
  const { children } = props;

  return (
    <Fragment>
      <div className={styles.auth}>{children}</div>
    </Fragment>
  );
};

export default Auth;
