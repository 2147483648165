import { useApiHandler, fetcher } from "./index";
import useSWR from "swr";
import Api from "../connection";
import qs from "qs";

const dataForm = new FormData();

export const index = async (offset, size, search) => {
  const posts = await Api.get(
    `/jenispost?all=all&offset=${offset}&limit=${size}&search=${search}`
  );
  return posts;
};
export const shows = async (id) => {
  const posts = await Api.get(`/jenispost/${id}`);
  return posts;
};
export const deletes = async (id) => {
  const posts = await Api.delete(`/jenispost/${id}`);
  return posts;
};
export const creates = async (data) => {
  const dataForm = new FormData();
  dataForm.append("jenis", data.jenis);
  dataForm.append("type", data.type);
  dataForm.append("point", data.point);
  dataForm.append("min", data.minChar);
  dataForm.append("max", data.maxChar);
  dataForm.append("duration", data.duration);
  dataForm.append("urutan", data.urutan);
  const posts = await Api.post(`/jenispost`, dataForm);

  return posts;
};

export const updates = async (id, data) => {
  let dataForm = {
    jenis: data.jenis,
    type: data.type,
    point: data.point,
    min: data.minChar,
    max: data.maxChar,
    duration: data.duration,
    urutan: data.urutan
  };
  const datas = qs.stringify(dataForm);
  const posts = await Api.patch(`/jenispost/${id}`, datas);
  return posts;
};

export const useIndex = () => {
  return useApiHandler(index);
};
export const useShow = () => {
  return useApiHandler(shows);
};
export const useDelete = () => {
  return useApiHandler(deletes);
};
export const useCreate = () => {
  return useApiHandler(creates);
};
export const useUpdate = () => {
  return useApiHandler(updates);
};
