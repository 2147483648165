import { useApiHandler, fetcher } from "./index";
import useSWR from "swr";
import Api from "../connection";
import qs from "qs";

const dataForm = new FormData();

export const rewardCategory = async () => {
  const posts = await Api.get(`/rewardcategory`);
  return posts;
};

export const allRewards = async () => {
    const posts = await Api.get(`/rewards`);
    return posts;
  };

export const index = async (offset, size, search) => {
  const posts = await Api.get(
    `/claimrequest?offset=${offset}&limit=${size}&search=${search}`
  );
  return posts;
};
export const approve = async (id) => {
    const posts = await Api.post(`/claimrequest-accept/${id}`);
    return posts;
  };
  
  export const reject = async (id) => {
    const posts = await Api.post(`/claimrequest-deny/${id}`);
    return posts;
  };


export const useIndex = () => {
  return useApiHandler(index);
};
export const useAprrove = () => {
    return useApiHandler(approve);
    
  };export const useReject = () => {
    return useApiHandler(reject);
  };
  