import { useApiHandler, fetcher } from "./index";
import useSWR from "swr";
import Api from "../connection";
import qs from "qs";

const dataForm = new FormData();

export const index = async (offset, size, search) => {
  const posts = await Api.get(
    `/rewardcategory?offset=${offset}&limit=${size}&search=${search}`
  );
  return posts;
};
export const shows = async (id) => {
  const posts = await Api.get(`/rewardcategory/${id}`);
  return posts;
};
export const deletes = async (id) => {
  const posts = await Api.delete(`/rewardcategory/${id}`);
  return posts;
};
export const creates = async (data) => {
  const dataForm = new FormData();
  dataForm.append("name", data.name);

  const posts = await Api.post(`/rewardcategory`, dataForm);
  return posts;
};
export const updates = async (id, data) => {
  let dataForm = {
    name: data.name,
  };
  const datas = qs.stringify(dataForm);
  const posts = await Api.patch(`/rewardcategory/${id}`, datas);
  return posts;
};

export const useIndex = () => {
  return useApiHandler(index);
};
export const useShow = () => {
  return useApiHandler(shows);
};
export const useDelete = () => {
  return useApiHandler(deletes);
};
export const useCreate = () => {
  return useApiHandler(creates);
};
export const useUpdate = () => {
  return useApiHandler(updates);
};
