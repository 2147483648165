import useSWR from "swr";
import { fetcher } from "./index";

export const useGetUser = () => {
  let me = "0";
  const userData = localStorage.getItem(process.env.REACT_APP_STORAGE_NAME);
  if (userData) {
    const transformedData = JSON.parse(userData);
    const { data } = transformedData;
    me = data.user_id;
  }
  const { data, error, ...rest } = useSWR(`/users/${me}`, fetcher);
  return { data, error, loading: !data && !error, ...rest };
};
