import React, { Fragment, useState, useEffect, useCallback } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import withAuth from "../../hoc/withAuth";
import Layout from "../../layouts";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import DataTable from "react-data-table-component";
import { Loader } from "../../components";
import { useGetAds, useDetailAds, useDeleteAds } from "../../store/actions/Ads";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Card, Image } from "react-bootstrap";

const Banner = (props) => {
  const { t } = props;
  const pageTitle = t("ads");
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [listData, setListData] = useState([]);
  const [totalListData, setTotalListData] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedData, setSelectedData] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [offPage, setOffPage] = useState(0);
  const {
    register: registerSearch,
    handleSubmit: handleSearch,
    reset: resetSearchForm,
  } = useForm();
  const [selectedDataShow, setSelectedDataShow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [detailSelected, setDetailSelected] = useState(null);

  const tableColumns = [
    {
      name: "#",
      cell: (row, index) => {
        return offPage + index + 1;
      },
      sortable: true,
      center: true,
      width: "10%",
    },
    {
      name: t("title"),
      selector: "title",
      sortable: true,
      center: false,
    },

    {
      name: t("option"),
      cell: (row) => rowOption(row),
      center: false,
    },
  ];

  const rowOption = (row) => {
    return (
      <Fragment>
        <button
          disabled={loadingDelete}
          onClick={() => deleteUserHandler(row.id)}
          className="btn btn-outline-danger btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-trash-fill"></i>
          </span>
        </button>
        <Link
          to={`${process.env.PUBLIC_URL}/ads/${row.id}`}
          className="btn-outline-primary btn  btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-pencil-square"></i>
          </span>
        </Link>
        <button
          onClick={() => viewDetail(row)}
          className="btn btn-outline-info btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-eye-fill"></i>
          </span>
        </button>
      </Fragment>
    );
  };

  const modalToggle = () => setShowModal(!showModal);

  const viewDetail = (data) => {
    setShowModal(true);
    setSelectedDataShow(data);
    loadDetail(data.id);
  };

  const [getAds, { data: dataAds, loading: loadingAds }] = useGetAds();

  const [
    getDetail,
    { data: dataDetail, error: errorDetail, loading: loadingDetail },
  ] = useDetailAds();

    const [deleteAds, { data: dataDelete, loading: loadingDelete }] =
    useDeleteAds();

  const refreshSearchHandler = () => {
    setSearchText("");
    resetSearchForm();
    loadAds(1, perPage, "");
    setIsSearch(false);
  };

  const searchAction = async (data) => {
    setSearchText(data.search);
    // setParams({ ...params, search: data.search });
    loadAds(1, perPage, data.search);
    setIsSearch(true);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    loadAds(page, newPerPage);
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    loadAds(page);
    setCurrentPage(page);
  };

  const loadDetail = async (id) => {
    await getDetail(id);
  };

  const loadAds = async (page = 1, size = perPage, search = searchText) => {
    let offset = (Number(page) - 1) * Number(size);
    setOffPage(offset);
    await getAds(offset, size, search);
  };

  const deleteHandler = async (id) => {
    await deleteAds(id);
  };
  const deleteUserHandler = async (id) => {
    Swal.fire({
      title: t("confirmAction"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#05b1a1",
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSelectedData(id);
        await deleteHandler(id);
      }
    });
  };
  useEffect(() => {
    loadAds();
  }, []);

  useEffect(() => {
    if (dataAds && dataAds.data) {
      const dataResults = Object.values(dataAds.data).sort(
        (a, b) => b.id - a.id
      );
      setListData(dataResults);
      setTotalListData(dataAds.total);
      // console.log("topic", dataResults)
    }
  }, [dataAds]);

  useEffect(() => {
    if (dataDetail && dataDetail.data) {
      setDetailSelected(dataDetail.data);
      // console.log("bbb",dataDetail.data.photo)
    }
  }, [dataDetail]);

  useEffect(() => {
    if (dataDelete && dataDelete.status === 200) {
      const newData = listData.filter((item) => {
        return item.id !== selectedData;
      });
      setListData(newData);
      setTotalListData(totalListData - 1);
    }
  }, [dataDelete]);

  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Modal isOpen={showModal} toggle={modalToggle} size="lg">
        <ModalHeader toggle={modalToggle}>{t("detail")}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <label>{t("title")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow.title && (
                    <label className="text-danger">
                      {detailSelected && detailSelected.title && (
                        <label className="text-success">
                          {detailSelected.title}{" "}
                        </label>
                      )}
                    </label>
                  )}
                </div>
              </div>

              <div className="col-12">
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <label>{t("image")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
              </div>
              <div className="row">
                <div className="col-8 mt-5 mx-auto">
                  <div className={`border p-3 my-2 rounded`}>
                    <div className="row text-center">
                    {selectedDataShow && selectedDataShow.photo && (
                    <Card>
                      {detailSelected && detailSelected.photo && (
                        <Image src={`${detailSelected.photo}`} />
                      )}
                    </Card>
                  )}
                   
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <hr />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={modalToggle}>
            {t("close")}
          </button>
        </ModalFooter>
      </Modal>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2 flex-row-reverse">
                <div className="col-sm-12 col-md-3">
                  <form
                    onSubmit={handleSearch(searchAction)}
                    className="form-inline theme-form "
                    noValidate=""
                  >
                    <div className="form-group ml-auto mr-3">
                      <div className="input-group">
                        <input
                          ref={registerSearch}
                          className="form-control"
                          type="text"
                          name="search"
                          placeholder={t("search")}
                          autoComplete="off"
                        />
                        {!isSearch && (
                          <button
                            type="submit"
                            className="btn btn-primary px-3"
                          >
                            <i className="bi bi-search" aria-hidden="true"></i>
                          </button>
                        )}
                        {isSearch && (
                          <button
                            onClick={refreshSearchHandler}
                            type="button"
                            className="btn btn-danger ml-1 px-3"
                          >
                            <i className="bi bi-x" aria-hidden="true"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-sm-12 col-md-9">
                  <Link
                    to={`${process.env.PUBLIC_URL}/ads/new`}
                    className="btn btn-primary"
                  >
                    <i className="fa fa-user-plus mr-2" aria-hidden="true"></i>
                    {t("new", { name: t("advertisment") })}
                  </Link>
                </div>
              </div>
              {/* table */}
              <DataTable
                data={listData}
                columns={tableColumns}
                progressPending={loadingAds}
                subHeader={false}
                fixedHeader={true}
                center={true}
                persistTableHead
                highlightOnHover={true}
                pagination
                paginationServer={true}
                noHeader={true}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={(val) => handlePageChange(val)}
                paginationTotalRows={totalListData}
                progressComponent={
                  <div className="d-block my-5">
                    <Loader loading />
                  </div>
                }
                noDataComponent={<div className="my-3">{t("emptyTable")}</div>}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withAuth(translate(Banner))(["admin"]);
