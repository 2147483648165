import React, { Fragment, useState, useEffect, useCallback } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Layout from "../../layouts";
import withAuth from "../../hoc/withAuth";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import {
  useGetUsers,
  useDeleteUsers,
  useActivateUsers,
  useDetailUser,
} from "../../store/actions/users";
import {
  useIndexAddPoint,
  useDeletePoint,
} from "../../store/actions/addPoint";
import DataTable from "react-data-table-component";
import { Loader } from "../../components";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import * as AiIcons from "react-icons/ai";
import { filter } from "htmlparser2/node_modules/domutils";

const AddPoint = (props) => {
  const { t } = props;
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [listUsers, setListUsers] = useState([]);
  const [totalListUsers, setTotalListUsers] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedUser, setSelectedUser] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [offPage, setOffPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedDataShow, setSelectedDataShow] = useState(null);
  const [contentCreator, setContentCreator] = useState([]);
  const [detailSelected, setDetailSelected] = useState(null);

  const tableColumns = [
    {
      name: "#",
      cell: (row, index) => {
        return offPage + index + 1;
      },
      sortable: true,
      center: true,
      width: "10%",
    },
    {
      name: "User Id",
      selector: "user",
      sortable: true,
      center: false,
    },
    ,
    {
      name: t("date"),
      selector: "postDate",
      sortable: true,
      center: true,
    },
    {
      name: t("type"),
      selector: "tipe",
      sortable: true,
      width: "20%",
      center: true,
    },
    {
      name: t("point"),
      selector: "point",
      sortable: false,
      center: true,
      width: "10%",
    },
    {
      name: t("option"),
      cell: (row) => (row.id >= "1" ? rowOption(row) : null),
      center: false,
    },
  ];

  const rowOption = (row) => {
    const stat = row.status !== "0" ? true : false;
    return (
      <Fragment>
        <button
          disabled={loadingDelete}
          onClick={() => deleteUserHandler(row.id)}
          className="btn btn-outline-danger btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-trash-fill"></i>
          </span>
        </button>

        <Link
          to={`${process.env.PUBLIC_URL}/addpoint/${row.id}`}
          className="btn-outline-primary btn  btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-pencil-square"></i>
          </span>
        </Link>

        <ToastContainer />
      </Fragment>
    );
  };

  const deleteUserHandler = useCallback(async (id) => {
    Swal.fire({
      title: t("confirmAction"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#05b1a1",
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSelectedUser(id);
        await deleteAction(id);
      }
    });
  }, []);

  const [
    detailData,
    { data: datadetail, loading: loadingdetail, error: errordetail },
  ] = useDetailUser();

  const loadDetail = async (id) => {
    await detailData(id);
  };

  // useEffect(() => {
  //   if (userId) {
  //     loadDetailUser(userId);
  //   }
  // }, [])

  const activeUserHandler = useCallback(async (id) => {
    Swal.fire({
      title: t("confirmAction"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#05b1a1",
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSelectedUser(id);
        await activateAction(id);
      }
    });
  }, []);

  const {
    register: registerSearch,
    handleSubmit: handleSearch,
    reset: resetSearchForm,
  } = useForm();

  const [deleteAction, { data: dataDelete, loading: loadingDelete }] =
  useDeletePoint();

  const [activateAction, { data: dataActivate, loading: loadingActivate }] =
    useActivateUsers();

  const [usersAction, { data: dataUsers, loading: loadingUsers }] =
    useIndexAddPoint();

  useEffect(() => {
    if (datadetail && datadetail.data) {
      setDetailSelected(datadetail.data);
    }
  }, [datadetail]);

  const viewDetail = (data) => {
    setShowModal(true);
    setSelectedDataShow(data);
    loadDetail(data.id);
  };

  const modalToggle = () => setShowModal(!showModal);

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    if (dataUsers && dataUsers.data) {
      const dataResults = Object.values(dataUsers.data).sort((a, b) => b.id - a.id);
      setListUsers(dataResults);
      setTotalListUsers(dataUsers.total);
    }
  }, [dataUsers]);

  useEffect(() => {
    if (dataActivate && dataActivate.status === 200) {
      const newData = listUsers.map((item) => {
        let newStatus = item.status;
        if (item.id === selectedUser) {
          if (item.status === "1") {
            newStatus = "0";
          } else {
            newStatus = "1";
          }
        }
        return { ...item, status: newStatus };
      });

      setListUsers(newData);
    }
  }, [dataActivate]);

  useEffect(() => {
    if (dataDelete && dataDelete.status === 200) {
      const newData = listUsers.filter((item) => {
        return item.id !== selectedUser;
      });
      setListUsers(newData);
      setTotalListUsers(totalListUsers - 1);
    }
  }, [dataDelete]);

  const refreshSearchHandler = () => {
    setSearchText("");
    resetSearchForm();
    loadUsers(1, perPage, "");
    setIsSearch(false);
  };

  const searchAction = async (data) => {
    setSearchText(data.search);
    // setParams({ ...params, search: data.search });
    loadUsers(1, perPage, data.search);
    setIsSearch(true);
  };

  const loadUsers = async (page = 1, size = perPage, search = searchText) => {
    let offset = (Number(page) - 1) * Number(size);
    setOffPage(offset);
    await usersAction(offset, size, search);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    loadUsers(page, newPerPage);
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    loadUsers(page);
    setCurrentPage(page);
  };

  return (
    <Layout {...props}>
      <Helmet>
        <title>Riwayat Tambah Poin</title>
      </Helmet>
      <Modal isOpen={showModal} toggle={modalToggle} size="lg">
        <ModalHeader toggle={modalToggle}>{t("detail")}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <label>{t("name")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow.name && (
                    <label>{selectedDataShow.name}</label>
                  )}
                </div>
              </div>

              <div className="col-12">
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <label>{t("phone")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow.phone && (
                    <label className="text-success">
                      {selectedDataShow.phone}
                    </label>
                  )}
                </div>
              </div>

              <div className="col-12">
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <label>{t("email")}</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow.email && (
                    <label className="text-success">
                      {selectedDataShow.email}
                    </label>
                  )}
                </div>
              </div>
              <div className="col-12">
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <label>Alamat</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow.address && (
                    <label className="text-success">
                      {selectedDataShow.address}
                    </label>
                  )}
                </div>
              </div>
              <div className="col-12">
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <label>No.Rekening</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow.bank_name && (
                    <label className="text-success">
                      {selectedDataShow.bank_name}
                    </label>
                  )}
                </div>
              </div>
              <div className="col-12">
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <label>No.Rekening</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow.bank_account_number && (
                    <label className="text-success">
                      {selectedDataShow.bank_account_number}
                    </label>
                  )}
                </div>
              </div>
              <div className="col-12">
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <label>POP Merchant</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow.name && (
                    <label className="text-success">
                      {selectedDataShow.name}
                    </label>
                  )}
                </div>
              </div>
              <div className="col-12">
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <label>POP Cash</label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-8">
                  {selectedDataShow && selectedDataShow.id && (
                    <label className="text-success">
                      {selectedDataShow.id}
                    </label>
                  )}
                </div>
              </div>
              <div className="col-12">
                <hr />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {selectedDataShow && selectedDataShow.email && (
            <a
              href={`https://link.opinia.id/profile/${selectedDataShow.id}`}
              target="_blank"
              className="btn btn-secondary"
            >
              Lihat profil
            </a>
          )}
          <button className="btn btn-secondary" onClick={modalToggle}>
            Tambah Poin
          </button>
          <button className="btn btn-secondary" onClick={modalToggle}>
            {t("close")}
          </button>
        </ModalFooter>
      </Modal>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2 flex-row-reverse">
                <div className="col-sm-12 col-md-3">
                  <form
                    onSubmit={handleSearch(searchAction)}
                    className="form-inline theme-form "
                    noValidate=""
                  >
                    <div className="form-group ml-auto mr-3">
                      <div className="input-group">
                        <input
                          ref={registerSearch}
                          className="form-control"
                          type="text"
                          name="search"
                          placeholder={t("search")}
                          autoComplete="off"
                        />
                        {!isSearch && (
                          <button
                            type="submit"
                            className="btn btn-primary px-3"
                          >
                            <i className="bi bi-search" aria-hidden="true"></i>
                          </button>
                        )}
                        {isSearch && (
                          <button
                            onClick={refreshSearchHandler}
                            type="button"
                            className="btn btn-danger ml-1 px-3"
                          >
                            <i className="bi bi-x" aria-hidden="true"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-sm-12 col-md-9">
                  <Link
                    to={`${process.env.PUBLIC_URL}/addpoint/new`}
                    className="btn btn-primary"
                  >
                    <i className="fa fa-user-plus mr-2" aria-hidden="true"></i>
                    Tambah Poin Baru
                  </Link>
                </div>
              </div>
              <DataTable
                data={listUsers}
                columns={tableColumns}
                progressPending={loadingUsers}
                subHeader={false}
                fixedHeader={true}
                center={true}
                persistTableHead
                highlightOnHover={true}
                pagination
                paginationServer={true}
                noHeader={true}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={(val) => handlePageChange(val)}
                paginationTotalRows={totalListUsers}
                progressComponent={
                  <div className="d-block my-5">
                    <Loader loading />
                  </div>
                }
                noDataComponent={<div className="my-3">{t("emptyTable")}</div>}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withAuth(translate(AddPoint))(["admin"]);
