import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import Header from "./header";
import Sidebar from "./sidebar";
import Footer from "./footer";

const Layouts = (props) => {
  const { children, title } = props;
  const width = useWindowSize();

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  console.warn = () => {};
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (width < 1200) {
      document.getElementById("sidebar").classList.remove("active");
    } else {
      document.getElementById("sidebar").classList.add("active");
    }
  }, [width]);

  return (
    <Fragment>
      <div id="app">
        <Sidebar {...props} />
        <div id="main" className="layout-navbar">
          <Header {...props} />
          <div id="main-content">
            {/* <div className="page-heading">
              <h3>{title}</h3>
            </div> */}
            <div className="page-content h-100">{children}</div>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Layouts;
