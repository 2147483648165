import React, { Fragment, useState, useEffect } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import withAuth from "../../hoc/withAuth";
import Layout from "../../layouts";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import DataTable from "react-data-table-component";
import { Loader } from "../../components";
import { useIndex, useDelete } from "../../store/actions/rewardCategory";

const Rewards = (props) => {
  const { t } = props;
  const pageTitle = t("category");
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [listData, setListData] = useState([]);
  const [totalListData, setTotalListData] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedData, setSelectedData] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [offPage, setOffPage] = useState(0);
  const {
    register: registerSearch,
    handleSubmit: handleSearch,
    reset: resetSearchForm,
  } = useForm();

  const [getData, { data: datas, loading: loadingData }] = useIndex();
  const [deleteData, { data: dataDelete, loading: loadingDelete }] =
    useDelete();

  const tableColumns = [
    {
      name: "#",
      cell: (row, index) => {
        return offPage + index + 1;
      },
      sortable: true,
      center: true,
      width: "10%",
    },
    {
      name: t("category"),
      selector: "name",
      sortable: true,
      center: false,
    },

    {
      name: t("option"),
      cell: (row) => rowOption(row),
      center: false,
    },
  ];

  const rowOption = (row) => {
    return (
      <Fragment>
        <button
          disabled={loadingDelete}
          onClick={() => deleteDataHandler(row.id)}
          className="btn btn-outline-danger btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-trash-fill"></i>
          </span>
        </button>
        <Link
          to={`${process.env.PUBLIC_URL}/reward-category/${row.id}`}
          className="btn-outline-primary btn  btn-sm px-2 mx-1"
        >
          <span>
            <i className="bi bi-pencil-square"></i>
          </span>
        </Link>
      </Fragment>
    );
  };

  const refreshSearchHandler = () => {
    setSearchText("");
    resetSearchForm();
    loadData(1, perPage, "");
    setIsSearch(false);
  };

  const searchAction = async (data) => {
    setSearchText(data.search);
    loadData(1, perPage, data.search);
    setIsSearch(true);
  };

  const deleteDataHandler = async (id) => {
    Swal.fire({
      title: t("confirmAction"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#05b1a1",
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSelectedData(id);
        await deleteHandler(id);
      }
    });
  };
  const loadData = async (page = 1, size = perPage, search = searchText) => {
    let offset = (Number(page) - 1) * Number(size);
    setOffPage(offset);
    await getData(offset, size, search);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    loadData(page, newPerPage);
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    loadData(page);
    setCurrentPage(page);
  };

  const deleteHandler = async (id) => {
    await deleteData(id);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (datas && datas.data) {
      const dataResults = Object.values(datas.data).sort((a, b) => b.id - a.id);
      setListData(dataResults);
      setTotalListData(datas.total);
    }
  }, [datas]);

  useEffect(() => {
    if (dataDelete && dataDelete.status === 200) {
      const newData = listData.filter((item) => {
        return item.id !== selectedData;
      });
      setListData(newData);
      setTotalListData(totalListData - 1);
    }
  }, [dataDelete]);

  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2 flex-row-reverse">
                <div className="col-sm-12 col-md-3">
                  <form
                    onSubmit={handleSearch(searchAction)}
                    className="form-inline theme-form "
                    noValidate=""
                  >
                    <div className="form-group ml-auto mr-3">
                      <div className="input-group">
                        <input
                          ref={registerSearch}
                          className="form-control"
                          type="text"
                          name="search"
                          placeholder={t("search")}
                          autoComplete="off"
                        />
                        {!isSearch && (
                          <button
                            type="submit"
                            className="btn btn-primary px-3"
                          >
                            <i className="bi bi-search" aria-hidden="true"></i>
                          </button>
                        )}
                        {isSearch && (
                          <button
                            onClick={refreshSearchHandler}
                            type="button"
                            className="btn btn-danger ml-1 px-3"
                          >
                            <i className="bi bi-x" aria-hidden="true"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-sm-12 col-md-9">
                  <Link
                    to={`${process.env.PUBLIC_URL}/reward-category/new`}
                    className="btn btn-primary"
                  >
                    <i className="fa fa-user-plus mr-2" aria-hidden="true"></i>
                    {t("new", { name: t("category") })}
                  </Link>
                </div>
              </div>
              <DataTable
                data={listData}
                columns={tableColumns}
                progressPending={loadingData}
                subHeader={false}
                fixedHeader={true}
                center={true}
                persistTableHead
                highlightOnHover={true}
                pagination
                paginationServer={true}
                noHeader={true}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={(val) => handlePageChange(val)}
                paginationTotalRows={totalListData}
                progressComponent={
                  <div className="d-block my-5">
                    <Loader loading />
                  </div>
                }
                noDataComponent={<div className="my-3">{t("emptyTable")}</div>}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withAuth(translate(Rewards))(["admin"]);
