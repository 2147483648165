import { refreshToken } from "./actions/AuthAction";
const axios = require("axios");

const Instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || null,
});

// / Request interceptor for API calls
Instance.interceptors.request.use(
  async (config) => {
    const userData = localStorage.getItem(process.env.REACT_APP_STORAGE_NAME);
    const langUser =
      localStorage.getItem("lang") || process.env.REACT_APP_LANGUAGE;
    let tokenData = null;
    if (userData) {
      const transformedData = JSON.parse(userData);
      const { token } = transformedData;
      tokenData = "Bearer " + token;
    }
    config.headers = {
      locale: langUser,
      Authorization: tokenData,
      "Content-Type": "text/plain"
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
Instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await refreshToken();
      return Instance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default Instance;
