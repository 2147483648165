import React, { useState, useEffect, useCallback } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import { Loader } from "../../components";

import { useAddPoint,useUpdatePoint,useDetailPoint } from "../../store/actions/addPoint";
import Layout from "../../layouts";
import withAuth from "../../hoc/withAuth";
import { useForm } from "react-hook-form";
import { Spinner } from "../../components";
import { useParams } from "react-router-dom";

const FormPoint = (props) => {
  const { t } = props;
  const { id: userId } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [errorLoad, setErrorLoad] = useState(false);
  const [selectedType, setSelectedType] = useState("");


  const {
    register: registerUser,
    handleSubmit: handleUser,
    setValue: setUserForm,
    reset: resetUserForm,
  } = useForm();

  const [
    newUserAction,
    { data: dataNew, loading: loadingNew, error: errorNew },
  ] = useAddPoint();

  const [
    updateUserAction,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useUpdatePoint();

  const [
    detailAction,
    { data: dataUser, loading: loadingUser, error: errorUser },
  ] = useDetailPoint();

  const loadDetailUser = async (id = userId) => {
    await detailAction(id);
  };
  useEffect(() => {
    if (userId) {
      loadDetailUser(userId);
    }
  }, []);

  useEffect(() => {
    registerUser({ name: "type" });
  }, [registerUser]);

  useEffect(() => {
    if (dataNew) {
      resetUserForm();
    }
  }, [dataNew]);

  useEffect(() => {
    if (userId && userId !== "1") {
      setLoadingData(loadingUser);
    }
  }, [loadingUser]);

  useEffect(() => {
    if (userId && errorUser) {
      setErrorLoad(true);
    }
  }, [errorUser]);

  const submitHandler = async (data) => {
    if (userId) {
      await updateUserAction(userId, data);
    } else {
      await newUserAction(data);
    }
  };

  const typeChange = useCallback(
    (event) => {
      setSelectedType(event.target.value);
      setUserForm("type", event.target.value);
    },
    [selectedType]
  );

  return (
    <Layout {...props}>
      <Helmet>
        <title>{userId ? "Edit Poin " : "Tambah Poin "}</title>
      </Helmet>
      <div className="row">
        {userId && userId === "1" && (
          <div className="alert alert-danger dark" role="alert">
            {t("restricted")}
          </div>
        )}
        {userId && errorUser && (
          <div className="alert alert-danger dark" role="alert">
            {errorUser}
          </div>
        )}
        <div className="col-12">
          <Loader loading={loadingData} />
        </div>
        {!loadingData && !errorLoad && userId !== "1" && (
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="card-header">
                <h4>{t("point")}</h4>
              </div>
              <div className="card-body">
                {errorNew && typeof errorNew === "string" && (
                  <div className="alert alert-danger dark" role="alert">
                    {errorNew}
                  </div>
                )}
                {errorUpdate && typeof errorUpdate === "string" && (
                  <div className="alert alert-danger dark" role="alert">
                    {errorUpdate}
                  </div>
                )}
                {dataNew && typeof dataNew.message === "string" && (
                  <div className="alert alert-success dark" role="alert">
                    {dataNew.message}
                  </div>
                )}
                {dataUpdate && typeof dataUpdate.message === "string" && (
                  <div className="alert alert-success dark" role="alert">
                    {dataUpdate.message}
                  </div>
                )}
                <form
                  action="#"
                  method="post"
                  onSubmit={handleUser(submitHandler)}
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>ID Pengguna</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerUser}
                          className="form-control"
                          name="user"
                          type="number"
                          required
                          defaultValue={
                            userId && dataUser && dataUser.data
                              ? dataUser.data.user
                              : ""
                          }
                          placeholder="Id Pengguna"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("type")}</label>
                        <span className="text-danger">(*)</span>
                        <select
                          name="tipe"
                          value={selectedType}
                          onChange={typeChange}
                          required
                          className="form-control"
                        >
                          <option value="" selected disabled>
                            {t("select", { name: t("type") })}
                          </option>
                          <option value="moneter">POP Cash</option>
                          <option value="non moneter">POP Merchant</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("point")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerUser}
                          className="form-control"
                          name="point"
                          type="number"
                          defaultValue={
                            userId && dataUser && dataUser.data
                              ? dataUser.data.point
                              : ""
                          }
                          placeholder={t("point")}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("description")}</label>
                        <span className="text-danger">(*)</span>

                        <input
                          ref={registerUser}
                          className="form-control"
                          name="description"
                          type="text"
                          required={userId ? false : true}
                          placeholder={t("description")}
                          defaultValue={
                            userId && dataUser && dataUser.data
                              ? dataUser.data.description
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button
                        disabled={loadingNew || loadingUpdate}
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        <Spinner loading={loadingNew || loadingUpdate} />
                        {!loadingNew &&
                          !loadingUpdate &&
                          t(userId ? "update" : "add", { name: t("point") })}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default withAuth(translate(FormPoint))(["admin"]);
