import * as Action from "../actionTypes";

const initialState = {
  isLogin: false,
  userId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.AUTHENTICATE:
      const data = action.payload;
      return {
        ...state,
        userId: data.userId,
        isLogin: data.isLogin,
      };

    case Action.lOGOUT:
      return {
        ...state,
        userId: null,
        isLogin: false,
      };
    default:
      return state;
  }
};
