import React, { Fragment, useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import withAuth from "../../../hoc/withAuth";
import Layout from "../../../layouts";
import NumberFormat from "react-number-format";
import {
  useGetInterest,
  useGetAdvancedHobby,
} from "../../../store/actions/analytic";
import { Chart } from "react-chartjs-2";
import "chart.js/auto";
import { Button, Container, Form, Modal, Table } from "react-bootstrap";

const InterestAnalytic = (props) => {
  const { t } = props;
  const pageTitle = t("interest");
  // hobby
  const [dataHobby, setDataHobby] = useState([]);
  const [hobbyLabels, setHobbyLabels] = useState(null);
  const [hobbyValue, setHobbyValue] = useState(null);
  // topik diikuti
  const [dataTopikDiikuti, setDataTopikDiikuti] = useState([]);
  const [topikDiikutiLabels, setTopikDiikutiLabels] = useState(null);
  const [topikDiikutiValue, setTopikDiikutiValue] = useState(null);
  // jenis postingan
  const [dataJenis, setDataJenis] = useState([]);
  const [jenislabels, setJenisLabels] = useState(null);
  const [jenisValues, setJenisValues] = useState(null);
  // mengisi bio
  const [dataBio, setDataBio] = useState([]);
  const [bioLabels, setBioLabels] = useState(null);
  const [bioValues, setBioValues] = useState(null);
  // menggunakan foto profil
  const [dataProfile, setDataProfile] = useState([]);
  const [profileLabels, setProfileLabels] = useState(null);
  const [profileValues, setProfileValues] = useState(null);
  //   data artikel
  const [dataArtikel, setDataArtikel] = useState([]);
  const [artikelLabels, setArtikelLabels] = useState(null);
  const [artikelValues, setArtikelValues] = useState(null);
  //   data poling
  const [dataPoling, setDataPoling] = useState([]);
  const [polingLabels, setPolingLabels] = useState(null);
  const [polingValues, setPolingValues] = useState(null);
  //   data show
  const [dataShow, setDataShow] = useState([]);
  const [showLabels, setShowLabels] = useState(null);
  const [showValues, setShowValues] = useState(null);
  const [showCharts, setShowCharts] = useState(null);
  // tanggal
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");

  //  get interest
  const [getInterest, { data: dataInterest, loading: loadingDataInterest }] =
    useGetInterest();

  //  get interest
  const [getAdvHobby, { data: dataAdvHobby, loading: loadingDataAdvHobby }] =
    useGetAdvancedHobby();

  const handleDate = () => {
    // console.log(new Date())
    loadDataInterest(startDate, finishDate);
    // console.log(startDate,finishDate)
  };

  const loadDataInterest = async () => {
    await getInterest(startDate, finishDate);
  };

  const loadDataAdvHobby = async () => {
    await getAdvHobby();
  };

  // useEffect(() => {
  // }, [dataAdvHobby])

  useEffect(() => {
    const today = new Date().toISOString().slice(0, 10);
    loadDataInterest("", today);
    loadDetailArtikel();
    loadDetailPoling();
    loadDataAdvHobby();
  }, []);

  // useEffect(() => {}, [dataAdvHobby]);

  useEffect(() => {
    if (dataInterest && dataInterest.total_user) {
      // data Hobby
      const resultsHobby = Object.values(dataInterest.hobby);
      const mapDataHobby = resultsHobby.map((item) => {
        return {
          ...item,
          hobby: item.hobby.charAt(0).toUpperCase() + item.hobby.slice(1),
        };
      });
      const hobbyLabels = mapDataHobby.map(({ hobby }) => hobby);
      const hobbyTotal = mapDataHobby.map(({ total }) => total);
      setHobbyLabels(hobbyLabels);
      setHobbyValue(hobbyTotal);
      setDataHobby(mapDataHobby);

      //   data Topik Diikuti
      const resultsTopikDiikuti = Object.values(dataInterest.interest);
      const topicLabels = resultsTopikDiikuti.map(({ topic }) =>
        topic.length < 11 ? topic : topic.slice(0, 11)
      );
      const topicTotal = resultsTopikDiikuti.map(({ total }) => total);
      setTopikDiikutiLabels(topicLabels);
      setTopikDiikutiValue(topicTotal);
      setDataTopikDiikuti(resultsTopikDiikuti);

      // data Jenis Postingan
      const resultsJenisPostingan = Object.values(dataInterest.jenis_postingan);
      const mapDataJenisPostingan = resultsJenisPostingan.map((item) => {
        return {
          ...item,
          jenis: item.jenis.charAt(0).toUpperCase() + item.jenis.slice(1),
        };
      });
      const jenisLabels = mapDataJenisPostingan.map(({ jenis }) => jenis);
      const jenisTotal = mapDataJenisPostingan.map(({ total }) => total);

      setJenisLabels(jenisLabels);
      setJenisValues(jenisTotal);
      setDataJenis(mapDataJenisPostingan);

      // data Bio
      const resultsBio = Object.values(dataInterest.bio);
      const mapDataBio = resultsBio.map((item) => {
        return {
          ...item,
          bio: item.bio === "bio" ? "Bio Terisi" : "Bio Kosong",
        };
      });
      const bioLabels = mapDataBio.map(({ bio }) => bio);
      const bioTotal = mapDataBio.map(({ total }) => total);
      setBioLabels(bioLabels);
      setBioValues(bioTotal);
      setDataBio(mapDataBio);

      // data profil
      const resultsProfil = Object.values(dataInterest.profil);
      const mapDataProfil = resultsProfil.map((item) => {
        return {
          ...item,
          profil:
            item.profil === "profil"
              ? "Foto Profil Terisi"
              : "Foto Profil Kosong",
        };
      });
      const profilLabels = mapDataProfil.map(({ profil }) => profil);
      const profilTotal = mapDataProfil.map(({ total }) => total);
      setProfileLabels(profilLabels);
      setProfileValues(profilTotal);
      setDataProfile(mapDataProfil);

      // data artikel
      const resultsArtikel = dataInterest.jenis_postingan;
      const dataArtikel = Object.values(resultsArtikel[1].detail);
      const artikelLabels = dataArtikel.map(({ type }) => type);
      const artikelTotal = dataArtikel.map(
        ({ total_per_type }) => total_per_type
      );
      setArtikelLabels(artikelLabels);
      setArtikelValues(artikelTotal);
      setDataArtikel(dataArtikel);

      // data poling
      const resultsPoling = dataInterest.jenis_postingan;
      const dataPoling = Object.values(resultsPoling[2].detail);
      const polingLabels = dataPoling.map(({ type }) => type);
      const polingTotal = dataPoling.map(
        ({ total_per_type }) => total_per_type
      );
      setPolingLabels(polingLabels);
      setPolingValues(polingTotal);
      setDataPoling(dataPoling);
    }
  }, [dataInterest]);

  const colorIndex = [
    "#A52A2A",
    "#DEB887",
    "#5F9EA0",
    "#7FFF00",
    "#D2691E",
    "#6495ED",
    "#DC143C",
  ];

  const dataChartHobby = {
    labels: hobbyLabels,
    datasets: [
      {
        label: "Hobi",
        data: hobbyValue,
        backgroundColor: colorIndex,
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const dataChartTopic = {
    labels: topikDiikutiLabels,
    datasets: [
      {
        label: "Topik Diikuti",
        data: topikDiikutiValue,
        backgroundColor: colorIndex,
        borderColor: ["black"],
        width: 10,
      },
    ],
  };

  const dataChartJenis = {
    labels: jenislabels,
    datasets: [
      {
        label: "Jenis Postingan",
        data: jenisValues,
        backgroundColor: colorIndex,
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const dataChartBio = {
    labels: bioLabels,
    datasets: [
      {
        label: "Bio",
        data: bioValues,
        backgroundColor: colorIndex,
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const dataChartProfil = {
    labels: profileLabels,
    datasets: [
      {
        label: "Profil",
        data: profileValues,
        backgroundColor: colorIndex,
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const dataChartArtikel = {
    labels: artikelLabels,
    datasets: [
      {
        label: "Artikel",
        data: artikelValues,
        backgroundColor: colorIndex,
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  const dataChartPoling = {
    labels: polingLabels,
    datasets: [
      {
        label: "Poling",
        data: polingValues,
        backgroundColor: colorIndex,
        borderColor: ["black"],
        borderWidth: 1,
      },
    ],
  };

  //   config for bar charts
  const barChartsConfig = {
    plugins: {
      legend: {
        labels: {
          boxWidth: 0,
        },
      },
    },
  };

  const barChartsConfigHorizontal = {
    // scales: {
    //   yAxes: [
    //     {
    //       type: "linear",
    //       display: true,
    //       position: "left",
    //       id: "y-axis-1",
    //     },
    //   ],
    // },

    // responsive:true,
  };
  //   Modal Jenis Post
  const [modalTitle, setModalTitle] = useState("");
  const [showing, setshowing] = useState(false);

  const modalShow = (data) => {
    if (data === "Artikel") {
      setshowing(!showing);
      setModalTitle(data);
      setDataShow(dataArtikel);
      setShowLabels(artikelLabels);
      setShowValues(artikelValues);
      setShowCharts(dataChartArtikel);
    } else if (data === "Polling") {
      setshowing(!showing);
      setModalTitle(data);
      setDataShow(dataPoling);
      setShowLabels(polingLabels);
      setShowValues(polingValues);
      setShowCharts(dataChartPoling);
    } else {
      setshowing(false);
    }
  };
  const modalClose = () => {
    setshowing(!showing);
    setDataShow([]);
    setShowLabels(null);
    setShowValues(null);
  };

  const loadDetailArtikel = () => {
    if (dataInterest && dataInterest.total_user) {
      const results = dataInterest.jenis_postingan;
      const data = Object.values(results[1].detail);
      setDataArtikel(data);
    }
  };

  const loadDetailPoling = () => {
    if (dataInterest && dataInterest.total_user) {
      const results = dataInterest.jenis_postingan;
      const data = Object.values(results[2].detail);
      setDataPoling(data);
    }
  };

  // Modal Hoby
  // const [showModalHobby, setshowModalHobby] = useState(initialState)
  // const handleHoby=(datas)=>{
  //   loadDataAdvHobby();
  //   // setshowing(true);
  //   const getDataHBG = Object.keys(dataAdvHobby.hobby_by_gender).map(function(key) {
  //     return dataAdvHobby.hobby_by_gender[key]});
  //   const getHobby = getDataHBG.filter((data)=>data.hobby === datas.toLowerCase());
  //   console.log(getDataHBG)
  //   console.log(getHobby)
  // }

  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Form>
        <div className="row g-3 align-items-center mb-5">
          <div className="col-auto">
            <Form.Group>
              <label>Tanggal Mulai</label>
              <input
                type="date"
                id="inputStart"
                className="form-control w-100"
                placeholder="Tanggal Mulai"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-auto">
            <label for="inputPassword6" className="col-form-label">
              -
            </label>
          </div>
          <div className="col-auto">
            <Form.Group>
              <label>Tanggal Berakhir</label>
              <input
                type="date"
                id="inputFinish"
                className="form-control w-100"
                placeholder="Tanggal Berakhir"
                value={finishDate}
                onChange={(e) => setFinishDate(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="">
            <Button onClick={handleDate}>Submit</Button>
          </div>
        </div>
      </Form>
      <div className="row">
        {/* Hobby */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card" style={{ height: "700px" }}>
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">Hobi</h6>
                  {loadingDataInterest ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <div
                style={{ width: "50vh", height: "50vh" }}
                className="mx-auto"
              >
                <Chart
                  type="doughnut"
                  data={dataChartHobby}
                  options={barChartsConfig}
                />
              </div>
              <div>
                {!loadingDataInterest ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Hoby</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataHobby.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.hobby}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
        {/* Topik diikuti */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card" style={{ height: "700px" }}>
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  {/* <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div> */}
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">Topik Diikuti</h6>
                  {loadingDataInterest ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              {dataTopikDiikuti !== "Tidak Ada Data" ? (
                <>
                  <div className="mx-auto">
                    <Chart
                      type="bar"
                      data={dataChartTopic}
                      options={barChartsConfig}
                    />
                  </div>
                  <div>
                    {!loadingDataInterest ? (
                      <div style={{ overflowY: "scroll", height: "400px" }}>
                        {/* {console.log({...dataTopikDiikuti.filter(data=>data === "Tidak Ada Data")})} */}
                        <Table
                          striped
                          bordered
                          hover
                          responsive
                          className="text-center mt-3"
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Topik Diikuti</th>
                              <th>{t("total")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataTopikDiikuti.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{data.topic}</td>
                                  <td>{data.total}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    ) : null}
                  </div>
                </>
              ) : (
                <h1>Tidak Ada</h1>
              )}

              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
        {/* Jenis Postingan */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card" style={{ height: "700px" }}>
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  {/* <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div> */}
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">Jenis Postingan</h6>
                  {loadingDataInterest ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <Chart
                type="bar"
                data={dataChartJenis}
                options={barChartsConfig}
              />
              <div>
                {!loadingDataInterest ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Jenis Postingan</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataJenis.map((data, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => modalShow(data.jenis)}
                              style={{ cursor: "pointer" }}
                            >
                              <td>{index + 1}</td>
                              <td>{data.jenis}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
        {/* Fill Bio */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card" style={{ height: "700px" }}>
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  {/* <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div> */}
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">Mengisi Bio</h6>
                  {loadingDataInterest ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <div
                style={{ width: "50vh", height: "50vh" }}
                className="mx-auto"
              >
                <Chart
                  type="doughnut"
                  data={dataChartBio}
                  options={barChartsConfig}
                />
              </div>
              <div>
                {!loadingDataInterest ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Data Bio</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataBio.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.bio}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
        {/* Use Profile Picture */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card" style={{ height: "700px" }}>
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-4">
                  {/* <div className="stats-icon green">
                    <i className="bi bi-people-fill"></i>
                  </div> */}
                </div>
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">
                    Menggunakan Foto Profil
                  </h6>
                  {loadingDataInterest ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <div
                style={{ width: "50vh", height: "50vh" }}
                className="mx-auto"
              >
                <Chart
                  type="doughnut"
                  data={dataChartProfil}
                  options={barChartsConfig}
                />
              </div>
              <div>
                {!loadingDataInterest ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Data Foto Profil</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataProfile.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.profil}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
              {/* <div className="text-center">
                <Button>Tampilan Penuh</Button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* Modal show */}
      <Modal show={showing} onHide={modalClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Chart type="bar" data={showCharts} options={barChartsConfig} />
          <div>
            {!loadingDataInterest ? (
              <>
                <Table
                  striped
                  bordered
                  hover
                  responsive
                  className="text-center mt-3"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Jenis {modalTitle}</th>
                      <th>{t("total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataShow.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.type}</td>
                          <td>{data.total_per_type}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={modalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default withAuth(translate(InterestAnalytic))([]);
