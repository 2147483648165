import React, { Fragment, useEffect, useState, useRef } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import withAuth from "../../../hoc/withAuth";
import Layout from "../../../layouts";
import NumberFormat from "react-number-format";
import {
  useGetInterest,
  useGetAdvancedHobby,
  useGetAdvancedTopic,
} from "../../../store/actions/analytic";
import { Chart } from "react-chartjs-2";
import "chart.js/auto";
import {
  Button,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import * as d3 from "d3";

const AdvancedAnalytic = (props) => {
  const { t } = props;
  const pageTitle = t("interest");
  // hobby
  const [dataHobby, setDataHobby] = useState([]);
  const [hobbyLabels, setHobbyLabels] = useState(null);
  const [hobbyValue, setHobbyValue] = useState(null);
  // adv hobby
  const [dataHBG, setDataHBG] = useState([]);
  const [dataHBA, setDataHBA] = useState([]);
  const [dataHBE, setDataHBE] = useState([]);
  const [dataHBS, setDataHBS] = useState([]);
  const [dataHBW, setDataHBW] = useState([]);
  const [dataHBP, setDataHBP] = useState([]);
  //   data show
  const [dataShow, setDataShow] = useState([]);
  const [showLabels, setShowLabels] = useState(null);
  const [showValues, setShowValues] = useState(null);
  const [showCharts, setShowCharts] = useState(null);
  // data kategori
  const [kategori, setKategori] = useState("");
  const [dataKategori, setDataKategori] = useState([
    "Jenis Kelamin",
    "Usia",
    "Pendidikan",
    "Status",
    "Pekerjaan",
    "Domisili",
  ]);
  const myRef = useRef();
  const dataTest = [10, 20, 30, 40, 50];

  //  get interest
  const [getInterest, { data: dataInterest, loading: loadingDataInterest }] =
    useGetInterest();

  //  get adv hobby
  const [getAdvHobby, { data: dataAdvHobby, loading: loadingDataAdvHobby }] =
    useGetAdvancedHobby();

  //  get adv topic
  const [getAdvTopic, { data: dataAdvTopic, loading: loadingDataAdvTopic }] =
    useGetAdvancedTopic();

  const loadDataInterest = async () => {
    await getInterest();
  };

  const loadDataAdvHobby = async () => {
    await getAdvHobby();
  };

  const loadDataAdvTopic = async () => {
    await getAdvTopic();
  };

  useEffect(() => {
    const today = new Date().toISOString().slice(0, 10);
    loadDataInterest("", today);
    loadDataAdvHobby();
    loadDataAdvTopic();
  }, []);

  useEffect(() => {
    if (dataInterest && dataInterest.total_user) {
      // data Hobby
      const resultsHobby = Object.values(dataInterest.hobby);
      const mapDataHobby = resultsHobby.map((item) => {
        return {
          ...item,
          hobby: item.hobby.charAt(0).toUpperCase() + item.hobby.slice(1),
        };
      });
      const hobbyLabels = mapDataHobby.map(({ hobby }) => hobby);
      const hobbyTotal = mapDataHobby.map(({ total }) => total);
      setHobbyLabels(hobbyLabels);
      setHobbyValue(hobbyTotal);
      // console.log(hobbyTotal);
      setDataHobby(mapDataHobby);

      // data Topic
      const resultsTopic = Object.values(dataInterest.interest);
      const mapDataTopic = resultsTopic.map((item) => {
        return {
          ...item,
          topic: item.topic.charAt(0).toUpperCase() + item.topic.slice(1),
        };
      });

      const w = 300;
      const h = 100;

      const svg = d3
        .select(myRef.current)
        .attr("width", w)
        .attr("height", h)
        .style("overflow", "visible")
        .style("margin-top", "75px");

      const xScale = d3
        .scaleBand()
        .domain(dataTest.map((val, i) => i))
        .range([0, w])
        .padding(0.5);
      const yScale = d3.scaleLinear().domain([0, h]).range([h, 0]);

      const xAxes = d3.axisBottom(xScale).ticks(hobbyTotal.length);
      const yAxes = d3.axisLeft(yScale).ticks(5);

      svg.append("g").call(xAxes).attr("transform", `translate(0,${h})`);
      svg.append("g").call(yAxes);

      const rect_width = 50;

      svg
        .selectAll(".pie")
        .data(hobbyTotal)
        .join("rect")
        .attr("x", (v, i) => 5 + i * (rect_width + 5))
        .attr("y", yScale)
        .attr("width", rect_width)
        .attr("height", (d) => d)
        .attr("padding-left", 100);
      // console.log(mapDataTopic);

      // const hobbyLabels = mapDataTopic.map(({ hobby }) => hobby);
      // const hobbyTotal = mapDataTopic.map(({ total }) => total);
      // setHobbyLabels(hobbyLabels);
      // setHobbyValue(hobbyTotal);
      // setDataHobby(mapDataTopic);
    }
  }, [dataInterest]);

  // hobi
  useEffect(() => {
    if (dataAdvHobby) {
      const res = dataAdvHobby;
      // console.log("data", res);
      // gender
      const resultsHBG = res.hobby_by_gender;
      // const dataMap = resultsHBG.map((({data})))
      // console.log("HBG", resultsHBG);
      // age
      const resultsHBA = Object.values(dataAdvHobby.hobby_by_age);
      // console.log("HBA", resultsHBA);
      // education
      const resultsHBE = Object.values(dataAdvHobby.hobby_by_edu);
      // console.log("HBE", resultsHBE);
      // status
      const resultsHBS = Object.values(dataAdvHobby.hobby_by_status);
      // console.log("HBS", resultsHBS);
      // work
      const resultsHBW = Object.values(dataAdvHobby.hobby_by_work);
      // console.log("HBW", resultsHBW);
      // place
      const resultsHBP = Object.values(dataAdvHobby.hobby_by_place);
      // console.log("HBP", resultsHBP);
    }
  }, [dataAdvHobby]);

  // Topik Diikuti
  useEffect(() => {
    if (dataAdvTopic && dataAdvTopic) {
      const res = dataAdvTopic;
      // console.log("data", res);
    }
  }, [dataAdvTopic]);

  useEffect(() => {}, []);

  // Modal Hoby
  const [showModalHobby, setshowModalHobby] = useState(false);
  const [modalTitle, setmodalTitle] = useState("");

  const modalHobi = (datas) => {
    setmodalTitle(datas);
    setshowModalHobby(!showModalHobby);
  };

  const modalHobiClose = (datas) => {
    setshowModalHobby(!showModalHobby);
  };

  const handleChangeHobi = (e) => {
    if (e.target.value === "Jenis Kelamin") {
      if (dataInterest && dataInterest.total_user) {
        // data gender
        setKategori(e.target.value);
        const resultsHobby = Object.values(dataInterest.hobby);
        console.log(resultsHobby);
      }
    } else if (e.target.value === "Usia") {
      setKategori(e.target.value);
      console.log("Usia", e.target.value);
    } else if (e.target.value === "Pendidikan") {
      setKategori(e.target.value);
      console.log("Pendidikan", e.target.value);
    } else if (e.target.value === "Status") {
      setKategori(e.target.value);
      console.log("Status", e.target.value);
    } else if (e.target.value === "Pekerjaan") {
      setKategori(e.target.value);
      console.log("Pekerjaan", e.target.value);
    } else {
      setKategori(e.target.value);
      console.log("Domisili", e.target.value);
    }
  };

  return (
    <Layout {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="row">
        {/* Hobby */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">Hobi</h6>
                  {loadingDataInterest ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              <div>
                {!loadingDataInterest ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Hoby</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataHobby.map((data, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                modalHobi(data.hobby);
                                setKategori("Jenis Kelamin");
                              }}
                            >
                              <td>{index + 1}</td>
                              <td>{data.hobby}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* bar */}
        <div className="col-6 col-lg-6 col-md-6">
          <div className="card">
            <div className="card-body px-3 py-4-5">
              <div className="row">
                <div className="col-md-8">
                  <h6 className="text-muted font-semibold">Test</h6>
                  {loadingDataInterest ? (
                    <label className="text-info">loading...</label>
                  ) : null}
                </div>
              </div>
              {/* data chart */}
              <div>
                <svg ref={myRef}></svg>
              </div>
              <div>
                {!loadingDataInterest ? (
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="text-center mt-3"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Hoby</th>
                          <th>{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataHobby.map((data, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                modalHobi(data.hobby);
                                setKategori("Jenis Kelamin");
                              }}
                            >
                              <td>{index + 1}</td>
                              <td>{data.hobby}</td>
                              <td>{data.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal show */}
      <Modal show={showModalHobby} onHide={modalHobiClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mb-3">
            <Form.Group>
              <Form.Control as="select" onChange={(e) => handleChangeHobi(e)}>
                <option defaultValue="" disabled>
                  Pilih Kategori
                </option>
                {dataKategori.map((data, idx) => {
                  return (
                    <option key={idx} value={data}>
                      {data}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Form>
          <div>
            <h6>
              {modalTitle} berdasarkan {kategori}
            </h6>
          </div>
          <div>
            {kategori === "Jenis Kelamin" ? (
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Jenis Kelamin</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Laki-laki</td>
                    <td>2123</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Perempuan</td>
                    <td>2123</td>
                  </tr>
                </tbody>
              </Table>
            ) : kategori === "Usia" ? (
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Usia</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>dibawah 13</td>
                    <td>2123</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>14-17</td>
                    <td>2123</td>
                  </tr>
                </tbody>
              </Table>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary">Close</Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default withAuth(translate(AdvancedAnalytic))([]);
