import React, { useState, useEffect, useCallback } from "react";
import { translate } from "react-switch-lang";
import { Helmet } from "react-helmet";
import { Loader } from "../../components";
import {
  useListRole,
  useNewUsers,
  useDetailUsers,
  useUpdateUsers,
  useDetailUser,
} from "../../store/actions/users";
import Layout from "../../layouts";
import withAuth from "../../hoc/withAuth";
import { useForm } from "react-hook-form";
import { Spinner } from "../../components";
import { useParams } from "react-router-dom";

const FormUser = (props) => {
  const { t } = props;
  const { id: userId } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [errorLoad, setErrorLoad] = useState(false);
  const [selectedKelamin, setSelectedKelamin] = useState("");

  const {
    register: registerUser,
    handleSubmit: handleUser,
    setValue: setUserForm,
    reset: resetUserForm,
  } = useForm();

  const [
    newUserAction,
    { data: dataNew, loading: loadingNew, error: errorNew },
  ] = useNewUsers();

  const [
    updateUserAction,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useUpdateUsers();

  const [
    detailAction,
    { data: dataUser, loading: loadingUser, error: errorUser },
  ] = useDetailUser();

  useEffect(() => {
    registerUser({ name: "kelamin" });
  }, [registerUser]);

  const loadDetailUser = async (id = userId) => {
    await detailAction(id);
  };
  useEffect(() => {
    if (userId) {
      loadDetailUser(userId);
    }
  }, []);

  const kelaminChange = useCallback(
    (event) => {
      setSelectedKelamin(event.target.value);
      setUserForm("kelamin", event.target.value);
    },
    [selectedKelamin]
  );

  useEffect(() => {
    if (dataNew) {
      resetUserForm();
      setSelectedKelamin("");
    }
  }, [dataNew]);

  useEffect(() => {
    if (userId && userId !== "1") {
      setLoadingData(loadingUser);
    }
  }, [loadingUser]);

  useEffect(() => {
    if (userId && errorUser) {
      setErrorLoad(true);
    }
  }, [errorUser]);

  useEffect(() => {
    if (userId && dataUser && dataUser.data && dataUser.data.gender) {
      setSelectedKelamin(dataUser.data.gender);
      setUserForm("kelamin", dataUser.data.gender);
    }
  }, [dataUser]);

  const submitHandler = async (data) => {
    if (userId) {
      await updateUserAction(userId, data);
    } else {
      await newUserAction(data);
    }
  };

  return (
    <Layout {...props}>
      <Helmet>
        <title>{t(userId ? "update" : "new", { name: t("user") })}</title>
      </Helmet>
      <div className="row">
        {userId && userId === "1" && (
          <div className="alert alert-danger dark" role="alert">
            {t("restricted")}
          </div>
        )}
        {userId && errorUser && (
          <div className="alert alert-danger dark" role="alert">
            {errorUser}
          </div>
        )}
        <div className="col-12">
          <Loader loading={loadingData} />
        </div>
        {!loadingData && !errorLoad && userId !== "1" && (
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="card-header">
                <h4>{t("user")}</h4>
              </div>
              <div className="card-body">
                {errorNew && typeof errorNew === "string" && (
                  <div className="alert alert-danger dark" role="alert">
                    {errorNew}
                  </div>
                )}
                {errorUpdate && typeof errorUpdate === "string" && (
                  <div className="alert alert-danger dark" role="alert">
                    {errorUpdate}
                  </div>
                )}
                {dataNew && typeof dataNew.message === "string" && (
                  <div className="alert alert-success dark" role="alert">
                    {dataNew.message}
                  </div>
                )}
                {dataUpdate && typeof dataUpdate.message === "string" && (
                  <div className="alert alert-success dark" role="alert">
                    {dataUpdate.message}
                  </div>
                )}
                <form
                  action="#"
                  method="post"
                  onSubmit={handleUser(submitHandler)}
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("name")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerUser}
                          className="form-control"
                          name="nama"
                          type="text"
                          required
                          defaultValue={
                            userId && dataUser && dataUser.data
                              ? dataUser.data.first_name +
                                  " " +
                                  dataUser.data.last_name ?? ""
                              : ""
                          }
                          placeholder={t("fullName")}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("email")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerUser}
                          className="form-control"
                          name="email"
                          type="email"
                          required
                          defaultValue={
                            userId && dataUser && dataUser.data
                              ? dataUser.data.email
                              : ""
                          }
                          placeholder={t("email")}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("phone")}</label>
                        <span className="text-danger">(*)</span>
                        <input
                          ref={registerUser}
                          className="form-control"
                          name="ponsel"
                          type="text"
                          defaultValue={
                            userId && dataUser && dataUser.data
                              ? dataUser.data.phone
                              : ""
                          }
                          placeholder={t("phone")}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("gender")}</label>
                        <span className="text-danger">(*)</span>
                        <select
                          name="kelamin"
                          value={selectedKelamin}
                          onChange={kelaminChange}
                          required
                          className="form-control"
                        >
                          <option value="" disabled>
                            {t("select", { name: t("gender") })}
                          </option>
                          <option value="l">{t("man")}</option>
                          <option value="p">{t("woman")}</option>
                        </select>
                      </div>
                    </div> */}

                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("password")}</label>
                        <input
                          ref={registerUser}
                          className="form-control"
                          name="password"
                          type="password"
                          required={userId ? false : true}
                          placeholder={t("password")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button
                        disabled={loadingNew || loadingUpdate}
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        <Spinner loading={loadingNew || loadingUpdate} />
                        {!loadingNew &&
                          !loadingUpdate &&
                          t(userId ? "update" : "add", { name: t("user") })}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default withAuth(translate(FormUser))(["admin"]);
