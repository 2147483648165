import { useApiHandler, fetcher } from "./index";
import useSWR from "swr";
import Api from "../connection";
import qs from "qs";

const dataForm = new FormData();

export const indexAddPoint = async (offset, size, search) => {
  const posts = await Api.get(
    `/manual-point?all=all&offset=${offset}&limit=${size}&search=${search}`
  );
  return posts;
};

export const detailAddPoint = async (id) => {
  const posts = await Api.get(
    `/manual-point/${id}`
  );
  return posts;
};

export const deletePoint = async (id) => {
  const posts = await Api.delete(`/manual-point/${id}`);
  return posts;
};

export const updatePoint = async (id, data) => {
  let datas = {
    user: data.user,
    description: data.description,
    point: data.point,
    tipe: data.tipe,
  };
  dataForm.append("user", data.user);
  dataForm.append("description", data.description);
  dataForm.append("point", data.point);
  dataForm.append("tipe", data.type);

  // const datass = qs.stringify(datas);
  // const posts = await Api.patch(`/manual-point/${id}`, dataForm);
  // return posts;
};

export const addPoint = async (data) => {
  dataForm.append("user", data.user);
  dataForm.append("description", data.description);
  dataForm.append("point", data.point);
  dataForm.append("tipe", data.type);

  const posts = await Api.post(`manual-point`, dataForm);
  return posts;
};

export const useAddPoint = () => {
  return useApiHandler(addPoint);
};

export const useIndexAddPoint = () => {
  return useApiHandler(indexAddPoint);
};

export const useDeletePoint = () => {
  return useApiHandler(deletePoint);
};

export const useUpdatePoint = () => {
  return useApiHandler(updatePoint);
};

export const useDetailPoint = () => {
  return useApiHandler(detailAddPoint);
};