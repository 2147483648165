import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useGetUser } from "../store/actions/me";
import { Loader } from "../components";
import { AppContext } from "../store/context";
import { setLanguage } from "react-switch-lang";
import { isAuthorizedArray } from "../helpers/index";

const withAuth = (Component) => (role) => {
  return (props) => {
    const { data, loading, error } = useGetUser();
    const user = data && data.data;

    useEffect(() => {
      if (user) {
        setLanguage(user.language);
        localStorage.setItem("lang", user.language);
      }
    }, [user]);
    if (loading) {
      return <Loader loading={loading} />;
    }
    if (error && typeof window !== "undefined") {
      return (
        <Route
          render={() => {
            return (
              <Redirect
                to={{
                  pathname: process.env.PUBLIC_URL + "/auth/login",
                  state: { from: props.location },
                }}
              />
            );
          }}
        />
      );
    } else {
      if (role && !isAuthorizedArray(user, role)) {
        return (
          <Route
            render={() => {
              return (
                <Redirect
                  to={{
                    pathname: process.env.PUBLIC_URL + "/",
                    state: { from: props.location },
                  }}
                />
              );
            }}
          />
        );
      }

      return <Component user={user} {...props} />;
    }
  };
};

export default withAuth;
