import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { MENUITEMS } from "../../data/menu";
import { Link } from "react-router-dom";
import { translate } from "react-switch-lang";
import { AppContext } from "../../store/context";
import { isAuthorizedArray } from "../../helpers/index";

const Sidebar = (props) => {
  const { t, user } = props;
  const [menusItem, setMenusItem] = useState([]);
  const userRole = user && user.role && user.role.name;
  const closeSidebar = () => {
    document.getElementById("sidebar").classList.toggle("active");
  };
  const currentUrl = window.location.pathname;
  const splitUrl = currentUrl.split("/");

  const updateMenuItem = useCallback(() => {
    const menuItem = MENUITEMS.map((item) => {
      let hasItem = false;
      if (item.items && item.items.length > 0) {
        hasItem = true;
      }
      let isActive = false;
      let isVisible = false;
      const splitItemUrl = item.url.split("/");
      if (!hasItem) {
        if (splitUrl[1] === splitItemUrl[1]) {
          isActive = true;
        }
        if (isAuthorizedArray(user, item.access)) {
          isVisible = true;
        }
        item.active = isActive;
        item.visible = isVisible;
        return item;
      } else {
        let isActive = 0;
        let isVisible = 0;
        item.items.map((subItem) => {
          let subActive = false;
          let subVisible = false;
          const splitSubItemUrl = subItem.url.split("/");
          if (splitSubItemUrl[1] === splitUrl[1]) {
            isActive = isActive + 1;
            subActive = true;
          }
          if (isAuthorizedArray(user, subItem.access)) {
            subVisible = true;
            isVisible = isVisible + 1;
          }
          subItem.visible = subVisible;
          subItem.active = subActive;
          return subItem;
        });
        if (isActive > 0) {
          item.active = true;
        } else {
          item.active = false;
          item.open = false;
        }
        if (isVisible > 0) {
          item.visible = true;
        } else {
          item.visible = false;
        }
        return item;
      }
    });
    setMenusItem(menuItem);
  }, [currentUrl, user]);

  const toggleMenu = useCallback(
    (items, index) => {
      const newMenu = [...menusItem];
      const menus = newMenu.map((item, itemIndex) => {
        if (item.items && item.items.length > 0) {
          if (itemIndex === index) {
            item.open = !item.open;
            item.active = !item.active;
          } else {
            item.active = false;
            item.open = false;
          }
          return item;
        } else {
          return item;
        }
      });
      setMenusItem(menus);
    },
    [menusItem]
  );

  useEffect(() => {
    updateMenuItem();
  }, [currentUrl, user]);
  return (
    <Fragment>
      <div id="sidebar">
        <div className="sidebar-wrapper active">
          <div className="sidebar-header">
            <div className="d-flex justify-content-between">
              <div className="logo">
                <Link to={`${process.env.PUBLIC_URL}/`}>
                  {/* <h4>{process.env.REACT_APP_NAME}</h4> */}
                </Link>
              </div>

              <div className="toggler">
                <a
                  href="#"
                  onClick={closeSidebar}
                  className="sidebar-hide d-xl-none d-block"
                >
                  <i className="bi bi-x bi-middle"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="sidebar-menu">
            <div className="text-center">
              <Link to={`${process.env.PUBLIC_URL}/`}>
                <img width="75" src={"/logo.png"} />
              </Link>
            </div>
            <ul className="menu">
              {menusItem.map((item, index) => {
                let hasItem = false;
                if (item.items && item.items.length > 0) {
                  hasItem = true;
                }
                if (!item.visible) {
                  return null;
                }
                if (!hasItem) {
                  return (
                    <li
                      key={index.toString()}
                      className={
                        item.active ? "sidebar-item active" : "sidebar-item"
                      }
                    >
                      <Link to={item.url} className="sidebar-link">
                        <i className={item.icon}></i>
                        <span>{t(item.menuTitle)}</span>
                      </Link>
                    </li>
                  );
                } else {
                  return (
                    <li
                      key={index.toString()}
                      className={
                        item.active
                          ? "sidebar-item has-sub active"
                          : "sidebar-item has-sub"
                      }
                    >
                      <a
                        href="#"
                        onClick={() => {
                          toggleMenu(item, index);
                        }}
                        className="sidebar-link"
                      >
                        <i className={item.icon}></i>
                        <span>{t(item.menuTitle)}</span>
                      </a>

                      <ul
                        className={item.active ? "submenu active" : "submenu"}
                      >
                        {item.items.map((itemSub, indexSub) => {
                          if (!itemSub.visible) {
                            return null;
                          }
                          return (
                            <li
                              className={`${
                                itemSub.active
                                  ? "submenu-item active"
                                  : "submenu-item"
                              }`}
                              key={indexSub.toString()}
                            >
                              <Link to={itemSub.url}>
                                {t(itemSub.menuTitle)}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default translate(Sidebar);
