import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { translate, setLanguage } from "react-switch-lang";

const Error404 = (props) => {
  useEffect(() => {
    setLanguage(localStorage.getItem("lang") || process.env.REACT_APP_LANGUAGE);
  }, []);

  const { t } = props;
  return (
    <Fragment>
      <div id="error">
        <div className="error-page container">
          <div className="col-md-8 col-12 offset-md-2">
            {/* <img className="img-error" src="404.png" alt="Not Found" /> */}
            <div className="text-center">
              <h1 className="error-title">404</h1>
              <p className="fs-5 text-gray-600">{t("404_message")}</p>
              <Link to="/" className="btn btn-lg btn-outline-primary mt-3">
                {t("home")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default translate(Error404);
